import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const SavingSVG = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M4 20V18H6.725C5.875 17.2667 5.20833 16.3833 4.725 15.35C4.24167 14.3167 4 13.2 4 12C4 10.1333 4.56667 8.4875 5.7 7.0625C6.83333 5.6375 8.26667 4.7 10 4.25V6.35C8.83333 6.76667 7.875 7.4875 7.125 8.5125C6.375 9.5375 6 10.7 6 12C6 12.9 6.17917 13.7292 6.5375 14.4875C6.89583 15.2458 7.38333 15.9 8 16.45V14H10V20H4ZM15 20C14.1667 20 13.4583 19.7083 12.875 19.125C12.2917 18.5417 12 17.8333 12 17C12 16.2 12.275 15.5125 12.825 14.9375C13.375 14.3625 14.05 14.0583 14.85 14.025C15.1333 13.425 15.5542 12.9375 16.1125 12.5625C16.6708 12.1875 17.3 12 18 12C18.8833 12 19.6458 12.2875 20.2875 12.8625C20.9292 13.4375 21.3167 14.15 21.45 15C22.15 15 22.75 15.2417 23.25 15.725C23.75 16.2083 24 16.7917 24 17.475C24 18.175 23.7583 18.7708 23.275 19.2625C22.7917 19.7542 22.2 20 21.5 20H15ZM17.9 11C17.7833 10.3167 17.5583 9.68333 17.225 9.1C16.8917 8.51667 16.4833 8 16 7.55V10H14V4H20V6H17.275C17.9917 6.63333 18.5792 7.375 19.0375 8.225C19.4958 9.075 19.7917 10 19.925 11H17.9ZM15 18H21.5C21.6333 18 21.75 17.95 21.85 17.85C21.95 17.75 22 17.6333 22 17.5C22 17.3667 21.95 17.25 21.85 17.15C21.75 17.05 21.6333 17 21.5 17H19.75V15.75C19.75 15.2667 19.5792 14.8542 19.2375 14.5125C18.8958 14.1708 18.4833 14 18 14C17.5167 14 17.1042 14.1708 16.7625 14.5125C16.4208 14.8542 16.25 15.2667 16.25 15.75V16H15C14.7167 16 14.4792 16.0958 14.2875 16.2875C14.0958 16.4792 14 16.7167 14 17C14 17.2833 14.0958 17.5208 14.2875 17.7125C14.4792 17.9042 14.7167 18 15 18Z'
        fill='#545454'
      />
    </svg>
  );
};

const SavingIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={SavingSVG} {...props} />;
};

export default SavingIcon;
