import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import { client } from 'store/services/client';
import {
  activateIzelActionParamTtypes,
  assignItemsParamTypes,
  assignSubItemsParamTypes,
  assignSubjectParamTypes,
  createSubjectSummaryParamTypes,
  deactivateStudentParamTypes,
  getItemsToAssignParamTypes,
  getReportListParamTypes,
  getStudentInfoParamTypes,
  getStudentReportParamTypes,
  getStudentsParams,
  getSubItemsToAssignParamTypes,
  getSubjectsToAssignParamTypes,
  grantAccessActionParamTypes,
  reassignItemParamTypes,
  reassignSubItemParamTypes,
  reassignSubjectParamTypes,
  selfEvaluateParamTypes,
} from 'store/actions/students.actions';

export const getStudentsService = (params: getStudentsParams): [ApiCall, string] => {
  const groupQuery = params.groupIds?.map((groupId) => `groupIds=${groupId}&`).join('');
  const schoolYears = params.schoolYears
    ?.map((schoolYear) => `schoolYears=${schoolYear}&`)
    .join('');
  return [
    client.get,
    `/students?page=${params.page}&size=${params.size}${
      params.name.length > 2 ? `&name=${params.name}` : ''
    }&${schoolYears}${groupQuery}${params.active ? 'active=true' : ''}&${
      params.sort ? params.sort : ''
    }`,
  ];
};

export const createStudentService = (params: {
  name: string;
  surname: string;
  gender: string;
  schoolYear: number;
  birthDate: string;
  email?: string | null;
}): [
  ApiCallWithParams,
  string,
  {
    name: string;
    surname: string;
    gender: string;
    schoolYear: number;
    birthDate: string;
    email?: string | null;
  },
] => [client.post, '/students', params];

export const updateStudentService = (params: {
  id: number;
  name: string;
  surname: string;
  gender: string;
  schoolYear: number;
  birthDate: string;
  email?: string | null;
  parentConsent: boolean;
  studentConsent: boolean;
}): [
  ApiCallWithParams,
  string,
  {
    name: string;
    surname: string;
    gender: string;
    schoolYear: number;
    birthDate: string;
    email?: string | null;
    parentConsent: boolean;
    studentConsent: boolean;
  },
] => [
  client.put,
  `/students/${params.id}`,
  {
    name: params.name,
    surname: params.surname,
    gender: params.gender,
    schoolYear: params.schoolYear,
    birthDate: params.birthDate,
    email: params.email,
    parentConsent: params.parentConsent,
    studentConsent: params.studentConsent,
  },
];

export const getSubjectsToAssignService = (
  params: getSubjectsToAssignParamTypes,
): [ApiCall, string] => [client.get, `/students/${params.studentId}/subjects-to-assign`];

export const getItemsToAssignService = (params: getItemsToAssignParamTypes): [ApiCall, string] => [
  client.get,
  `/students/${params.studentId}/subjects/${params.subjectId}/items-to-assign`,
];

export const getSubItemsToAssignService = (
  params: getSubItemsToAssignParamTypes,
): [ApiCall, string] => [
  client.get,
  `/students/${params.studentId}/subjects/${params.subjectId}/items/${params.itemId}/sub-items-to-assign`,
];

export const assignSubjectService = (
  params: assignSubjectParamTypes,
): [ApiCallWithParams, string, number[]] => [
  client.post,
  `/students/${params.studentId}/subjects`,
  params.selectedSubjects,
];

export const assignItemsService = (
  params: assignItemsParamTypes,
): [ApiCallWithParams, string, number[]] => [
  client.post,
  `/students/${params.studentId}/subjects/${params.subjectId}/items`,
  params.selectedItems,
];

export const assignSubItemsService = (
  params: assignSubItemsParamTypes,
): [ApiCallWithParams, string, number[]] => [
  client.post,
  `/students/${params.studentId}/subjects/${params.subjectId}/items/${params.itemId}/sub-items`,
  params.selectedSubItems,
];

export const reassignSubjectService = (params: reassignSubjectParamTypes): [ApiCall, string] => [
  client.delete,
  `/students/${params.studentId}/subjects?subjectId=${params.subjectId}`,
];

export const reassignItemService = (params: reassignItemParamTypes): [ApiCall, string] => [
  client.delete,
  `/students/${params.studentId}/subjects/${params.subjectId}/items?itemId=${params.itemId}`,
];

export const reassignSubItemService = (params: reassignSubItemParamTypes): [ApiCall, string] => [
  client.delete,
  `/students/${params.studentId}/subjects/${params.subjectId}/items/${params.itemId}/sub-items?subItemId=${params.subItemId}`,
];

export const getStudentInfoService = (params: getStudentInfoParamTypes): [ApiCall, string] => [
  client.get,
  `/students/${params.studentId}`,
];

export const getStudentsReportService = (params: getStudentReportParamTypes): [ApiCall, string] => [
  client.get,
  `/students/current/izel?page=${params.page}&size=25`,
];

export const selfEvaluateService = (
  params: selfEvaluateParamTypes,
): [
  ApiCallWithParams,
  string,
  { subItemId: number; assessmentScaleValueId: number; izelId?: number },
] => [
  client.post,
  params.studentId
    ? `students/${params.studentId}/self-evaluations`
    : 'students/current/evaluations',
  {
    subItemId: params.subItemId,
    assessmentScaleValueId: params.assessmentScaleValueId,
    izelId: params.izelId,
  },
];

export const createSubjectSummaryService = (
  params: createSubjectSummaryParamTypes,
): [ApiCallWithParams, string, { text: string | null; subjectId: number; izelId: number }] => [
  client.post,
  `students/${params.studentId}/personal-text`,
  { text: params.text, subjectId: params.subjectId, izelId: params.izelId },
];

export const deactivateStudentService = (
  params: deactivateStudentParamTypes,
): [ApiCall, string] => [client.delete, `/students/${params.id}/deactivate`];

export const reactivateStudentService = (
  params: deactivateStudentParamTypes,
): [ApiCall, string] => [client.post, `/students/${params.id}/activate`];

export const grantAccessService = (
  params: grantAccessActionParamTypes,
): [ApiCallWithParams, string, grantAccessActionParamTypes] => [
  client.post,
  `students/${params.studentId}/access`,
  params,
];

export const removeAccessService = (params: grantAccessActionParamTypes): [ApiCall, string] => [
  client.delete,
  `/students/${params.studentId}/access`,
];

export const resendLinkService = (
  params: grantAccessActionParamTypes,
): [ApiCallWithParams, string, grantAccessActionParamTypes] => [
  client.post,
  `students/${params.studentId}/send-app-link-email`,
  params,
];

export const getReportListService = (params: getReportListParamTypes): [ApiCall, string] => [
  client.get,
  `/students/${params.id}/school-years`,
];

export const activateStudentIzelService = (
  params: activateIzelActionParamTtypes,
): [ApiCall, string] => [client.post, `/students/${params.id}/izel/${params.izelId}/activate`];

export const deActivateStudentIzelService = (
  params: activateIzelActionParamTtypes,
): [ApiCall, string] => [client.delete, `/students/${params.id}/izel/${params.izelId}/deactivate`];
