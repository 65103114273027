import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { DatePickerProps } from 'antd';
import DatePicker from 'UI/DatePicker';
import { Text } from 'UI/Typography';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import dayjs from 'dayjs';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import {
  createPracticeAction,
  practiceTypes,
  updatePracticeAction,
} from 'store/actions/report.actions';
import { useParams } from 'react-router-dom';

const { TextArea } = Input;

const PracticeModal = ({
  managePracticeVisible,
  toggleManagePractice,
  editablePractice,
  handleChangeEditablePracticeId,
  activeReportId,
  currentPage,
}: {
  managePracticeVisible: boolean;
  toggleManagePractice: (arg1: boolean) => void;
  editablePractice: practiceTypes | null;
  handleChangeEditablePracticeId: (arg1: null | number) => void;
  activeReportId: number;
  currentPage: number;
}) => {
  const [startDate, setStartDate] = useState('');
  const [endDate, setEndDate] = useState('');
  const [practiceName, setPracticeName] = useState('');
  const [practiceCity, setPracticeCity] = useState('');
  const [practiceDesc, setPracticeDesc] = useState('');
  const dispatch = useDispatch();
  const { studentId } = useParams();

  const isEditMode = useMemo(() => {
    return !!editablePractice;
  }, [editablePractice]);

  const okButtonDisabled = useMemo(() => {
    return !startDate || !endDate || !practiceDesc || !practiceName || !practiceCity;
  }, [endDate, practiceCity, practiceDesc, practiceName, startDate]);

  const changeStartDate = useCallback((value: DatePickerProps['value'], dateString: string) => {
    setStartDate(dateString);
  }, []);

  const changeEndDate = useCallback((value: DatePickerProps['value'], dateString: string) => {
    setEndDate(dateString);
  }, []);

  const changePracticeName = useCallback((value: string) => {
    setPracticeName(value);
  }, []);

  const changePracticeCity = useCallback((value: string) => {
    setPracticeCity(value);
  }, []);

  const changePracticeDesc = useCallback((value: string) => {
    setPracticeDesc(value);
  }, []);

  const handleResetModal = useCallback(() => {
    setStartDate('');
    setEndDate('');
    setPracticeName('');
    setPracticeCity('');
    setPracticeDesc('');
    toggleManagePractice(false);
    handleChangeEditablePracticeId(null);
  }, [handleChangeEditablePracticeId, toggleManagePractice]);

  const handleCreatePractice = useCallback(() => {
    if (isEditMode) {
      dispatch(
        updatePracticeAction({
          id: Number(editablePractice?.id),
          studentId: Number(studentId),
          startDate,
          endDate,
          city: practiceCity,
          organization: practiceName,
          description: practiceDesc,
          izelId: activeReportId,
          page: currentPage - 1,
        }),
      );
    } else {
      dispatch(
        createPracticeAction({
          studentId: Number(studentId),
          startDate,
          endDate,
          city: practiceCity,
          organization: practiceName,
          description: practiceDesc,
          izelId: activeReportId,
          page: currentPage - 1,
        }),
      );
    }
    handleResetModal();
  }, [
    isEditMode,
    handleResetModal,
    dispatch,
    editablePractice?.id,
    studentId,
    startDate,
    endDate,
    practiceCity,
    practiceName,
    practiceDesc,
    activeReportId,
    currentPage,
  ]);

  useEffect(() => {
    if (isEditMode) {
      setEndDate(editablePractice?.endDate || '');
      setStartDate(editablePractice?.startDate || '');
      setPracticeName(editablePractice?.organization || '');
      setPracticeCity(editablePractice?.city || '');
      setPracticeDesc(editablePractice?.description || '');
    }
  }, [editablePractice, isEditMode]);

  return (
    <Modal
      destroyOnClose
      centered
      title='Praktikum'
      open={managePracticeVisible}
      onCancel={handleResetModal}
      okText='Speichern'
      cancelText='Abbrechen'
      onOk={handleCreatePractice}
      okDisabled={okButtonDisabled}
    >
      <div className={styles.startDateLabel}>
        <Text level={1} bold>
          Startdatum
        </Text>
      </div>
      <DatePicker
        value={startDate ? dayjs(startDate, 'DD.MM.YYYY') : undefined}
        inputReadOnly
        placeholder='tt.mm.jjjj'
        onChange={changeStartDate}
        style={{ width: '100%' }}
        format='DD.MM.YYYY'
      />
      <div className={styles.endDateLabel}>
        <Text level={1} bold>
          Enddatum
        </Text>
      </div>
      <DatePicker
        value={endDate ? dayjs(endDate, 'DD.MM.YYYY') : undefined}
        inputReadOnly
        placeholder='tt.mm.jjjj'
        onChange={changeEndDate}
        style={{ width: '100%' }}
        format='DD.MM.YYYY'
      />
      <div className={styles.endDateLabel}>
        <Text level={1} bold>
          Praktikumsbetrieb
        </Text>
      </div>
      <Input
        showCount
        maxLength={100}
        value={practiceName}
        placeholder='Name'
        onChange={(e: ChangeEvent<HTMLInputElement>) => changePracticeName(e.target.value)}
      />
      <div className={styles.endDateLabel}>
        <Text level={1} bold>
          Ort
        </Text>
      </div>
      <Input
        showCount
        maxLength={50}
        value={practiceCity}
        placeholder='Stadt'
        onChange={(e: ChangeEvent<HTMLInputElement>) => changePracticeCity(e.target.value)}
      />
      <div className={styles.endDateLabel}>
        <Text level={1} bold>
          Berufsfeld/Tätigkeitsbereich
        </Text>
      </div>
      <TextArea
        autoSize
        showCount
        maxLength={200}
        value={practiceDesc}
        placeholder='Berufsfeld/Tätigkeitsbereich'
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => changePracticeDesc(e.target.value)}
      />
    </Modal>
  );
};

export default PracticeModal;
