import { useLocation, Navigate, Outlet } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { getAuthDataSelector } from 'store/selectors/users.selectors';

type RoutGateTypes = {
  user: { [key: string]: string };
  allowedRoles: string[];
};

const RouteGate = ({ user, allowedRoles }: RoutGateTypes) => {
  const location = useLocation();
  const authUser = useSelector(getAuthDataSelector);

  return allowedRoles.find((role) => user.role.includes(role)) && authUser ? (
    <Outlet />
  ) : user?.name && !authUser ? (
    <Navigate to='/login' state={{ from: location }} replace />
  ) : (
    <Navigate to='/login' state={{ from: location }} replace />
  );
};

export default RouteGate;
