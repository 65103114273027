import { createSelector } from 'reselect';
import { assessmentScalesTypes, itemTypes } from 'store/actions/items.actions';
import { subjectTypes } from 'store/actions/subjects.actions';

const appState = (state: {
  itemsReducer: {
    items: Array<itemTypes> | [];
    itemsCount: number;
    assessmentScales: assessmentScalesTypes | [];
    singleSubject: subjectTypes | null;
  };
}): {
  items: Array<itemTypes> | [];
  itemsCount: number;
  assessmentScales: assessmentScalesTypes | [];
  singleSubject: subjectTypes | null;
} => state.itemsReducer;

export const getItemsSelector = createSelector(appState, (state) => state.items);
export const getSingleSubjectSelector = createSelector(appState, (state) => state.singleSubject);
export const getItemsCountSelector = createSelector(appState, (state) => state.itemsCount);
export const getAssessmentScalesSelector = createSelector(
  appState,
  (state) => state.assessmentScales,
);
