import { useDispatch, useSelector } from 'react-redux';
import { getStudentReportSelector } from 'store/selectors/students.selectors';
import React, { useCallback, useEffect, useState } from 'react';
import { getStudentReportAction, selfEvaluateAction } from 'store/actions/students.actions';
import styles from './styles.module.css';
import { Text, Title } from '../../UI/Typography';
import { Col, Collapse, RadioChangeEvent, Row } from 'antd';
import cc from 'classcat';
import { primary5 } from '../../colors';
import RadioGroup from '../../UI/RadioGroup';
import OfflineIcon from '../../UI/Icons/OfflineIcon';
import SavingIcon from '../../UI/Icons/SavingIcon';
import SavedIcon from '../../UI/Icons/SavedIcon';
import Pagination from '../../UI/Pagination';
import LinkIcon from '../../UI/Icons/LinkIcon';

const { Panel } = Collapse;

const SelfEvaluation = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [saveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const studentReport = useSelector(getStudentReportSelector) as any;

  const handleEvaluateSubItem = useCallback(
    (e: RadioChangeEvent, subItemId: number) => {
      setSaveLoading(true);
      dispatch(
        selfEvaluateAction({
          subItemId,
          assessmentScaleValueId: Number(e.target.value),
          onSuccess: () => setSaveLoading(false),
          page: currentPage - 1,
        }),
      );
    },
    [currentPage, dispatch],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(getStudentReportAction({ page: page - 1 }));
    },
    [dispatch],
  );

  useEffect(() => {
    dispatch(getStudentReportAction({ page: 0 }));
  }, [dispatch]);

  return (
    <div>
      <div className={styles.evalInner}>
        <div className={styles.headWrapper}>
          <div>
            <div className={styles.nameWrapper}>
              <Title
                level={1}
              >{`Selbstbewertung für ${studentReport?.surname}, ${studentReport?.name}`}</Title>
            </div>
            <div>
              <Text level={1} bold>{`${studentReport?.schoolYear}. Jahrgangsstufe`}</Text>
            </div>
          </div>
          <div>
            {!navigator.onLine ? (
              <div className={styles.statusWrapper}>
                <OfflineIcon /> <Text level={1}>Daten nicht gespeichert (kein Internet?)</Text>
              </div>
            ) : saveLoading ? (
              <div className={styles.statusWrapper}>
                <SavingIcon /> <Text level={1}>Änderungen speichern</Text>
              </div>
            ) : (
              <div className={styles.statusWrapper}>
                <SavedIcon /> <Text level={1}>Alle Änderungen sind gespeichert</Text>
              </div>
            )}
          </div>
        </div>
        <Collapse className={styles.collapseWrapper}>
          {studentReport?.subjects?.map((subject: any) => {
            return (
              <Panel
                className={cc([{ [styles.collapseOutline]: !subject.selfEvaluationFilled }])}
                key={subject.id}
                showArrow={!!subject?.items?.length}
                header={
                  <Text level={2} bold>
                    {subject.name}
                  </Text>
                }
              >
                {subject?.items?.length ? (
                  <Collapse
                    bordered={false}
                    ghost
                    className={styles.innerCollapse}
                    destroyInactivePanel
                  >
                    {subject.items?.map((item: any) => {
                      return (
                        <Panel
                          showArrow={!!item?.subItems?.length}
                          className={cc([
                            {
                              [styles.notFilledCollapse]:
                                !item.selfEvaluationFilled && item?.subItems?.length,
                            },
                          ])}
                          key={item.id}
                          header={
                            <Text level={2} bold>
                              {item.alias}
                            </Text>
                          }
                        >
                          {item?.subItems?.length ? (
                            <Row style={{ backgroundColor: primary5 }}>
                              <Col span={12} offset={12}>
                                <div
                                  style={{
                                    display: 'flex',
                                    textAlign: 'right',
                                    justifyContent: 'space-between',
                                    padding: '17px 0',
                                  }}
                                >
                                  {item.assessmentScaleValues?.map((value: any) => {
                                    if (value.id === 12 || value.id === 11) {
                                      return;
                                    }
                                    return (
                                      <div
                                        style={{ width: '25%', textAlign: 'center' }}
                                        key={value.id}
                                      >
                                        {value.scaleValue}
                                      </div>
                                    );
                                  })}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                          {item.subItems?.map((subItem: any) => {
                            const evalScaleValuesInitial = item.assessmentScaleValues.map(
                              (scale: any) => {
                                return {
                                  key: scale.id,
                                  label: '',
                                };
                              },
                            );
                            const evalScaleValues = evalScaleValuesInitial
                              .filter((val: { key: number }) => val.key !== 12)
                              .filter((v: { key: number }) => v.key !== 11);
                            return (
                              <Row
                                key={subItem.id}
                                className={cc([
                                  styles.openReviewBottomBorder,
                                  {
                                    [styles.notFilledSubItem]: !subItem.selfEvaluatedScaleValueId,
                                  },
                                ])}
                                align='middle'
                              >
                                <Col span={12} style={{ paddingLeft: '34px' }}>
                                  <div>
                                    <Text level={2} bold>
                                      {subItem.name}
                                    </Text>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <RadioGroup
                                    defaultValue={subItem.selfEvaluatedScaleValueId}
                                    className={styles.radioGroup}
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                    groupOptions={evalScaleValues}
                                    onChange={(e: RadioChangeEvent) =>
                                      handleEvaluateSubItem(e, subItem.id)
                                    }
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </Panel>
                      );
                    })}
                  </Collapse>
                ) : null}
              </Panel>
            );
          })}
        </Collapse>
        <div className={styles.paginationWrapper}>
          <Pagination
            pageSize={25}
            current={currentPage}
            onChange={(page: number) => handlePageChange(page)}
            total={studentReport?.count}
          />
        </div>
      </div>
      <div className={styles.footer}>
        <div>
          <a href='/impressum' target='_blank' rel='noreferrer nofollow noopener'>
            Impressum <LinkIcon />
          </a>
        </div>
        <div>
          <a href='/datenschutzhinweise' target='_blank' rel='noreferrer nofollow noopener'>
            Datenschutzhinweise <LinkIcon />
          </a>
        </div>
        <div>
          <a href='/endnutzerlizenzbedingungen' target='_blank' rel='noreferrer nofollow noopener'>
            Endnutzerlizenzbedingungen <LinkIcon />
          </a>
        </div>
      </div>
    </div>
  );
};

export default SelfEvaluation;
