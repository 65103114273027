import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const ArrowDownSVG = () => {
  return (
    <svg width='12' height='8' viewBox='0 0 12 8' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M10.59 0.0898438L6 4.66984L1.41 0.0898438L0 1.49984L6 7.49984L12 1.49984L10.59 0.0898438Z'
        fill='#545454'
      />
    </svg>
  );
};

const ArrowDownIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={ArrowDownSVG} {...props} />;
};

export default ArrowDownIcon;
