import { getReportAction, saveInterimEvaluationsAction } from 'store/actions/report.actions';
import { getReportSelector } from 'store/selectors/report.selectors';
import { Col, Collapse, RadioChangeEvent, Row } from 'antd';
import React, { ChangeEvent, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation, useParams } from 'react-router-dom';
import RadioGroup from 'UI/RadioGroup';
import { Text, Title } from 'UI/Typography';
import cc from 'classcat';
import { primary5 } from '../../colors';
import styles from './styles.module.css';
import Breadcrumb from 'components/Breadcrumb';
import PlusIcon from '../../UI/Icons/PlusIcon';
import CollapseHeader from './__partials/CollapseHeader';
import AddObservationModal from 'pages/InterimReport/__partials/AddObservationModal';
import Button from '../../UI/Button';
import Pagination from '../../UI/Pagination';

const { Panel } = Collapse;

const InterimReport = () => {
  const [observationModalVisible, setObservationModalVisible] = useState(false);
  const [subjectId, setSubjectId] = useState<number | null>(null);
  const [itemId, setItemId] = useState<number | null>(null);
  const [subItemId, setSubItemId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [observationType, setObservationType] = useState<
    'student' | 'subject' | 'item' | 'subItem' | null
  >(null);
  const [breadCrumbValue, setBreadCrumbValue] = useState('');
  const [selectedScales, setSelectedScales] = useState<
    Array<{ subItemId: number; assessmentScaleValueId: number }>
  >([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const myElementRef = useRef<null>(null);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const report = useSelector(getReportSelector);
  const location = useLocation();

  const toggleObservationModal = useCallback((value: boolean) => {
    if (!value) {
      setSubjectId(null);
      setSubItemId(null);
      setItemId(null);
      setSubjectId(null);
      setBreadCrumbValue('');
    }
    setObservationModalVisible(value);
  }, []);

  const handleViewStudentObservation = useCallback(
    (breadCrumb: string) => {
      toggleObservationModal(true);
      setBreadCrumbValue(breadCrumb);
      setObservationType('student');
    },
    [toggleObservationModal],
  );

  const handleViewSubjectObservation = useCallback(
    (e: ChangeEvent<HTMLDivElement>, subjectId: number | null, breadCrumb: string) => {
      e.stopPropagation();
      setBreadCrumbValue(breadCrumb);
      toggleObservationModal(true);
      setObservationType('subject');
      setSubjectId(subjectId);
    },
    [toggleObservationModal],
  );

  const handleViewItemObservation = useCallback(
    (
      e: ChangeEvent<HTMLDivElement>,
      subjectId: number | null,
      itemId: number | null,
      breadCrumb: string,
    ) => {
      e.stopPropagation();
      setBreadCrumbValue(breadCrumb);
      toggleObservationModal(true);
      setObservationType('item');
      setSubjectId(subjectId);
      setItemId(itemId);
    },
    [toggleObservationModal],
  );

  const handleViewSubItemObservation = useCallback(
    (
      subjectId: number | null,
      itemId: number | null,
      subItemId: number | null,
      breadCrumb: string,
    ) => {
      setBreadCrumbValue(breadCrumb);
      toggleObservationModal(true);
      setObservationType('subItem');
      setSubjectId(subjectId);
      setItemId(itemId);
      setSubItemId(subItemId);
    },
    [toggleObservationModal],
  );

  const handleSelectInterimEvaluation = useCallback(
    (e: RadioChangeEvent, id: number) => {
      if (!selectedScales.find((value) => value.subItemId === id)?.assessmentScaleValueId) {
        const updatedScales = [...selectedScales];
        updatedScales.push({ subItemId: id, assessmentScaleValueId: e.target.value });
        setSelectedScales(updatedScales);
      } else {
        const renewedScales = selectedScales.map((scale) =>
          scale.subItemId === id
            ? { ...scale, assessmentScaleValueId: e.target.value }
            : { ...scale },
        );
        setSelectedScales(renewedScales);
      }
    },
    [selectedScales],
  );

  const handleDeselectScales = useCallback(() => {
    setSelectedScales([]);
  }, []);

  const handleSaveEvaluations = useCallback(() => {
    dispatch(saveInterimEvaluationsAction({ id: Number(studentId), selectedScales }));
    setSelectedScales([]);
  }, [dispatch, selectedScales, studentId]);

  const handleScrollPosition = useCallback(() => {
    if (myElementRef.current) {
      const elTop: { scrollTop: number } = myElementRef.current;
      if (
        (elTop.scrollTop === 0 && scrollPosition !== 0) ||
        (elTop.scrollTop !== 0 && scrollPosition === 0)
      ) {
        setScrollPosition(elTop.scrollTop);
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    dispatch(getReportAction({ id: Number(studentId), page: currentPage - 1, size: 10 }));
  }, [currentPage, dispatch, studentId]);

  return (
    <div className={styles.reportContainer}>
      <div className={styles.wrapper}>
        <div
          className={cc([styles.wrapperInner, { [styles.wrapperScrolled]: scrollPosition > 0 }])}
        >
          <div className={styles.breadcrumbWrapper}>
            <Breadcrumb
              items={[
                { title: 'Schüler/Schülerinnen', url: `/students${location.search}` },
                { title: 'Beobachtung und Zwischenbewertung', url: '' },
              ]}
            />
          </div>
          <div className={styles.titleWrapper}>
            <Title level={1}>{`${report.surname}, ${report.name} Dokumentation`}</Title>
          </div>
          <div
            className={styles.personalReview}
            onClick={() => handleViewStudentObservation(`${report.surname}, ${report.name}`)}
          >
            <span>
              <PlusIcon className='primary-40' />
            </span>
            <Text level={2} bold className='primary-40'>
              Schüler/Schülerinnen persönliche Beobachtungen hinzufügen
            </Text>
          </div>
        </div>
        <div className={styles.titleWrapper} onScroll={handleScrollPosition} ref={myElementRef}>
          <Collapse className={styles.collapseWrapper}>
            {report.subjects?.map((subject) => {
              return (
                <Panel
                  key={subject.id}
                  header={
                    <CollapseHeader
                      name={subject.name}
                      handleViewSubjectObservation={(e: ChangeEvent<HTMLDivElement>) =>
                        handleViewSubjectObservation(
                          e,
                          subject.id,
                          `${report.surname}, ${report.name} / ${subject.name}`,
                        )
                      }
                    />
                  }
                >
                  <Collapse
                    bordered={false}
                    ghost
                    className={styles.innerCollapse}
                    destroyInactivePanel
                  >
                    {subject.items?.map((item) => {
                      return (
                        <Panel
                          showArrow={!!item?.subItems?.length}
                          key={item.id}
                          header={
                            <CollapseHeader
                              handleViewSubjectObservation={(e: ChangeEvent<HTMLDivElement>) =>
                                handleViewItemObservation(
                                  e,
                                  subject.id,
                                  item.id,
                                  `${report.surname}, ${report.name} / ${subject.name} / ${item.name}`,
                                )
                              }
                              name={item.name}
                              description={item.alias}
                            />
                          }
                        >
                          {item?.subItems?.length ? (
                            <Row style={{ backgroundColor: primary5 }}>
                              <Col span={12} offset={12}>
                                <div
                                  style={{
                                    display: 'flex',
                                    textAlign: 'right',
                                    justifyContent: 'space-between',
                                    padding: '17px 0',
                                  }}
                                >
                                  {item.assessmentScaleValues?.map((value) => {
                                    if (value.id === 12 || value.id === 11) {
                                      return;
                                    }
                                    return (
                                      <div
                                        style={{ width: '25%', textAlign: 'center' }}
                                        key={value.id}
                                      >
                                        {value.scaleValue}
                                      </div>
                                    );
                                  })}
                                </div>
                              </Col>
                            </Row>
                          ) : null}
                          {item.subItems?.map((subItem) => {
                            const evalScaleValuesInitial = item.assessmentScaleValues.map(
                              (scale) => {
                                return {
                                  key: scale.id,
                                  label: '',
                                };
                              },
                            );
                            const evalScaleValues = evalScaleValuesInitial
                              .filter((val: { key: number }) => val.key !== 12)
                              .filter((v: { key: number }) => v.key !== 11);
                            return (
                              <Row
                                key={subItem.id}
                                className={cc([styles.openReviewBottomBorder])}
                                align='middle'
                              >
                                <Col span={12} style={{ paddingLeft: '34px' }}>
                                  <div>
                                    <Text level={2} bold>
                                      {subItem.name}
                                    </Text>
                                  </div>
                                  <div
                                    className={styles.openReviewMargin}
                                    onClick={() =>
                                      handleViewSubItemObservation(
                                        subject.id,
                                        item.id,
                                        subItem.id,
                                        `${report.surname}, ${report.name} / ${subject.name} / ${item.name} / ${subItem.name}`,
                                      )
                                    }
                                  >
                                    <span>
                                      <PlusIcon className='primary-40' />
                                    </span>
                                    <Text level={2} bold className='primary-40'>
                                      Beobachtungen hinzufügen
                                    </Text>
                                  </div>
                                </Col>
                                <Col span={12}>
                                  <RadioGroup
                                    value={
                                      selectedScales.find((scale) => scale.subItemId === subItem.id)
                                        ?.assessmentScaleValueId
                                    }
                                    className={styles.radioGroup}
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                    groupOptions={evalScaleValues}
                                    onChange={(e: RadioChangeEvent) =>
                                      handleSelectInterimEvaluation(e, subItem.id)
                                    }
                                  />
                                </Col>
                              </Row>
                            );
                          })}
                        </Panel>
                      );
                    })}
                  </Collapse>
                </Panel>
              );
            })}
          </Collapse>
          <div className={styles.paginationWrapper}>
            <Pagination
              pageSize={25}
              current={currentPage}
              onChange={(page: number) => setCurrentPage(page)}
              total={report?.count}
            />
          </div>
        </div>
      </div>
      <div className={styles.footerSubmit}>
        <Button type='default' onClick={handleDeselectScales}>
          Abbrechen
        </Button>
        <Button type='primary' onClick={handleSaveEvaluations} disabled={!selectedScales.length}>
          Speichern
        </Button>
      </div>
      <AddObservationModal
        breadCrumbValue={breadCrumbValue}
        itemId={Number(itemId)}
        observationModalVisible={observationModalVisible}
        observationType={observationType}
        subjectId={Number(subjectId)}
        subItemId={Number(subItemId)}
        toggleObservationModal={toggleObservationModal}
      />
    </div>
  );
};
export default InterimReport;
