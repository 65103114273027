import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  addTeacherService,
  deactivateTeacherService,
  editTeacherService,
  getTeachersService,
  reactivateTeacherService,
} from 'store/services/teachers.services';
import {
  addTeacherParamTypes,
  deactivateTeacherParamTypes,
  editTeacherActionParamTypes,
  getTeacherActions,
  getTeacherParamTypes,
  getTeachersSuccess,
  teachersResponseType,
} from 'store/actions/teachers.actions';
import { ActionNames } from 'store/actions/actionNames';

function* watchGetTeachers(action: { params: getTeacherParamTypes; type: string }) {
  try {
    const response: teachersResponseType = yield call(...getTeachersService(action.params));
    yield put(getTeachersSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    console.log('Can not get teachers');
  }
}

function* watchCreateTeacher(action: { params: addTeacherParamTypes; type: string }) {
  try {
    yield call(...addTeacherService(action.params));
    yield put(
      getTeacherActions({
        active: action.params.active,
        page: action.params.page,
        size: 10,
      }),
    );
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.createTeacherRequest));
    console.log('Can not create teacher');
  }
}

function* watchEditTeacher(action: { params: editTeacherActionParamTypes; type: string }) {
  try {
    yield call(...editTeacherService(action.params));
    yield put(
      getTeacherActions({
        active: action.params.active,
        page: action.params.page,
        size: 10,
      }),
    );
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.saveTeacherRequest));
    console.log('Can not create teacher');
  }
}

function* watchDeactivateTeacher(action: { params: deactivateTeacherParamTypes; type: string }) {
  try {
    yield call(...deactivateTeacherService(action.params));
    yield put(
      getTeacherActions({
        active: action.params.active,
        page: action.params.page,
        size: action.params.size,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchReactivateTeacher(action: { params: deactivateTeacherParamTypes; type: string }) {
  try {
    yield call(...reactivateTeacherService(action.params));
    yield put(
      getTeacherActions({
        active: action.params.active,
        page: action.params.page,
        size: action.params.size,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* teachersSaga() {
  yield all([takeLatest(ActionNames.GET_TEACHERS, watchGetTeachers)]);
  yield all([takeLatest(ActionNames.ADD_TEACHER, watchCreateTeacher)]);
  yield all([takeLatest(ActionNames.EDIT_TEACHER, watchEditTeacher)]);
  yield all([takeLatest(ActionNames.DEACTIVATE_TEACHER, watchDeactivateTeacher)]);
  yield all([takeLatest(ActionNames.RE_DEACTIVATE_TEACHER, watchReactivateTeacher)]);
}

export default teachersSaga;
