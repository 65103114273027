import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo } from 'react';
import {
  activateStudentIzelAction,
  deActivateStudentIzelAction,
  getReportListAction,
} from 'store/actions/students.actions';
import { getReportListSelector } from 'store/selectors/students.selectors';
import { Text } from '../../../../UI/Typography';
import styles from 'pages/Students/styles.module.css';
import { Dropdown } from 'antd';
import ActionsIcon from '../../../../UI/Icons/ActionsIcon';
import Spinner from '../../../../UI/Spinner';
import Table from '../../../../UI/Table';

const columns = (handleSelectYear: (arg1: number, arg2: string) => void) => {
  return [
    {
      title: 'Schuljahr',
      dataIndex: 'schoolYearName',
      key: 'schoolYearName',
      render: (record: string) => (
        <Text level={1} bold>
          {record}
        </Text>
      ),
    },
    {
      title: 'Typ',
      dataIndex: 'izelHalfYear',
      key: 'izelHalfYear',
      render: (record: boolean) => (
        <Text level={1}>{record ? 'Halbjahres-IzEL' : 'Schuljahres-IzEL'}</Text>
      ),
    },
    {
      title: 'Jahrgang',
      dataIndex: 'grade',
      key: 'grade',
      render: (record: string) => <Text level={1}>{record}</Text>,
    },
    {
      title: 'IzEL Status',
      dataIndex: 'status',
      key: 'status',
      render: (record: string) => <Text level={1}>{record}</Text>,
    },
    {
      title: '',
      dataIndex: 'izelId',
      key: 'izelId',
      render: (record: number, i: { status: string }) => {
        return (
          i.status !== 'inaktiviert' && (
            <Dropdown
              trigger={['click']}
              overlayClassName={styles.dropdownContent}
              placement='bottomRight'
              menu={{
                items: [
                  {
                    key: '1',
                    label: (
                      <span
                        onClick={(e) => {
                          e.stopPropagation();
                          handleSelectYear(record, i.status);
                        }}
                      >
                        {i.status === 'inaktiviert' || i.status === 'deaktiviert'
                          ? 'Reaktivieren'
                          : 'Deaktivieren'}
                      </span>
                    ),
                  },
                ],
              }}
            >
              <ActionsIcon />
            </Dropdown>
          )
        );
      },
      width: 50,
    },
  ];
};

const IzelList = () => {
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const reportList = useSelector(getReportListSelector);

  const reportTableData = useMemo(() => {
    return reportList.map((value) => {
      return {
        ...value,
        status: value?.izelNotActivated
          ? 'inaktiviert'
          : value?.izelActive
          ? 'aktiviert'
          : 'deaktiviert',
      };
    });
  }, [reportList]);

  const handleSelectYear = useCallback(
    (value: number, status: string) => {
      if (status === 'aktiviert') {
        dispatch(deActivateStudentIzelAction({ id: Number(studentId), izelId: value }));
      } else {
        dispatch(activateStudentIzelAction({ id: Number(studentId), izelId: value }));
      }
    },
    [dispatch, studentId],
  );

  useEffect(() => {
    dispatch(getReportListAction({ id: Number(studentId) }));
  }, [dispatch, studentId]);

  return (
    <div>
      <Table
        className={styles.studentsTable}
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={reportTableData}
        columns={columns(handleSelectYear)}
      />
    </div>
  );
};

export default IzelList;
