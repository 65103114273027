import { createSelector } from 'reselect';
import { teachersResponseType } from 'store/actions/teachers.actions';

const appState = (state: {
  teachersReducer: {
    teachers: teachersResponseType | null;
  };
}): { teachers: teachersResponseType | null } => state.teachersReducer;

export const getTeachersSelector = createSelector(appState, (state) => state.teachers);
