import { useDrag, useDrop } from 'react-dnd';
import { Identifier, XYCoord } from 'dnd-core';
import { Col, Row } from 'antd';
import styles from 'pages/Subjects/__partials/ReorderSubjectsModal/styles.module.css';
import DragIcon from '../../../../UI/Icons/DragIcon';
import { subjectTypes } from 'actions/subjects.actions';
import { useRef } from 'react';
import { getUserRole } from 'store/selectors/users.selectors';
import { useSelector } from 'react-redux';

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const SubjectList = ({
  subject,
  index,
  moveCard,
  handleChangeScrollHidden,
}: {
  subject: subjectTypes;
  index: number;
  moveCard: (arg1: number, arg2: number) => void;
  handleChangeScrollHidden: (arg1: boolean) => void;
}) => {
  const userRole = useSelector(getUserRole);
  const ref = useRef<HTMLDivElement>(null);
  const [, drag, preview] = useDrag({
    type: 'subjects',
    item: () => {
      return { id: subject.id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end() {
      handleChangeScrollHidden(false);
    },
  });
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'subjects',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(subject: DragItem, monitor) {
      handleChangeScrollHidden(true);
      if (!ref.current) {
        return;
      }
      const dragIndex = subject.index;
      const hoverIndex = index;
      if (dragIndex === hoverIndex) {
        return;
      }
      const hoverBoundingRect = ref.current?.getBoundingClientRect();
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;
      const clientOffset = monitor.getClientOffset();
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      subject.index = hoverIndex;
    },
  });
  drop(ref);
  return (
    <div ref={ref} className={styles.tableRow}>
      <Row key={subject.id} ref={preview}>
        <Col span={4}>
          {(!subject.isAssociation || userRole === 'ASSOCIATION_ADMIN') && (
            <span ref={drag}>
              <DragIcon />
            </span>
          )}
        </Col>
        <Col span={12}>{subject.name}</Col>
        <Col span={8}>{subject.optional ? 'Optional' : 'Bindend'}</Col>
      </Row>
    </div>
  );
};

export default SubjectList;
