import EditIcon from 'UI/Icons/EditIcon';
import { Text } from 'UI/Typography';
import Button from 'UI/Button';
import React, { useCallback, useEffect, useState } from 'react';
import styles from './styles.module.css';
import UpdateSchoolIfoModal from './__partials/UpdateSchoolIfoModal';
import { useDispatch, useSelector } from 'react-redux';
import { getSchoolInfoAction } from 'store/actions/school.actions';
import { schoolInfoSelector } from 'store/selectors/schools.selectors';

const SchoolInfo = () => {
  const [schoolInfoVisible, setSchoolInfoVisible] = useState(false);
  const schoolInfo = useSelector(schoolInfoSelector);
  const dispatch = useDispatch();

  const toggleSchoolInfo = useCallback((value: boolean) => {
    setSchoolInfoVisible(value);
  }, []);

  useEffect(() => {
    dispatch(getSchoolInfoAction);
  }, [dispatch]);

  return (
    <div>
      <div className={styles.editWrapper}>
        <Button type='default' icon={<EditIcon />} onClick={() => toggleSchoolInfo(true)}>
          Ändern
        </Button>
      </div>
      <div className={styles.contentWrapper}>
        <div className={styles.logoWrapper}>
          {schoolInfo?.logo ? (
            <img
              src={'data:image/png;base64,' + schoolInfo?.logo}
              alt='school logo'
              width='100%'
              height='100%'
              style={{ borderRadius: '16px' }}
            />
          ) : (
            'Logo'
          )}
        </div>
        <div className={styles.infoWrapper}>
          <div className={styles.singleInfo}>
            <Text level={1}>Schulname</Text>
            <Text level={1} bold>
              {schoolInfo?.name}
            </Text>
          </div>
          <div className={styles.singleInfo}>
            <Text level={1}>Offizieller Schulname</Text>
            <Text level={1} bold>
              {schoolInfo?.officialName}
            </Text>
          </div>
          <div className={styles.singleInfo}>
            <Text level={1}>Angebotene Jahrgangsstufen</Text>
            <Text level={1} bold>
              {schoolInfo?.startGrade}-{schoolInfo?.endGrade} Jahrgangsstufe
            </Text>
          </div>
          <div className={styles.singleInfo}>
            <Text level={1}>Ort</Text>
            <Text level={1} bold>
              {schoolInfo?.city}
            </Text>
          </div>
          <div className={styles.singleInfo}>
            <Text level={1}>PLZ</Text>
            <Text level={1} bold>
              {schoolInfo?.zip}
            </Text>
          </div>
          <div className={styles.singleInfo}>
            <Text level={1}>Straße und Hausnummer</Text>
            <Text level={1} bold>
              {schoolInfo?.street}
            </Text>
          </div>
        </div>
      </div>
      <UpdateSchoolIfoModal
        schoolData={schoolInfo}
        schoolInfoVisible={schoolInfoVisible}
        toggleSchoolInfo={toggleSchoolInfo}
      />
    </div>
  );
};

export default SchoolInfo;
