import { getEvaluationsSelector, getObservationsSelector } from 'store/selectors/report.selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Col, Radio, Row } from 'antd';
import {
  getItemObservationAction,
  getStudentObservationAction,
  getSubItemEvaluationAction,
  getSubItemObservationAction,
  getSubjectObservationAction,
} from 'store/actions/report.actions';
import { Text } from 'UI/Typography';
import Modal from 'UI/Modal';
import Table from 'UI/Table';
import Tabs from 'UI/Tabs';
import styles from './styles.module.css';

type observationModalTypes = {
  subjectId: number;
  itemId: number;
  subItemId: number;
  toggleObservationModal: (arg1: boolean) => void;
  observationModalVisible: boolean;
  observationType: 'student' | 'subject' | 'item' | 'subItem' | null;
  breadCrumbValue: string;
  activeReportId: number;
  subjectName: string;
};

const items = [
  {
    label: <span style={{ padding: '0 40px' }}>Beobachtungen</span>,
    key: 'observations',
  },
  {
    label: <span style={{ padding: '0 40px' }}>Bewertungen</span>,
    key: 'evaluations',
  },
];

const evaluationScaleValues: { [key: string]: string } = {
  1: 'selten',
  2: 'wechselnd',
  3: 'häufig',
  4: 'fast immer',
  5: 'Anfänge',
  6: 'Basiskenntnisse',
  7: 'gesicherte Kenntnisse',
  8: 'vertiefte Kenntnisse',
  9: 'Ja',
  10: 'Nein',
};

const ObservationsModal = ({
  subjectId,
  itemId,
  subItemId,
  toggleObservationModal,
  observationModalVisible,
  observationType,
  breadCrumbValue,
  activeReportId,
  subjectName,
}: observationModalTypes) => {
  const [activeTab, setActiveTab] = useState('observations');
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const observations = useSelector(getObservationsSelector);
  const evaluations = useSelector(getEvaluationsSelector);
  const filteredScaleValues = useMemo(() => {
    return evaluations?.assessmentScaleValues?.filter(
      (evaluation) => evaluation.id !== 12 && evaluation.id !== 11,
    );
  }, [evaluations?.assessmentScaleValues]);

  const studentFullName = useMemo(() => {
    return breadCrumbValue.split('/')[0];
  }, [breadCrumbValue]);

  const breadCrumbFirstPart = useMemo(() => {
    const splitBreadcrumb = breadCrumbValue.split('/');
    splitBreadcrumb.pop();
    return splitBreadcrumb.join(' / ');
  }, [breadCrumbValue]);

  const breadCrumbLastPart = useMemo(() => {
    const splitBreadcrumb = breadCrumbValue.split('/');
    return ` ${splitBreadcrumb.length > 1 ? '/' : ''} ${
      splitBreadcrumb[splitBreadcrumb.length - 1]
    }`;
  }, [breadCrumbValue]);

  const baseColumns = useMemo(() => {
    return [
      {
        title: 'Datum',
        dataIndex: 'evaluationDateTime',
        key: 'evaluationDateTime',
      },
      {
        title: 'Verfasser',
        dataIndex: 'teacherName',
        key: 'teacherName',
      },
    ];
  }, []);

  const evaluationsHeaders = useMemo(() => {
    return filteredScaleValues?.map((value) => {
      return {
        title: value.scaleValue,
        dataIndex: value.scaleValue,
        key: value.scaleValue,
        render: (record: boolean) => (
          <Radio
            style={{ width: '100%', justifyContent: 'center' }}
            className={styles.radio}
            checked={record}
          />
        ),
      };
    });
  }, [filteredScaleValues]);

  const getColumns = useCallback(() => {
    return baseColumns.concat(evaluationsHeaders);
  }, [baseColumns, evaluationsHeaders]);

  const tableData = useMemo(() => {
    return evaluations?.interimEvaluations?.map((value) => {
      const assessmentKey = evaluationScaleValues[value.assessmentScaleValueId];
      return {
        ...value,
        teacherName: `${value.teacherName}, ${value.teacherSurname}`,
        [assessmentKey]: true,
      };
    });
  }, [evaluations?.interimEvaluations]);

  useEffect(() => {
    if (observationType === 'student') {
      dispatch(getStudentObservationAction({ id: Number(studentId), izelId: activeReportId }));
    } else if (observationType === 'subject') {
      dispatch(
        getSubjectObservationAction({
          id: Number(subjectId),
          studentId: Number(studentId),
          izelId: activeReportId,
        }),
      );
    } else if (observationType === 'item') {
      dispatch(
        getItemObservationAction({
          id: Number(studentId),
          itemId: Number(itemId),
          izelId: activeReportId,
        }),
      );
    } else {
      if (subItemId) {
        dispatch(
          getSubItemEvaluationAction({
            id: Number(studentId),
            itemId: Number(itemId),
            subItemId: Number(subItemId),
            izelId: activeReportId,
          }),
        );
        dispatch(
          getSubItemObservationAction({
            id: Number(studentId),
            itemId: Number(itemId),
            subItemId: Number(subItemId),
            izelId: activeReportId,
          }),
        );
      }
    }
  }, [activeReportId, dispatch, itemId, observationType, studentId, subItemId, subjectId]);

  return (
    <Modal
      destroyOnClose
      width={800}
      centered
      title={
        observationType === 'student'
          ? studentFullName + ' persönliche Beobachtungen'
          : studentFullName + ' ' + subjectName + ' Beobachtung'
      }
      open={observationModalVisible}
      onOk={() => {
        toggleObservationModal(false);
        setActiveTab('observations');
      }}
      closable
      okText='Schließen'
      cancelButtonProps={{ style: { display: 'none' } }}
      okButtonProps={{ type: 'default', className: 'primary-40' }}
      onCancel={() => {
        toggleObservationModal(false);
        setActiveTab('observations');
      }}
      fixScroll
    >
      <div className={styles.observationsWrapper}>
        <div className={styles.breadCrumbWrapper}>
          <Text level={1}>{breadCrumbFirstPart}</Text>
          <Text level={1} bold>
            {breadCrumbLastPart}
          </Text>
        </div>
        <div>
          {observationType === 'subItem' && (
            <Tabs
              defaultActiveKey='observations'
              items={items}
              onTabClick={(key: string) => setActiveTab(key)}
            />
          )}
        </div>
        {activeTab === 'observations' ? (
          <div>
            {observations?.map((observation) => {
              return (
                <div key={observation.id}>
                  <Row gutter={16} style={{ marginTop: '16px' }}>
                    <Col>
                      <Text level={1} bold>
                        {observation.observationDateTime}
                      </Text>
                    </Col>
                    <Col>
                      <Text level={1} bold>
                        {observation.teacherName}
                        {', '}
                        {observation.teacherSurname}
                      </Text>
                    </Col>
                  </Row>
                  <div
                    className='ql-editor'
                    style={{ margin: '8px 0' }}
                    dangerouslySetInnerHTML={{ __html: observation.text }}
                  />
                </div>
              );
            })}
          </div>
        ) : (
          <div>
            {evaluations ? (
              <Table
                className={styles.evalTable}
                rowKey={(record) => record.id}
                pagination={false}
                dataSource={tableData}
                columns={getColumns()}
              />
            ) : null}
          </div>
        )}
      </div>
    </Modal>
  );
};
export default ObservationsModal;
