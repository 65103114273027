import { ApiCallWithParams } from 'store/services/services.types';
import {
  changeUserRoleParamTypes,
  firstLoginParamTypes,
  loginParaTypes,
  resetPasswordParamTypes,
} from 'store/actions/auth.actions';
import { client } from 'store/services/client';

export const loginService = (
  params: loginParaTypes,
): [ApiCallWithParams, string, loginParaTypes] => [client.post, '/login', params];

export const firstLoginService = (
  params: firstLoginParamTypes,
): [ApiCallWithParams, string, firstLoginParamTypes] => [
  client.post,
  '/login/change-password',
  params,
];

export const resetPasswordService = (
  params: resetPasswordParamTypes,
): [ApiCallWithParams, string, resetPasswordParamTypes] => [
  client.post,
  '/login/reset-password',
  params,
];

export const changeUserRoleService = (
  params: changeUserRoleParamTypes,
): [ApiCallWithParams, string, changeUserRoleParamTypes] => [
  client.put,
  '/users/current/last-login-role',
  params,
];
