import styles from './styles.module.css';
import { Text } from '../../../../UI/Typography';
import { Col, Collapse, Dropdown, Row } from 'antd';
import Button from '../../../../UI/Button';
import { useDispatch, useSelector } from 'react-redux';
import { getSchoolYearDataSelector } from 'store/selectors/schools.selectors';
import Checkbox from '../../../../UI/Checkbox';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  activateSchoolYearAction,
  deactivateHalfIzelAction,
  deactivateIzelAction,
  editSchoolYearSettingsAction,
  getSchoolYearDataAction,
} from 'store/actions/school.actions';
import ActionsIcon from '../../../../UI/Icons/ActionsIcon';
import EditYearsModal from 'pages/SchoolManagement/__partials/SchoolYear/__partials/EditYearsModal';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Modal from '../../../../UI/Modal';
import dayjs from 'dayjs';
import EditIcon from '../../../../UI/Icons/EditIcon';
import ManageIssueDate from 'pages/SchoolManagement/__partials/SchoolYear/__partials/ManageIssueDate';
import { CalendarOutlined } from '@ant-design/icons';

const { Panel } = Collapse;
const { Confirm } = Modal;

const SchoolYear = () => {
  const [editYearsVisible, setEditYearsVisible] = useState(false);
  const [startSchoolYearVisible, setStartSchoolYearVisible] = useState(false);
  const [checkboxValues, setCheckboxValues] = useState<Array<{ value: number; halfYear: boolean }>>(
    [],
  );
  const [selectedFullYearIzel, setSelectedFullYearIzel] = useState<number | null>(null);
  const [selectedHalfYearIzel, setSelectedHalfYearIzel] = useState<number | null>(null);
  const [deactivateIzelLoading, setDeactivateIzelLoading] = useState(false);
  const [manageIssueDateVisible, setManageIssueDate] = useState(false);
  const [dateHalfYear, setDateHalfYear] = useState(false);
  const [currentIssueDate, setCurrentIssueDate] = useState('');
  const dispatch = useDispatch();
  const schoolYearData = useSelector(getSchoolYearDataSelector);

  const hasPreviousSchoolYear = useMemo(
    () => !!schoolYearData?.schoolYears?.length,
    [schoolYearData?.schoolYears?.length],
  );

  const selectedIzelData = useMemo(() => {
    if (selectedFullYearIzel) {
      return schoolYearData?.schoolYears?.find((value) => value.id === selectedFullYearIzel);
    } else if (selectedHalfYearIzel) {
      return schoolYearData?.schoolYears?.find((value) => value.id === selectedHalfYearIzel);
    } else return null;
  }, [schoolYearData?.schoolYears, selectedFullYearIzel, selectedHalfYearIzel]);

  const toggleEditYears = useCallback((value: boolean) => {
    setEditYearsVisible(value);
  }, []);

  const toggleStartSchoolYearModal = useCallback((value: boolean) => {
    setStartSchoolYearVisible(value);
  }, []);

  const handleChangeYearValues = useCallback(
    (halfYear: boolean, value: number) => {
      const newValues =
        (checkboxValues.length ? checkboxValues : schoolYearData?.halfYearSettings)?.map((year) => {
          return year.value !== value ? year : { value, halfYear };
        }) || [];
      setCheckboxValues(newValues);
    },
    [checkboxValues, schoolYearData?.halfYearSettings],
  );

  const handleSchoolYearSettings = useCallback(() => {
    if (editYearsVisible) {
      dispatch(editSchoolYearSettingsAction(checkboxValues));
      toggleEditYears(false);
    } else {
      toggleEditYears(true);
    }
  }, [checkboxValues, dispatch, editYearsVisible, toggleEditYears]);

  const handleActivateSchoolYear = useCallback(() => {
    dispatch(activateSchoolYearAction({ onSuccess: () => null }));
  }, [dispatch]);

  const toggleFullYearIZel = useCallback((izelId: number | null) => {
    setSelectedFullYearIzel(izelId);
  }, []);

  const toggleHalfYearIZel = useCallback((izelId: number | null) => {
    setSelectedHalfYearIzel(izelId);
  }, []);

  const toggleManageIssueDate = useCallback((value: boolean) => {
    setManageIssueDate(value);
  }, []);

  const toggleIssueHalfYear = useCallback((value: boolean) => {
    setDateHalfYear(value);
  }, []);

  const handelChangeCurrentIssueDate = useCallback((value: string) => {
    setCurrentIssueDate(value);
  }, []);

  const handleManageFullYearIzel = useCallback(() => {
    setDeactivateIzelLoading(false);
    dispatch(
      deactivateIzelAction({
        izelId: Number(selectedFullYearIzel),
        onSuccess: () => {
          setDeactivateIzelLoading(false);
          setSelectedFullYearIzel(null);
        },
      }),
    );
  }, [dispatch, selectedFullYearIzel]);

  const handleManageHalfYearIzel = useCallback(() => {
    setDeactivateIzelLoading(false);
    dispatch(
      deactivateHalfIzelAction({
        izelId: Number(selectedHalfYearIzel),
        onSuccess: () => {
          setDeactivateIzelLoading(false);
          setSelectedHalfYearIzel(null);
        },
      }),
    );
  }, [dispatch, selectedHalfYearIzel]);

  useEffect(() => {
    dispatch(getSchoolYearDataAction);
  }, [dispatch]);

  return (
    <div>
      <div
        style={{
          boxShadow: '0px 4px 8px 0px #00000033',
          borderRadius: '20px 20px 20px 20px',
        }}
      >
        <div className={styles.headerWrapper}>
          <Text level={1} bold>
            Schuljahr-Einstellungen
          </Text>
        </div>
        <Row className={styles.infoWrapper} align='middle' justify='space-between'>
          <Col span={4}>
            <Text level={1} bold>
              {hasPreviousSchoolYear
                ? `Aktuelles Schuljahr ${schoolYearData?.currentSchoolYearName}`
                : `Aktuelles Jahr ${dayjs().year()}`}
            </Text>
          </Col>
          <Col>
            <Button type='default' onClick={() => toggleStartSchoolYearModal(true)}>
              <Text level={1} bold>
                Neues Schuljahr starten
              </Text>
            </Button>
          </Col>
        </Row>
      </div>
      <div className={styles.secondBlock}>
        <div className={styles.headerWrapper}>
          <div className={styles.yearsWrapper}>
            <div>
              <div>
                <Text level={1} bold>
                  Schuljahr-Einstellungen
                </Text>
              </div>
              <div>
                <Text level={1}>
                  Bitte markieren Sie die Jahrgangsstufen, für welche ein Halbjahres IzEL zu
                  erstellen ist.
                </Text>
              </div>
            </div>
            <Button
              type={editYearsVisible ? 'primary' : 'default'}
              onClick={() => handleSchoolYearSettings()}
            >
              <Text level={1} bold>
                {editYearsVisible ? 'Speichern' : 'Ändern'}
              </Text>
            </Button>
          </div>
        </div>
        <div className={styles.infoWrapper} style={{ opacity: editYearsVisible ? 1 : 0.5 }}>
          {schoolYearData?.halfYearSettings?.map((value) => {
            return (
              <div key={value.value} className={styles.checkboxWrapper}>
                <div>{value.value}. Jahrgangstufe</div>
                <div style={{ textAlign: 'center' }}>
                  <Checkbox
                    disabled={!editYearsVisible}
                    defaultChecked={value.halfYear}
                    onChange={(e: CheckboxChangeEvent) =>
                      handleChangeYearValues(e.target.checked, value.value)
                    }
                  />
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div
        style={{
          boxShadow: '0px 4px 8px 0px #00000033',
          borderRadius: '20px 20px 20px 20px',
          marginTop: '24px',
        }}
      >
        <Row className={styles.headerWrapper}>
          <Col span={15}>
            <Text level={1} bold>
              Schuljahr
            </Text>
          </Col>
          <Col span={9}>
            <Text level={1} bold>
              IzEL Status
            </Text>
          </Col>
        </Row>
        {hasPreviousSchoolYear ? (
          schoolYearData?.schoolYears?.map((value) => {
            return (
              <div key={value.id} className={styles.collapseWrapper}>
                {!value.izelsNotActivated ? (
                  <Collapse ghost>
                    <Panel
                      key={value.id}
                      header={
                        <Row>
                          <Col span={15}>
                            <Text level={1} bold>
                              IzEL {value.name}
                            </Text>
                          </Col>
                          <Col
                            span={9}
                            style={{
                              opacity:
                                value.halfYearIzelActive || value.fullYearIzelActive ? 1 : 0.5,
                            }}
                          >
                            <Text level={1} italic>
                              {value.halfYearIzelActive || value.fullYearIzelActive
                                ? 'Aktiv'
                                : 'Deaktiviert'}
                            </Text>
                          </Col>
                        </Row>
                      }
                    >
                      <Row justify='space-between' className={styles.statusHeader}>
                        <Col span={4} offset={1}>
                          IzEL Typ
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            Status
                            <div style={{ width: '200px', marginRight: '7vw' }}>Datum</div>
                          </div>
                        </Col>
                        <Col>&nbsp;</Col>
                      </Row>
                      <Row justify='space-between' className={styles.actionWrapper}>
                        <Col span={4} offset={1}>
                          Halbjahres-IzEL
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            {value.halfYearIzelActive ? 'Aktiviert' : 'Deaktiviert'}
                            <div
                              style={{
                                width: '200px',
                                marginRight: value.issueDateHalfYear ? '7vw' : '5.5vw',
                              }}
                            >
                              {value.issueDateHalfYear}{' '}
                              {value.halfYearIzelActive &&
                                schoolYearData?.currentSchoolYearName === value.name && (
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      toggleManageIssueDate(true);
                                      toggleIssueHalfYear(true);
                                      handelChangeCurrentIssueDate(value.issueDateHalfYear);
                                    }}
                                  >
                                    {value.issueDateHalfYear ? (
                                      <EditIcon style={{ fontSize: 20 }} />
                                    ) : (
                                      <CalendarOutlined style={{ fontSize: 18 }} />
                                    )}
                                  </span>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col style={{ width: '24px' }}>
                          {value.halfYearIzelActive && (
                            <Dropdown
                              trigger={['click']}
                              overlayClassName={styles.dropdownContent}
                              placement='bottomRight'
                              menu={{
                                items: [
                                  {
                                    key: '1',
                                    label: (
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleHalfYearIZel(value.id);
                                        }}
                                      >
                                        {value.halfYearIzelActive ? ' Deaktivieren' : 'Re-Activete'}
                                      </span>
                                    ),
                                  },
                                ],
                              }}
                            >
                              <ActionsIcon />
                            </Dropdown>
                          )}
                        </Col>
                      </Row>
                      <Row justify='space-between' className={styles.actionWrapper}>
                        <Col span={4} offset={1}>
                          Schuljahr IzEL
                        </Col>
                        <Col span={12}>
                          <div
                            style={{
                              display: 'flex',
                              justifyContent: 'space-between',
                              alignItems: 'center',
                            }}
                          >
                            {value.fullYearIzelActive ? 'Aktiviert' : 'Deaktiviert'}
                            <div
                              style={{
                                width: '200px',
                                marginRight: value.issueDateFullYear ? '7vw' : '5.5vw',
                              }}
                            >
                              {value.issueDateFullYear}{' '}
                              {value.fullYearIzelActive &&
                                schoolYearData?.currentSchoolYearName === value.name && (
                                  <span
                                    style={{ cursor: 'pointer' }}
                                    onClick={() => {
                                      toggleManageIssueDate(true);
                                      handelChangeCurrentIssueDate(value.issueDateFullYear);
                                    }}
                                  >
                                    {value.issueDateFullYear ? (
                                      <EditIcon style={{ fontSize: 20 }} />
                                    ) : (
                                      <CalendarOutlined style={{ fontSize: 18 }} />
                                    )}
                                  </span>
                                )}
                            </div>
                          </div>
                        </Col>
                        <Col style={{ width: '24px' }}>
                          {value.fullYearIzelActive && !value.active && (
                            <Dropdown
                              trigger={['click']}
                              overlayClassName={styles.dropdownContent}
                              placement='bottomRight'
                              menu={{
                                items: [
                                  {
                                    key: '2',
                                    label: (
                                      <span
                                        onClick={(e) => {
                                          e.stopPropagation();
                                          toggleFullYearIZel(value.id);
                                        }}
                                      >
                                        {value.fullYearIzelActive ? ' Deaktivieren' : 'Re-Activete'}
                                      </span>
                                    ),
                                  },
                                ],
                              }}
                            >
                              <ActionsIcon />
                            </Dropdown>
                          )}
                        </Col>
                      </Row>
                    </Panel>
                  </Collapse>
                ) : (
                  <div className={styles.inactiveWrapper}>
                    <Row align='middle'>
                      <Col span={15} style={{ paddingLeft: '25px' }}>
                        <Text level={1} bold>
                          IzEL {value.name}
                        </Text>
                      </Col>
                      <Col span={9} style={{ paddingLeft: '8px' }}>
                        {value.active ? (
                          <Button
                            type='default'
                            size='small'
                            onClick={() => handleActivateSchoolYear()}
                          >
                            <Text level={1} bold>
                              Aktivieren
                            </Text>
                          </Button>
                        ) : (
                          <Text level={1} italic>
                            Inaktiv
                          </Text>
                        )}
                      </Col>
                    </Row>
                  </div>
                )}
              </div>
            );
          })
        ) : (
          <div className={styles.inactiveWrapper}>
            <Row align='middle'>
              <Col span={15}>
                <Text level={1}>Zur Anzeige der IzEL müssen Sie das Schuljahr starten</Text>
              </Col>
              <Col span={9}>
                <Text level={1}>-</Text>
              </Col>
            </Row>
          </div>
        )}
      </div>
      <EditYearsModal
        currentYear={schoolYearData?.currentSchoolYearName || ''}
        nextYear={schoolYearData?.nextSchoolYearName || ''}
        hasPreviousSchoolYear={hasPreviousSchoolYear}
        startSchoolYearVisible={startSchoolYearVisible}
        toggleStartSchoolYearModal={toggleStartSchoolYearModal}
      />
      <Confirm
        confirmLoading={deactivateIzelLoading}
        closable
        okText={selectedIzelData?.halfYearIzelActive ? 'Deaktivieren' : 'Re-Activete'}
        cancelText='Abbrechen'
        centered
        message={`Wenn Sie das IzEL Halbjahres-IzEL ${selectedIzelData?.name} deaktivieren, können Sie für keine Schülerin und keinen Schüler mehr Änderungen in den entsprechenden IzEL-Formularen durchführen.`}
        open={!!selectedHalfYearIzel}
        onOk={handleManageHalfYearIzel}
        onCancel={() => {
          toggleHalfYearIZel(null);
        }}
      />
      <Confirm
        confirmLoading={deactivateIzelLoading}
        closable
        okText={selectedIzelData?.fullYearIzelActive ? 'Deaktivieren' : 'Re-Activete'}
        cancelText='Abbrechen'
        centered
        message={`Wenn Sie das IzEL Schuljahres-IzEL ${selectedIzelData?.name} deaktivieren, können Sie für keine Schülerin und keinen Schüler mehr Änderungen in den entsprechenden IzEL-Formularen durchführen.`}
        open={!!selectedFullYearIzel}
        onOk={handleManageFullYearIzel}
        onCancel={() => {
          toggleFullYearIZel(null);
        }}
      />
      <ManageIssueDate
        toggleIssueHalfYear={toggleIssueHalfYear}
        toggleManageIssueDate={toggleManageIssueDate}
        manageIssueDateVisible={manageIssueDateVisible}
        halfYear={dateHalfYear}
        currentIssueDate={currentIssueDate}
        handelChangeCurrentIssueDate={handelChangeCurrentIssueDate}
      />
    </div>
  );
};

export default SchoolYear;
