import Modal from '../../../../../../UI/Modal';
import styles from 'pages/InterimReport/__partials/AddObservationModal/styles.module.css';
import { Text } from '../../../../../../UI/Typography';
import React, { useCallback, useEffect, useState } from 'react';
import RichText from 'components/RichText';
import { useDispatch } from 'react-redux';
import { createGroupSubjectSummaryAction } from 'store/actions/group.actions';
import { useParams } from 'react-router-dom';
import cc from 'classcat';

type summaryModalTypes = {
  groupName: string;
  selectedSubjectName: string;
  selectedSubject: number;
  summaryModalVisible: boolean;
  toggleSummaryModal: (arg1: boolean) => void;
  groupText: string;
};

const GroupSubjectSummaryModal = ({
  summaryModalVisible,
  toggleSummaryModal,
  groupName,
  selectedSubjectName,
  selectedSubject,
  groupText,
}: summaryModalTypes) => {
  const [subjectSummary, setSubjectSummary] = useState('');
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [summaryLength, setSummaryLength] = useState(0);
  const [emptyText, setEmptyText] = useState(false);
  const { groupId } = useParams();
  const dispatch = useDispatch();

  const handleChangeEmptyText = useCallback((active: boolean) => {
    setEmptyText(active);
  }, []);

  const handleChangeSummary = useCallback((value: string, length: number) => {
    setSubjectSummary(value);
    setSummaryLength(length);
  }, []);

  const resetSummaryModal = useCallback(() => {
    toggleSummaryModal(false);
    if (!groupText) {
      setSubjectSummary('');
    }
  }, [groupText, toggleSummaryModal]);

  const handleCreateSummary = useCallback(() => {
    setSummaryLoading(true);
    dispatch(
      createGroupSubjectSummaryAction({
        groupId: Number(groupId),
        subjectId: selectedSubject,
        text:
          subjectSummary === '<p><br></p>' || emptyText
            ? null
            : `<div style="font-size: 11pt; line-height: 1.5; font-family: 'Avenir Next', sans-serif" class="outer-div" >${subjectSummary}</div>`,
        onSuccess: () => {
          resetSummaryModal();
          setSummaryLoading(false);
        },
      }),
    );
  }, [dispatch, emptyText, groupId, resetSummaryModal, selectedSubject, subjectSummary]);

  useEffect(() => {
    if (groupText) {
      setSubjectSummary('');
      setTimeout(() => {
        setSubjectSummary(groupText);
      }, 400);
    } else {
      setSubjectSummary('');
    }
  }, [groupText]);

  return (
    <Modal
      confirmLoading={summaryLoading}
      width={800}
      centered
      title='Entwicklungsbericht'
      open={summaryModalVisible}
      onOk={handleCreateSummary}
      onCancel={resetSummaryModal}
      closable
      cancelText='Abbrechen'
      okText='Speichern'
      okDisabled={summaryLength > 5000}
    >
      <div className={styles.breadCrumbWrapper}>
        <Text level={1}>{groupName}</Text>
        <Text level={1} bold>
          /{selectedSubjectName}
        </Text>
      </div>
      <div className={styles.editorWrapper}>
        <RichText
          value={subjectSummary}
          onChange={handleChangeSummary}
          emptyCheck={handleChangeEmptyText}
        />
      </div>
      <div
        className={cc([
          styles.editorCount,
          {
            [styles.error]: summaryLength > 5000,
          },
        ])}
      >
        {summaryLength}/5000
      </div>
    </Modal>
  );
};

export default GroupSubjectSummaryModal;
