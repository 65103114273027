import { Text, Title } from 'UI/Typography';
import React, { ReactNode } from 'react';
import styles from './styles.module.css';
import RemoveOutlinedIcon from '../../../../UI/Icons/RemoveOutlinedIcon';
import { Tooltip } from 'antd';
import InfoIcon from '../../../../UI/Icons/InfoIcon';
import { evalScaleValueNames } from '../../../../constants';

const CollapseHeader = ({
  name,
  item = false,
  itemProps,
  removable,
  handleSelectRemovableSubject,
  handleSelectRemovableItem,
  description,
}: {
  name: ReactNode | string;
  item?: boolean;
  itemProps?: { optional: string; assessmentScale: { name: string }; schoolYears: string[] } & any;
  removable?: boolean;
  handleSelectRemovableSubject?: () => void;
  handleSelectRemovableItem?: () => void;
  description?: string;
}) => {
  return (
    <div>
      <div className={styles.titleWrapper}>
        <div className={styles.titleFirst}>
          <Text level={2} bold>
            {name}
          </Text>
          {description && (
            <Tooltip title={description}>
              <InfoIcon />
            </Tooltip>
          )}
        </div>
        {removable && !item && (
          <span
            onClick={(e) => {
              e.stopPropagation();
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              handleSelectRemovableSubject();
            }}
          >
            <RemoveOutlinedIcon />
          </span>
        )}
      </div>
      {item && (
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <div className={styles.itemInfo}>
            <div className={styles.infoInnerItem}>
              Typ <Title level={5}>{itemProps?.optional ? 'Optional' : 'Bindend'}</Title>
            </div>
            <div className={styles.infoInnerItem}>
              Bewertungsskala {/* eslint-disable-next-line @typescript-eslint/ban-ts-comment*/}
              {/* @ts-ignore*/}
              <Title level={5}>{evalScaleValueNames[itemProps?.assessmentScale?.name]}</Title>
            </div>
            <div className={styles.infoInnerItem}>
              Jahrgänge{' '}
              <Title level={5}>
                {(structuredClone(itemProps?.schoolYears)[0] === 0
                  ? ['-']
                  : structuredClone(itemProps?.schoolYears)
                )
                  ?.sort((a: string, b: string) => Number(a) - Number(b))
                  .join(', ')}
              </Title>
            </div>
          </div>
          {removable && (
            <span
              onClick={(e) => {
                e.stopPropagation();
                // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                // @ts-ignore
                handleSelectRemovableItem();
              }}
            >
              <RemoveOutlinedIcon />
            </span>
          )}
        </div>
      )}
    </div>
  );
};

export default CollapseHeader;
