import Modal from '../../../../UI/Modal';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { Text } from '../../../../UI/Typography';
import styles from './styles.module.css';
import Select from '../../../../UI/Select';
import RichText from 'components/RichText';
import {
  createGroupObservationAction,
  getGroupItemsAction,
  getGroupsAllSubjectsAction,
  getGroupSubItemsAction,
} from 'store/actions/group.actions';
import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import {
  grtGroupAllSubjectsSelector,
  grtGroupItemsSelector,
  grtGroupSubItemsSelector,
} from 'store/selectors/groups.selectors';
import cc from 'classcat';

type groupObservationModalTypes = {
  groupObservationVisible: boolean;
  toggleGroupObservation: (arg1: boolean) => void;
  selectedStudents: any;
  handleChangeSelectedStudents: (arg1: { id: number }[]) => void;
};

const GroupObservationModal = ({
  groupObservationVisible,
  toggleGroupObservation,
  selectedStudents,
  handleChangeSelectedStudents,
}: groupObservationModalTypes) => {
  const [selectedSubject, setSelectedSubject] = useState<number | null>(null);
  const [selectedItem, setSelectedItem] = useState<number | null>(null);
  const [selectedSubItem, setSelectedSubItem] = useState<number | null>(null);
  const [observationValue, setObservationValue] = useState('');
  const [createObservationLoading, setCreateObservationLoading] = useState(false);
  const [letterLength, setLetterLength] = useState(0);
  const { groupId } = useParams();
  const dispatch = useDispatch();
  const groupAllSubjects = useSelector(grtGroupAllSubjectsSelector);
  const groupItems = useSelector(grtGroupItemsSelector);
  const groupSubItems = useSelector(grtGroupSubItemsSelector);
  const subjectOptions = useMemo(() => {
    return groupAllSubjects?.map((sub) => {
      return {
        label: sub.name,
        value: sub.id,
      };
    });
  }, [groupAllSubjects]);

  const itemOptions = useMemo(() => {
    return groupItems?.map((sub) => {
      return {
        label: sub.name,
        value: sub.id,
      };
    });
  }, [groupItems]);

  const subItemOptions = useMemo(() => {
    return groupSubItems?.map((sub) => {
      return {
        label: sub.name,
        value: sub.id,
      };
    });
  }, [groupSubItems]);

  const studentIds = useMemo(
    () => selectedStudents.map((value: { id: number }) => value.id),
    [selectedStudents],
  );

  const handleSelectSubject = useCallback((subjectId: number) => {
    setSelectedItem(null);
    setSelectedSubItem(null);
    setSelectedSubject(subjectId);
  }, []);

  const handleSelectItem = useCallback((itemId: number) => {
    setSelectedSubItem(null);
    setSelectedItem(itemId);
  }, []);

  const handleSelectSubItem = useCallback((subItemId: number) => {
    setSelectedSubItem(subItemId);
  }, []);

  const handeChangeObservationValue = useCallback((value: string, length: number) => {
    setObservationValue(value);
    setLetterLength(length);
  }, []);

  const handleResetModal = useCallback(() => {
    setSelectedSubject(null);
    setSelectedItem(null);
    setSelectedSubItem(null);
    setObservationValue('');
    toggleGroupObservation(false);
  }, [toggleGroupObservation]);

  const handleCreateObservation = useCallback(() => {
    setCreateObservationLoading(true);
    dispatch(
      createGroupObservationAction({
        id: Number(groupId),
        subjectId: Number(selectedSubject),
        studentIds,
        itemId: Number(selectedItem),
        subItemId: Number(selectedSubItem),
        onSuccess: () => {
          setCreateObservationLoading(false);
          handleResetModal();
          handleChangeSelectedStudents([]);
        },
        text: observationValue,
      }),
    );
  }, [
    dispatch,
    groupId,
    handleChangeSelectedStudents,
    handleResetModal,
    observationValue,
    selectedItem,
    selectedSubItem,
    selectedSubject,
    studentIds,
  ]);

  useEffect(() => {
    dispatch(getGroupsAllSubjectsAction({ id: Number(groupId), all: true }));
  }, [dispatch, groupId]);

  useEffect(() => {
    dispatch(getGroupItemsAction({ id: Number(groupId), subjectId: Number(selectedSubject) }));
  }, [dispatch, groupId, selectedSubject]);

  useEffect(() => {
    dispatch(
      getGroupSubItemsAction({
        id: Number(groupId),
        subjectId: Number(selectedSubject),
        itemId: Number(selectedItem),
      }),
    );
  }, [dispatch, groupId, selectedItem, selectedSubject]);

  return (
    <Modal
      width={800}
      centered
      okText='Hinzufügen'
      cancelText='Abbrechen'
      open={groupObservationVisible}
      title='Beobachtung'
      onOk={handleCreateObservation}
      onCancel={handleResetModal}
      okDisabled={!selectedSubject || !letterLength}
      confirmLoading={createObservationLoading}
    >
      <div>
        <Text level={2} bold>
          Beobachtung für
        </Text>
      </div>
      <div className={styles.tagsWrapper}>
        {selectedStudents.map((value: any) => {
          return (
            <div key={value.id} className={styles.tagsInner}>
              <Text level={1} bold>
                {value.surname}, {value.name}
              </Text>
            </div>
          );
        })}
      </div>
      <div>
        <Text level={2} bold>
          Fach
        </Text>
      </div>
      <div className={styles.subjectSelectWrapper}>
        <Select
          showSearch={false}
          value={selectedSubject}
          onChange={(years) => handleSelectSubject(years)}
          showArrow
          options={subjectOptions}
          placeholder='Fach'
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <Text level={2} bold>
          Item
        </Text>
      </div>
      <div className={styles.subjectSelectWrapper}>
        <Select
          disabled={!selectedSubject || !itemOptions.length}
          showSearch={false}
          value={selectedItem}
          onChange={(years) => handleSelectItem(years)}
          showArrow
          options={itemOptions}
          placeholder='Item'
          style={{ width: '100%' }}
        />
      </div>
      <div>
        <Text level={2} bold>
          SubItem
        </Text>
      </div>
      <div className={styles.subjectSelectWrapper}>
        <Select
          disabled={!selectedItem || !subItemOptions.length}
          showSearch={false}
          value={selectedSubItem}
          onChange={(years) => handleSelectSubItem(years)}
          showArrow
          options={subItemOptions}
          placeholder='SubItem'
          style={{ width: '100%' }}
        />
      </div>
      <div className={styles.editorWrapper}>
        <RichText value={observationValue} onChange={handeChangeObservationValue} />
      </div>
      <div
        className={cc([
          styles.editorCount,
          {
            [styles.error]: letterLength > 2000,
          },
        ])}
      >
        {letterLength}/2000
      </div>
    </Modal>
  );
};

export default GroupObservationModal;
