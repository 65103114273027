import {
  getItemsCountSelector,
  getItemsSelector,
  getSingleSubjectSelector,
} from 'store/selectors/items.selectors';
import ManageSubItemModal from 'pages/SingleSubject/__partials/ManageSubItemModal';
import { PlusOutlined } from '@ant-design/icons';
import CollapseHeader from 'pages/SingleSubject/__partials/CollapseHeader';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Collapse, Dropdown, List, Row } from 'antd';
import Breadcrumb from 'components/Breadcrumb';
import { useParams } from 'react-router-dom';
import { Text, Title } from 'UI/Typography';
import {
  deactivateItemAction,
  getItemsAction,
  itemTypes,
  reactivateItemAction,
  removeItemAction,
  reorderSubItemsAction,
  subItemTypes,
} from 'store/actions/items.actions';
import Button from 'UI/Button';
import Modal from 'UI/Modal';
import cc from 'classcat';
import ManageItemModal from './__partials/ManageItemModal';
import styles from './styles.module.css';
import SubItemList from 'pages/SingleSubject/__partials/SubItemList';
import Pagination from '../../UI/Pagination';
import { getUserRole } from 'store/selectors/users.selectors';
import ActionsIcon from '../../UI/Icons/ActionsIcon';
import Checkbox from '../../UI/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const { Panel } = Collapse;
const { Confirm } = Modal;

const SingleSubject = () => {
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [addSubItemModalVisible, setAddSubItemModalVisible] = useState(false);
  const [parentId, setParentId] = useState<number | null>(null);
  const [editableItem, setEditableItem] = useState<itemTypes | null>(null);
  const [editableSubItem, setEditableSubItem] = useState<subItemTypes | null>(null);
  const [confirmItem, setConfirmItem] = useState(false);
  const [deletingItemId, setDeletingItemId] = useState<number | null>(null);
  const [confirmSubItem, setConfirmSubItem] = useState(false);
  const [deletingSubItemId, setDeletingSubItemId] = useState<number | null>(null);
  const [showDeactivatedItems, setShowDeactivatedItems] = useState(false);
  const [deactivatingItemId, setDeactivatingItemId] = useState<null | number>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [items, setItems] = useState<Array<itemTypes>>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const userRole = useSelector(getUserRole);
  const myElementRef = useRef<null>(null);
  const backendItems = useSelector(getItemsSelector);
  const itemsCount = useSelector(getItemsCountSelector);
  const selectedSubject = useSelector(getSingleSubjectSelector);
  const dispatch = useDispatch();
  const { subjectId } = useParams();

  const toggleManageItemModal = useCallback((value: boolean) => {
    setAddItemModalVisible(value);
  }, []);

  const toggleSubItemModal = useCallback((value: boolean) => {
    setAddSubItemModalVisible(value);
  }, []);

  const handleSubItemParentId = useCallback((value: number) => {
    setParentId(value);
  }, []);

  const handleChangeEditableItem = useCallback((value: itemTypes | null) => {
    setEditableItem(value);
  }, []);

  const handleChangeEditableSubItem = useCallback((value: subItemTypes | null) => {
    setEditableSubItem(value);
  }, []);

  const handleEditItem = useCallback(
    (e: { stopPropagation: () => void }, id: number) => {
      e.stopPropagation();
      const itemToEdit = items.find((item: itemTypes) => item.id === id) || null;
      handleChangeEditableItem(itemToEdit);
      toggleManageItemModal(true);
    },
    [items, handleChangeEditableItem, toggleManageItemModal],
  );

  const handleEditSubItem = useCallback(
    (e: { stopPropagation: () => void }, id: number, parentId: number) => {
      e.stopPropagation();
      const subItemToEdit = items
        .find((item: itemTypes) => item.id === parentId)
        ?.subItems.find((subItem) => subItem.id === id);
      handleChangeEditableSubItem(subItemToEdit || null);
      toggleSubItemModal(true);
      handleSubItemParentId(parentId);
    },
    [items, handleChangeEditableSubItem, handleSubItemParentId, toggleSubItemModal],
  );

  const handleDeleteItem = useCallback(
    (e: { stopPropagation: () => void }, id: number) => {
      e.stopPropagation();
      dispatch(removeItemAction({ id }));
    },
    [dispatch],
  );

  const handleDeactivatingItem = useCallback(
    (e: { stopPropagation: () => void }, id: number | null) => {
      e.stopPropagation();
      setDeactivatingItemId(id);
    },
    [],
  );

  const handleDeleteSubItem = useCallback(
    (e: { stopPropagation: () => void }, id: number, parentId: number) => {
      e.stopPropagation();
      dispatch(removeItemAction({ id, parentId }));
    },
    [dispatch],
  );

  const handleDeactivateItem = useCallback(
    (value: itemTypes) => {
      if (value.active) {
        dispatch(
          deactivateItemAction({
            id: value.id,
            currentPage: currentPage - 1,
            showDeactivatedItems,
            subjectId: Number(subjectId),
            onSuccess: () => setDeactivatingItemId(null),
          }),
        );
      } else {
        dispatch(
          reactivateItemAction({
            id: value.id,
            currentPage: currentPage - 1,
            showDeactivatedItems,
            subjectId: Number(subjectId),
            onSuccess: () => setDeactivatingItemId(null),
          }),
        );
      }
    },
    [currentPage, dispatch, showDeactivatedItems, subjectId],
  );

  const moveCard = useCallback(
    (dragIndex: number, hoverIndex: number, itemId: number) => {
      const selectedItem = items.find((item) => item.id === itemId);
      const reorderSubItems = selectedItem?.subItems || [];
      reorderSubItems[dragIndex] = reorderSubItems.splice(
        hoverIndex,
        1,
        reorderSubItems[dragIndex],
      )[0];
      const finalItems = items.map((finalItem) =>
        finalItem.id === itemId ? { ...finalItem, subItems: reorderSubItems } : finalItem,
      );
      setItems(finalItems);
    },
    [items],
  );

  const handleDragEnd = useCallback(
    (dragIndex: number, hoverIndex: number, itemId: number) => {
      const selectedItem = items.find((item) => item.id === itemId);
      const previousSubItemId = selectedItem?.subItems?.[hoverIndex - 1]?.id || null;
      const nextSubItemId = selectedItem?.subItems?.[hoverIndex + 1]?.id || null;
      dispatch(
        reorderSubItemsAction({
          itemId,
          subItemId: dragIndex,
          previousSubItemId,
          nextSubItemId: previousSubItemId ? null : nextSubItemId,
        }),
      );
    },
    [dispatch, items],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(
        getItemsAction({
          subjectId: Number(subjectId),
          page: page - 1,
          size: 10,
          showDeactivatedItems,
        }),
      );
    },
    [dispatch, subjectId, showDeactivatedItems],
  );

  const handleScrollPosition = useCallback(() => {
    if (myElementRef.current) {
      const elTop: { scrollTop: number } = myElementRef.current;
      if (
        (elTop.scrollTop === 0 && scrollPosition !== 0) ||
        (elTop.scrollTop !== 0 && scrollPosition === 0)
      ) {
        setScrollPosition(elTop.scrollTop);
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    dispatch(
      getItemsAction({ subjectId: Number(subjectId), page: 0, size: 10, showDeactivatedItems }),
    );
  }, [dispatch, subjectId, showDeactivatedItems]);

  useEffect(() => {
    setItems(structuredClone(backendItems));
  }, [backendItems]);

  return (
    <div>
      <div className={styles.subjectContainer}>
        <div className={cc([{ [styles.scrollView]: scrollPosition > 0 }])}>
          <div className={styles.breadcrumbWrapper}>
            <Breadcrumb
              items={[
                { title: 'Fächer', url: '/subjects' },
                { title: selectedSubject?.name || '', url: '' },
              ]}
            />
          </div>
          <div className={styles.titleWrapper}>
            <Title level={1}>{selectedSubject?.name || ''}</Title>
          </div>
        </div>
        <div
          className={styles.singleSubjectInner}
          onScroll={handleScrollPosition}
          ref={myElementRef}
        >
          <div className={styles.descWrapper}>
            <Collapse
              className={cc([
                styles.collapseWrapper,
                {
                  [styles.descCollapse]: true,
                },
              ])}
            >
              <Panel
                key=''
                header={
                  <div>
                    <div className={styles.typWrapper}>
                      Typ{' '}
                      <Title level={5}>{selectedSubject?.optional ? 'Optional' : 'Bindend'}</Title>
                    </div>
                    <div className={styles.typWrapper}>
                      Jahrgänge{' '}
                      <Title level={5}>
                        {structuredClone(selectedSubject?.schoolYears)
                          ?.sort((a: number, b: number) => a - b)
                          .join(', ')}
                      </Title>
                    </div>
                  </div>
                }
              >
                <div className={styles.descInner}>
                  Beschreibung
                  <Text level={2}>{selectedSubject?.description || ''}</Text>
                </div>
              </Panel>
            </Collapse>
          </div>
          <div className={styles.subjectContent}>
            <Row justify='space-between'>
              <Col>
                <div className={styles.filtersWrapper}>
                  <span className={styles.itemCount}>
                    {itemsCount} {itemsCount > 1 ? 'items' : 'item'}
                  </span>
                </div>
              </Col>
              <Col>
                <Button
                  type='primary'
                  icon={<PlusOutlined />}
                  onClick={() => toggleManageItemModal(true)}
                >
                  Item hinzufügen
                </Button>
              </Col>
            </Row>
            <div>
              <Checkbox
                checked={showDeactivatedItems}
                onChange={(e: CheckboxChangeEvent) => {
                  setCurrentPage(1);
                  setShowDeactivatedItems(e.target.checked);
                }}
              >
                Deaktivierte Item anzeigen
              </Checkbox>
            </div>
            <div className={styles.itemsWrapper}>
              {items?.map((value) => {
                return (
                  <Collapse
                    key={value.id}
                    className={cc([
                      styles.collapseWrapper,
                      { [styles.collapseDisabled]: !value.active },
                    ])}
                    collapsible={!value.active ? 'disabled' : undefined}
                  >
                    <Panel
                      header={<CollapseHeader item={value} />}
                      key={value.id}
                      extra={
                        <>
                          {deletingItemId === value.id && (
                            <Confirm
                              closable
                              okText='Löschen'
                              cancelText='Abbrechen'
                              centered
                              message={
                                <div>
                                  <Title level={5}>{`Das Item ${value?.name}`}</Title>
                                  <ul>
                                    <li>
                                      <Title
                                        level={5}
                                      >{`beinhaltet ${value?.subItems.length} Subitems`}</Title>
                                    </li>
                                    <li>
                                      <Title level={5}>{`ist ${
                                        value?.groupsCount || 0
                                      } Gruppen zugeordnet und`}</Title>
                                    </li>
                                    <li>
                                      <Title
                                        level={5}
                                      >{`ist ${value?.studentsCount} Schülern und Schülerinnen
                                      zugeordnet`}</Title>
                                    </li>
                                  </ul>
                                  <Title level={5}>
                                    {`Durch das Löschen werden alle enthaltenen Sub-Items ebenfalls gelöscht und die Zuweisungen zu Gruppen und Schülern und Schülerinnen werden entfernt. Wollen Sie das Item ${value?.name} und alle darin enthaltenen Sub-Items wirklich löschen?`}
                                  </Title>
                                </div>
                              }
                              type='node'
                              open={confirmItem}
                              onOk={(event) => handleDeleteItem(event, value.id)}
                              onCancel={(event) => {
                                event.stopPropagation();
                                setConfirmItem(false);
                                setDeletingItemId(null);
                              }}
                            />
                          )}
                          {deactivatingItemId === value.id && (
                            <Confirm
                              closable
                              okText='Deaktivieren'
                              cancelText='Abbrechen'
                              centered
                              message={`Das Item ${value.name} ist derzeit ${value.studentsCount} Schülern und Schülerinnen zugeordnet. Sind sie sicher, dass Sie das Item ${value.name} deaktivieren wollen?`}
                              open={!!deactivatingItemId}
                              onOk={(event) => {
                                event.stopPropagation();
                                handleDeactivateItem(value);
                              }}
                              onCancel={(event) => {
                                event.stopPropagation();
                                handleDeactivatingItem(event, null);
                              }}
                            />
                          )}
                          {(!value.isAssociation || userRole === 'ASSOCIATION_ADMIN') && (
                            <div style={{ display: 'flex' }}>
                              <Dropdown
                                overlayClassName={styles.dropdownContent}
                                trigger={['click']}
                                placement='bottomRight'
                                menu={{
                                  items: [
                                    {
                                      key: '1',
                                      disabled: !value.active,
                                      label: (
                                        <span
                                          onClick={(e) => {
                                            e.stopPropagation();
                                            if (value.active) {
                                              handleEditItem(e, value.id);
                                            }
                                          }}
                                        >
                                          Ändern
                                        </span>
                                      ),
                                    },
                                    ...(userRole === 'SCHOOL_ADMIN'
                                      ? [
                                          {
                                            key: '2',
                                            label: (
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  if (value.active) {
                                                    handleDeactivatingItem(e, value.id);
                                                  } else {
                                                    handleDeactivateItem(value);
                                                  }
                                                }}
                                              >
                                                {value.active ? ' Deaktivieren' : 'Reaktivieren'}
                                              </span>
                                            ),
                                          },
                                        ]
                                      : []),
                                    ...(userRole === 'SCHOOL_ADMIN' && !value.active
                                      ? [
                                          {
                                            key: '3',
                                            label: (
                                              <span
                                                onClick={(e) => {
                                                  e.stopPropagation();
                                                  setConfirmItem(true);
                                                  setDeletingItemId(value.id);
                                                }}
                                              >
                                                Löschen
                                              </span>
                                            ),
                                          },
                                        ]
                                      : []),
                                  ],
                                }}
                              >
                                <span
                                  onClick={(e) => e.stopPropagation()}
                                  style={{ cursor: 'pointer' }}
                                >
                                  <ActionsIcon />
                                </span>
                              </Dropdown>
                            </div>
                          )}
                        </>
                      }
                    >
                      <List
                        locale={{
                          emptyText: (
                            <List.Item className={styles.subItemWrapper}>
                              <Button
                                type='text'
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  handleSubItemParentId(Number(value.id));
                                  toggleSubItemModal(true);
                                }}
                              >
                                Subitem hinzufügen
                              </Button>
                            </List.Item>
                          ),
                        }}
                        size='small'
                        dataSource={value.subItems}
                        renderItem={(item, index) => (
                          <div key={index}>
                            {deletingSubItemId === item.id && (
                              <Confirm
                                closable
                                okText='Löschen'
                                cancelText='Abbrechen'
                                centered
                                message={`Das Subitem ${item.name} ist aktuell ${item.studentsCount} Schülern und Schülerinnen zugewiesen. Wenn Sie das Subitem dennoch löschen, können hierzu keine Bewertungen mehr vorgenommen werden, es können keine Beobachtungen eingetragen werden und es erscheint nicht im IzEL. Möchten Sie das Subitem ${item.name} wirklich löschen?`}
                                open={confirmSubItem}
                                onOk={(event) => handleDeleteSubItem(event, item.id, value.id)}
                                onCancel={(event) => {
                                  event.stopPropagation();
                                  setConfirmSubItem(false);
                                  setDeletingSubItemId(null);
                                }}
                              />
                            )}
                            <SubItemList
                              handleDragEnd={handleDragEnd}
                              itemId={value.id}
                              id={item.id}
                              index={index}
                              moveCard={moveCard}
                              item={item}
                              handleEditSubItem={handleEditSubItem}
                              setDeletingSubItemId={setDeletingSubItemId}
                              setConfirmSubItem={setConfirmSubItem}
                            />
                            {index === value.subItems.length - 1 && (
                              <List.Item className={styles.subItemWrapper}>
                                <Button
                                  type='text'
                                  icon={<PlusOutlined />}
                                  onClick={() => {
                                    handleSubItemParentId(Number(value.id));
                                    toggleSubItemModal(true);
                                  }}
                                >
                                  Subitem hinzufügen
                                </Button>
                              </List.Item>
                            )}
                          </div>
                        )}
                      />
                    </Panel>
                  </Collapse>
                );
              })}
            </div>
          </div>
          <div className={styles.paginationWrapper}>
            <Pagination
              pageSize={10}
              current={currentPage}
              onChange={(page: number) => handlePageChange(page)}
              total={itemsCount}
            />
          </div>
        </div>
      </div>
      <ManageItemModal
        showDeactivatedItems={showDeactivatedItems}
        currentPage={currentPage}
        handleChangeEditableItem={handleChangeEditableItem}
        editableItem={editableItem}
        addItemModalVisible={addItemModalVisible}
        toggleManageItemModal={toggleManageItemModal}
      />
      {parentId && (
        <ManageSubItemModal
          handleChangeEditableSubItem={handleChangeEditableSubItem}
          editableSubItem={editableSubItem}
          handleSubItemParentId={handleSubItemParentId}
          parentId={parentId}
          addSubItemModalVisible={addSubItemModalVisible}
          toggleSubItemModal={toggleSubItemModal}
        />
      )}
    </div>
  );
};

export default SingleSubject;
