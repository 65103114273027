import {
  clearSubjectListAction,
  subjectResponse,
  subjectTypes,
} from 'store/actions/subjects.actions';
import { ActionNames } from 'store/actions/actionNames';
import produce from 'immer';

const initialState: {
  subjects: Array<subjectTypes> | [];
  subjectCount: number;
  reorderList: { subjects: Array<subjectTypes>; count: number } | null;
} = {
  subjects: [],
  subjectCount: 0,
  reorderList: null,
};

const subjectsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: { data: subjectResponse & subjectTypes };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_SUBJECTS_SUCCESS:
        draft.subjects = action.payload.data.subjects;
        draft.subjectCount = action.payload.data.count;
        break;
      case ActionNames.GET_REORDER_LIST_SUCCESS:
        draft.reorderList = {
          count: action.payload.data.count,
          subjects: action.payload.data.subjects,
        };
        break;
      case ActionNames.CLEAR_SUBJECT_LIST:
        draft.reorderList = null;
        break;
      default:
        break;
    }
  });

export default subjectsReducer;
