import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { createGroupAction, editGroupAction } from 'store/actions/group.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'UI/Typography';
import { SelectProps } from 'antd';
import Select from 'UI/Select';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import { groupOptions } from '../../../../constants';
import { gradesRangeSelector } from 'store/selectors/schools.selectors';
import { getGroupsSelector } from 'store/selectors/groups.selectors';

type ManageGroupModalTypes = {
  manageGroupVisible: boolean;
  toggleManageGroup: (arg1: boolean) => void;
  handleSelectGroup: (arg1: number | null) => void;
  selectedGroupId: number | null;
  page: number;
};

const ManageGroupModal = ({
  manageGroupVisible,
  toggleManageGroup,
  selectedGroupId,
  handleSelectGroup,
  page,
}: ManageGroupModalTypes) => {
  const [groupName, setGroupName] = useState('');
  const [groupDesc, setGroupDesc] = useState('');
  const [groupType, setGroupType] = useState<string | null>(null);
  const [schoolYears, setSchoolYears] = useState<Array<number> | []>([]);
  const [errorMessage, setErrorMessage] = useState('');
  const options = useSelector(gradesRangeSelector) as SelectProps['options'];
  const dispatch = useDispatch();
  const groups = useSelector(getGroupsSelector);

  const editableGroup = useMemo(() => {
    return groups?.find((group) => group.id === selectedGroupId);
  }, [groups, selectedGroupId]);

  const okButtonDisabled = useMemo(() => {
    return !groupName || !groupType || !schoolYears.length;
  }, [groupName, groupType, schoolYears.length]);

  const editMode = false;

  const handleChangeErrorMessage = useCallback((value: string) => {
    setErrorMessage(value);
  }, []);

  const handleGroupName = useCallback(
    (value: string) => {
      handleChangeErrorMessage('');
      setGroupName(value);
    },
    [handleChangeErrorMessage],
  );

  const handleGroupDesc = useCallback((value: string) => {
    setGroupDesc(value);
  }, []);

  const handleGroupType = useCallback((value: string) => {
    setGroupType(value);
  }, []);

  const handleSchoolYears = useCallback(
    (value: number[]) => {
      handleChangeErrorMessage('');
      setSchoolYears(value);
    },
    [handleChangeErrorMessage],
  );

  const handleResetGroupModal = useCallback(() => {
    setGroupType('');
    setGroupDesc('');
    setGroupName('');
    setSchoolYears([]);
    toggleManageGroup(false);
    handleSelectGroup(null);
    handleChangeErrorMessage('');
  }, [handleChangeErrorMessage, handleSelectGroup, toggleManageGroup]);

  const handleCreateGroup = useCallback(() => {
    if (selectedGroupId) {
      dispatch(
        editGroupAction({
          name: groupName,
          schoolYears: schoolYears,
          id: selectedGroupId,
          description: groupDesc,
          onSuccess: () => handleResetGroupModal(),
          onFailure: (message) => handleChangeErrorMessage(message),
          page: page - 1,
        }),
      );
    } else {
      dispatch(
        createGroupAction({
          name: groupName,
          description: groupDesc,
          type: String(groupType),
          schoolYears,
          onSuccess: () => handleResetGroupModal(),
          onFailure: (message) => handleChangeErrorMessage(message),
          page: page - 1,
        }),
      );
    }
  }, [
    dispatch,
    groupDesc,
    groupName,
    groupType,
    handleChangeErrorMessage,
    handleResetGroupModal,
    page,
    schoolYears,
    selectedGroupId,
  ]);

  const handleManageGroup = useCallback(() => {
    if (!editMode) {
      handleCreateGroup();
    }
  }, [editMode, handleCreateGroup]);

  useEffect(() => {
    if (selectedGroupId && manageGroupVisible) {
      setGroupName(editableGroup?.name || '');
      setGroupDesc(editableGroup?.description || '');
      setSchoolYears(editableGroup?.schoolYears || []);
      setGroupType(editableGroup?.type || '');
    }
  }, [editableGroup, manageGroupVisible, selectedGroupId]);

  return (
    <Modal
      centered
      title={selectedGroupId ? 'Gruppe' : 'Gruppe hinzufügen'}
      open={manageGroupVisible}
      onOk={() => handleManageGroup()}
      onCancel={() => handleResetGroupModal()}
      closable
      okText={selectedGroupId ? 'Speichern' : 'Hinzufügen'}
      cancelText='Abbrechen'
      okDisabled={okButtonDisabled}
      confirmLoading={false}
      destroyOnClose
    >
      <div className={styles.groupLabel}>
        <Text level={1} bold>
          Gruppe
        </Text>
      </div>
      <Input
        error={errorMessage === 'not_unique'}
        placeholder='bitte auswählen'
        value={groupName}
        onChange={(e) => handleGroupName(e.target.value)}
        maxLength={150}
        showCount
      />
      {errorMessage === 'not_unique' && (
        <div className={styles.errorText}>Name ist bereits vorhanden</div>
      )}
      <div className={styles.descLabel}>
        <Text level={1} bold>
          Beschreibung (Optional)
        </Text>
      </div>
      <Input.TextArea
        value={groupDesc}
        placeholder='bitte auswählen'
        onChange={(e: ChangeEvent<HTMLTextAreaElement>) => handleGroupDesc(e.target.value)}
        maxLength={1000}
        showCount
      />
      <div className={styles.groupSelectLabel}>
        <Text level={1} bold>
          Art der Gruppe
        </Text>
      </div>
      <Select
        disabled={!!selectedGroupId}
        value={groupType}
        onChange={(type) => handleGroupType(type)}
        showSearch={false}
        options={groupOptions}
        showArrow
        placeholder='Art der Gruppe'
        style={{ width: '100%' }}
      />
      <div className={styles.schoolYearsLabel}>
        <Text level={1} bold>
          Jahrgänge
        </Text>
      </div>
      <Select
        status={errorMessage === 'grades_out_of_range' ? 'error' : ''}
        value={schoolYears}
        onChange={(years) => handleSchoolYears(years)}
        showSearch={false}
        options={options}
        showArrow
        placeholder='Jahrgänge'
        mode='multiple'
        style={{ width: '100%' }}
      />
      {errorMessage === 'grades_out_of_range' && (
        <div className={styles.errorText}>
          Bitte prüfen Sie die Jahrgangsstufen unter &quot;Meine Schule&quot;. Diese scheinen nicht
          korrekt zu sein.
        </div>
      )}
    </Modal>
  );
};

export default ManageGroupModal;
