import { DatePicker as AntdDatePicker, ConfigProvider } from 'antd';
import CalendarLocale from 'rc-picker/lib/locale/de_DE';
import { primary40 } from '../../colors';

const locale: any = {
  lang: {
    ...CalendarLocale,
    shortWeekDays: ['So', 'Mo', 'Di', 'Mi', 'Do', 'Fr', 'Sa'],
    shortMonths: [
      'Januar',
      'Februar',
      'März',
      'April',
      'Mai',
      'Juni',
      'Juli',
      'August',
      'September',
      'Oktober',
      'November',
      'Dezember',
    ],
  },
};

const DatePicker = ({ ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <AntdDatePicker {...props} locale={locale} size='large' />
    </ConfigProvider>
  );
};

export default DatePicker;
