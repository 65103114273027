import {
  getGroupsStudentsAction,
  groupStudentType,
  removeStudentFromGroupAction,
} from 'store/actions/group.actions';
import {
  getGroupStudentsCountSelector,
  getGroupStudentsSelector,
} from 'store/selectors/groups.selectors';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'UI/Spinner';
import Table from 'UI/Table';
import { Dropdown } from 'antd';
import styles from 'pages/Students/styles.module.css';
import ActionsIcon from '../../../../UI/Icons/ActionsIcon';
import Modal from '../../../../UI/Modal';
import { getUserRole } from 'store/selectors/users.selectors';
import Pagination from '../../../../UI/Pagination';

const { Confirm } = Modal;

const columns = (
  toggleConfirm: (arg1: boolean) => void,
  handleChangeSelectedStudent: (arg1: number | null) => void,
  groupType: string,
  userRole: string,
) => [
  {
    title: 'Name, Vorname',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Stammgruppe',
    dataIndex: 'coreGroup',
    key: 'coreGroup',
    render: (record: { name: string }) => record?.name,
  },
  {
    title: 'Jahrgang',
    dataIndex: 'schoolYear',
    key: 'schoolYear',
  },
  {
    ...(groupType !== 'CORE_GROUP' || userRole === 'SCHOOL_ADMIN'
      ? {
          title: '',
          dataIndex: 'id',
          key: 'id',
          render: (record: number) => {
            return (
              <Dropdown
                trigger={['click']}
                overlayClassName={styles.dropdownContent}
                placement='bottomRight'
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleChangeSelectedStudent(record);
                            toggleConfirm(true);
                          }}
                        >
                          Entfernen
                        </span>
                      ),
                    },
                  ],
                }}
              >
                <ActionsIcon />
              </Dropdown>
            );
          },
          width: 50,
        }
      : {}),
  },
];

const GroupStudents = ({
  handleChangeSelectedStudents,
  groupType,
  selectedStudents,
}: {
  handleChangeSelectedStudents: (arg1: { id: number }[]) => void;
  groupType: string;
  selectedStudents: { id: number }[];
}) => {
  const [confirmRemoveVisible, setConfirmRemoveVisible] = useState(false);
  const [selectedStudent, setSelectedStudent] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const groupStudents = useSelector(getGroupStudentsSelector);
  const studentsCount = useSelector(getGroupStudentsCountSelector);
  const userRole = useSelector(getUserRole);

  const selectedStudentsIds = useMemo(
    () => selectedStudents.map((value: { id: number }) => value.id),
    [selectedStudents],
  );

  const groupStudentsData = useMemo(() => {
    return groupStudents?.map((student: groupStudentType) => {
      return {
        ...student,
        fullName: student.surname + ', ' + student.name,
      };
    });
  }, [groupStudents]);

  const toggleConfirm = useCallback((value: boolean) => {
    setConfirmRemoveVisible(value);
  }, []);

  const handleChangeSelectedStudent = useCallback((value: number | null) => {
    setSelectedStudent(value);
  }, []);

  const handleRemoveStudent = useCallback(() => {
    dispatch(
      removeStudentFromGroupAction({
        studentId: Number(selectedStudent),
        groupId: Number(groupId),
      }),
    );
    setConfirmRemoveVisible(false);
    setSelectedStudent(null);
  }, [dispatch, groupId, selectedStudent]);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(getGroupsStudentsAction({ id: Number(groupId), page: page - 1 }));
    },
    [dispatch, groupId],
  );

  useEffect(() => {
    dispatch(getGroupsStudentsAction({ id: Number(groupId), page: 0 }));
  }, [dispatch, groupId]);

  return (
    <div>
      <Table
        rowSelection={
          userRole === 'TEACHER'
            ? {
                type: 'checkbox',
                onChange: (selectedRowKeys: number[], selectedRows) => {
                  handleChangeSelectedStudents(selectedRows);
                },
                preserveSelectedRowKeys: true,
                selectedRowKeys: selectedStudentsIds,
              }
            : undefined
        }
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={groupStudentsData}
        columns={columns(toggleConfirm, handleChangeSelectedStudent, groupType, userRole)}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={studentsCount}
        />
      </div>
      <Confirm
        closable
        okText='Entfernen'
        cancelText='Abbrechen'
        centered
        message='Wenn Sie den Schüler / die Schülerin aus der Gruppe entfernen, werden ebenfalls alle Zuordnungen zu Lerninhalten entfernt.  Sind Sie sicher, dass Sie dies wollen?'
        open={confirmRemoveVisible}
        onOk={handleRemoveStudent}
        onCancel={() => {
          setConfirmRemoveVisible(false);
          setSelectedStudent(null);
        }}
      />
    </div>
  );
};
export default GroupStudents;
