import styles from './../styles.module.css';

const Impressum = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mb12}>
        <strong>Impressum</strong>
      </div>
      <div className={styles.mb12}>Verantwortlich für Inhalte i. S. v § 5 TMG</div>
      <div>Montessori Landesverbandes Bayern e.V.</div>
      <div>Hirtenstraße 26</div>
      <div>80335 München</div>
      <div>Tel. 089-54801730</div>
      <div> Fax 089-548017333</div>
      <div className={styles.mb20}>E-Mail: izeladmin@montessoribayern.de</div>
      <div className={styles.mb8}>
        vertreten durch den jeweils einzelvertretungsberechtigten Vorstand Monika Ullmann und
        Manfred Burghardt
      </div>
      <div>Registergericht: Amtsgericht München</div>
      <div className={styles.mb12}>Registernummer: VR11660</div>
      <div className={styles.mb20}>
        Umsatzsteueridentifikationsnummer gemäß § 27a Umsatzsteuergesetz: DE DE331613511
      </div>
      <div>
        Verantwortlich für zentrale journalistisch-redaktionell gestaltete Inhalte i.S.v. § 18 Abs.
        2 MStV
      </div>
      <div>
        <b>Manfred Burghardt</b>
      </div>
      <div>
        <i>c/o Montessori Landesverbandes Bayern e.V.</i>
      </div>
      <div>
        <i>Hirtenstraße 26</i>
      </div>
      <div className={styles.mb20}>
        <i>80335 München</i>
      </div>
      <div className={styles.mb12}>
        Haftung für InhalteDie Inhalte <br /> unserer Seiten wurden mit größter Sorgfalt erstellt.
        Als Diensteanbieter sind wir gemäß § 7 Abs.1 TMG für eigene Inhalte auf diesen Seiten nach
        den allgemeinen Gesetzen verantwortlich. Nach §§ 8 bis 10 TMG sind wir als Diensteanbieter
        jedoch nicht generell verpflichtet, übermittelte oder gespeicherte fremde Informationen zu
        überwachen oder nach Umständen zu forschen, die auf eine rechtswidrige Tätigkeit hinweisen.
        Verpflichtungen zur Entfernung oder Sperrung der Nutzung von Informationen nach den
        allgemeinen Gesetzen bleiben hiervon unberührt. Eine diesbezügliche Haftung ist jedoch
        regelmäßig erst ab dem Zeitpunkt der Kenntnis einer konkreten Rechtsverletzung möglich. Bei
        Bekanntwerden von entsprechenden Rechtsverletzungen werden wir diese Inhalte umgehend
        entfernen.
      </div>
      <div className={styles.mb12}>
        Haftung für Links <br />
        Unser Angebot enthält Links zu externen Webseiten Dritter, auf deren Inhalte wir keinen
        Einfluss haben. Für die Inhalte der verlinkten Seiten ist stets der jeweilige Anbieter oder
        Betreiber der Seiten verantwortlich. Die verlinkten Seiten wurden zum Zeitpunkt der
        Verlinkung auf mögliche Rechtsverstöße überprüft. Rechtswidrige Inhalte waren zum Zeitpunkt
        der Verlinkung nicht erkennbar. Eine permanente inhaltliche Kontrolle der verlinkten Seiten
        ist jedoch ohne konkrete Anhaltspunkte einer Rechtsverletzung nicht zumutbar. Bei
        Bekanntwerden von Rechtsverletzungen werden wir derartige Links umgehend entfernen.
      </div>
      <div className={styles.mb12}>
        Urheberrecht <br /> Die von uns oder in unserem Auftrag erstellten Inhalte und Werke auf
        diesen Seiten unterliegen dem deutschen Urheberrecht. Die Vervielfältigung, Bearbeitung,
        Verbreitung und jede Art der Verwertung außerhalb der Grenzen des Urheberrechtes bedürfen
        der schriftlichen Zustimmung des jeweiligen Autors bzw. Erstellers. Downloads und Kopien
        dieser Seite sind nur für den privaten, nicht kommerziellen Gebrauch gestattet. Soweit die
        Inhalte auf dieser Seite nicht vom Betreiber erstellt wurden, werden die Urheberrechte
        Dritter beachtet. Insbesondere werden Inhalte Dritter als solche gekennzeichnet. Sollten Sie
        trotzdem auf eine Urheberrechtsverletzung aufmerksam werden, bitten wir um einen
        entsprechenden Hinweis. Bei Bekanntwerden von Rechtsverletzungen werden wir derartige
        Inhalte umgehend entfernen.
      </div>
      <div>Wir sind nicht bereit und nicht verpflichtet an Streitbeilegungsverfahren vor einer</div>
      <div>Verbraucherschlichtungsstelle teilzunehmen.</div>
    </div>
  );
};

export default Impressum;
