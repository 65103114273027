import Button from 'UI/Button';
import FilterIcon from 'UI/Icons/FilterIcon';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { Col, Dropdown, Popover, Row, SelectProps } from 'antd';
import Input from 'UI/Input';
import CloseIcon from '../../../../../../UI/Icons/CloseIcon';
import styles from './styles.module.css';
import ArrowDownIcon from '../../../../../../UI/Icons/ArrowDownIcon';
import Select from '../../../../../../UI/Select';
import Checkbox from '../../../../../../UI/Checkbox';
import { Text } from '../../../../../../UI/Typography';
import { useDispatch, useSelector } from 'react-redux';
import { getAllGroupsSelector } from 'store/selectors/groups.selectors';
import { getAllGroupsAction } from 'store/actions/group.actions';
import { gradesRangeSelector } from 'store/selectors/schools.selectors';

type groupStudentFilterTypes = {
  schoolYears: string[];
  groups: string[];
  handleChangeSchoolYears: (arg1: string[]) => void;
  handleChangeGroup: (arg1: string[]) => void;
  searchValue: string;
  handleChangeSearchValue: (arg1: string) => void;
};

const { Search } = Input;

const GroupStudentFilters = ({
  schoolYears,
  groups,
  handleChangeSchoolYears,
  handleChangeGroup,
  handleChangeSearchValue,
  searchValue,
}: groupStudentFilterTypes) => {
  const [schoolYearsEnabled, setSchoolYearsEnabled] = useState(false);
  const [groupsEnabled, setGroupsEnabled] = useState(false);
  const [schoolFilterOpen, setSchoolFilterOpen] = useState(false);
  const [groupFilterOpen, setGroupFilterOpen] = useState(false);
  const allGroups = useSelector(getAllGroupsSelector);
  const schoolYearOptions = useSelector(gradesRangeSelector) as SelectProps['options'];
  const dispatch = useDispatch();

  const groupsSelectValue = useMemo(() => {
    return allGroups.map((value) => {
      return {
        label: value.name,
        value: value.id,
      };
    });
  }, [allGroups]);

  const handleChangeSchoolYearsEnabled = useCallback((value: boolean) => {
    setSchoolYearsEnabled(value);
  }, []);

  const handleChangeGroupsEnabled = useCallback((value: boolean) => {
    setGroupsEnabled(value);
  }, []);

  const handleRemoveSchoolYearFilter = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleChangeSchoolYearsEnabled(false);
      handleChangeSchoolYears([]);
      setSchoolFilterOpen(false);
    },
    [handleChangeSchoolYears, handleChangeSchoolYearsEnabled],
  );

  const handleRemoveGroupsFilter = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleChangeGroupsEnabled(false);
      handleChangeGroup([]);
      setGroupFilterOpen(false);
    },
    [handleChangeGroup, handleChangeGroupsEnabled],
  );

  useEffect(() => {
    dispatch(getAllGroupsAction);
  }, [dispatch]);

  return (
    <div>
      <Row gutter={16}>
        <Col>
          <Search
            allowClear
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeSearchValue(e.target.value)}
          />
        </Col>
        <Col>
          <Dropdown
            overlayClassName={styles.dropdownContent}
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeSchoolYearsEnabled(true);
                      }}
                    >
                      Jahrgänge
                    </span>
                  ),
                },
                {
                  key: '2',
                  label: (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        handleChangeGroupsEnabled(true);
                      }}
                    >
                      Gruppen
                    </span>
                  ),
                },
              ],
            }}
            placement='bottomRight'
            trigger={['click']}
          >
            <Button icon={<FilterIcon />} type='default'>
              Filters
            </Button>
          </Dropdown>
        </Col>
      </Row>
      <Row className={styles.filtersWrapper} gutter={16}>
        {schoolYearsEnabled && (
          <Col>
            <Popover
              getPopupContainer={(node) => node.parentElement || document.body}
              trigger={['click']}
              placement='bottomLeft'
              onOpenChange={(open) => setSchoolFilterOpen(open)}
              content={
                <div className={styles.popoverInner}>
                  <div className={styles.popoverHeader}>
                    <Text level={2} bold>
                      Jahrgänge
                    </Text>
                    <Button type='default' onClick={() => handleChangeSchoolYears([])}>
                      Alles löschen
                    </Button>
                  </div>
                  <Select
                    popupClassName={styles.schoolYearSelect}
                    value={schoolYears}
                    showSearch={false}
                    options={schoolYearOptions}
                    onChange={(years) => {
                      handleChangeSchoolYears(years);
                    }}
                    showArrow={false}
                    mode='multiple'
                    style={{ width: '310px' }}
                    dropdownRender={() => <span />}
                  />
                  <div>
                    <Checkbox.Group
                      className={styles.checkboxes}
                      options={schoolYearOptions}
                      onChange={handleChangeSchoolYears}
                      value={schoolYears}
                    />
                  </div>
                </div>
              }
            >
              <div className={schoolFilterOpen ? styles.filterWrapper : styles.filterWrapperClosed}>
                <span
                  onClick={(e) => handleRemoveSchoolYearFilter(e)}
                  className={styles.removeIcon}
                >
                  <CloseIcon />
                </span>
                Jahrgänge:
                <span className={styles.arrow}>
                  <ArrowDownIcon />
                </span>
              </div>
            </Popover>
          </Col>
        )}
        <Col>
          {groupsEnabled && (
            <Popover
              getPopupContainer={(node) => node.parentElement || document.body}
              trigger={['click']}
              placement='bottomLeft'
              onOpenChange={(open) => setGroupFilterOpen(open)}
              content={
                <div className={styles.popoverInner}>
                  <div className={styles.popoverHeader}>
                    <Text level={2} bold>
                      Gruppen
                    </Text>
                    <Button type='default' onClick={() => handleChangeGroup([])}>
                      Alles löschen
                    </Button>
                  </div>
                  <Select
                    popupClassName={styles.schoolYearSelect}
                    value={groups}
                    showSearch={false}
                    options={groupsSelectValue}
                    onChange={(years) => {
                      handleChangeGroup(years);
                    }}
                    showArrow={false}
                    mode='multiple'
                    style={{ width: '310px' }}
                    dropdownRender={() => <span />}
                  />
                  <div>
                    <Checkbox.Group
                      className={styles.checkboxes}
                      options={groupsSelectValue}
                      onChange={handleChangeGroup}
                      value={groups}
                    />
                  </div>
                </div>
              }
            >
              <div className={groupFilterOpen ? styles.filterWrapper : styles.filterWrapperClosed}>
                <span onClick={(e) => handleRemoveGroupsFilter(e)} className={styles.removeIcon}>
                  <CloseIcon />
                </span>
                Gruppen:
                <span className={styles.arrow}>
                  <ArrowDownIcon />
                </span>
              </div>
            </Popover>
          )}
        </Col>
      </Row>
    </div>
  );
};

export default GroupStudentFilters;
