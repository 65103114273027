import Modal from '../../../../../../UI/Modal';
import Spinner from '../../../../../../UI/Spinner';
import Table from '../../../../../../UI/Table';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Input from '../../../../../../UI/Input';
import { useDispatch, useSelector } from 'react-redux';
import { assignSubjectsAction, getSubjectsToAssignAction } from 'store/actions/students.actions';
import { useParams } from 'react-router-dom';
import { getSubjectsToAssignSelector } from 'store/selectors/students.selectors';

type assignSubjectModalTypes = {
  assignSubjectVisible: boolean;
  toggleAssignSubject: (arg1: boolean) => void;
  page: number;
};

const { Search } = Input;

const columns = [
  {
    title: 'Fächer',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Typ',
    dataIndex: 'optional',
    key: 'optional',
    render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
  },
  {
    title: 'Autor',
    dataIndex: 'isAssociation',
    key: 'isAssociation',
    render: (record: boolean) => (record ? 'MLVB' : 'Schule'),
  },
];

const AssignSubjectModal = ({
  assignSubjectVisible,
  toggleAssignSubject,
  page,
}: assignSubjectModalTypes) => {
  const [selectedSubjects, setSelectedSubjects] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const availableSubjects = useSelector(getSubjectsToAssignSelector);

  const handleResetModal = useCallback(() => {
    toggleAssignSubject(false);
    setLoading(false);
    setSelectedSubjects([]);
  }, [toggleAssignSubject]);

  const handleAssignSubjects = useCallback(() => {
    dispatch(
      assignSubjectsAction({
        studentId: Number(studentId),
        selectedSubjects,
        onSuccess: () => handleResetModal(),
        page: page - 1,
      }),
    );
  }, [dispatch, handleResetModal, page, selectedSubjects, studentId]);

  useEffect(() => {
    dispatch(getSubjectsToAssignAction({ studentId: Number(studentId) }));
  }, [dispatch, studentId]);

  return (
    <Modal
      width={800}
      centered
      okText='Hinzufügen'
      cancelText='Abbrechen'
      title='Fach hinzufügen'
      onCancel={handleResetModal}
      closable
      open={assignSubjectVisible}
      okDisabled={!selectedSubjects.length}
      onOk={handleAssignSubjects}
      confirmLoading={loading}
      fixScroll
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: Array<number>) => setSelectedSubjects(selectedRowKeys),
          selectedRowKeys: selectedSubjects,
        }}
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={availableSubjects}
        columns={columns}
      />
    </Modal>
  );
};

export default AssignSubjectModal;
