import React, { useCallback, useEffect, useState } from 'react';
import Modal from '../../../../../UI/Modal';
import { Text } from '../../../../../UI/Typography';
import styles from './styles.module.css';
import RichText from 'components/RichText';
import { useDispatch } from 'react-redux';
import { createSubjectSummaryAction } from 'store/actions/students.actions';
import { useParams } from 'react-router-dom';
import cc from 'classcat';

type SubjectSummaryModalTypes = {
  toggleSubjectSummary: (arg1: boolean) => void;
  subjectSummaryVisible: boolean;
  groupText: string[];
  personalText: string;
  subjectId: number | null;
  page: number;
  activeReportId: number;
};

const SubjectSummaryModal = ({
  subjectSummaryVisible,
  groupText,
  toggleSubjectSummary,
  personalText,
  subjectId,
  page,
  activeReportId,
}: SubjectSummaryModalTypes) => {
  const [subjectSummary, setSubjectSummary] = useState('');
  const [createSummaryLoading, setCreateSummaryLoading] = useState(false);
  const [letterLength, setLetterLength] = useState(0);
  const [emptyText, setEmptyText] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();

  const handleChangeEmptyText = useCallback((active: boolean) => {
    setEmptyText(active);
  }, []);

  const handleChangeSummary = useCallback((value: string, length: number) => {
    setSubjectSummary(value);
    setLetterLength(length);
  }, []);

  const handleResetModal = useCallback(() => {
    toggleSubjectSummary(false);
    if (!personalText) {
      setSubjectSummary('');
    }
    setCreateSummaryLoading(false);
  }, [personalText, toggleSubjectSummary]);

  const handleCreateSummary = useCallback(() => {
    setCreateSummaryLoading(true);
    dispatch(
      createSubjectSummaryAction({
        studentId: Number(studentId),
        subjectId: Number(subjectId),
        text:
          subjectSummary === '<p><br></p>' || emptyText
            ? null
            : `<div style="font-size: 11pt; line-height: 1.5; font-family: 'Avenir Next', sans-serif" class="outer-div" >${subjectSummary}</div>`,
        onSuccess: () => handleResetModal(),
        page: page - 1,
        izelId: activeReportId,
      }),
    );
  }, [
    activeReportId,
    dispatch,
    handleResetModal,
    emptyText,
    page,
    studentId,
    subjectId,
    subjectSummary,
  ]);

  useEffect(() => {
    if (personalText) {
      setTimeout(() => {
        setSubjectSummary('');
        setSubjectSummary(personalText);
      }, 400);
    } else {
      setSubjectSummary('');
    }
  }, [personalText]);

  return (
    <Modal
      width={800}
      centered
      title='Entwicklungsbericht'
      open={subjectSummaryVisible}
      onOk={handleCreateSummary}
      onCancel={handleResetModal}
      closable
      cancelText='Abbrechen'
      okText='Speichern'
      confirmLoading={createSummaryLoading}
      okDisabled={letterLength > 5000}
    >
      <div>
        <Text level={2} bold>
          Entwicklungsbericht der Gruppe
        </Text>
      </div>
      <div className={styles.groupTextWrapper}>
        {groupText.length
          ? groupText.map((text, index) => {
              return (
                <div
                  key={index}
                  className={`${styles.groupSummaryWrapper} ql-editor`}
                  dangerouslySetInnerHTML={{
                    __html: text,
                  }}
                />
              );
            })
          : 'Für dieses Fach wurde (noch) kein Gruppenentwicklungsbericht verfasst'}
      </div>
      <div>
        <Text level={2} bold>
          Persönlicher Entwicklungsbericht
        </Text>
      </div>
      <div className={styles.editorWrapper}>
        <RichText
          value={subjectSummary}
          onChange={handleChangeSummary}
          emptyCheck={handleChangeEmptyText}
        />
      </div>
      <div
        className={cc([
          styles.editorCount,
          {
            [styles.error]: letterLength > 5000,
          },
        ])}
      >
        {letterLength}/5000
      </div>
    </Modal>
  );
};

export default SubjectSummaryModal;
