import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const UploadImageSVG = () => {
  return (
    <svg width='25' height='24' viewBox='0 0 25 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M18.5 20H4.5V6H13.5V4H4.5C3.4 4 2.5 4.9 2.5 6V20C2.5 21.1 3.4 22 4.5 22H18.5C19.6 22 20.5 21.1 20.5 20V11H18.5V20ZM10.71 16.83L8.75 14.47L6 18H17L13.46 13.29L10.71 16.83ZM20.5 4V1H18.5V4H15.5C15.51 4.01 15.5 6 15.5 6H18.5V8.99C18.51 9 20.5 8.99 20.5 8.99V6H23.5V4H20.5Z'
        fill='#545454'
      />
    </svg>
  );
};

const UploadImageIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={UploadImageSVG} {...props} />;
};

export default UploadImageIcon;
