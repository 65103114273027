import { Text } from 'UI/Typography';
import Modal from 'UI/Modal';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import {
  createItemObservationAction,
  createStudentObservationAction,
  createSubItemObservationAction,
  createSubjectObservationAction,
} from 'store/actions/report.actions';
import { useParams } from 'react-router-dom';
import RichText from 'components/RichText';
import styles from './styles.module.css';
import cc from 'classcat';

type observationModalTypes = {
  subjectId: number;
  itemId: number;
  subItemId: number;
  toggleObservationModal: (arg1: boolean) => void;
  observationModalVisible: boolean;
  observationType: 'student' | 'subject' | 'item' | 'subItem' | null;
  breadCrumbValue: string;
};

const AddObservationModal = ({
  subjectId,
  itemId,
  subItemId,
  toggleObservationModal,
  observationModalVisible,
  observationType,
  breadCrumbValue,
}: observationModalTypes) => {
  const dispatch = useDispatch();
  const [personalLetter, setPersonalLetter] = useState('');
  const [summaryLength, setSummaryLength] = useState(0);
  const { studentId } = useParams();
  const breadCrumbFirstPart = useMemo(() => {
    const splitBreadcrumb = breadCrumbValue.split('/');
    splitBreadcrumb.pop();
    return splitBreadcrumb.join(' / ');
  }, [breadCrumbValue]);

  const breadCrumbLastPart = useMemo(() => {
    const splitBreadcrumb = breadCrumbValue.split('/');
    return ` ${splitBreadcrumb.length > 1 ? '/' : ''} ${
      splitBreadcrumb[splitBreadcrumb.length - 1]
    }`;
  }, [breadCrumbValue]);

  const handleResetModal = useCallback(() => {
    toggleObservationModal(false);
    setPersonalLetter('');
    setSummaryLength(0);
  }, [toggleObservationModal]);

  const handleChangePersonalLetter = useCallback((value: string, length: number) => {
    setPersonalLetter(value);
    setSummaryLength(length);
  }, []);

  const handleCreateObservation = useCallback(() => {
    if (observationType === 'student') {
      dispatch(
        createStudentObservationAction({
          studentId: Number(studentId),
          text: personalLetter,
        }),
      );
    } else if (observationType === 'subject') {
      dispatch(
        createSubjectObservationAction({
          subjectId,
          studentId: Number(studentId),
          text: personalLetter,
        }),
      );
    } else if (observationType === 'item') {
      dispatch(
        createItemObservationAction({
          itemId,
          studentId: Number(studentId),
          text: personalLetter,
        }),
      );
    } else {
      dispatch(
        createSubItemObservationAction({
          subItemId,
          itemId,
          studentId: Number(studentId),
          text: personalLetter,
        }),
      );
    }
    handleResetModal();
  }, [
    dispatch,
    handleResetModal,
    itemId,
    observationType,
    personalLetter,
    studentId,
    subItemId,
    subjectId,
  ]);

  return (
    <Modal
      destroyOnClose
      width={800}
      centered
      title='Beobachtung'
      open={observationModalVisible}
      onOk={handleCreateObservation}
      onCancel={() => handleResetModal()}
      closable
      cancelText='Abbrechen'
      okText='Speichern'
      okDisabled={summaryLength > 2000}
    >
      <div className={styles.breadCrumbWrapper}>
        <Text level={1}>{breadCrumbFirstPart}</Text>
        <Text level={1} bold>
          {breadCrumbLastPart}
        </Text>
      </div>
      <div className={styles.editorWrapper}>
        <RichText value={personalLetter} onChange={handleChangePersonalLetter} />
      </div>
      <div
        className={cc([
          styles.editorCount,
          {
            [styles.error]: summaryLength > 2000,
          },
        ])}
      >
        {summaryLength}/2000
      </div>
    </Modal>
  );
};

export default AddObservationModal;
