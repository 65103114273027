import { ActionNames } from 'store/actions/actionNames';

export type getGroupsParamTypes = {
  page: number;
  size: number;
  showOnlyTeacherGroups?: boolean;
  name?: string;
  onSuccess?: () => void;
};

export type createGroupParamTypes = {
  name: string;
  description: string;
  type: string;
  schoolYears: Array<number>;
  onSuccess: () => void;
  onFailure: (arg1: string) => void;
  page: number;
};

export type groupTypes = {
  id: number;
  name: string;
  studentsNumber: number;
  schoolYears: number[];
  type: string;
  description: string;
};

export type editGroupParamTypes = {
  name: string;
  id: number;
  schoolYears: number[];
  description: string;
  onSuccess: () => void;
  onFailure: (arg1: string) => void;
  page: number;
};

export type groupsResponse = {
  count: number;
  groups: Array<groupTypes>;
};

export type allGroupsResponse = Array<{ id: number; name: string }>;

export type groupDetailsParamTypes = {
  id: number;
};

export type groupDetailsResponseType = {
  id: number;
  name: string;
  studentsNumber: number;
  schoolYears: number[];
  type: string;
  description: string;
};

export type getGroupStudentsParamTypes = {
  id: number;
  page: number;
  all?: boolean;
};

export type removeGroupParamTypes = {
  id: number;
  keepAssignments: boolean;
  page: number;
  showTeachers: boolean;
  onSuccess: () => void;
};

export type getGroupStudentsToAddParamTypes = {
  id: number;
  name?: string;
  groups: string[];
  schoolYears: string[];
};

export type getGroupSubjectsToAddParamTypes = {
  id: number;
  name?: string;
};

export type getGroupTeachersToAddParamTypes = {
  id: number;
  name?: string;
};

export type groupStudentType = {
  id: number;
  name: string;
  schoolYear: number;
  surname: string;
  groups: Array<{ id: number; name: string }>;
};

export type groupStudentsResponseType = {
  count: number;
  students: Array<groupStudentType>;
};

export type groupTeachersResponseType = {
  count: number;
  teachers: Array<groupStudentType>;
};

export type groupSubjectType = {
  id: number;
  isAssociation: boolean;
  items: number;
  name: string;
  optional: boolean;
};

export type groupSubjectsResponseType = {
  count: number;
  subjects: Array<groupSubjectType>;
};

export type getSubjectsToAddResponseTypes = Array<{
  id: number;
}>;

export type getTeachersToAddResponseTypes = Array<{
  id: number;
}>;

export type getStudentsToAddResponseTypes = Array<{
  id: number;
}>;

export type addSubjectToGroupParamTypes = { id: number; subjects: number[] };

export type removeSubjectFromGroupParamTypes = {
  groupId: number;
  subjectId: number;
  onSuccess: () => void;
};

export type removeTeacherFromGroupParamTypes = {
  groupId: number;
  teacherId: number;
  onSuccess: () => void;
};

export type addTeacherToGroupParamTypes = {
  id: number;
  type: string;
  subjectIds: string[];
  groupId: number;
};

export type addStudentToGroupParamTypes = {
  id: number;
  students: number[];
};

export type removeStudentFromGroupParamTypes = {
  studentId: number;
  groupId: number;
};

export type createGroupSubjectSummaryParamTypes = {
  subjectId: number;
  groupId: number;
  text: string | null;
  onSuccess: () => void;
};

export type getGroupAllSubjectsParamTypes = {
  id: number;
  all: boolean;
};

export type getGroupItemsParamTypes = {
  id: number;
  subjectId: number;
};

export type getGroupItemsResponseTypes = Array<{ id: number; name: string }>;

export type getGroupSubItemsParamTypes = {
  id: number;
  subjectId: number;
  itemId: number;
};

export type getGroupSubItemsResponseTypes = Array<{ id: number; name: string }>;

export type groupAllSubjectsTypes = Array<{ id: number; name: string }>;

export type createGroupObservationParamTypes = {
  id: number;
  studentIds: number[];
  subjectId: number;
  itemId?: number;
  subItemId?: number;
  text: string;
  onSuccess?: () => void;
};

export const getGroupsAction = (params: getGroupsParamTypes) => ({
  type: ActionNames.GET_GROUPS,
  params,
});
export const getGroupsActionSuccess = (groups: groupsResponse) => ({
  type: ActionNames.GET_GROUPS_SUCCESS,
  payload: groups,
});

export const getAllGroupsAction = { type: ActionNames.GET_ALL_GROUPS };

export const getAllGroupsActionSuccess = (groups: allGroupsResponse) => ({
  type: ActionNames.GET_ALL_GROUPS_SUCCESS,
  payload: groups,
});

export const getGroupDetailsAction = (params: groupDetailsParamTypes) => ({
  type: ActionNames.GET_GROUP_DETAILS,
  params,
});

export const getGroupDetailsActionSuccess = (groupDetails: groupDetailsResponseType) => ({
  type: ActionNames.GET_GROUP_DETAILS_SUCCESS,
  payload: groupDetails,
});

export const createGroupAction = (params: createGroupParamTypes) => ({
  type: ActionNames.CREATE_GROUP,
  params,
});

export const createGroupActionSuccess = (group: groupTypes) => ({
  type: ActionNames.CREATE_GROUP_SUCCESS,
  payload: group,
});

export const editGroupAction = (params: editGroupParamTypes) => ({
  type: ActionNames.EDIT_GROUP,
  params,
});

export const getGroupsStudentsAction = (params: getGroupStudentsParamTypes) => ({
  type: ActionNames.GET_GROUPS_STUDENTS,
  params,
});

export const removeGroupAction = (params: removeGroupParamTypes) => ({
  type: ActionNames.REMOVE_GROUP,
  params,
});

export const getGroupsStudentsActionSuccess = (groupStudents: groupStudentsResponseType) => ({
  type: ActionNames.GET_GROUPS_STUDENTS_SUCCESS,
  payload: groupStudents,
});

export const getGroupsTeachersAction = (params: getGroupStudentsParamTypes) => ({
  type: ActionNames.GET_GROUPS_TEACHERS,
  params,
});

export const getGroupsTeachersActionSuccess = (groupTeachers: groupTeachersResponseType) => ({
  type: ActionNames.GET_GROUPS_TEACHERS_SUCCESS,
  payload: groupTeachers,
});

export const getGroupsSubjectsAction = (params: getGroupStudentsParamTypes) => ({
  type: ActionNames.GET_GROUPS_SUBJECTS,
  params,
});

export const getGroupsSubjectsActionSuccess = (groupSubjects: groupSubjectsResponseType) => ({
  type: ActionNames.GET_GROUPS_SUBJECTS_SUCCESS,
  payload: groupSubjects,
});

export const getSubjectsToAddAction = (params: getGroupSubjectsToAddParamTypes) => ({
  type: ActionNames.GET_SUBJECTS_TO_ADD,
  params,
});

export const getSubjectsToAddActionSuccess = (subjectsToAdd: getSubjectsToAddResponseTypes) => ({
  type: ActionNames.GET_SUBJECTS_TO_ADD_SUCCESS,
  payload: subjectsToAdd,
});

export const AddSubjectToGroupAction = (params: addSubjectToGroupParamTypes) => ({
  type: ActionNames.ADD_SUBJECTS_TO_GROUP,
  params,
});

export const removeSubjectFromGroupAction = (params: removeSubjectFromGroupParamTypes) => ({
  type: ActionNames.REMOVE_SUBJECT_FROM_GROUP,
  params,
});

export const removeTeacherFromGroupAction = (params: removeTeacherFromGroupParamTypes) => ({
  type: ActionNames.REMOVE_TEACHER_FROM_GROUP,
  params,
});

export const AddSubjectToGroupActionSuccess = (groupSubject: Array<groupSubjectType>) => ({
  type: ActionNames.ADD_SUBJECTS_TO_GROUP_SUCCESS,
  payload: groupSubject,
});

export const getTeacherToAddAction = (params: getGroupTeachersToAddParamTypes) => ({
  type: ActionNames.GET_TEACHERS_TO_ADD,
  params,
});

export const getTeachersToAddActionSuccess = (teachersToAdd: getTeachersToAddResponseTypes) => ({
  type: ActionNames.GET_TEACHERS_TO_ADD_SUCCESS,
  payload: teachersToAdd,
});

export const AddTeacherToGroupAction = (params: addTeacherToGroupParamTypes) => ({
  type: ActionNames.ADD_TEACHER_TO_GROUP,
  params,
});

export const getStudentsToAddAction = (params: getGroupStudentsToAddParamTypes) => ({
  type: ActionNames.GET_STUDENTS_TO_ADD,
  params,
});

export const getStudentsToAddActionSuccess = (studentsToAdd: getStudentsToAddResponseTypes) => ({
  type: ActionNames.GET_STUDENTS_TO_ADD_SUCCESS,
  payload: studentsToAdd,
});

export const AddStudentToGroupAction = (params: addStudentToGroupParamTypes) => ({
  type: ActionNames.ADD_STUDENT_TO_GROUP,
  params,
});

export const removeStudentFromGroupAction = (params: removeStudentFromGroupParamTypes) => ({
  type: ActionNames.REMOVE_STUDENT_FROM_GROUP,
  params,
});

export const createGroupSubjectSummaryAction = (params: createGroupSubjectSummaryParamTypes) => ({
  type: ActionNames.CREATE_GROUP_SUBJECT_SUMMARY,
  params,
});

export const getGroupsAllSubjectsAction = (params: getGroupAllSubjectsParamTypes) => ({
  type: ActionNames.GET_GROUP_ALL_SUBJECTS,
  params,
});

export const getGroupsAllSubjectsActionSuccess = (subjects: groupAllSubjectsTypes) => ({
  type: ActionNames.GET_GROUP_ALL_SUBJECTS_SUCCESS,
  payload: subjects,
});

export const getGroupItemsAction = (params: getGroupItemsParamTypes) => ({
  type: ActionNames.GET_GROUP_ITEMS,
  params,
});

export const getGroupItemsActionSuccess = (items: getGroupItemsResponseTypes) => ({
  type: ActionNames.GET_GROUP_ITEMS_SUCCESS,
  payload: items,
});

export const getGroupSubItemsAction = (params: getGroupSubItemsParamTypes) => ({
  type: ActionNames.GET_GROUP_SUB_ITEMS,
  params,
});

export const getGroupSubItemsActionSuccess = (subItems: getGroupSubItemsResponseTypes) => ({
  type: ActionNames.GET_GROUP_SUB_ITEMS_SUCCESS,
  payload: subItems,
});

export const createGroupObservationAction = (params: createGroupObservationParamTypes) => ({
  type: ActionNames.CREATE_GROUP_OBSERVATION,
  params,
});
