import styles from './styles.module.css';
import React, { ReactElement, ReactNode, ElementType } from 'react';
import cc from 'classcat';
type TitleType = {
  level: 1 | 3 | 4 | 5;
  children: string | ReactElement;
  style?: object;
  bold?: boolean;
  className?: string;
};

export const Title = ({ level, children, bold, className, ...props }: TitleType): ReactElement => {
  const Element = `h${level}` as ElementType;
  return (
    <Element
      className={cc([
        styles.heading,
        className,
        styles[`level-${level}`],
        {
          [styles.bold]: bold,
        },
      ])}
      {...props}
    >
      {children}
    </Element>
  );
};

type TextType = {
  level: 1 | 2 | 3;
  children: string | ReactNode;
  italic?: boolean;
  bold?: boolean;
  className?: string;
};

export const Text = ({ children, bold, italic, level, className, ...props }: TextType) => {
  return (
    <p
      className={cc([
        styles[`text-${level}`],
        className,
        {
          [styles.bold]: bold,
          [styles.italic]: italic,
        },
      ])}
      {...props}
    >
      {children}
    </p>
  );
};
