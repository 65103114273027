import Tabs from '../../UI/Tabs';
import React, { useState } from 'react';
import SchoolInfo from 'pages/SchoolManagement/__partials/SchoolInfo';
import styles from './styles.module.css';
import { Title } from '../../UI/Typography';
import SchoolYear from 'pages/SchoolManagement/__partials/SchoolYear';

const items = () => [
  {
    label: <span style={{ padding: '0 40px' }}>Schuljahr-Management</span>,
    key: 'schoolYear',
    children: <SchoolYear />,
  },
  {
    label: <span style={{ padding: '0 40px' }}>Allgemeine Informationen</span>,
    key: 'schoolInfo',
    children: <SchoolInfo />,
  },
];

const SchoolManagement = () => {
  const [selectedTab, setSelectedTab] = useState('');
  return (
    <div className={styles.schoolInfoContainer}>
      <div className={styles.titleWrapper}>
        <Title level={1}>Meine Schule</Title>
      </div>
      <div className={styles.tabsContainer}>
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey='1'
          items={items()}
          onChange={(key: string) => setSelectedTab(key)}
        />
      </div>
    </div>
  );
};

export default SchoolManagement;
