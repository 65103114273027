const EventEmitter = () => {
  const _listeners: Array<() => void> = [];

  return {
    on: (listener: () => void) => {
      _listeners.push(listener);
    },
    emit: () => {
      _listeners.map((listener) => listener());
    },
  };
};

export const eventEmitter = EventEmitter();
