import { ActionNames } from 'store/actions/actionNames';
import {
  evalDataTypes,
  getAllObservationsResponseTypes,
  getEvaluationsResponseTypes,
  getObservationResponseTypes,
  getObservationSubjectsResponseTypes,
  getPersonalLetterResponseTypes,
  getReportResponseTypes,
  getSchoolYearsResponseTypes,
  practiceResponseType,
  practiceTypes,
} from 'store/actions/report.actions';
import produce from 'immer';

const initialState: {
  report: getReportResponseTypes | [];
  observations: getObservationResponseTypes | null;
  evaluations: getEvaluationsResponseTypes | null;
  practice: practiceResponseType | null;
  personalLetter: getPersonalLetterResponseTypes | null;
  allObservations: getAllObservationsResponseTypes | null;
  schoolYears: getSchoolYearsResponseTypes | [];
  observationSubjects: getObservationSubjectsResponseTypes | [];
  observationItems: getObservationSubjectsResponseTypes | [];
  observationSubItems: getObservationSubjectsResponseTypes | [];
} = {
  report: [],
  observations: null,
  evaluations: null,
  practice: null,
  personalLetter: null,
  allObservations: null,
  schoolYears: [],
  observationSubjects: [],
  observationItems: [],
  observationSubItems: [],
};

const reportReducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: practiceResponseType &
        practiceTypes &
        getReportResponseTypes &
        getObservationResponseTypes &
        getEvaluationsResponseTypes &
        getPersonalLetterResponseTypes &
        getAllObservationsResponseTypes &
        getSchoolYearsResponseTypes &
        getObservationSubjectsResponseTypes &
        evalDataTypes;
    };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_REPORT_SUCCESS:
        draft.report = action.payload.data;
        break;
      case ActionNames.GET_SCHOOL_YEARS_SUCCESS:
        draft.schoolYears = action.payload.data;
        break;
      case ActionNames.GET_OBSERVATIONS_SUCCESS:
        draft.observations = action.payload.data;
        break;
      case ActionNames.GET_SUB_ITEM_EVALUATION_SUCCESS:
        draft.evaluations = action.payload.data;
        break;
      case ActionNames.EVALUATE_SUB_ITEM_SUCCESS:
        if (
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          !draft.report.hasAnyEvaluations &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          action.payload.scaleId !== 12 &&
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          action.payload.scaleId !== 11
        ) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          draft.report.hasAnyEvaluations = true;
        }
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects.find((sub: any) => sub.id === action.payload.data.subjectId).filled =
          action.payload.data.subjectEvaluationData.filled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects.find(
          (sub: any) => sub.id === action.payload.data.subjectId,
        ).selfEvaluationMatches = action.payload.data.subjectEvaluationData.selfEvaluationMatches;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects.find(
          (sub: any) => sub.id === action.payload.data.subjectId,
        ).selfEvaluationFilled = action.payload.data.subjectEvaluationData.selfEvaluationFilled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId).filled =
          action.payload.data.itemEvaluationData.filled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId).selfEvaluationMatches =
          action.payload.data.itemEvaluationData.selfEvaluationMatches;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId).selfEvaluationFilled =
          action.payload.data.itemEvaluationData.selfEvaluationFilled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId)
          .subItems.find(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (subItem: any) => subItem.id === action.payload.subItemId,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          ).evaluatedScaleValueId = action.payload.scaleId;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId)
          .subItems.find(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (subItem: any) => subItem.id === action.payload.subItemId,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          ).evaluatedScaleValueId = action.payload.scaleId;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId)
          .subItems.find(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (subItem: any) => subItem.id === action.payload.subItemId,
          ).selfEvaluationMatches = action.payload.data.subItemEvaluationData.selfEvaluationMatches;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.report.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId)
          .subItems.find(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (subItem: any) => subItem.id === action.payload.subItemId,
          ).selfEvaluationFilled = action.payload.data.subItemEvaluationData.selfEvaluationFilled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        if (action.payload.self) {
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          // @ts-ignore
          draft.report.subjects
            .find((sub: any) => sub.id === action.payload.data.subjectId)
            .items.find((item: any) => item.id === action.payload.data.itemId)
            .subItems.find(
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              (subItem: any) => subItem.id === action.payload.subItemId,
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
            ).selfEvaluatedScaleValueId = action.payload.scaleId;
        }

        break;
      case ActionNames.GET_PRACTICE_SUCCESS:
        draft.practice = action.payload.data;
        break;
      case ActionNames.CREATE_PRACTICE_SUCCESS:
        const updated = draft.practice?.internships ? [...draft.practice.internships] : [];
        updated.unshift(action.payload.data);
        if (draft.practice) {
          draft.practice.internships = updated;
        }
        break;
      case ActionNames.UPDATE_PRACTICE_SUCCESS:
        const editedPractice = action.payload.data;
        const updatedPractices =
          draft.practice?.internships.map((val) => {
            return val.id === editedPractice.id ? { ...editedPractice } : { ...val };
          }) || [];
        if (draft.practice) {
          draft.practice.internships = updatedPractices;
        }
        break;
      case ActionNames.REMOVE_PRACTICE_SUCCESS:
        const filtered =
          state.practice?.internships.filter((item) => item.id !== action.payload.data.id) || [];
        if (draft.practice) {
          draft.practice.internships = filtered;
        }
        break;
      case ActionNames.GET_PERSONAL_LETTER_SUCCESS:
        draft.personalLetter = action.payload.data;
        break;
      case ActionNames.GET_ALL_OBSERVATIONS_SUCCESS:
        if (!draft.allObservations) {
          draft.allObservations = action.payload.data;
        } else if (draft.allObservations.data) {
          draft.allObservations.data = [...draft.allObservations.data, ...action.payload.data.data];
        } else {
          draft.allObservations.observations = [
            ...draft.allObservations.observations,
            ...action.payload.data.observations,
          ];
        }
        break;
      case ActionNames.REMOVE_ALL_OBSERVATIONS:
        draft.allObservations = null;
        break;
      case ActionNames.GET_OBSERVATION_SUBJECTS_SUCCESS:
        draft.observationSubjects = action.payload.data;
        break;
      case ActionNames.GET_OBSERVATION_ITEMS_SUCCESS:
        draft.observationItems = action.payload.data;
        break;
      case ActionNames.GET_OBSERVATION_SUB_ITEMS_SUCCESS:
        draft.observationSubItems = action.payload.data;
        break;
      default:
        break;
    }
  });

export default reportReducer;
