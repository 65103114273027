import { useLocation, useParams } from 'react-router-dom';
import SingleSubject from 'pages/SingleSubject';
import SingleGroup from 'pages/SingleGroup';
import Students from 'pages/Students';
import Subjects from 'pages/Subjects';
import Teachers from 'pages/Teachers';
import { ReactElement } from 'react';
import Schools from 'pages/Schools';
import Groups from 'pages/Groups';
import Report from 'pages/Report';
import InterimReport from 'pages/InterimReport';
import SchoolInfo from 'pages/SchoolManagement';
import SingleStudent from 'pages/SingleStudent';
import SelfEvaluation from 'pages/SelfEvaluation';

const MainContent = ({ pageKey }: { pageKey: string }): ReactElement => {
  const { schoolId, subjectId, groupId, studentId } = useParams();
  const locations = useLocation();
  const mainContentItems: { [key: string]: ReactElement } = {
    subjects: subjectId ? <SingleSubject /> : <Subjects />,
    groups: groupId ? <SingleGroup /> : <Groups />,
    schools: schoolId ? <Schools /> : <Schools />,
    teachers: schoolId ? <Teachers /> : <Teachers />,
    schoolInfo: <SchoolInfo />,
    students: !locations.pathname.includes('/interim-report') ? (
      locations.pathname.includes('/report') ? (
        <Report />
      ) : studentId ? (
        <SingleStudent />
      ) : (
        <Students />
      )
    ) : (
      <InterimReport />
    ),
    student: <SelfEvaluation />,
  };
  return mainContentItems[pageKey];
};

export default MainContent;
