import { getSubjectsCountSelector, getSubjectsSelector } from 'store/selectors/subjects.selectors';
import { getSubjectsAction } from 'store/actions/subjects.actions';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Text, Title } from 'UI/Typography';
import Spinner from 'UI/Spinner';
import { Col, Dropdown, Row } from 'antd';
import Button from 'UI/Button';
import Input from 'UI/Input';
import Table from 'UI/Table';
import AddSubjectModal from './__partials/AddSubjectModal';
import styles from './styles.module.css';
import Pagination from '../../UI/Pagination';
import { getUserRole } from 'store/selectors/users.selectors';
import EditIcon from '../../UI/Icons/EditIcon';
import ReorderSubjectsModal from 'pages/Subjects/__partials/ReorderSubjectsModal';
import ActionsIcon from '../../UI/Icons/ActionsIcon';

const { Search } = Input;

const columns = (role: string, toggleEditSubject: (arg1: number) => void) => [
  {
    title: 'Fächer',
    dataIndex: 'name',
    key: 'name',
    render: (record: string) => (
      <Text level={1} bold>
        {record}
      </Text>
    ),
  },
  {
    title: 'Typ',
    dataIndex: 'optional',
    key: 'optional',
    render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
  },
  {
    ...(role === 'SCHOOL_ADMIN'
      ? {
          title: 'Autor',
          dataIndex: 'isAssociation',
          key: 'isAssociation',
          render: (record: string) => (record ? 'MLVB' : 'Schule'),
        }
      : {}),
  },
  {
    ...(role === 'SCHOOL_ADMIN'
      ? {
          title: 'Itemanzahl',
          dataIndex: 'items',
          key: 'items',
        }
      : {}),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    onCell: () => {
      return {
        onClick: (event: { stopPropagation: () => void }) => {
          event.stopPropagation();
        },
      };
    },
    render: (record: number, i: { isAssociation: boolean }) => {
      return (
        !i.isAssociation && (
          <Dropdown
            overlayClassName={styles.dropdownContent}
            trigger={['click']}
            placement='bottomRight'
            menu={{
              items: [
                {
                  key: '1',
                  label: (
                    <span
                      onClick={(e) => {
                        e.stopPropagation();
                        toggleEditSubject(record);
                      }}
                    >
                      Ändern
                    </span>
                  ),
                },
              ],
            }}
          >
            <ActionsIcon />
          </Dropdown>
        )
      );
    },
    width: 50,
  },
];

const Subjects = () => {
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [reorderVisible, setReorderVisible] = useState(false);
  const [selectedSubjectId, setSelectedSubject] = useState<number | null>(null);
  const [searchValue, setSearchValue] = useState('');
  const [getSubjectsLoading, setGetSubjectsLoading] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const subjects = useSelector(getSubjectsSelector);
  const subjectsCount = useSelector(getSubjectsCountSelector);
  const role = useSelector(getUserRole);

  const selectedSubject = useMemo(() => {
    return subjects?.find((subject: { id: number }) => subject.id === selectedSubjectId);
  }, [selectedSubjectId, subjects]);

  const toggleAddItemModal = useCallback((value: boolean) => {
    setAddItemModalVisible(value);
  }, []);

  const toggleReorderModal = useCallback((value: boolean) => {
    setReorderVisible(value);
  }, []);

  const toggleEditSubject = useCallback((value: number) => {
    setSelectedSubject(value);
    if (value) {
      setAddItemModalVisible(true);
    }
  }, []);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(
        getSubjectsAction({
          page: page - 1,
          size: 10,
          ...(searchValue.length > 2 ? { name: `&name=${searchValue}` } : {}),
        }),
      );
    },
    [dispatch, searchValue],
  );

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      setCurrentPage(1);
      if (value.length > 2) {
        dispatch(getSubjectsAction({ page: 0, size: 10, name: `&name=${value}` }));
      } else {
        dispatch(getSubjectsAction({ page: 0, size: 10 }));
      }
    },
    [dispatch],
  );

  useEffect(() => {
    setGetSubjectsLoading(true);
    dispatch(
      getSubjectsAction({
        page: 0,
        size: 10,
        ...(searchValue.length > 2 ? { name: `&name=${searchValue}` } : {}),
        onSuccess: () => setGetSubjectsLoading(false),
      }),
    );
  }, [dispatch, searchValue]);

  return (
    <div className={styles.subjectsWrapper}>
      <div className={styles.titleWrapper}>
        <Title level={1}>Fächer</Title>
      </div>
      <Row className={styles.dashboardWrapper} justify='space-between'>
        <Col>
          <Search
            allowClear
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeSearchValue(e.target.value)}
          />
        </Col>
        <Col>
          <Button
            type='default'
            icon={<EditIcon />}
            onClick={() => toggleReorderModal(true)}
            style={{ marginRight: '16px' }}
          >
            Umordnen
          </Button>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => toggleAddItemModal(true)}>
            Schulfach hinzufügen
          </Button>
        </Col>
      </Row>
      <div className={styles.tableWrapper}>
        <Table
          className={styles.subjectsTable}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: getSubjectsLoading }}
          pagination={false}
          dataSource={subjects}
          columns={columns(role, toggleEditSubject)}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/subjects/${record.id}`);
              },
            };
          }}
        />
      </div>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={subjectsCount}
        />
      </div>
      <ReorderSubjectsModal
        handlePageChange={handlePageChange}
        reorderVisible={reorderVisible}
        toggleReorderModal={toggleReorderModal}
      />
      <AddSubjectModal
        toggleEditSubject={toggleEditSubject}
        selectedSubject={selectedSubject}
        page={currentPage}
        addItemModalVisible={addItemModalVisible}
        toggleAddItemModal={toggleAddItemModal}
      />
    </div>
  );
};

export default Subjects;
