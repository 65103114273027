import {
  getItemsToAssignResponseTypes,
  getReportListResponseTypes,
  getStudentIfoResponseTypes,
  getStudentReportResponseTypes,
  getSubItemsToAssignResponseTypes,
  getSubjectsToAssignResponseTypes,
  studentTypes,
} from 'store/actions/students.actions';
import { createSelector } from 'reselect';

const appState = (state: {
  studentsReducer: {
    students: Array<studentTypes> | [];
    studentsCount: number;
    subjectsToAssign: getSubjectsToAssignResponseTypes;
    itemsToAssign: getItemsToAssignResponseTypes;
    subItemsToAssign: getSubItemsToAssignResponseTypes;
    studentInfo: getStudentIfoResponseTypes | null;
    studentReport: getStudentReportResponseTypes | null;
    reportList: getReportListResponseTypes | [];
  };
}): {
  students: Array<studentTypes> | [];
  studentsCount: number;
  subjectsToAssign: getSubjectsToAssignResponseTypes;
  itemsToAssign: getItemsToAssignResponseTypes;
  subItemsToAssign: getSubItemsToAssignResponseTypes;
  studentInfo: getStudentIfoResponseTypes | null;
  studentReport: getStudentReportResponseTypes | null;
  reportList: getReportListResponseTypes | [];
} => state.studentsReducer;

export const getStudentsSelector = createSelector(appState, (state) => state.students);
export const getStudentsCountSelector = createSelector(appState, (state) => state.studentsCount);
export const getSubjectsToAssignSelector = createSelector(
  appState,
  (state) => state.subjectsToAssign,
);
export const getItemsToAssignSelector = createSelector(appState, (state) => state.itemsToAssign);
export const getSubItemsToAssignSelector = createSelector(
  appState,
  (state) => state.subItemsToAssign,
);
export const getStudentInfoSelector = createSelector(appState, (state) => state.studentInfo);
export const getStudentReportSelector = createSelector(appState, (state) => state.studentReport);
export const getReportListSelector = createSelector(appState, (state) => state.reportList);
