import React, { useEffect } from 'react';
import { Outlet, Route, Routes } from 'react-router-dom';
import Home from './components/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import RouteGate from 'components/RouteGate';
import './colors.module.css';
import FirstLogin from 'pages/FirstLogin';
import ResetPassword from 'pages/ResetPassword';
import Impressum from 'pages/Legal/Impressum';
import Daten from 'pages/Legal/Daten';
import Endnut from 'pages/Legal/Endnut';
import Nutzun from 'pages/Legal/Nutzun';

const App = () => {
  return (
    <Routes>
      <Route path='/' element={<Outlet />}>
        <Route index element={<Login />} />
        <Route path='/login' element={<Login />} />
        <Route path='/reset-pass' element={<ResetPassword />} />
        <Route path='/firstLogin/:token?' element={<FirstLogin />} />
        <Route path='/register' element={<Register />} />
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/groups/:groupId?' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/subjects/:subjectId?' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/schools/:schoolId?' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/students/:studentId?' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/students/:studentId?/report' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/students/:studentId?/interim-report' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/teachers/:teacherId?' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/schoolInfo' element={<Home />} />
        </Route>
        <Route
          element={<RouteGate allowedRoles={['admin']} user={{ role: 'admin', name: 'John' }} />}
        >
          <Route path='/student' element={<Home />} />
        </Route>
        <Route path='/impressum' element={<Impressum />} />
        <Route path='/datenschutzhinweise' element={<Daten />} />

        <Route path='/endnutzerlizenzbedingungen' element={<Endnut />} />
        <Route path='/nutzungsbedingungen' element={<Nutzun />} />
        <Route path='*' element={<div>Not found</div>} />
      </Route>
    </Routes>
  );
};

export default App;
