import ArrowBackIcon from 'UI/Icons/ArrowBackIcon';
import { useNavigate } from 'react-router-dom';
import { useCallback } from 'react';
import cc from 'classcat';
import styles from './styles.module.css';

type BreadcrumbTypes = {
  items: Array<{ [key: string]: string }>;
};

const Breadcrumb = ({ items }: BreadcrumbTypes) => {
  const navigate = useNavigate();

  const handleGoBack = useCallback(() => {
    navigate(items?.[0]?.url);
  }, [items, navigate]);

  const handleNavigate = useCallback(
    (value: string) => {
      navigate(value);
    },
    [navigate],
  );

  return (
    <div className={styles.bcWrapper}>
      {items.map((brItem, index) => {
        return (
          <span key={index} onClick={() => handleNavigate(brItem.url)} className={styles.brItem}>
            {brItem.title}
            <span className={cc([styles.separator, { [styles.visible]: !!brItem.url }])}>/</span>
          </span>
        );
      })}
    </div>
  );
};

export default Breadcrumb;
