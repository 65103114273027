import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StarsSVG = () => {
  return (
    <svg
      width='114'
      height='115'
      viewBox='0 0 114 115'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path
        d='M93.167 41.9998L86.7087 27.7915L72.5003 21.3332L86.7087 14.8748L93.167 0.666504L99.6253 14.8748L113.834 21.3332L99.6253 27.7915L93.167 41.9998ZM93.167 114.333L86.7087 100.125L72.5003 93.6665L86.7087 87.2082L93.167 72.9998L99.6253 87.2082L113.834 93.6665L99.6253 100.125L93.167 114.333ZM41.5003 98.8332L28.5837 70.4165L0.166992 57.4998L28.5837 44.5832L41.5003 16.1665L54.417 44.5832L82.8337 57.4998L54.417 70.4165L41.5003 98.8332Z'
        fill='#4B8A80'
      />
    </svg>
  );
};

const StarsIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={StarsSVG} {...props} />;
};

export default StarsIcon;
