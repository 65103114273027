import { ActionNames } from 'store/actions/actionNames';

export type getTeacherParamTypes = {
  active: boolean;
  page?: number;
  size?: number;
  onSuccess?: () => void;
};

export type teachersResponseType = {
  teachers: Array<{ id: number }>;
  count: number;
};

export type addTeacherParamTypes = {
  name: string;
  surname: string;
  title: string | null;
  salutation: string | null;
  email: string;
  roles: string[];
  onSuccess?: () => void;
  onFailure: (arg1: string) => void;
  page: number;
  active: boolean;
};

export type editTeacherActionParamTypes = {
  name: string;
  surname: string;
  title: string | null;
  salutation: string | null;
  email: string;
  roles: string[];
  onSuccess?: () => void;
  onFailure: (arg1: string) => void;
  id: number;
  page: number;
  active: boolean;
  consent: boolean;
};

export type teacherTypes = {
  id: number;
};

export type deactivateTeacherParamTypes = {
  id: number;
  page: number;
  size: 10;
  active: boolean;
  onSuccess: () => void;
};

export const getTeacherActions = (params: getTeacherParamTypes) => ({
  type: ActionNames.GET_TEACHERS,
  params,
});

export const getTeachersSuccess = (teachers: teachersResponseType) => ({
  type: ActionNames.GET_TEACHERS_SUCCESS,
  payload: teachers,
});

export const addTeacherAction = (params: addTeacherParamTypes) => ({
  type: ActionNames.ADD_TEACHER,
  params,
});

export const editTeacherAction = (params: editTeacherActionParamTypes) => ({
  type: ActionNames.EDIT_TEACHER,
  params,
});

export const addTeacherActionSuccess = (teacher: teacherTypes) => ({
  type: ActionNames.ADD_TEACHER_SUCCESS,
  payload: teacher,
});

export const deactivateTeacherAction = (params: deactivateTeacherParamTypes) => ({
  type: ActionNames.DEACTIVATE_TEACHER,
  params,
});

export const reactivateTeacherAction = (params: deactivateTeacherParamTypes) => ({
  type: ActionNames.RE_DEACTIVATE_TEACHER,
  params,
});
