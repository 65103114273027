import { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import {
  getGroupSubjectsSelector,
  getTeachersToAddSelector,
} from 'store/selectors/groups.selectors';
import {
  AddTeacherToGroupAction,
  getGroupsSubjectsAction,
  getTeacherToAddAction,
} from 'store/actions/group.actions';
import { Text } from 'UI/Typography';
import { SelectProps } from 'antd';
import Select from 'UI/Select';
import Modal from 'UI/Modal';
import styles from './styles.module.css';

const roleOptions: SelectProps['options'] = [
  { label: 'Fachexperte', value: 'SUBJECT_EXPERT' },
  { label: 'Lernbegleitung', value: 'REGULAR' },
  { label: 'Stammgruppenleitung', value: 'CORE_GROUP_RESPONSIBLE' },
];

const roleOptionsLearning = [
  { label: 'Fachexperte', value: 'SUBJECT_EXPERT' },
  { label: 'Lernbegleitung', value: 'REGULAR' },
];

type groupTeacherModal = {
  groupTeacherVisible: boolean;
  toggleGroupTeacher: (arg1: boolean) => void;
  groupType: string;
  selectedTab: string;
};

const AddGroupTeacherModal = ({
  groupTeacherVisible,
  toggleGroupTeacher,
  groupType,
  selectedTab,
}: groupTeacherModal) => {
  const [teacherId, setTeacherId] = useState<number | null>(null);
  const [teacherRole, setTeacherRole] = useState<string | null>(null);
  const [subjects, setSubjects] = useState<Array<string>>([]);
  const [subjectsDisabled, setSubjectsDisabled] = useState(false);
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const groupSubjects = useSelector(getGroupSubjectsSelector);
  const groupTeachersToAdd = useSelector(getTeachersToAddSelector);

  const subjectsList = useMemo(() => {
    if (groupSubjects?.length) {
      return groupSubjects?.map((subject: { id: number; name: string }) => ({
        value: subject.id,
        label: subject.name,
      }));
    } else return [];
  }, [groupSubjects]);

  const teachersList = useMemo(() => {
    return groupTeachersToAdd?.map((teacher: { id: number; name: string; surname: string }) => {
      return { value: teacher.id, label: `${teacher.surname}, ${teacher.name}` };
    });
  }, [groupTeachersToAdd]);

  const okButtonDisabled = useMemo(() => {
    if (subjectsDisabled) {
      return false;
    }
    return !teacherId || !teacherRole || (teacherRole !== 'REGULAR' && !subjects.length);
  }, [subjects.length, subjectsDisabled, teacherId, teacherRole]);

  const handleResetModal = useCallback(() => {
    toggleGroupTeacher(false);
    setTeacherId(null);
    setTeacherRole(null);
    setSubjects([]);
  }, [toggleGroupTeacher]);

  const handleAddTeacherToGroup = useCallback(() => {
    dispatch(
      AddTeacherToGroupAction({
        id: Number(teacherId),
        type: String(teacherRole),
        subjectIds: subjects,
        groupId: Number(groupId),
      }),
    );
    handleResetModal();
  }, [dispatch, groupId, handleResetModal, subjects, teacherId, teacherRole]);

  useEffect(() => {
    if (groupType === 'CORE_GROUP') {
      if (teacherRole === 'CORE_GROUP_RESPONSIBLE') {
        setSubjectsDisabled(true);
      } else {
        setSubjectsDisabled(false);
      }
    }
  }, [groupType, teacherRole]);

  useEffect(() => {
    if (groupTeacherVisible) {
      dispatch(getGroupsSubjectsAction({ id: Number(groupId), page: 0, all: true }));
      dispatch(getTeacherToAddAction({ id: Number(groupId) }));
    }
  }, [dispatch, groupId, groupTeacherVisible, selectedTab]);

  return (
    <Modal
      centered
      open={groupTeacherVisible}
      onCancel={handleResetModal}
      onOk={handleAddTeacherToGroup}
      title='Lehrkraft'
      okText='Hinzufügen'
      cancelText='Abbrechen'
      okDisabled={okButtonDisabled}
    >
      <div className={styles.teacherLabel}>
        <Text level={1} bold>
          Name
        </Text>
      </div>
      <Select
        value={teacherId}
        onChange={(value) => setTeacherId(Number(value))}
        style={{ width: '100%' }}
        placeholder='Lehrkraft auswählen'
        options={teachersList}
      />
      <div className={styles.roleLabel}>
        <Text level={1} bold>
          Typ (Funktion)
        </Text>
      </div>
      <Select
        value={teacherRole}
        onChange={(value) => setTeacherRole(value)}
        style={{ width: '100%' }}
        placeholder='Typ'
        options={
          groupType === 'LEARNING_GROUP' || groupType === 'ADDITIONAL_OFFER'
            ? roleOptionsLearning
            : roleOptions
        }
      />
      <div className={styles.roleLabel}>
        <Text level={1} bold>
          Fächer
        </Text>
      </div>
      <Select
        disabled={subjectsDisabled}
        mode='multiple'
        showSearch={false}
        value={subjects}
        onChange={(value) => setSubjects(value)}
        style={{ width: '100%' }}
        placeholder='Fächer'
        options={subjectsList}
      />
    </Modal>
  );
};

export default AddGroupTeacherModal;
