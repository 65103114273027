import { ActionNames } from 'store/actions/actionNames';

export type studentTypes = {
  id: number;
  name: string;
  surname: string;
  birthDate: string;
  groups: Array<{ id: number; name: string }>;
  schoolYear: number;
};

export type getStudentsParams = {
  groupIds?: number[];
  schoolYears?: number[];
  page: number;
  size: number;
  active: boolean;
  sort?: string;
  name: string;
  onSuccess?: () => void;
};

export type studentsResponse = { students: Array<studentTypes>; count: number };

export type createStudentParamTypes = {
  groupIds?: number[];
  schoolYears?: number[];
  page: number;
  size: number;
  name: string;
  surname: string;
  gender: string;
  schoolYear: number;
  birthDate: string;
  email?: string | null;
  onSuccess: () => void;
  searchValue: string;
  active: boolean;
  onFailure: (arg1: string) => void;
};

export type updateStudentParamTypes = {
  id: number;
  name: string;
  surname: string;
  gender: string;
  schoolYear: number;
  birthDate: string;
  email?: string | null;
  onSuccess: (arg1: boolean) => void;
  parentConsent: boolean;
  studentConsent: boolean;
  onFailure: (arg1: string) => void;
};

export type getSubjectsToAssignParamTypes = {
  studentId: number;
};

export type getSubjectsToAssignResponseTypes = Array<{
  id: number;
  name: string;
  optional: boolean;
  isAssociation: boolean;
}>;

export type getItemsToAssignParamTypes = {
  studentId: number;
  subjectId: number;
};

export type getItemsToAssignResponseTypes = Array<{
  id: number;
  name: string;
  optional: boolean;
  scale: string;
  schoolYears: number[];
}>;

export type getSubItemsToAssignParamTypes = {
  studentId: number;
  subjectId: number;
  itemId: number;
};

export type getSubItemsToAssignResponseTypes = Array<{
  id: number;
  name: string;
  optional: boolean;
  isAssociation: boolean;
}>;

export type assignSubjectParamTypes = {
  studentId: number;
  selectedSubjects: number[];
  onSuccess: () => void;
  page: number;
};

export type reassignSubjectParamTypes = {
  studentId: number;
  subjectId: number;
  onSuccess: () => void;
};

export type reassignItemParamTypes = {
  studentId: number;
  subjectId: number;
  itemId: number;
  page: number;
  onSuccess: () => void;
};

export type reassignSubItemParamTypes = {
  studentId: number;
  subjectId: number;
  itemId: number;
  subItemId: number;
  page: number;
  onSuccess: () => void;
};

export type assignItemsParamTypes = {
  studentId: number;
  subjectId: number;
  selectedItems: number[];
  onSuccess: () => void;
  page: number;
};

export type assignSubItemsParamTypes = {
  studentId: number;
  subjectId: number;
  itemId: number;
  selectedSubItems: number[];
  onSuccess: () => void;
  page: number;
};

export type getStudentInfoParamTypes = {
  studentId?: number;
};

export type getStudentIfoResponseTypes = {
  id: number;
  name: string;
  surname: string;
  gender: 'MALE' | 'FEMALE' | 'NOT_DEFINED';
  birthDate: string;
  zip: string;
  city: string;
  street: string;
  email: string;
  schoolYear: number;
  parentConsent: boolean;
  studentConsent: boolean;
  groups: [
    {
      id: number;
      name: string;
      type: string;
    },
  ];
};

export type getStudentReportParamTypes = {
  page?: number;
  size?: number;
};

export type getStudentReportResponseTypes = {
  id: number;
  subItemId: number;
  scaleId: number;
  itemId: number;
  subjectId: number;
  subItemEvaluationData: {
    filled: boolean;
    selfEvaluationFilled: boolean;
    selfEvaluationMatches: boolean;
  };
  itemEvaluationData: {
    filled: boolean;
    selfEvaluationFilled: boolean;
    selfEvaluationMatches: boolean;
  };
  subjectEvaluationData: {
    filled: boolean;
    selfEvaluationFilled: boolean;
    selfEvaluationMatches: boolean;
  };
};

export type selfEvaluateParamTypes = {
  izelId?: number;
  studentId?: number;
  subItemId: number;
  assessmentScaleValueId: number;
  onSuccess: () => void;
  page: number;
};

export type createSubjectSummaryParamTypes = {
  studentId: number;
  subjectId: number;
  text: string | null;
  onSuccess: () => void;
  page: number;
  izelId: number;
};

export type deactivateStudentParamTypes = {
  id: number;
  groupIds?: number[];
  schoolYears?: number[];
  page: number;
  size: number;
  onSuccess: () => void;
  active: boolean;
  name: string;
};

export type grantAccessActionParamTypes = {
  studentId: number;
};

export type getGradesRangeResponseTypes = Array<{
  id: number;
  value: number;
  label: string;
}>;

export type getReportListParamTypes = {
  id: number;
};

export type getReportListResponseTypes = Array<{
  name: string;
  id: number;
  izelNotActivated: boolean;
  izelActive: boolean;
}>;

export type activateIzelActionParamTtypes = {
  id: number;
  izelId: number;
};

export const getStudentsAction = (params: getStudentsParams) => ({
  type: ActionNames.GET_STUDENTS,
  params,
});

export const getStudentsActionSuccess = (students: studentsResponse) => ({
  type: ActionNames.GET_STUDENTS_SUCCESS,
  payload: students,
});

export const createStudentAction = (params: createStudentParamTypes) => ({
  type: ActionNames.CREATE_STUDENT,
  params,
});

export const updateStudentAction = (params: updateStudentParamTypes) => ({
  type: ActionNames.UPDATE_STUDENT,
  params,
});

export const getSubjectsToAssignAction = (params: getSubjectsToAssignParamTypes) => ({
  type: ActionNames.GET_SUBJECTS_TO_ASSIGN,
  params,
});

export const getSubjectsToAssignActionSuccess = (subjects: getSubjectsToAssignResponseTypes) => ({
  type: ActionNames.GET_SUBJECTS_TO_ASSIGN_SUCCESS,
  payload: subjects,
});

export const assignSubjectsAction = (params: assignSubjectParamTypes) => ({
  type: ActionNames.ASSIGN_SUBJECTS,
  params,
});

export const getItemsToAssignAction = (params: getItemsToAssignParamTypes) => ({
  type: ActionNames.GET_ITEMS_TO_ASSIGN,
  params,
});

export const getItemsToAssignActionSuccess = (items: getItemsToAssignResponseTypes) => ({
  type: ActionNames.GET_ITEMS_TO_ASSIGN_SUCCESS,
  payload: items,
});

export const assignItemAction = (params: assignItemsParamTypes) => ({
  type: ActionNames.ASSIGN_ITEMS,
  params,
});

export const getSubItemsToAssignAction = (params: getSubItemsToAssignParamTypes) => ({
  type: ActionNames.GET_SUB_ITEMS_TO_ASSIGN,
  params,
});

export const getSubItemsToAssignActionSuccess = (subItems: getSubItemsToAssignResponseTypes) => ({
  type: ActionNames.GET_SUB_ITEMS_TO_ASSIGN_SUCCESS,
  payload: subItems,
});

export const assignSubItemAction = (params: assignSubItemsParamTypes) => ({
  type: ActionNames.ASSIGN_SUB_ITEMS,
  params,
});

export const reassignSubjectAction = (params: reassignSubjectParamTypes) => ({
  type: ActionNames.RE_ASSIGN_SUBJECTS,
  params,
});

export const reassignItemAction = (params: reassignItemParamTypes) => ({
  type: ActionNames.RE_ASSIGN_ITEMS,
  params,
});

export const reassignSubItemAction = (params: reassignSubItemParamTypes) => ({
  type: ActionNames.RE_ASSIGN_SUB_ITEMS,
  params,
});

export const getStudentInfoAction = (params: getStudentInfoParamTypes) => ({
  type: ActionNames.GET_STUDENT_INFO,
  params,
});

export const getStudentInfoActionSuccess = (student: getStudentIfoResponseTypes) => ({
  type: ActionNames.GET_STUDENT_INFO_SUCCESS,
  payload: student,
});

export const getStudentReportAction = (params: getStudentReportParamTypes) => ({
  type: ActionNames.GET_STUDENT_REPORT,
  params,
});

export const getStudentReportActionSuccess = (report: getStudentReportResponseTypes) => ({
  type: ActionNames.GET_STUDENT_REPORT_SUCCESS,
  payload: report,
});

export const getStudentEvaluationSuccess = (report: getStudentReportResponseTypes) => ({
  type: ActionNames.SELF_EVALUATE_SUCCESS,
  payload: report,
});

export const selfEvaluateAction = (params: selfEvaluateParamTypes) => ({
  type: ActionNames.SELF_EVALUATE,
  params,
});

export const createSubjectSummaryAction = (params: createSubjectSummaryParamTypes) => ({
  type: ActionNames.CREATE_SUBJECT_SUMMARY,
  params,
});

export const deactivateStudentActions = (params: deactivateStudentParamTypes) => ({
  type: ActionNames.DEACTIVATE_STUDENT,
  params,
});

export const reactivateStudentAction = (params: deactivateStudentParamTypes) => ({
  type: ActionNames.REACTIVATE_STUDENT,
  params,
});

export const grantAccessAction = (params: grantAccessActionParamTypes) => ({
  type: ActionNames.GRANT_ACCESS,
  params,
});

export const removeAccessAction = (params: grantAccessActionParamTypes) => ({
  type: ActionNames.REMOVE_ACCESS,
  params,
});

export const resendLinkAction = (params: grantAccessActionParamTypes) => ({
  type: ActionNames.RESEND_LINK,
  params,
});

export const getGradesRangeAction = { type: ActionNames.GET_GRADES_ACTION };

export const getGradesActionSuccess = (grades: getGradesRangeResponseTypes) => ({
  type: ActionNames.GET_GRADES_ACTION_SUCCESS,
  payload: grades,
});

export const getReportListAction = (params: getReportListParamTypes) => ({
  type: ActionNames.GET_REPORT_LIST,
  params,
});

export const getReportListActionSuccess = (reports: getReportListResponseTypes) => ({
  type: ActionNames.GET_REPORT_LIST_SUCCESS,
  payload: reports,
});

export const activateStudentIzelAction = (params: activateIzelActionParamTtypes) => ({
  type: ActionNames.ACTIVATE_STUDENT_IZEL,
  params,
});

export const deActivateStudentIzelAction = (params: activateIzelActionParamTtypes) => ({
  type: ActionNames.DE_ACTIVATE_STUDENT_IZEL,
  params,
});
