import { itemTypes } from 'actions/items.actions';
import { Title } from 'UI/Typography';
import styles from './styles.module.css';
import { Tooltip } from 'antd';
import InfoIcon from '../../../../UI/Icons/InfoIcon';
import React from 'react';
import { evalScaleValueNames } from '../../../../constants';

const CollapseHeader = ({ item }: { item: itemTypes }) => {
  return (
    <div>
      <div className={styles.titleWrapper}>
        <Title level={5} style={{ color: '#232121D9' }}>
          {item.name}
        </Title>
        <Tooltip title={item.alias}>
          <InfoIcon />
        </Tooltip>
      </div>
      <div className={styles.itemInfo}>
        <div className={styles.infoInnerItem}>
          Typ <Title level={5}>{item.optional ? 'Optional' : 'Bindend'}</Title>
        </div>
        <div className={styles.infoInnerItem}>
          Bewertungsskala <Title level={5}>{evalScaleValueNames[item.assessmentScale?.name]}</Title>
        </div>
        <div className={styles.infoInnerItem}>
          Jahrgänge{' '}
          <Title level={5}>
            {structuredClone(item.schoolYears)
              ?.sort((a: string, b: string) => Number(a) - Number(b))
              .join(', ') || ''}
          </Title>
        </div>
      </div>
    </div>
  );
};

export default CollapseHeader;
