import React, { ReactElement, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import Breadcrumb from 'components/Breadcrumb';
import { Text, Title } from 'UI/Typography';
import styles from './styles.module.css';
import Tabs from '../../UI/Tabs';
import ManualAssign from './__partials/ManualAssign';
import Button from '../../UI/Button';
import { PlusOutlined } from '@ant-design/icons';
import { useDispatch, useSelector } from 'react-redux';
import { getUserRole } from 'store/selectors/users.selectors';
import PersonalInfo from 'pages/SingleStudent/__partials/PersonalInfo';
import EditIcon from '../../UI/Icons/EditIcon';
import { getStudentInfoAction } from 'store/actions/students.actions';
import { useLocation, useParams, useSearchParams } from 'react-router-dom';
import { getStudentInfoSelector } from 'store/selectors/students.selectors';
import cc from 'classcat';
import AssistedEvaluation from 'pages/SingleStudent/__partials/AssistedEvaluation';
import OfflineIcon from '../../UI/Icons/OfflineIcon';
import SavingIcon from '../../UI/Icons/SavingIcon';
import SavedIcon from '../../UI/Icons/SavedIcon';
import { getReportSelector } from 'store/selectors/report.selectors';
import ObservationList from 'pages/SingleStudent/__partials/ObservationList';
import IzelList from 'pages/SingleStudent/__partials/IzelList';

const items = (
  assignSubjectVisible: boolean,
  toggleAssignSubject: (arg1: boolean) => void,
  toggleAddStudentModal: (arg1: boolean) => void,
  handleSaveLoading: (arg1: boolean) => void,
  addStudentModalVisible: boolean,
  locked: boolean,
  userRole: 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN',
  selectedTab: 'personalInfo' | 'manualAssign' | 'assistedEval' | 'observationList',
) => [
  {
    label: <span style={{ padding: '0 40px' }}>Fachlicher Inhalt</span>,
    key: 'manualAssign',
    children: (
      <ManualAssign
        assignSubjectVisible={assignSubjectVisible}
        toggleAssignSubject={toggleAssignSubject}
        selectedTab={selectedTab}
      />
    ),
  },
  {
    ...(!locked && userRole === 'TEACHER'
      ? {
          label: <span style={{ padding: '0 40px' }}>Selbstbewertung</span>,
          key: 'assistedEval',
          children: (
            <AssistedEvaluation handleSaveLoading={handleSaveLoading} selectedTab={selectedTab} />
          ),
        }
      : {}),
  },
  {
    label: <span style={{ padding: '0 40px' }}>Persönliche Angaben</span>,
    key: 'personalInfo',
    children: (
      <PersonalInfo
        addStudentModalVisible={addStudentModalVisible}
        toggleAddStudentModal={toggleAddStudentModal}
      />
    ),
  },
  {
    label: <span style={{ padding: '0 40px' }}>Dokumentations-Historie</span>,
    key: 'observationList',
    children: <ObservationList selectedTab={selectedTab} />,
  },
  {
    ...(userRole !== 'TEACHER'
      ? {
          label: <span style={{ padding: '0 40px' }}>IzEL Formulare</span>,
          key: 'izelList',
          children: <IzelList />,
        }
      : {}),
  },
];

const SingleStudent = () => {
  const [selectedTab, setSelectedTab] = useState<
    'personalInfo' | 'manualAssign' | 'assistedEval' | 'observationList'
  >('manualAssign');
  const [saveLoading, setSaveLoading] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const userRole = useSelector(getUserRole);
  const studentInfo = useSelector(getStudentInfoSelector);
  const [assignSubjectVisible, setAssignSubjectVisible] = useState(false);
  const [addStudentModalVisible, setAddStudentModalVisible] = useState(false);
  const [scrollPosition, setScrollPosition] = useState(0);
  const myElementRef = useRef<null>(null);
  const report = useSelector(getReportSelector);
  const location = useLocation();

  const toggleAssignSubject = useCallback((value: boolean) => {
    setAssignSubjectVisible(value);
  }, []);

  const toggleAddStudentModal = useCallback((value: boolean) => {
    setAddStudentModalVisible(value);
  }, []);

  const handleSaveLoading = useCallback((value: boolean) => {
    setSaveLoading(value);
  }, []);

  useEffect(() => {
    dispatch(getStudentInfoAction({ studentId: Number(studentId) }));
  }, [dispatch, studentId]);

  const handleScrollPosition = useCallback(() => {
    if (myElementRef.current) {
      const elTop: { scrollTop: number } = myElementRef.current;
      if (
        (elTop.scrollTop === 0 && scrollPosition !== 0) ||
        (elTop.scrollTop !== 0 && scrollPosition === 0)
      ) {
        setScrollPosition(elTop.scrollTop);
      }
    }
  }, [scrollPosition]);

  return (
    <div className={styles.singleStudent}>
      <div
        className={cc([
          {
            [styles.scrollView]: scrollPosition > 0,
          },
        ])}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', padding: '28px 22px 0' }}>
          <div>
            <div>
              <Breadcrumb
                items={[
                  { title: 'Schüler/Schülerinnen', url: `/students${location.search}` },
                  { title: `${studentInfo?.surname}, ${studentInfo?.name}`, url: '' },
                ]}
              />
            </div>
            <div className={styles.titleWrapper}>
              <Title
                level={scrollPosition > 0 ? 4 : 1}
              >{`${studentInfo?.surname}, ${studentInfo?.name}`}</Title>
            </div>
          </div>
          {selectedTab === 'assistedEval' && (
            <div>
              {!navigator.onLine ? (
                <div className={styles.statusWrapper}>
                  <OfflineIcon /> <Text level={1}>Daten nicht gespeichert (kein Internet?)</Text>
                </div>
              ) : saveLoading ? (
                <div className={styles.statusWrapper}>
                  <SavingIcon /> <Text level={1}>Änderungen speichern</Text>
                </div>
              ) : (
                <div className={styles.statusWrapper}>
                  <SavedIcon /> <Text level={1}>Alle Änderungen sind gespeichert</Text>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
      <div
        className={cc([
          styles.descWrapper,
          {
            [styles.descWrapperScroll]: scrollPosition > 0,
          },
        ])}
        ref={myElementRef}
        onScroll={handleScrollPosition}
      >
        <Tabs
          destroyInactiveTabPane
          defaultActiveKey='1'
          items={items(
            assignSubjectVisible,
            toggleAssignSubject,
            toggleAddStudentModal,
            handleSaveLoading,
            addStudentModalVisible,
            report.nonLocked,
            userRole,
            selectedTab,
          )}
          onChange={(key: 'personalInfo' | 'manualAssign' | 'assistedEval' | 'observationList') =>
            setSelectedTab(key)
          }
          tabBarExtraContent={
            <div>
              {userRole === 'SCHOOL_ADMIN' && selectedTab === 'personalInfo' ? (
                <Button
                  type='default'
                  icon={<EditIcon />}
                  onClick={() => toggleAddStudentModal(true)}
                >
                  Ändern
                </Button>
              ) : userRole === 'SCHOOL_ADMIN' && selectedTab === 'manualAssign' ? (
                <Button
                  type='default'
                  icon={<PlusOutlined />}
                  onClick={() => toggleAssignSubject(true)}
                >
                  Fach hinzufügen
                </Button>
              ) : null}
            </div>
          }
        />
      </div>
    </div>
  );
};

export default SingleStudent;
