import { getReportDocumentAction } from 'store/actions/report.actions';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { useCallback, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Text } from 'UI/Typography';
import Checkbox from 'UI/Checkbox';
import Modal from 'UI/Modal';
import styles from './styles.module.css';

type DownloadReportModalTypes = {
  downloadReportVisible: boolean;
  toggleDownloadReport: (arg1: boolean) => void;
  personalLetterEnabled: boolean;
  reportDisabled: boolean;
  reportName: string;
  activeReportId: number | null;
};

const DownloadReportModal = ({
  downloadReportVisible,
  toggleDownloadReport,
  personalLetterEnabled,
  reportDisabled,
  reportName,
  activeReportId,
}: DownloadReportModalTypes) => {
  const [printReport, setPrintReport] = useState(false);
  const [printLetter, setPrintLetter] = useState(false);
  const [downloadLoading, setDownloadLoading] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();

  const handleResetModal = useCallback(() => {
    toggleDownloadReport(false);
    setPrintReport(false);
    setPrintLetter(false);
    setDownloadLoading(false);
  }, [toggleDownloadReport]);

  const handleDownloadReport = useCallback(() => {
    setDownloadLoading(true);
    dispatch(
      getReportDocumentAction({
        studentId: Number(studentId),
        printReport,
        printLetter,
        reportName,
        izelId: Number(activeReportId),
        onSuccess: () => handleResetModal(),
      }),
    );
  }, [activeReportId, dispatch, handleResetModal, printLetter, printReport, reportName, studentId]);

  return (
    <Modal
      confirmLoading={downloadLoading}
      title='IzEL-Druckeinstellungen'
      open={downloadReportVisible}
      centered
      onCancel={handleResetModal}
      okText='Drucken'
      cancelText='Abbrechen'
      onOk={handleDownloadReport}
      okDisabled={!printReport && !printLetter}
    >
      <div className={styles.title}>
        <Text level={2} bold className='secondary-40'>
          Was möchten Sie für dieses IzEL-Dokument drucken?
        </Text>
      </div>
      <div className={styles.title}>
        <Checkbox
          disabled={personalLetterEnabled}
          checked={printLetter}
          onChange={(e: CheckboxChangeEvent) => setPrintLetter(e.target.checked)}
        >
          <Text level={1}>Persönlicher Brief</Text>
        </Checkbox>
      </div>
      <div>
        <Checkbox
          disabled={!reportDisabled}
          checked={printReport}
          onChange={(e: CheckboxChangeEvent) => setPrintReport(e.target.checked)}
        >
          <Text level={1}>IzEL</Text>
        </Checkbox>
      </div>
    </Modal>
  );
};

export default DownloadReportModal;
