import styles from './styles.module.css';
import { Text } from '../../../../UI/Typography';
import { Row, Col } from 'antd';
import { useSelector } from 'react-redux';
import { getStudentInfoSelector } from 'store/selectors/students.selectors';
import { groupTypesMapping } from '../../../../constants';
import AddStudentModal from 'pages/Students/__partials/AddStudentModal';
import { useMemo } from 'react';
import dayjs from 'dayjs';

const genderOptions = {
  MALE: 'Männlich',
  FEMALE: 'Weiblich',
  NOT_DEFINED: 'divers',
};

const PersonalInfo = ({
  toggleAddStudentModal,
  addStudentModalVisible,
}: {
  toggleAddStudentModal: (arg1: boolean) => void;
  addStudentModalVisible: boolean;
}) => {
  const studentInfo = useSelector(getStudentInfoSelector);
  const isAdult = useMemo(() => {
    return (
      dayjs(studentInfo?.birthDate, 'DD.MM.YYYY').unix() >
      dayjs(new Date()).subtract(16, 'year').unix()
    );
  }, [studentInfo?.birthDate]);

  return (
    <div>
      <div className={styles.containerWrapper}>
        <div className={styles.headerWrapper}>
          <Text level={1} bold>
            Personliche Information
          </Text>
        </div>
        <Row className={styles.infoWrapper}>
          <Col span={4}>
            <Text level={1}>Name, Vorname</Text>
          </Col>
          <Col>
            <Text level={1} bold>
              {`${studentInfo?.surname}, ${studentInfo?.name}`}
            </Text>
          </Col>
        </Row>
        <Row className={styles.infoWrapper}>
          <Col span={4}>
            <Text level={1}>Geschlecht</Text>
          </Col>
          <Col>
            <Text level={1} bold>
              {studentInfo?.gender ? genderOptions[studentInfo?.gender] : ''}
            </Text>
          </Col>
        </Row>
        <Row className={styles.infoWrapper}>
          <Col span={4}>
            <Text level={1}>Geburtsdatum</Text>
          </Col>
          <Col>
            <Text level={1} bold>
              {studentInfo?.birthDate}
            </Text>
          </Col>
        </Row>
        <Row className={styles.infoWrapper}>
          <Col span={4}>
            <Text level={1}>Jahrgangsstufe</Text>
          </Col>
          <Col>
            <Text level={1} bold>
              {`${studentInfo?.schoolYear}.Schuljahr`}
            </Text>
          </Col>
        </Row>
        <Row className={styles.infoWrapper}>
          <Col span={4}>
            <Text level={1}>E-Mail</Text>
          </Col>
          <Col>
            <Text level={1} bold>
              {studentInfo?.email}
            </Text>
          </Col>
        </Row>
        <div className={styles.consentWrapper}>
          <Text level={1} bold>
            Zustimmung zur Datenverarbeitung
          </Text>
        </div>
        <Row className={!isAdult ? styles.infoWrapper : styles.infoWrapperLast}>
          <Col span={4}>
            <Text level={1}>Zustimmung Erziehungsberechtigte</Text>
          </Col>
          <Col>
            <Text level={1} className={studentInfo?.parentConsent ? '' : 'errorMessage'}>
              {studentInfo?.parentConsent ? 'Liegt vor' : 'Liegt nicht vor'}
            </Text>
          </Col>
        </Row>
        {!isAdult && (
          <Row className={styles.infoWrapperLast}>
            <Col span={4}>
              <Text level={1}>Zustimmung Schüler/in (ab 16 Jahre)</Text>
            </Col>
            <Col>
              <Text level={1} className={studentInfo?.studentConsent ? '' : 'errorMessage'}>
                {studentInfo?.studentConsent ? 'Liegt vor' : 'Liegt nicht vor'}
              </Text>
            </Col>
          </Row>
        )}
      </div>
      <div className={styles.containerWrapper} style={{ marginTop: '32px' }}>
        <div className={styles.headerWrapper}>
          <Row>
            <Col span={4}>
              <Text level={1} bold>
                Gruppenname
              </Text>
            </Col>
            <Col>
              <Text level={1} bold>
                Gruppentyp
              </Text>
            </Col>
          </Row>
        </div>
        {studentInfo?.groups?.map((value, index) => {
          return (
            <Row
              key={value.id}
              className={
                index === studentInfo?.groups?.length - 1
                  ? styles.infoWrapperLast
                  : styles.infoWrapper
              }
            >
              <Col span={4}>
                <Text level={1}>{value.name}</Text>
              </Col>
              <Col>
                <Text level={1} bold>
                  {groupTypesMapping[value.type]}
                </Text>
              </Col>
            </Row>
          );
        })}
      </div>
      <AddStudentModal
        mode='edit'
        addStudentModalVisible={addStudentModalVisible}
        toggleAddStudentModal={toggleAddStudentModal}
      />
    </div>
  );
};

export default PersonalInfo;
