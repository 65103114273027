import { createSelector } from 'reselect';
import {
  getSchoolYearDataResponseTypes,
  schoolInfoResponseTypes,
  schoolType,
} from 'store/actions/school.actions';
import { getGradesRangeResponseTypes } from 'actions/students.actions';

const appState = (state: {
  schoolsReducer: {
    schools: Array<schoolType> | [];
    schoolInfo: schoolInfoResponseTypes | null;
    schoolsCount: number;
    gradesRange: getGradesRangeResponseTypes | [];
    schoolYearData: getSchoolYearDataResponseTypes | null;
  };
}): {
  schools: Array<schoolType> | [];
  schoolInfo: schoolInfoResponseTypes | null;
  schoolsCount: number;
  gradesRange: getGradesRangeResponseTypes | [];
  schoolYearData: getSchoolYearDataResponseTypes | null;
} => state.schoolsReducer;

export const getSchoolsSelector = createSelector(appState, (state) => state.schools);
export const getSchoolsCountSelector = createSelector(appState, (state) => state.schoolsCount);
export const schoolInfoSelector = createSelector(appState, (state) => state.schoolInfo);
export const gradesRangeSelector = createSelector(appState, (state) => state.gradesRange);
export const getSchoolYearDataSelector = createSelector(appState, (state) => state.schoolYearData);
