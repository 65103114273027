import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getAssessmentScalesSelector } from 'store/selectors/items.selectors';
import { getUserRole } from 'store/selectors/users.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { RadioChangeEvent } from 'antd';
import RadioGroup from 'UI/RadioGroup';
import { Text } from 'UI/Typography';
import {
  createItemAction,
  editItemAction,
  getAssessmentScalesAction,
  itemTypes,
} from 'store/actions/items.actions';
import Select from 'UI/Select';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import { evalScaleValueNames } from '../../../../constants';

type ManageItemModalType = {
  addItemModalVisible: boolean;
  toggleManageItemModal: (arg1: boolean) => void;
  editableItem: itemTypes | null;
  handleChangeEditableItem: (arg1: itemTypes | null) => void;
  currentPage: number;
  showDeactivatedItems: boolean;
};

const ManageItemModal = ({
  addItemModalVisible,
  toggleManageItemModal,
  editableItem,
  handleChangeEditableItem,
  currentPage,
  showDeactivatedItems,
}: ManageItemModalType) => {
  const [itemName, setItemName] = useState('');
  const [itemState, setItemState] = useState(2);
  const [scaleId, setScaleId] = useState<number | null>(null);
  const [itemAlias, setItemAlias] = useState('');
  const [errorForUniqueName, setErrorForUniqueName] = useState(false);
  const assessmentScales = useSelector(getAssessmentScalesSelector);
  const userRole = useSelector(getUserRole);
  const { subjectId } = useParams();
  const dispatch = useDispatch();

  const editMode = useMemo(() => {
    return !!editableItem?.id;
  }, [editableItem?.id]);

  const okDisabled = useMemo(() => {
    return !itemName || !scaleId;
  }, [itemName, scaleId]);

  const assessmentScalesOptions = useMemo(() => {
    return assessmentScales?.map(
      (value: { id: number; name: 'Quantitative' | 'Qualtaive' | 'List' }) => {
        return { value: value.id, label: evalScaleValueNames[value.name] };
      },
    );
  }, [assessmentScales]);

  const handleItemName = useCallback((value: string) => {
    setItemName(value);
    setItemAlias(value);
    setErrorForUniqueName(false);
  }, []);

  const handleItemOptional = useCallback((state: number) => {
    setItemState(state);
  }, []);

  const handleScaleId = useCallback((value: number | null) => {
    setScaleId(value);
  }, []);

  const handleChangeAlias = useCallback((value: string) => {
    setItemAlias(value);
  }, []);

  const handleResetItemModal = useCallback(() => {
    setItemName('');
    setItemState(1);
    setScaleId(null);
    setItemAlias('');
    setErrorForUniqueName(false);
    handleChangeEditableItem(null);
    toggleManageItemModal(false);
  }, [handleChangeEditableItem, toggleManageItemModal]);

  const handleCreateItem = useCallback(() => {
    dispatch(
      createItemAction({
        name: itemName,
        alias: itemAlias,
        optional: itemState === 2,
        subjectId: Number(subjectId),
        assessmentScaleId: Number(scaleId),
        currentPage: currentPage - 1,
        showDeactivatedItems,
        onSuccess: () => handleResetItemModal(),
        onFailure: (message) => {
          if (message === 'not_unique') {
            setErrorForUniqueName(true);
          }
        },
      }),
    );
  }, [
    currentPage,
    dispatch,
    handleResetItemModal,
    itemAlias,
    itemName,
    itemState,
    scaleId,
    subjectId,
  ]);

  const handleEditItem = useCallback(() => {
    dispatch(
      editItemAction({
        id: Number(editableItem?.id),
        name: itemName,
        alias: itemAlias,
        optional: itemState === 2,
        assessmentScaleId: Number(scaleId),
        onSuccess: () => {
          handleResetItemModal();
        },
        onFailure: (message) => {
          if (message === 'not_unique') {
            setErrorForUniqueName(true);
          }
        },
      }),
    );
  }, [dispatch, editableItem?.id, handleResetItemModal, itemAlias, itemName, itemState, scaleId]);

  const handleManageItem = useCallback(() => {
    if (editMode) {
      handleEditItem();
    } else {
      handleCreateItem();
    }
  }, [editMode, handleCreateItem, handleEditItem]);

  useEffect(() => {
    if (editMode) {
      handleItemName(editableItem?.name || '');
      handleItemOptional(editableItem?.optional ? 2 : 1);
      handleScaleId(editableItem?.assessmentScale.id || null);
      handleChangeAlias(editableItem?.alias || '');
    }
  }, [
    editMode,
    editableItem?.alias,
    editableItem?.assessmentScale.id,
    editableItem?.name,
    editableItem?.optional,
    handleChangeAlias,
    handleItemName,
    handleItemOptional,
    handleScaleId,
  ]);

  useEffect(() => {
    dispatch(getAssessmentScalesAction);
  }, [dispatch]);

  return (
    <Modal
      centered
      title={!editMode ? 'Neues Item' : 'Item'}
      open={addItemModalVisible}
      onOk={() => handleManageItem()}
      okDisabled={okDisabled}
      onCancel={() => {
        handleResetItemModal();
      }}
      closable
      okText={!editMode ? 'Hinzufügen' : 'Speichern'}
      cancelText='Abbrechen'
    >
      <div className={styles.itemLabel}>
        <Text level={1} bold>
          Itemname
        </Text>
      </div>
      <Input
        error={errorForUniqueName}
        value={itemName}
        placeholder='bitte auswählen'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleItemName(e.target.value)}
        maxLength={150}
        showCount
      />
      {errorForUniqueName && <div className={styles.errorText}>Name ist bereits vorhanden</div>}
      {userRole === 'SCHOOL_ADMIN' && (
        <>
          <div className={styles.alisLabel}>
            <Text level={1} bold>
              Alias
            </Text>
            <Text level={1}>(dieser Text wird im IzEL-Dokument als Item-Name sichtbar)</Text>
          </div>
          <Input
            value={itemAlias}
            placeholder='bitte auswählen'
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeAlias(e.target.value)}
            maxLength={150}
            showCount
          />
        </>
      )}
      <div className={styles.groupLabel}>
        <Text level={1} bold>
          Bewertungsskala
        </Text>
      </div>
      <Select
        disabled={editMode}
        value={scaleId}
        onChange={(value) => handleScaleId(value)}
        style={{ width: '100%' }}
        placeholder='Bewertungsskala'
        options={assessmentScalesOptions}
      />
      {userRole === 'ASSOCIATION_ADMIN' && (
        <>
          <div className={styles.itemTypeLabel}>
            <Text level={1} bold>
              Itemtyp
            </Text>
          </div>
          <RadioGroup
            value={itemState}
            onChange={(e: RadioChangeEvent) => handleItemOptional(e.target.value)}
            groupOptions={[
              { key: 1, label: 'Bindend' },
              { key: 2, label: 'Optional' },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default ManageItemModal;
