import axios from 'axios';
import { store } from '../../store';
import { eventEmitter } from '../../utils';

const authData = '';

const client = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api',
  headers: {
    ...(authData ? { Authorization: 'Basic ' + authData } : {}),
    'Content-Type': 'application/json',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
    timeout: 1000,
  },
});

const pdfClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api',
  headers: {
    ...(authData ? { Authorization: 'Basic ' + authData } : {}),
    'Content-Type': 'application/json',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
    timeout: 1000,
  },
  responseType: 'arraybuffer',
});

const fileClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL + '/api',
  headers: {
    ...(authData ? { Authorization: 'Basic ' + authData } : {}),
    'Content-Type': 'multipart/form-data',
    'Cache-Control': 'no-store, no-cache, must-revalidate',
  },
});

eventEmitter.on(() => {
  client.defaults.headers.common['Authorization'] = store.getState().userReducer.authData
    ? 'Basic ' + store.getState().userReducer.authData
    : undefined;
  pdfClient.defaults.headers.common['Authorization'] = store.getState().userReducer.authData
    ? 'Basic ' + store.getState().userReducer.authData
    : undefined;
  fileClient.defaults.headers.common['Authorization'] = store.getState().userReducer.authData
    ? 'Basic ' + store.getState().userReducer.authData
    : undefined;
});

export { client, pdfClient, fileClient };
