import Modal from '../../../../UI/Modal';
import styles from 'pages/Schools/__partials/ManageSchoolModal/styles.module.css';
import { Text } from '../../../../UI/Typography';
import Input from '../../../../UI/Input';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { createSchoolAdminAction } from 'store/actions/school.actions';
import Checkbox from '../../../../UI/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

const AddAdminModal = ({
  selectedSchool,
  handleSelectSchool,
  currentPage,
}: {
  selectedSchool: number | null;
  handleSelectSchool: (arg1: number | null) => void;
  currentPage: number;
}) => {
  const [adminLastName, setAdminLastName] = useState('');
  const [adminName, setAdminName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [legalApproved, setLegalApproved] = useState(false);
  const [createAdminLoading, setCreateAdminLoading] = useState(false);
  const dispatch = useDispatch();

  const okButtonDisabled = useMemo(
    () => !adminLastName || !adminName || !adminEmail || !legalApproved,
    [adminEmail, adminLastName, adminName, legalApproved],
  );

  const handleChangeAdminLastName = useCallback((value: string) => {
    setAdminLastName(value);
  }, []);

  const handleChangeAdminName = useCallback((value: string) => {
    setAdminName(value);
  }, []);

  const handleChangeAdminEmail = useCallback((value: string) => {
    setAdminEmail(value);
  }, []);

  const handleResetModal = useCallback(() => {
    setAdminEmail('');
    setAdminLastName('');
    setAdminName('');
    handleSelectSchool(null);
    setCreateAdminLoading(false);
    setLegalApproved(false);
  }, [handleSelectSchool]);

  const handleCreateSchoolAdmin = useCallback(() => {
    setCreateAdminLoading(true);
    dispatch(
      createSchoolAdminAction({
        name: adminName,
        surname: adminLastName,
        email: adminEmail,
        schoolId: Number(selectedSchool),
        onSuccess: () => handleResetModal(),
        page: currentPage - 1,
      }),
    );
  }, [
    adminEmail,
    adminLastName,
    adminName,
    currentPage,
    dispatch,
    handleResetModal,
    selectedSchool,
  ]);

  return (
    <Modal
      centered
      title='Nächste Schulverwaltung hinzufügen'
      open={!!selectedSchool}
      onOk={handleCreateSchoolAdmin}
      onCancel={handleResetModal}
      closable
      okText='Hinzufügen'
      cancelText='Abbrechen'
      okDisabled={okButtonDisabled}
      confirmLoading={createAdminLoading}
    >
      <div className={styles.schoolLabel}>
        <Text level={1} bold>
          Schulverwaltung Name
        </Text>
      </div>
      <Input
        value={adminLastName}
        onChange={(e) => handleChangeAdminLastName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Schulverwaltung Vorname
        </Text>
      </div>
      <Input
        value={adminName}
        onChange={(e) => handleChangeAdminName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Schulverwaltung Email
        </Text>
      </div>
      <Input value={adminEmail} onChange={(e) => handleChangeAdminEmail(e.target.value)} />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Zustimmung zur Datenverarbeitung
        </Text>
      </div>
      <Checkbox
        checked={legalApproved}
        onChange={(e: CheckboxChangeEvent) => {
          setLegalApproved(e.target.checked);
        }}
      >
        Liegt vor
      </Checkbox>
    </Modal>
  );
};

export default AddAdminModal;
