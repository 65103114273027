import { LoadingOutlined } from '@ant-design/icons';
import { ConfigProvider, Spin } from 'antd';
import styles from './styles.module.css';
import { primary40 } from '../../colors';

const spinIcon = <LoadingOutlined style={{ fontSize: 32 }} spin />;

const Spinner = ({ ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <div className={styles.spinner}>
        <Spin indicator={spinIcon} {...props} />
      </div>
    </ConfigProvider>
  );
};

export default Spinner;
