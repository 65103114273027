import { ConfigProvider, Tabs as AntdTabs } from 'antd';
import { primary40 } from '../../colors';
import React from 'react';

const Tabs = ({ ...props }) => {
  return (
    <ConfigProvider theme={{ token: { colorPrimary: primary40 } }}>
      <AntdTabs {...props} />
    </ConfigProvider>
  );
};

export default Tabs;
