import {
  getGroupDetailsSelector,
  getGroupSubjectsCountSelector,
  getGroupSubjectsSelector,
  getGroupTeachersCountSelector,
  getGroupTeachersSelector,
} from 'store/selectors/groups.selectors';
import {
  getGroupsSubjectsAction,
  getGroupsTeachersAction,
  removeTeacherFromGroupAction,
} from 'store/actions/group.actions';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'UI/Spinner';
import Table from 'UI/Table';
import { teacherRoleMapping } from '../../../../constants';
import styles from 'pages/Students/styles.module.css';
import Pagination from '../../../../UI/Pagination';
import Modal from '../../../../UI/Modal';
import { Dropdown } from 'antd';
import ActionsIcon from '../../../../UI/Icons/ActionsIcon';
import { getUserRole } from 'store/selectors/users.selectors';

const { Confirm } = Modal;

const columns = (
  toggleRemoveTeacher: (arg1: number | null) => void,
  userRole: string,
  subjectCount: number,
) => [
  {
    title: 'Name, Vorname',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Rolle',
    dataIndex: 'type',
    key: 'type',
    render: (record: 'CORE_GROUP_RESPONSIBLE' | 'SUBJECT_EXPERT' | 'REGULAR') =>
      teacherRoleMapping[record],
  },
  {
    title: 'Fächer',
    dataIndex: 'subjects',
    key: 'subjects',
    render: (record: { name: string }[]) =>
      !record.length || record.length === subjectCount
        ? 'Alle'
        : record.map((value) => value.name).join(', '),
  },
  {
    ...(userRole === 'SCHOOL_ADMIN'
      ? {
          title: '',
          dataIndex: 'action',
          key: 'action',
          width: 50,
          render: (record: number, i: { id: number }) => {
            return (
              <Dropdown
                trigger={['click']}
                overlayClassName={styles.dropdownContent}
                placement='bottomRight'
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            toggleRemoveTeacher(i.id);
                          }}
                        >
                          Entfernen
                        </span>
                      ),
                    },
                  ],
                }}
              >
                <ActionsIcon />
              </Dropdown>
            );
          },
        }
      : {}),
  },
];

const GroupTeachers = () => {
  const [currentPage, setCurrentPage] = useState(1);
  const [removeTeacherLoading, setRemoveTeacherLoading] = useState(false);
  const [removeTeacherVisible, setRemoveTeacherVisible] = useState<number | null>(null);
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const groupTeachers = useSelector(getGroupTeachersSelector);
  const teachersCount = useSelector(getGroupTeachersCountSelector);
  const groupDetails = useSelector(getGroupDetailsSelector);
  const userRole = useSelector(getUserRole);
  const subjectsCount = useSelector(getGroupSubjectsCountSelector);
  const groupSubjects = useSelector(getGroupSubjectsSelector);

  const groupTeachersData = useMemo(() => {
    return groupTeachers
      .map((teacher: { name: string; surname: string }) => {
        return { ...teacher, fullName: teacher.surname + ', ' + teacher.name };
      })
      .map((newTeacher: any) => {
        return {
          ...newTeacher,
          subjects:
            newTeacher.type === 'CORE_GROUP_RESPONSIBLE'
              ? [{ id: 1, name: 'Alle' }]
              : newTeacher.subjects,
        };
      });
  }, [groupTeachers]);

  const selectedTeacherName = useMemo(() => {
    return groupTeachersData.find((teacher) => teacher.id === removeTeacherVisible)?.fullName;
  }, [groupTeachersData, removeTeacherVisible]);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(getGroupsTeachersAction({ id: Number(groupId), page: page - 1 }));
    },
    [dispatch, groupId],
  );

  const toggleRemoveTeacher = useCallback((value: number | null) => {
    setRemoveTeacherVisible(value);
  }, []);

  const handleRemoveTeacher = useCallback(() => {
    setRemoveTeacherLoading(true);
    dispatch(
      removeTeacherFromGroupAction({
        groupId: Number(groupId),
        teacherId: Number(removeTeacherVisible),
        onSuccess: () => {
          setRemoveTeacherLoading(false);
          setRemoveTeacherVisible(null);
        },
      }),
    );
  }, [dispatch, groupId, removeTeacherVisible]);

  useEffect(() => {
    dispatch(getGroupsTeachersAction({ id: Number(groupId), page: 0 }));
    dispatch(getGroupsSubjectsAction({ id: Number(groupId), page: 0 }));
  }, [dispatch, groupId]);

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={groupTeachersData}
        columns={columns(toggleRemoveTeacher, userRole, subjectsCount || groupSubjects.length)}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={teachersCount}
        />
      </div>
      <Confirm
        confirmLoading={removeTeacherLoading}
        closable
        okText='Entfernen'
        cancelText='Abbrechen'
        centered
        message={`Sind Sie sicher, dass Sie ${selectedTeacherName} von der Gruppe ${groupDetails?.name} entfernen wollen?`}
        open={!!removeTeacherVisible}
        onOk={handleRemoveTeacher}
        onCancel={() => {
          toggleRemoveTeacher(null);
        }}
      />
    </div>
  );
};

export default GroupTeachers;
