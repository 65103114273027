import {
  changeUserRoleParamTypes,
  firstLoginParamTypes,
  loginActionSuccess,
  loginParaTypes,
  resetPasswordParamTypes,
  userTypes,
} from 'store/actions/auth.actions';
import { all, call, takeLatest, put } from 'redux-saga/effects';
import {
  changeUserRoleService,
  firstLoginService,
  loginService,
  resetPasswordService,
} from 'store/services/auth.services';
import { ActionNames } from 'store/actions/actionNames';

function* watchLogin(action: { params: loginParaTypes; type: string }) {
  try {
    const response: userTypes = yield call(...loginService(action.params));
    yield put(loginActionSuccess(response));
    yield call(() => action.params.onSuccess(response));
    yield call(action.params.onResolve);
  } catch (e) {
    if (action.params.onFailure) {
      yield call(() => action?.params?.onFailure(e.response.data.messages?.[0]));
    }
    console.log('Can not log in');
  }
}

function* watchFirstLogin(action: { params: firstLoginParamTypes; type: string }) {
  try {
    yield call(...firstLoginService(action.params));
    yield call(action.params.onSuccess);
  } catch (e) {
    yield call(action.params.onFailure);
    console.log('Can not log in');
  }
}

function* watchResetPassword(action: { params: resetPasswordParamTypes; type: string }) {
  try {
    yield call(...resetPasswordService(action.params));
    yield call(action.params.onSuccess);
  } catch (e) {
    yield call(action.params.onFailure);
    console.log('Can not log in');
  }
}
function* watchChangeRole(action: { payload: { data: changeUserRoleParamTypes }; type: string }) {
  try {
    yield call(...changeUserRoleService(action.payload.data));
  } catch (e) {
    console.log('Can not log in');
  }
}

function* authSaga() {
  yield all([takeLatest(ActionNames.LOGIN, watchLogin)]);
  yield all([takeLatest(ActionNames.FIRST_LOGIN, watchFirstLogin)]);
  yield all([takeLatest(ActionNames.RESET_PASSWORD, watchResetPassword)]);
  yield all([takeLatest(ActionNames.CHANGE_USER_ROLE, watchChangeRole)]);
}

export default authSaga;
