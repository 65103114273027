import Modal from 'UI/Modal';
import Table from '../../../../../../UI/Table';
import Spinner from '../../../../../../UI/Spinner';
import React, { useCallback, useEffect, useState } from 'react';
import Input from '../../../../../../UI/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getItemsToAssignSelector } from 'store/selectors/students.selectors';
import { useParams } from 'react-router-dom';
import { assignItemAction, getItemsToAssignAction } from 'store/actions/students.actions';
import { evalScaleValueNames } from '../../../../../../constants';
import styles from './styles.module.css';

type assignItemModalTypes = {
  assignItemVisible: boolean;
  toggleAssignItem: (arg1: boolean) => void;
  subjectId: number | null;
  page: number;
};

const { Search } = Input;

const columns = [
  {
    title: 'Item',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Typ',
    dataIndex: 'optional',
    key: 'optional',
    render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
  },
  {
    title: 'Skalentyp',
    dataIndex: 'assessmentScale',
    key: 'assessmentScale',
    render: (record: { name: 'Quantitative' | 'Qualtaive' | 'List' }) => (
      <span className={styles.scalesWrapper}>{evalScaleValueNames[record.name]}</span>
    ),
  },
  {
    title: 'Jahrgänge',
    dataIndex: 'schoolYears',
    key: 'schoolYears',
    render: (years: number[]) =>
      structuredClone(years)
        ?.sort((a: number, b: number) => Number(a) - Number(b))
        .join(', '),
  },
];

const AssignItemModal = ({
  assignItemVisible,
  toggleAssignItem,
  subjectId,
  page,
}: assignItemModalTypes) => {
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const availableItems = useSelector(getItemsToAssignSelector);

  const handleResetModal = useCallback(() => {
    setLoading(false);
    setSelectedItems([]);
    toggleAssignItem(false);
  }, [toggleAssignItem]);

  const handleAssignItems = useCallback(() => {
    setLoading(true);
    dispatch(
      assignItemAction({
        studentId: Number(studentId),
        subjectId: Number(subjectId),
        selectedItems,
        onSuccess: () => handleResetModal(),
        page: page - 1,
      }),
    );
  }, [dispatch, handleResetModal, page, selectedItems, studentId, subjectId]);

  useEffect(() => {
    dispatch(
      getItemsToAssignAction({ studentId: Number(studentId), subjectId: Number(subjectId) }),
    );
  }, [dispatch, studentId, subjectId]);

  return (
    <Modal
      width={800}
      centered
      okText='Hinzufügen'
      cancelText='Abbrechen'
      title='Item hinzufügen'
      onCancel={handleResetModal}
      closable
      open={assignItemVisible}
      okDisabled={!selectedItems.length}
      onOk={handleAssignItems}
      confirmLoading={loading}
      fixScroll
    >
      <div>
        <Table
          rowSelection={{
            type: 'checkbox',
            onChange: (selectedRowKeys: Array<number>) => setSelectedItems(selectedRowKeys),
            selectedRowKeys: selectedItems,
          }}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: false }}
          pagination={false}
          dataSource={availableItems}
          columns={columns}
        />
      </div>
    </Modal>
  );
};

export default AssignItemModal;
