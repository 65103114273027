import { Col, Collapse, RadioChangeEvent, Row } from 'antd';
import styles from 'pages/SelfEvaluation/styles.module.css';
import cc from 'classcat';
import { Text } from '../../../../UI/Typography';
import { primary5 } from '../../../../colors';
import RadioGroup from '../../../../UI/RadioGroup';
import Pagination from '../../../../UI/Pagination';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { selfEvaluateAction } from 'store/actions/students.actions';
import { getReportSelector } from 'store/selectors/report.selectors';
import { useParams } from 'react-router-dom';
import { getReportAction } from 'store/actions/report.actions';

const { Panel } = Collapse;

const AssistedEvaluation = ({
  handleSaveLoading,
  selectedTab,
}: {
  handleSaveLoading: (arg1: boolean) => void;
  selectedTab: 'personalInfo' | 'manualAssign' | 'assistedEval' | 'observationList';
}) => {
  const [currentPage, setCurrentPage] = useState(1);
  const dispatch = useDispatch();
  const studentReport = useSelector(getReportSelector);
  const { studentId } = useParams();

  const handleEvaluateSubItem = useCallback(
    (e: RadioChangeEvent, subItemId: number) => {
      handleSaveLoading(true);
      dispatch(
        selfEvaluateAction({
          studentId: Number(studentId),
          subItemId,
          assessmentScaleValueId: Number(e.target.value),
          izelId: studentReport.izelId,
          onSuccess: () => handleSaveLoading(false),
          page: currentPage - 1,
        }),
      );
    },
    [currentPage, dispatch, handleSaveLoading, studentId, studentReport.izelId],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(getReportAction({ page: page - 1, id: Number(studentId), size: 10 }));
    },
    [dispatch, studentId],
  );

  useEffect(() => {
    if (selectedTab === 'assistedEval') {
      dispatch(getReportAction({ id: Number(studentId), page: currentPage - 1, size: 10 }));
    }
  }, [currentPage, dispatch, selectedTab, studentId]);

  return (
    <div>
      <Collapse className={styles.collapseWrapper}>
        {studentReport?.subjects?.map((subject: any) => {
          return (
            <Panel
              className={cc([{ [styles.collapseOutline]: !subject.selfEvaluationFilled }])}
              key={subject.id}
              header={
                <Text level={2} bold>
                  {subject.name}
                </Text>
              }
            >
              <Collapse
                bordered={false}
                ghost
                className={styles.innerCollapse}
                destroyInactivePanel
              >
                {subject.items?.map((item: any) => {
                  return (
                    <Panel
                      showArrow={!!item?.subItems?.length}
                      className={cc([
                        {
                          [styles.notFilledCollapse]:
                            !item.selfEvaluationFilled && item?.subItems?.length,
                        },
                      ])}
                      key={item.id}
                      header={
                        <Text level={2} bold>
                          {item.alias}
                        </Text>
                      }
                    >
                      {item?.subItems?.length ? (
                        <Row style={{ backgroundColor: primary5 }}>
                          <Col span={12} offset={12}>
                            <div
                              style={{
                                display: 'flex',
                                textAlign: 'right',
                                justifyContent: 'space-between',
                                padding: '17px 0',
                              }}
                            >
                              {item.assessmentScaleValues?.map((value: any) => {
                                if (value.id === 12 || value.id === 11) {
                                  return;
                                }
                                return (
                                  <div style={{ width: '25%', textAlign: 'center' }} key={value.id}>
                                    {value.scaleValue}
                                  </div>
                                );
                              })}
                            </div>
                          </Col>
                        </Row>
                      ) : null}
                      {item.subItems?.map((subItem: any) => {
                        const evalScaleValuesInitial = item.assessmentScaleValues.map(
                          (scale: any) => {
                            return {
                              key: scale.id,
                              label: '',
                            };
                          },
                        );
                        const evalScaleValues = evalScaleValuesInitial
                          .filter((val: { key: number }) => val.key !== 12)
                          .filter((v: { key: number }) => v.key !== 11);
                        return (
                          <Row
                            key={subItem.id}
                            className={cc([
                              styles.openReviewBottomBorder,
                              {
                                [styles.notFilledSubItem]: !subItem.selfEvaluatedScaleValueId,
                              },
                            ])}
                            align='middle'
                          >
                            <Col span={12} style={{ paddingLeft: '34px' }}>
                              <div>
                                <Text level={2} bold>
                                  {subItem.name}
                                </Text>
                              </div>
                            </Col>
                            <Col span={12}>
                              <RadioGroup
                                defaultValue={subItem.selfEvaluatedScaleValueId}
                                className={styles.radioGroup}
                                style={{
                                  width: '100%',
                                  display: 'flex',
                                  justifyContent: 'space-between',
                                }}
                                groupOptions={evalScaleValues}
                                onChange={(e: RadioChangeEvent) =>
                                  handleEvaluateSubItem(e, subItem.id)
                                }
                              />
                            </Col>
                          </Row>
                        );
                      })}
                    </Panel>
                  );
                })}
              </Collapse>
            </Panel>
          );
        })}
      </Collapse>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={25}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={studentReport?.count}
        />
      </div>
    </div>
  );
};

export default AssistedEvaluation;
