import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const PurpleCheckSVG = () => {
  return (
    <svg width='20' height='20' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M12 2C6.48 2 2 6.48 2 12C2 17.52 6.48 22 12 22C17.52 22 22 17.52 22 12C22 6.48 17.52 2 12 2ZM10 17L5 12L6.41 10.59L10 14.17L17.59 6.58L19 8L10 17Z'
        fill='#C41D7F'
      />
    </svg>
  );
};

const PurpleCheckIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={PurpleCheckSVG} {...props} />;
};

export default PurpleCheckIcon;
