import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { addTeacherAction, editTeacherAction } from 'store/actions/teachers.actions';
import { useDispatch } from 'react-redux';
import { Text } from 'UI/Typography';
import Select from 'UI/Select';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Checkbox from '../../../../UI/Checkbox';

type addTeacherModalTypes = {
  teacherModalVisible: boolean;
  toggleAddTeacherModal: (arg1: boolean) => void;
  handleEditVisible: (arg1: number | null) => void;
  editableTeacher: any;
  currentPage: number;
  showDeactivatedTeachers: boolean;
};

const roleOptions = [
  { value: 'TEACHER', label: 'Lehrkraft' },
  { value: 'SCHOOL_ADMIN', label: 'Admin' },
];

const AddTeacherModal = ({
  teacherModalVisible,
  toggleAddTeacherModal,
  handleEditVisible,
  editableTeacher,
  currentPage,
  showDeactivatedTeachers,
}: addTeacherModalTypes) => {
  const [teacherName, setTeacherName] = useState('');
  const [lastName, setLastName] = useState('');
  const [roles, setRoles] = useState<Array<string>>([]);
  const [teacherTitle, setTeacherTitle] = useState<string | null>(null);
  const [salutation, setSalutation] = useState<string | null>(null);
  const [legalApproved, setLegalApproved] = useState(false);
  const [email, setEmail] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();

  const okButtonDisabled = useMemo(() => {
    return (
      !teacherName || !lastName || !email || (!editableTeacher && !legalApproved) || !roles.length
    );
  }, [editableTeacher, email, lastName, legalApproved, roles.length, teacherName]);

  const handleChangeName = useCallback((value: string) => {
    setTeacherName(value);
  }, []);

  const handleChangeLastName = useCallback((value: string) => {
    setLastName(value);
  }, []);

  const handleChangeRole = useCallback((value: string[]) => {
    setRoles(value);
  }, []);

  const handleChaneTeacherTitle = useCallback((value: string) => {
    setTeacherTitle(value);
  }, []);

  const handleChangeSalutation = useCallback((value: string) => {
    setSalutation(value);
  }, []);

  const handleChangeEmail = useCallback((value: string) => {
    setEmail(value);
  }, []);

  const handleResetModal = useCallback(() => {
    setEmail('');
    setTeacherName('');
    setLastName('');
    setSalutation(null);
    setTeacherTitle(null);
    setLegalApproved(false);
    setRoles([]);
    setErrorMessage('');
    toggleAddTeacherModal(false);
    handleEditVisible(null);
  }, [handleEditVisible, toggleAddTeacherModal]);

  const handleCreateTeacher = useCallback(() => {
    if (editableTeacher) {
      dispatch(
        editTeacherAction({
          consent: legalApproved,
          page: currentPage - 1,
          id: editableTeacher.id,
          email,
          name: teacherName,
          surname: lastName,
          title: teacherTitle,
          salutation,
          roles,
          active: showDeactivatedTeachers,
          onSuccess: () => handleResetModal(),
          onFailure: (message) => {
            setErrorMessage(message);
          },
        }),
      );
    } else {
      dispatch(
        addTeacherAction({
          page: currentPage - 1,
          email,
          name: teacherName,
          surname: lastName,
          title: teacherTitle,
          salutation,
          roles,
          active: showDeactivatedTeachers,
          onSuccess: () => handleResetModal(),
          onFailure: (message) => {
            setErrorMessage(message);
          },
        }),
      );
    }
  }, [
    currentPage,
    dispatch,
    editableTeacher,
    email,
    handleResetModal,
    lastName,
    legalApproved,
    roles,
    salutation,
    showDeactivatedTeachers,
    teacherName,
    teacherTitle,
  ]);

  useEffect(() => {
    if (editableTeacher && teacherModalVisible) {
      setEmail(editableTeacher.email);
      setTeacherName(editableTeacher.name);
      setLastName(editableTeacher.surname);
      setSalutation(editableTeacher.salutation);
      setTeacherTitle(editableTeacher.title);
      setLegalApproved(editableTeacher.consent);
      setRoles(editableTeacher.roles);
    }
  }, [editableTeacher, teacherModalVisible]);

  return (
    <Modal
      title={editableTeacher ? 'Person ändern' : 'Person hinzufügen'}
      onCancel={handleResetModal}
      open={teacherModalVisible}
      centered
      okText={editableTeacher ? 'Speichern' : 'Hinzufügen'}
      cancelText='Abbrechen'
      okDisabled={okButtonDisabled}
      onOk={handleCreateTeacher}
    >
      <div className={styles.nameLabel}>
        <Text level={1} bold>
          Name
        </Text>
      </div>
      <Input
        value={lastName}
        placeholder='Lehrkraft Name'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeLastName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Vorname
        </Text>
      </div>
      <Input
        value={teacherName}
        placeholder='Lehrkraft Vorname'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Rolle
        </Text>
      </div>
      <Select
        showSearch={false}
        mode='multiple'
        value={roles}
        onChange={(value) => handleChangeRole(value)}
        style={{ width: '100%' }}
        options={roleOptions}
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          E-Mail
        </Text>
      </div>
      <Input
        value={email}
        placeholder='E-Mail'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeEmail(e.target.value)}
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Zustimmung zur Datenverarbeitung
        </Text>
      </div>
      <Checkbox
        checked={legalApproved}
        onChange={(e: CheckboxChangeEvent) => {
          setLegalApproved(e.target.checked);
        }}
      >
        Liegt vor
      </Checkbox>
      {errorMessage ? (
        <Text level={1} className='errorMessage'>
          E-Mail ist bereits vorhanden
        </Text>
      ) : (
        ''
      )}
    </Modal>
  );
};

export default AddTeacherModal;
