import { ActionNames } from 'store/actions/actionNames';
import { RcFile } from 'antd/es/upload';

export type getSchoolsParams = {
  page: number;
  size: number;
};

export type schoolType = {
  id: number;
  name: string;
  officialName: string;
  adminName: string;
  adminSurname: string;
  email: string;
  active: boolean;
};

export type schoolsResponseType = { schools: Array<schoolType>; count: number };

export type createSchoolParamTypes = {
  name: string;
  officialName: string;
  adminName: string;
  adminSurname: string;
  email: string;
  onSuccess: () => void;
  onFailure: (arg1: string) => void;
  page: number;
};

export type schoolInfoResponseTypes = {
  id: number;
  name: string;
  officialName: string;
  street: string;
  city: string;
  zip: string;
  logo: string;
  startGrade: number;
  endGrade: number;
};

export type updateSchoolInfoParamTypes = {
  file: RcFile | null | undefined;
  request: Blob;
  onSuccess: () => void;
  onFailure: () => void;
};

export type createSchoolAdminParamTypes = {
  name: string;
  surname: string;
  email: string;
  schoolId: number;
  onSuccess: () => void;
  page: number;
};

export type deactivateSchoolParamTypes = {
  id: number;
  page: number;
  onSuccess: () => void;
};

export type getSchoolYearDataResponseTypes = {
  currentSchoolYearName: string;
  nextSchoolYearName: string;
  schoolYears: {
    id: number;
    name: string;
    active: boolean;
    halfYearIzelActive: boolean;
    fullYearIzelActive: boolean;
    izelsNotActivated: boolean;
    issueDateHalfYear: string;
    issueDateFullYear: string;
  }[];
  halfYearSettings: { value: number; halfYear: boolean }[];
};

export type startSchoolYearParams = {
  onSuccess: () => void;
};

export type editSchoolYearSettingParamTypes = {
  value: number;
  halfYear: boolean;
}[];

export type activateSchoolYearsParamTypes = {
  onSuccess: () => void;
};

export type deactivateIzelParamTypes = {
  izelId: number;
  onSuccess: () => void;
};

export type editIssueDateParamTypes = {
  issueDate: string;
  izelHalfYear: boolean;
  onSuccess: () => void;
};

export const getSchoolsAction = (params: getSchoolsParams) => ({
  type: ActionNames.GET_SCHOOLS,
  params,
});

export const getSchoolsActionSuccess = (schools: schoolsResponseType) => ({
  type: ActionNames.GET_SCHOOLS_SUCCESS,
  payload: schools,
});

export const createSchoolAction = (params: createSchoolParamTypes) => ({
  type: ActionNames.CREATE_SCHOOL,
  params,
});

export const createSchoolActionSuccess = (school: schoolType) => ({
  type: ActionNames.CREATE_SCHOOL_SUCCESS,
  payload: school,
});

export const getSchoolInfoAction = {
  type: ActionNames.GET_SCHOOL_INFO,
};

export const getSchoolInfoActionSuccess = (school: schoolInfoResponseTypes) => ({
  type: ActionNames.GET_SCHOOL_INFO_SUCCESS,
  payload: school,
});

export const updateSchoolInfoAction = (params: updateSchoolInfoParamTypes) => ({
  type: ActionNames.UPDATE_SCHOOL_INFO,
  params,
});

export const createSchoolAdminAction = (params: createSchoolAdminParamTypes) => ({
  type: ActionNames.CREATE_SCHOOL_ADMIN,
  params,
});

export const deactivateSchoolAction = (params: deactivateSchoolParamTypes) => ({
  type: ActionNames.DEACTIVATE_SCHOOL,
  params,
});

export const activateSchoolAction = (params: deactivateSchoolParamTypes) => ({
  type: ActionNames.ACTIVATE_SCHOOL,
  params,
});

export const getSchoolYearDataAction = {
  type: ActionNames.GET_SCHOOL_YEAR_DATA,
};

export const getSchoolYearDataActionSuccess = (schoolYearData: getSchoolYearDataResponseTypes) => ({
  type: ActionNames.GET_SCHOOL_YEAR_DATA_SUCCESS,
  payload: schoolYearData,
});

export const startSchoolYearAction = (params: startSchoolYearParams) => ({
  type: ActionNames.START_SCHOOL_YEAR,
  params,
});

export const editSchoolYearSettingsAction = (params: editSchoolYearSettingParamTypes) => ({
  type: ActionNames.EDIT_SCHOOL_YEAR_SETTINGS,
  params,
});

export const activateSchoolYearAction = (params: activateSchoolYearsParamTypes) => ({
  type: ActionNames.ACTIVATE_SCHOOL_YEAR,
  params,
});

export const deactivateIzelAction = (params: deactivateIzelParamTypes) => ({
  type: ActionNames.DE_ACTIVATE_FULL_IZEL,
  params,
});

export const deactivateHalfIzelAction = (params: deactivateIzelParamTypes) => ({
  type: ActionNames.DE_ACTIVATE_HALF_IZEL,
  params,
});

export const editIssueDateAction = (params: editIssueDateParamTypes) => ({
  type: ActionNames.EDIT_ISSUE_DATES,
  params,
});
