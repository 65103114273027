import { all, fork } from 'redux-saga/effects';
import subjectsSaga from './subjects.sagas';
import itemsSagas from './items.sagas';
import authSaga from './auth.sagas';
import groupSaga from './group.sagas';
import schoolSaga from './school.sagas';
import studentsSagas from './students.sagas';
import reportSaga from './report.sagas';
import teachersSaga from './teachers.sagas';

export default function* rootSaga() {
  yield all([
    fork(subjectsSaga),
    fork(itemsSagas),
    fork(authSaga),
    fork(groupSaga),
    fork(schoolSaga),
    fork(studentsSagas),
    fork(reportSaga),
    fork(teachersSaga),
  ]);
}
