import { Pagination as AntdPagination, ConfigProvider } from 'antd';
import { primary40 } from '../../colors';

const Pagination = ({ ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <AntdPagination {...props} showSizeChanger={false} />
    </ConfigProvider>
  );
};

export default Pagination;
