import Modal from 'UI/Modal';
import Table from '../../../../../../UI/Table';
import Spinner from '../../../../../../UI/Spinner';
import React, { useCallback, useEffect, useState } from 'react';
import { Col, Row } from 'antd';
import Input from '../../../../../../UI/Input';
import { useDispatch, useSelector } from 'react-redux';
import { getSubItemsToAssignSelector } from 'store/selectors/students.selectors';
import { useParams } from 'react-router-dom';
import { assignSubItemAction, getSubItemsToAssignAction } from 'store/actions/students.actions';

type assignSubItemModalTypes = {
  assignSubItemVisible: boolean;
  toggleAssignSubItem: (arg1: boolean) => void;
  itemId: number | null;
  subjectId: number | null;
  page: number;
};

const { Search } = Input;

const columns = [
  {
    title: 'Subitem',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Typ',
    dataIndex: 'optional',
    key: 'optional',
    render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
  },
  {
    title: 'Jahrgänge',
    dataIndex: 'schoolYears',
    key: 'schoolYears',
    render: (years: number[]) =>
      structuredClone(years)
        ?.sort((a: number, b: number) => Number(a) - Number(b))
        .join(', '),
  },
];

const AssignSubItemModal = ({
  assignSubItemVisible,
  toggleAssignSubItem,
  itemId,
  subjectId,
  page,
}: assignSubItemModalTypes) => {
  const [selectedSubItems, setSelectedSubItems] = useState<Array<number>>([]);
  const [loading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const availableSubItems = useSelector(getSubItemsToAssignSelector);

  const handleResetModal = useCallback(() => {
    toggleAssignSubItem(false);
    setLoading(false);
    setSelectedSubItems([]);
  }, [toggleAssignSubItem]);

  const handleAssignSubItems = useCallback(() => {
    setLoading(true);
    dispatch(
      assignSubItemAction({
        studentId: Number(studentId),
        subjectId: Number(subjectId),
        itemId: Number(itemId),
        selectedSubItems,
        onSuccess: () => handleResetModal(),
        page: page - 1,
      }),
    );
  }, [dispatch, handleResetModal, itemId, page, selectedSubItems, studentId, subjectId]);

  useEffect(() => {
    dispatch(
      getSubItemsToAssignAction({
        studentId: Number(studentId),
        subjectId: Number(subjectId),
        itemId: Number(itemId),
      }),
    );
  }, [dispatch, itemId, studentId, subjectId]);
  return (
    <Modal
      width={800}
      centered
      okText='Hinzufügen'
      cancelText='Abbrechen'
      title='Subitem hinzufügen'
      onCancel={() => toggleAssignSubItem(false)}
      closable
      open={assignSubItemVisible}
      okDisabled={!selectedSubItems.length}
      onOk={handleAssignSubItems}
      confirmLoading={loading}
      fixScroll
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: Array<number>) => setSelectedSubItems(selectedRowKeys),
          selectedRowKeys: selectedSubItems,
        }}
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={availableSubItems}
        columns={columns}
      />
    </Modal>
  );
};

export default AssignSubItemModal;
