import { combineReducers } from 'redux';
import subjectsReducer from './subjects.reducer';
import itemsReducer from './items.reducer';
import groupsReducer from './groups.reducer';
import userReducer from './auth.reducer';
import schoolsReducer from './schools.reducer';
import studentsReducer from './students.reducer';
import reportReducer from './report.reducer';
import teachersReducer from './teachers.reducer';

const rootReducer = {
  subjectsReducer,
  itemsReducer,
  groupsReducer,
  userReducer,
  schoolsReducer,
  studentsReducer,
  reportReducer,
  teachersReducer,
};

export default combineReducers(rootReducer);
