import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const OfflineSVG = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M19.8 22.5998L17.15 19.9998H6.5C4.96667 19.9998 3.66667 19.4665 2.6 18.3998C1.53333 17.3331 1 16.0331 1 14.4998C1 13.2165 1.39583 12.0748 2.1875 11.0748C2.97917 10.0748 4 9.43314 5.25 9.14981C5.3 9.01647 5.35 8.88731 5.4 8.76231C5.45 8.63731 5.5 8.4998 5.55 8.34981L1.4 4.1998L2.8 2.7998L21.2 21.1998L19.8 22.5998ZM6.5 17.9998H15.15L7.1 9.94981C7.06667 10.1331 7.04167 10.3081 7.025 10.4748C7.00833 10.6415 7 10.8165 7 10.9998H6.5C5.53333 10.9998 4.70833 11.3415 4.025 12.0248C3.34167 12.7081 3 13.5331 3 14.4998C3 15.4665 3.34167 16.2915 4.025 16.9748C4.70833 17.6581 5.53333 17.9998 6.5 17.9998ZM21.6 18.7498L20.15 17.3498C20.4333 17.1165 20.6458 16.8456 20.7875 16.5373C20.9292 16.229 21 15.8831 21 15.4998C21 14.7998 20.7583 14.2081 20.275 13.7248C19.7917 13.2415 19.2 12.9998 18.5 12.9998H17V10.9998C17 9.61647 16.5125 8.4373 15.5375 7.46231C14.5625 6.4873 13.3833 5.9998 12 5.9998C11.55 5.9998 11.1167 6.05397 10.7 6.1623C10.2833 6.27064 9.88333 6.44147 9.5 6.6748L8.05 5.2248C8.63333 4.8248 9.25417 4.52064 9.9125 4.3123C10.5708 4.10397 11.2667 3.9998 12 3.9998C13.95 3.9998 15.6042 4.67897 16.9625 6.0373C18.3208 7.39564 19 9.04981 19 10.9998C20.15 11.1331 21.1042 11.629 21.8625 12.4873C22.6208 13.3456 23 14.3498 23 15.4998C23 16.1498 22.875 16.754 22.625 17.3123C22.375 17.8706 22.0333 18.3498 21.6 18.7498Z'
        fill='#545454'
      />
    </svg>
  );
};

const OfflineIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={OfflineSVG} {...props} />;
};

export default OfflineIcon;
