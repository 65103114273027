import {
  createSchoolAdminParamTypes,
  createSchoolParamTypes,
  deactivateIzelParamTypes,
  deactivateSchoolParamTypes,
  editIssueDateParamTypes,
  editSchoolYearSettingParamTypes,
  getSchoolsParams,
  updateSchoolInfoParamTypes,
} from 'store/actions/school.actions';
import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import { client, fileClient } from 'store/services/client';
import { deactivateTeacherParamTypes } from 'actions/teachers.actions';

export const getSchoolsService = (params: getSchoolsParams): [ApiCall, string] => [
  client.get,
  `/schools?page=${params.page}&size=${params.size}`,
];

export const createSchoolService = (
  params: createSchoolParamTypes,
): [
  ApiCallWithParams,
  string,
  Omit<createSchoolParamTypes, 'page' | 'onSuccess' | 'onFailure'>,
] => [
  client.post,
  '/schools',
  {
    name: params.name,
    officialName: params.officialName,
    adminName: params.adminName,
    adminSurname: params.adminSurname,
    email: params.email,
  },
];

export const getSchoolService = (): [ApiCall, string] => [client.get, '/schools/current'];

export const updateSchoolInfoService = (
  params: updateSchoolInfoParamTypes,
): [ApiCallWithParams, string, updateSchoolInfoParamTypes] => {
  return [fileClient.put, '/schools/current', params];
};

export const createSchoolAdminService = (
  params: createSchoolAdminParamTypes,
): [ApiCallWithParams, string, Omit<createSchoolAdminParamTypes, 'page' | 'onSuccess'>] => {
  return [
    client.post,
    '/school-admins',
    { name: params.name, surname: params.surname, email: params.email, schoolId: params.schoolId },
  ];
};

export const deactivateSchoolService = (params: deactivateSchoolParamTypes): [ApiCall, string] => [
  client.delete,
  `/schools/${params.id}/deactivate`,
];

export const activateSchoolService = (params: deactivateSchoolParamTypes): [ApiCall, string] => [
  client.post,
  `/schools/${params.id}/activate`,
];

export const getGradesRangeService = (): [ApiCall, string] => [
  client.get,
  '/schools/current/grades',
];

export const getSchoolYearDataService = (): [ApiCall, string] => [
  client.get,
  '/schools/current/school-year',
];

export const startSchoolYearService = (): [ApiCall, string] => [
  client.post,
  '/schools/current/school-year',
];

export const editSchoolYearSettingsService = (
  params: editSchoolYearSettingParamTypes,
): [ApiCallWithParams, string, string] => {
  return [client.post, '/schools/current/half-year-izel-flags', JSON.stringify(params)];
};

export const activateSchoolYearService = (): [ApiCall, string] => [
  client.post,
  '/school-years/current/izels/activate',
];

export const deactivateIZelService = (params: deactivateIzelParamTypes): [ApiCall, string] => [
  client.delete,
  `/school-years/${params.izelId}/izels/full-year/deactivate`,
];

export const deactivateHalfIzelService = (params: deactivateIzelParamTypes): [ApiCall, string] => [
  client.delete,
  `/school-years/${params.izelId}/izels/half-year/deactivate`,
];

export const editIssueDatesService = (
  params: editIssueDateParamTypes,
): [ApiCallWithParams, string, editIssueDateParamTypes] => {
  return [client.post, '/school-years/current/izels/issue-date', params];
};
