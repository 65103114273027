import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CloseSVG = () => {
  return (
    <svg width='14' height='15' viewBox='0 0 14 15' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M14 1.91L12.59 0.5L7 6.09L1.41 0.5L0 1.91L5.59 7.5L0 13.09L1.41 14.5L7 8.91L12.59 14.5L14 13.09L8.41 7.5L14 1.91Z'
        fill='#545454'
      />
    </svg>
  );
};

const CloseIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={CloseSVG} {...props} />;
};

export default CloseIcon;
