import {
  getItemsToAssignResponseTypes,
  getReportListResponseTypes,
  getStudentIfoResponseTypes,
  getStudentReportResponseTypes,
  getSubItemsToAssignResponseTypes,
  getSubjectsToAssignResponseTypes,
  studentsResponse,
  studentTypes,
} from 'store/actions/students.actions';
import { ActionNames } from 'store/actions/actionNames';
import produce from 'immer';

const initialState: {
  students: Array<studentTypes> | [];
  studentsCount: number;
  subjectsToAssign: getSubjectsToAssignResponseTypes;
  itemsToAssign: getItemsToAssignResponseTypes;
  subItemsToAssign: getSubItemsToAssignResponseTypes;
  studentInfo: getStudentIfoResponseTypes | null;
  studentReport: getStudentReportResponseTypes | null;
  reportList: getReportListResponseTypes | [];
} = {
  students: [],
  studentsCount: 0,
  subjectsToAssign: [],
  itemsToAssign: [],
  subItemsToAssign: [],
  studentInfo: null,
  studentReport: null,
  reportList: [],
};

const studentsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: studentsResponse &
        studentTypes &
        getSubjectsToAssignResponseTypes &
        getItemsToAssignResponseTypes &
        getSubItemsToAssignResponseTypes &
        getStudentIfoResponseTypes &
        getStudentReportResponseTypes &
        getReportListResponseTypes;
    };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_STUDENTS_SUCCESS:
        draft.students = action.payload.data.students;
        draft.studentsCount = action.payload.data.count;
        break;
      case ActionNames.GET_SUBJECTS_TO_ASSIGN_SUCCESS:
        draft.subjectsToAssign = action.payload.data;
        break;
      case ActionNames.GET_ITEMS_TO_ASSIGN_SUCCESS:
        draft.itemsToAssign = action.payload.data;
        break;
      case ActionNames.GET_SUB_ITEMS_TO_ASSIGN_SUCCESS:
        draft.subItemsToAssign = action.payload.data;
        break;
      case ActionNames.GET_STUDENT_INFO_SUCCESS:
        draft.studentInfo = action.payload.data;
        break;
      case ActionNames.GET_STUDENT_REPORT_SUCCESS:
        draft.studentReport = action.payload.data;
        break;
      case ActionNames.SELF_EVALUATE_SUCCESS:
        console.log('action.payload', action.payload);
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.studentReport.subjects.find(
          (sub: any) => sub.id === action.payload.data.subjectId,
        ).selfEvaluationFilled = action.payload.data.subjectEvaluationData.selfEvaluationFilled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.studentReport.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId).selfEvaluationFilled =
          action.payload.data.itemEvaluationData.selfEvaluationFilled;
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        draft.studentReport.subjects
          .find((sub: any) => sub.id === action.payload.data.subjectId)
          .items.find((item: any) => item.id === action.payload.data.itemId)
          .subItems.find(
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
            (subItem: any) => subItem.id === action.payload.subItemId,
            // eslint-disable-next-line @typescript-eslint/ban-ts-comment
            // @ts-ignore
          ).selfEvaluatedScaleValueId = action.payload.scaleId;
        break;
      case ActionNames.GET_REPORT_LIST_SUCCESS:
        draft.reportList = action.payload.data;
        break;
      default:
        break;
    }
  });

export default studentsReducer;
