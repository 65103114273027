import { SelectProps } from 'antd';

export const userRoles: { [key: string]: string } = {
  ASSOCIATION_ADMIN: 'VERBANDADMIN',
  SCHOOL_ADMIN: 'Schulverwaltung'.toUpperCase(),
  TEACHER: 'Lehrkraft'.toUpperCase(),
  STUDENT: 'Schüler/Schülerinnen'.toUpperCase(),
};

export const groupOptions: SelectProps['options'] = [
  { label: 'Stammgruppe', value: 'CORE_GROUP' },
  { label: 'Lerngruppe', value: 'LEARNING_GROUP' },
  { label: 'Zusatzangebot', value: 'ADDITIONAL_OFFER' },
];

export const groupTypesMapping: { [key: string]: string } = {
  CORE_GROUP: 'Stammgruppe',
  LEARNING_GROUP: 'Lerngruppe',
  ADDITIONAL_OFFER: 'Zusatzangebot',
};

export const teacherRoleMapping = {
  CORE_GROUP_RESPONSIBLE: 'Stammgruppenleitung',
  SUBJECT_EXPERT: 'Fachexperte',
  REGULAR: 'Lernbegleitung',
};

export const evalScaleValueNames = {
  Quantitative: 'Selten/Wechselnd/Häufig/Fast immer',
  Qualtaive: 'Anfänge/Basiskenntnisse/Gesicherte Kenntnisse/Vertiefte Kenntnisse',
  List: 'Ja/Nein',
};

export const schoolYearOptions: SelectProps['options'] = [
  { label: '1. Jahrgangstufe', value: '1' },
  { label: '2. Jahrgangstufe', value: '2' },
  { label: '3. Jahrgangstufe', value: '3' },
  { label: '4. Jahrgangstufe', value: '4' },
  { label: '5. Jahrgangstufe', value: '5' },
  { label: '6. Jahrgangstufe', value: '6' },
  { label: '7. Jahrgangstufe', value: '7' },
  { label: '8. Jahrgangstufe', value: '8' },
  { label: '9. Jahrgangstufe', value: '9' },
  { label: '10. Jahrgangstufe', value: '10' },
  { label: '11. Jahrgangstufe', value: '11' },
  { label: '12. Jahrgangstufe', value: '12' },
  { label: '13. Jahrgangstufe', value: '13' },
];
