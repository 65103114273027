import { createSelector } from 'reselect';
import {
  getAllObservationsResponseTypes,
  getEvaluationsResponseTypes,
  getObservationResponseTypes,
  getObservationSubjectsResponseTypes,
  getPersonalLetterResponseTypes,
  getReportResponseTypes,
  getSchoolYearsResponseTypes,
  practiceResponseType,
} from 'store/actions/report.actions';

const appState = (state: {
  reportReducer: {
    report: getReportResponseTypes;
    observations: getObservationResponseTypes;
    evaluations: getEvaluationsResponseTypes;
    practice: practiceResponseType;
    personalLetter: getPersonalLetterResponseTypes;
    allObservations: getAllObservationsResponseTypes;
    schoolYears: getSchoolYearsResponseTypes;
    observationSubjects: getObservationSubjectsResponseTypes | [];
    observationItems: getObservationSubjectsResponseTypes | [];
    observationSubItems: getObservationSubjectsResponseTypes | [];
  };
}): {
  report: getReportResponseTypes;
  observations: getObservationResponseTypes;
  evaluations: getEvaluationsResponseTypes;
  practice: practiceResponseType;
  personalLetter: getPersonalLetterResponseTypes;
  allObservations: getAllObservationsResponseTypes;
  schoolYears: getSchoolYearsResponseTypes;
  observationSubjects: getObservationSubjectsResponseTypes | [];
  observationItems: getObservationSubjectsResponseTypes | [];
  observationSubItems: getObservationSubjectsResponseTypes | [];
} => state.reportReducer;

export const getReportSelector = createSelector(appState, (state) => state.report);
export const getSchoolYearsSelector = createSelector(appState, (state) => state.schoolYears);
export const getObservationsSelector = createSelector(appState, (state) => state.observations);
export const getEvaluationsSelector = createSelector(appState, (state) => state.evaluations);
export const getPractice = createSelector(appState, (state) => state.practice);
export const getPersonalLetter = createSelector(appState, (state) => state.personalLetter);
export const getAllObservations = createSelector(appState, (state) => state.allObservations);
export const getObservationSubjects = createSelector(
  appState,
  (state) => state.observationSubjects,
);
export const getObservationItemsSelector = createSelector(
  appState,
  (state) => state.observationItems,
);
export const getObservationSubItemsSelector = createSelector(
  appState,
  (state) => state.observationSubItems,
);
