import { createSelector } from 'reselect';
import { subjectTypes } from 'actions/subjects.actions';

const appState = (state: {
  subjectsReducer: {
    subjects: Array<subjectTypes> | [];
    subjectCount: number;
    reorderList: { subjects: Array<subjectTypes>; count: number };
  };
}): {
  subjects: Array<subjectTypes> | [];
  subjectCount: number;
  reorderList: { subjects: Array<subjectTypes>; count: number };
} => state.subjectsReducer;

export const getSubjectsSelector = createSelector(appState, (state) => state.subjects);
export const getSubjectsToReorderSelector = createSelector(appState, (state) => state.reorderList);
export const getSubjectsCountSelector = createSelector(appState, (state) => state.subjectCount);
