import React from 'react';
import ReactQuill from 'react-quill';

type RichEditorTypes = {
  value: string;
  onChange: (arg1: string, arg2: number) => void;
  emptyCheck?: (arg1: boolean) => void;
  readOnly?: boolean;
};

const modules = {
  toolbar: [
    ['bold', 'italic', 'underline'],
    [{ list: 'ordered' }, { list: 'bullet' }],
    [{ align: '' }, { align: 'center' }, { align: 'right' }, { align: 'justify' }],
  ],
  clipboard: {
    matchVisual: false,
  },
};
const formats = ['bold', 'italic', 'underline', 'align', 'bullet', 'list'];

const RichText = ({ value, onChange, readOnly, emptyCheck }: RichEditorTypes) => {
  return (
    <ReactQuill
      readOnly={readOnly}
      value={value}
      onChange={(value, deltaOp, sources, editor) => {
        onChange(value, editor?.getLength() - 1);
        emptyCheck?.(!editor.getText().trim());
      }}
      modules={modules}
      formats={formats}
      placeholder='Text hinzufügen'
    />
  );
};

export default RichText;
