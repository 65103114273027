import { createSelector } from 'reselect';
import { userTypes } from 'actions/auth.actions';

const appState = (state: {
  userReducer: {
    user: userTypes | null;
    userRole: 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN';
    authData: string;
  };
}): {
  user: userTypes | null;
  userRole: 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN';
  authData: string;
} => state.userReducer;

export const getUserSelector = createSelector(appState, (state) => state.user);
export const getUserRole = createSelector(appState, (state) => state.userRole);
export const getAuthDataSelector = createSelector(appState, (state) => state.authData);
