import { useDispatch, useSelector } from 'react-redux';
import {
  getAllObservations,
  getObservationItemsSelector,
  getObservationSubItemsSelector,
  getObservationSubjects,
  getReportSelector,
} from 'store/selectors/report.selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  getAllObservationsAction,
  getObservationItemsAction,
  getObservationSubItemsAction,
  getObservationSubjectsAction,
  removeAllObservations,
} from 'store/actions/report.actions';
import { useParams } from 'react-router-dom';
import styles from 'pages/Report/__partials/PersonalLetter/__partials/AllObservations/styles.module.css';
import { Text } from '../../../../UI/Typography';
import { Col, DatePicker, Dropdown, Popover, Radio, Row } from 'antd';
import Table from '../../../../UI/Table';
import Button from '../../../../UI/Button';
import Select from '../../../../UI/Select';
import FilterIcon from '../../../../UI/Icons/FilterIcon';
import Checkbox from '../../../../UI/Checkbox';
import CloseIcon from '../../../../UI/Icons/CloseIcon';
import ArrowDownIcon from '../../../../UI/Icons/ArrowDownIcon';
const { RangePicker } = DatePicker;

const evaluationScaleValues: { [key: string]: string } = {
  1: 'selten',
  2: 'wechselnd',
  3: 'häufig',
  4: 'fast immer',
  5: 'Anfänge',
  6: 'Basiskenntnisse',
  7: 'gesicherte Kenntnisse',
  8: 'vertiefte Kenntnisse',
  9: 'Ja',
  10: 'Nein',
};

const ObservationList = ({ selectedTab }: { selectedTab: string }) => {
  const [page, setPage] = useState(0);
  const [getObservationsLoading, setGetObservationsLoading] = useState(false);
  const [onlyMyObservationsEnabled, setOnlyMyObservationsEnabled] = useState(false);
  const [personalObservationsEnabled, setPersonalObservationsEnabled] = useState(false);
  const [timePeriodEnabled, setTimePeriodEnabled] = useState(false);
  const [timePeriodOpen, setTimePeriodOpen] = useState(false);
  const [selectedDates, setSelectedDates] = useState<Array<string>>([]);
  const [subjectsEnabled, setSubjectsEnabled] = useState(false);
  const [subjectsFilterOpen, setSubjectsFilterOpen] = useState(false);
  const [selectedSubjects, setSelectedSubjects] = useState<Array<number>>([]);
  const [itemsEnabled, setItemsEnabled] = useState(false);
  const [itemsFilterOpen, setItemsFilterOpen] = useState(false);
  const [selectedItems, setSelectedItems] = useState<Array<number>>([]);
  const [subItemsEnabled, setSubItemsEnabled] = useState(false);
  const [subItemFilterOpen, setSubItemFilterOpen] = useState(false);
  const [selectedSubItems, setSelectedSubItems] = useState<Array<number>>([]);
  const observations = useSelector(getAllObservations);
  const observationSubjects = useSelector(getObservationSubjects);
  const observationItems = useSelector(getObservationItemsSelector);
  const observationSubItems = useSelector(getObservationSubItemsSelector);
  const report = useSelector(getReportSelector);
  const dispatch = useDispatch();
  const { studentId } = useParams();

  const allSubjects = useMemo(
    () =>
      observationSubjects.map((value) => {
        return { label: value.name, value: value.id };
      }),
    [observationSubjects],
  );

  const allItems = useMemo(
    () =>
      observationItems.map((value) => {
        return { label: value.name, value: value.id };
      }),
    [observationItems],
  );

  const allSubItems = useMemo(
    () =>
      observationSubItems.map((value) => {
        return { label: value.name, value: value.id };
      }),
    [observationSubItems],
  );

  const handleChangePage = useCallback(() => {
    setPage((prev) => prev + 1);
  }, []);

  const handleSelectSubjects = useCallback(
    (value: number[]) => {
      setPage(0);
      setSelectedSubjects(value);
      dispatch(removeAllObservations);
      setGetObservationsLoading(true);
    },
    [dispatch],
  );
  const handleSelectItems = useCallback(
    (value: number[]) => {
      setPage(0);
      setSelectedItems(value);
      dispatch(removeAllObservations);
      setGetObservationsLoading(true);
    },
    [dispatch],
  );

  const handleSelectSubItems = useCallback(
    (value: number[]) => {
      setPage(0);
      setSelectedSubItems(value);
      dispatch(removeAllObservations);
      setGetObservationsLoading(true);
    },
    [dispatch],
  );

  const handleChangeSubjectsEnabled = useCallback((value: boolean) => {
    setSubjectsEnabled(value);
  }, []);

  const handleRemoveDateFilter = useCallback(
    (e: any) => {
      e.stopPropagation();
      setSelectedDates([]);
      setTimePeriodOpen(false);
      setTimePeriodEnabled(false);
      setPage(0);
      dispatch(removeAllObservations);
    },
    [dispatch],
  );

  const handleRemoveSubItemsFilter = useCallback(
    (e: any) => {
      if (e) {
        e.stopPropagation();
      }
      setSubItemsEnabled(false);
      setSelectedSubItems([]);
      setSubItemFilterOpen(false);
      setPage(0);
      dispatch(removeAllObservations);
    },
    [dispatch],
  );

  const handleRemoveItemsFilter = useCallback(
    (e: any) => {
      if (e) {
        e.stopPropagation();
      }
      setItemsEnabled(false);
      setSelectedItems([]);
      setItemsFilterOpen(false);
      setPage(0);
      dispatch(removeAllObservations);
      handleRemoveSubItemsFilter(null);
    },
    [dispatch, handleRemoveSubItemsFilter],
  );

  const handleRemoveSubjectsFilter = useCallback(
    (e: any) => {
      e.stopPropagation();
      handleChangeSubjectsEnabled(false);
      handleSelectSubjects([]);
      setSubjectsFilterOpen(false);
      handleRemoveItemsFilter(null);
      handleRemoveSubItemsFilter(null);
      setPage(0);
      dispatch(removeAllObservations);
    },
    [
      dispatch,
      handleChangeSubjectsEnabled,
      handleRemoveItemsFilter,
      handleRemoveSubItemsFilter,
      handleSelectSubjects,
    ],
  );

  useEffect(() => {
    setGetObservationsLoading(true);
    dispatch(
      getAllObservationsAction({
        studentId: Number(studentId),
        page,
        size: 3,
        onSuccess: () => setGetObservationsLoading(false),
        subjectIds: selectedSubjects,
        profile: true,
        personalObservations: personalObservationsEnabled,
        onlyMyDocumentation: onlyMyObservationsEnabled,
        itemIds: selectedItems,
        subItemIds: selectedSubItems,
        startDate: selectedDates?.[0],
        endDate: selectedDates?.[1],
      }),
    );
  }, [
    dispatch,
    onlyMyObservationsEnabled,
    page,
    selectedItems,
    selectedSubItems,
    selectedSubjects,
    personalObservationsEnabled,
    studentId,
    selectedDates,
  ]);

  useEffect(() => {
    dispatch(
      getObservationSubjectsAction({
        studentId: Number(studentId),
      }),
    );
  }, [dispatch, studentId]);

  useEffect(() => {
    if (selectedSubjects.length) {
      dispatch(getObservationItemsAction({ id: Number(studentId), subjectIds: selectedSubjects }));
    }
  }, [dispatch, selectedSubjects, studentId]);

  useEffect(() => {
    if (selectedItems.length) {
      dispatch(getObservationSubItemsAction({ id: Number(studentId), itemIds: selectedItems }));
    }
  }, [dispatch, selectedItems, selectedSubjects, studentId]);

  useEffect(() => {
    return () => {
      dispatch(removeAllObservations);
      setPage(0);
      setSelectedSubjects([]);
      setSubjectsFilterOpen(false);
      setSubjectsEnabled(false);
      setItemsFilterOpen(false);
      setSelectedItems([]);
    };
  }, [dispatch]);

  return (
    <div>
      <Dropdown
        overlayClassName={styles.dropdownContent}
        menu={{
          items: [
            {
              key: '1',
              label: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setOnlyMyObservationsEnabled(true);
                    setPage(0);
                    dispatch(removeAllObservations);
                    setGetObservationsLoading(true);
                  }}
                >
                  <Text level={1} bold>
                    Nur meine Beobachtungen
                  </Text>
                </span>
              ),
            },
            {
              key: '2',
              label: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setPersonalObservationsEnabled(true);
                    setPage(0);
                    dispatch(removeAllObservations);
                    setGetObservationsLoading(true);
                  }}
                >
                  <Text level={1} bold>
                    Persönliche Beobachtungen
                  </Text>
                </span>
              ),
            },
            {
              key: '3',
              label: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    setTimePeriodEnabled(true);
                  }}
                >
                  <Text level={1} bold>
                    Datum
                  </Text>
                </span>
              ),
            },
            {
              key: '4',
              label: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    handleChangeSubjectsEnabled(true);
                  }}
                >
                  <Text level={1} bold>
                    Fächer
                  </Text>
                </span>
              ),
            },
            {
              key: '5',
              disabled: !selectedSubjects.length,
              label: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    if (selectedSubjects.length) {
                      setItemsEnabled(true);
                    }
                  }}
                >
                  <Text level={1} bold>
                    Item
                  </Text>
                </span>
              ),
            },
            {
              key: '6',
              disabled: !selectedItems.length,
              label: (
                <span
                  onClick={(e) => {
                    e.stopPropagation();
                    if (selectedItems.length) {
                      setSubItemsEnabled(true);
                    }
                  }}
                >
                  <Text level={1} bold>
                    Subitem
                  </Text>
                </span>
              ),
            },
          ],
        }}
        placement='bottomRight'
        trigger={['click']}
      >
        <Button icon={<FilterIcon />} type='default'>
          Filters
        </Button>
      </Dropdown>
      <div className={styles.studentFilterWrapper}>
        {onlyMyObservationsEnabled && (
          <div className={styles.filterWrapperClosed}>
            <span
              onClick={() => {
                setPage(0);
                setOnlyMyObservationsEnabled(false);
                dispatch(removeAllObservations);
                setGetObservationsLoading(true);
              }}
              className={styles.removeIcon}
            >
              <CloseIcon />
            </span>
            Nur meine Beobachtungen
          </div>
        )}
        {personalObservationsEnabled && (
          <div className={styles.filterWrapperClosed}>
            <span
              onClick={() => {
                setPage(0);
                setPersonalObservationsEnabled(false);
                dispatch(removeAllObservations);
                setGetObservationsLoading(true);
              }}
              className={styles.removeIcon}
            >
              <CloseIcon />
            </span>
            Persönliche Beobachtungen
          </div>
        )}
        {timePeriodEnabled && (
          <Col>
            <Popover
              getPopupContainer={(node) => node.parentElement || document.body}
              trigger={['click']}
              placement='bottomLeft'
              onOpenChange={(open) => setTimePeriodOpen(open)}
              content={
                <div className={styles.popoverInner}>
                  <RangePicker
                    format='DD.MM.YYYY'
                    style={{ width: '300px' }}
                    onChange={(val, dateString) => {
                      setSelectedDates(dateString);
                      setPage(0);
                      dispatch(removeAllObservations);
                    }}
                  />
                </div>
              }
            >
              <div className={timePeriodOpen ? styles.filterWrapper : styles.filterWrapperClosed}>
                <span onClick={(e) => handleRemoveDateFilter(e)} className={styles.removeIcon}>
                  <CloseIcon />
                </span>
                Datum:
                <span className={styles.arrow}>
                  <ArrowDownIcon />
                </span>
              </div>
            </Popover>
          </Col>
        )}
        {subjectsEnabled && (
          <Col>
            <Popover
              getPopupContainer={(node) => node.parentElement || document.body}
              trigger={['click']}
              placement='bottomLeft'
              onOpenChange={(open) => setSubjectsFilterOpen(open)}
              content={
                <div className={styles.popoverInner}>
                  <div className={styles.popoverHeader}>
                    <Text level={2} bold>
                      Fächer
                    </Text>
                    <Button type='default' onClick={() => handleSelectSubjects([])}>
                      Alles löschen
                    </Button>
                  </div>
                  <Select
                    popupClassName={styles.schoolYearSelect}
                    value={selectedSubjects}
                    showSearch={false}
                    options={allSubjects}
                    onChange={(years) => {
                      handleSelectSubjects(years);
                    }}
                    showArrow={false}
                    mode='multiple'
                    style={{ width: '310px' }}
                    dropdownRender={() => <span />}
                  />
                  <div>
                    <Checkbox.Group
                      className={styles.checkboxes}
                      options={allSubjects}
                      onChange={handleSelectSubjects}
                      value={selectedSubjects}
                    />
                  </div>
                </div>
              }
            >
              <div
                className={subjectsFilterOpen ? styles.filterWrapper : styles.filterWrapperClosed}
              >
                <span onClick={(e) => handleRemoveSubjectsFilter(e)} className={styles.removeIcon}>
                  <CloseIcon />
                </span>
                Fächer:
                <span className={styles.arrow}>
                  <ArrowDownIcon />
                </span>
              </div>
            </Popover>
          </Col>
        )}
        {itemsEnabled && (
          <Col>
            <Popover
              getPopupContainer={(node) => node.parentElement || document.body}
              trigger={['click']}
              placement='bottomLeft'
              onOpenChange={(open) => setItemsFilterOpen(open)}
              content={
                <div className={styles.popoverInner}>
                  <div className={styles.popoverHeader}>
                    <Text level={2} bold>
                      Item
                    </Text>
                    <Button type='default' onClick={() => setSelectedItems([])}>
                      Alles löschen
                    </Button>
                  </div>
                  <Select
                    popupClassName={styles.schoolYearSelect}
                    value={selectedItems}
                    showSearch={false}
                    options={allItems}
                    onChange={(years) => {
                      handleSelectItems(years);
                    }}
                    showArrow={false}
                    mode='multiple'
                    style={{ width: '310px' }}
                    dropdownRender={() => <span />}
                  />
                  <div>
                    <Checkbox.Group
                      className={styles.checkboxes}
                      options={allItems}
                      onChange={handleSelectItems}
                      value={selectedItems}
                    />
                  </div>
                </div>
              }
            >
              <div className={itemsFilterOpen ? styles.filterWrapper : styles.filterWrapperClosed}>
                <span onClick={(e) => handleRemoveItemsFilter(e)} className={styles.removeIcon}>
                  <CloseIcon />
                </span>
                Item:
                <span className={styles.arrow}>
                  <ArrowDownIcon />
                </span>
              </div>
            </Popover>
          </Col>
        )}
        {subItemsEnabled && (
          <Col>
            <Popover
              getPopupContainer={(node) => node.parentElement || document.body}
              trigger={['click']}
              placement='bottomLeft'
              onOpenChange={(open) => setSubItemFilterOpen(open)}
              content={
                <div className={styles.popoverInner}>
                  <div className={styles.popoverHeader}>
                    <Text level={2} bold>
                      SubItem
                    </Text>
                    <Button type='default' onClick={() => setSelectedSubItems([])}>
                      Alles löschen
                    </Button>
                  </div>
                  <Select
                    popupClassName={styles.schoolYearSelect}
                    value={selectedSubItems}
                    showSearch={false}
                    options={allSubItems}
                    onChange={(years) => {
                      handleSelectSubItems(years);
                    }}
                    showArrow={false}
                    mode='multiple'
                    style={{ width: '310px' }}
                    dropdownRender={() => <span />}
                  />
                  <div>
                    <Checkbox.Group
                      className={styles.checkboxes}
                      options={allSubItems}
                      onChange={handleSelectSubItems}
                      value={selectedSubItems}
                    />
                  </div>
                </div>
              }
            >
              <div
                className={subItemFilterOpen ? styles.filterWrapper : styles.filterWrapperClosed}
              >
                <span onClick={(e) => handleRemoveSubItemsFilter(e)} className={styles.removeIcon}>
                  <CloseIcon />
                </span>
                SubItem:
                <span className={styles.arrow}>
                  <ArrowDownIcon />
                </span>
              </div>
            </Popover>
          </Col>
        )}
      </div>
      {observations?.data?.map((observation, index) => {
        const breadCrumbs = `${report.surname}, ${report.name} ${
          observation.subjectName ? '/' + observation.subjectName : ''
        } ${observation.itemName ? '/' + observation.itemName : ''}  ${
          observation.subItemName ? '/' + observation.subItemName : ''
        }`;
        const split = breadCrumbs.split('/');
        const breadCrumbFirst = [...split];
        breadCrumbFirst.pop();
        const baseColumns = [
          {
            title: 'Datum',
            dataIndex: 'evaluationDateTime',
            key: 'evaluationDateTime',
          },
          {
            title: 'Verfasser',
            dataIndex: 'teacherName',
            key: 'teacherName',
          },
        ];

        const filteredScaleValues = () => {
          return observation?.assessmentScaleValues?.filter(
            (observation: { id: number }) => observation.id !== 12 && observation.id !== 11,
          );
        };

        const evaluationsHeaders = filteredScaleValues()?.map((value: { scaleValue: any }) => {
          return {
            title: value.scaleValue,
            dataIndex: value.scaleValue,
            key: value.scaleValue,
            render: (record: boolean) => (
              <Radio
                style={{ width: '100%', justifyContent: 'center' }}
                className={styles.radio}
                checked={record}
              />
            ),
          };
        });

        const getColumns = () => baseColumns.concat(evaluationsHeaders);

        const tableData = [
          {
            id: observation.id,
            teacherName: observation.teacherSurname + ', ' + observation.teacherName,
            teacherSurname: observation.teacherSurname,
            assessmentScaleValueId: observation.assessmentScaleValueId,
            evaluationDateTime: observation.evaluationDateTime,
            [evaluationScaleValues[observation.assessmentScaleValueId]]: true,
          },
        ];
        return (
          <div key={index} className={styles.observationInner}>
            <div className={styles.breadCrumbWrapper}>
              <Text className='secondary-30' level={1}>
                {breadCrumbFirst.join(' / ')}
              </Text>
              <Text level={1} bold className='secondary-40'>
                {` ${split.length > 1 ? '/' : ''} ${split[split.length - 1]}`}
              </Text>
            </div>
            <Row gutter={16}>
              <Col>
                <Text level={1} bold>
                  {observation.observationDateTime || observation.evaluationDateTime}
                </Text>
              </Col>
              <Col>
                <Text level={1} bold>
                  {observation.teacherSurname}
                  {', '}
                  {observation.teacherName}
                </Text>
              </Col>
            </Row>
            <div
              className='ql-editor'
              style={{ margin: '8px 0' }}
              dangerouslySetInnerHTML={{ __html: observation.text }}
            />
            {!observation.text && (
              <div>
                <Table
                  className={styles.evalTable}
                  rowKey={(record) => record.id}
                  pagination={false}
                  dataSource={tableData}
                  columns={getColumns()}
                />
              </div>
            )}
          </div>
        );
      })}
      <div className={styles.showMore} style={{ marginBottom: '20px' }}>
        {Number(observations?.data?.length) !== observations?.count &&
          !!observations?.data?.length && (
            <Button type='primary' onClick={handleChangePage} loading={getObservationsLoading}>
              Mehr anzeigen
            </Button>
          )}
      </div>
    </div>
  );
};

export default ObservationList;
