import { ReactNode } from 'react';
import Spinner from '../../UI/Spinner';
import styles from './styles.module.css';

const SpinContainer = ({ children, spinning }: { children: ReactNode; spinning: boolean }) => {
  return (
    <div>
      {spinning && (
        <div className={styles.spinnerPosition}>
          <Spinner spinning={true} />
        </div>
      )}
      {children}
    </div>
  );
};

export default SpinContainer;
