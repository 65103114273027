import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const DragSVG = () => {
  return (
    <svg width='24' height='24' viewBox='0 0 24 24' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        fillRule='evenodd'
        clipRule='evenodd'
        d='M20 9H4V11H20V9ZM4 15H20V13H4V15Z'
        fill='#545454'
      />
    </svg>
  );
};

const DragIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={DragSVG} {...props} />;
};

export default DragIcon;
