import { Radio as AntdRadio, RadioGroupProps, ConfigProvider, RadioProps } from 'antd';
import cc from 'classcat';
import styles from './styles.module.css';
import { primary40 } from '../../colors';
import { ReactElement } from 'react';

type GroupOptionsType = {
  groupOptions: { key: number; label: string | ReactElement }[];
};

const RadioGroup = ({
  groupOptions,
  className,
  ...props
}: RadioGroupProps & RadioProps & GroupOptionsType) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <AntdRadio.Group {...props} className={cc([className, styles.radio])}>
        {groupOptions?.map((value) => {
          return (
            <AntdRadio key={value.key} value={value.key}>
              {value.label}
            </AntdRadio>
          );
        })}
      </AntdRadio.Group>
    </ConfigProvider>
  );
};

export default RadioGroup;
