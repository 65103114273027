import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import { client } from 'store/services/client';
import {
  addTeacherParamTypes,
  deactivateTeacherParamTypes,
  editTeacherActionParamTypes,
  getTeacherParamTypes,
} from 'store/actions/teachers.actions';

export const getTeachersService = (params: getTeacherParamTypes): [ApiCall, string] => [
  client.get,
  `/teachers?page=${params.page}&size=${params.size}${!params.active ? '&active=true' : ''}`,
];

export const addTeacherService = (
  params: addTeacherParamTypes,
): [
  ApiCallWithParams,
  string,
  Omit<addTeacherParamTypes, 'page' | 'active'> & {
    page: undefined;
    active: undefined;
  },
] => [client.post, '/teachers', { ...params, page: undefined, active: undefined }];

export const editTeacherService = (
  params: editTeacherActionParamTypes,
): [
  ApiCallWithParams,
  string,
  Omit<editTeacherActionParamTypes, 'id' | 'page' | 'active'> & {
    id: undefined;
    page: undefined;
    active: undefined;
  },
] => [
  client.put,
  `/teachers/${params.id}`,
  { ...params, id: undefined, page: undefined, active: undefined },
];

export const deactivateTeacherService = (
  params: deactivateTeacherParamTypes,
): [ApiCall, string] => [client.delete, `/teachers/${params.id}/deactivate`];

export const reactivateTeacherService = (
  params: deactivateTeacherParamTypes,
): [ApiCall, string] => [client.post, `/teachers/${params.id}/activate`];
