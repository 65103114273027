import { all, call, takeLatest, put } from 'redux-saga/effects';
import {
  createItemObservationService,
  createPersonalLetterService,
  createPracticeService,
  createStudentObservationService,
  createSubItemObservationService,
  createSubjectObservationService,
  evaluateSubItemService,
  getAllObservationsService,
  getItemObservationService,
  getObservationItemsService,
  getObservationSubItemsService,
  getObservationSubjectsService,
  getPersonalLetterService,
  getPracticeService,
  getReportDocumentService,
  getReportService,
  getSchoolYearsService,
  getStudentObservationService,
  getSubItemEvaluationService,
  getSubItemObservationService,
  getSubjectObservationService,
  removePracticeService,
  saveInterimEvaluationsService,
  updatePracticeService,
} from 'store/services/report.services';
import {
  createItemObservationParamTypes,
  createPersonalLetterParamTypes,
  createPracticeParamTypes,
  createStudentObservationParamTypes,
  createSubItemObservationParamTypes,
  createSubjectObservationParamTypes,
  evalDataTypes,
  evaluateSubItemActionSuccess,
  evaluateSubItemTypes,
  getAllObservationsActionSuccess,
  getAllObservationsParamTypes,
  getAllObservationsResponseTypes,
  getEvaluationsResponseTypes,
  getEvaluationsSuccess,
  getObservationItemsActionSuccess,
  getObservationItemsParamTypes,
  getObservationResponseTypes,
  getObservationsSuccess,
  getObservationSubItemsActionSuccess,
  getObservationSubItemsParamTypes,
  getObservationSubjectsActionSuccess,
  getObservationSubjectsParamTypes,
  getObservationSubjectsResponseTypes,
  getPersonalLetterActionSuccess,
  getPersonalLetterParamTypes,
  getPersonalLetterResponseTypes,
  getPracticeAction,
  getPracticeActionSuccess,
  getPracticeParamTypes,
  getReportAction,
  getReportActionSuccess,
  getReportDocumentParamTypes,
  getReportParamTypes,
  getReportResponseTypes,
  getSchoolYearsActionSuccess,
  getSchoolYearsParamTypes,
  getSchoolYearsResponseTypes,
  itemObservationParamTypes,
  practiceResponseType,
  practiceTypes,
  removePracticeActionSuccess,
  removePracticeParamTypes,
  saveInterimEvaluationsParamTypes,
  studentObservationParamTypes,
  subItemObservationParamTypes,
  subjectObservationParamTypes,
  updatePracticeActionSuccess,
  updatePracticeParamTypes,
} from 'store/actions/report.actions';
import { ActionNames } from 'store/actions/actionNames';

function* watchGetReport(action: { params: getReportParamTypes; type: string }) {
  try {
    const response: getReportResponseTypes = yield call(...getReportService(action.params));
    yield put(getReportActionSuccess(response));
  } catch (e) {
    console.log('Can not get report data');
  }
}

function* watchGetSchoolYears(action: { params: getSchoolYearsParamTypes; type: string }) {
  try {
    const response: getSchoolYearsResponseTypes = yield call(
      ...getSchoolYearsService(action.params),
    );
    yield put(getSchoolYearsActionSuccess(response));
  } catch (e) {
    console.log('Can not get report data');
  }
}

function* watchEvaluateSubItem(action: { params: evaluateSubItemTypes; type: string }) {
  try {
    const response: evalDataTypes = yield call(...evaluateSubItemService(action.params));
    yield put(
      evaluateSubItemActionSuccess({
        ...response,
        subItemId: action.params.subItemId,
        scaleId: action.params.assessmentScaleValueId,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not evaluate');
  }
}

function* watchGetStudentObservation(action: {
  params: studentObservationParamTypes;
  type: string;
}) {
  try {
    const response: getObservationResponseTypes = yield call(
      ...getStudentObservationService(action.params),
    );
    yield put(getObservationsSuccess(response));
  } catch (e) {
    console.log('Can not get student observations');
  }
}

function* watchGetSubjectObservation(action: {
  params: subjectObservationParamTypes;
  type: string;
}) {
  try {
    const response: getObservationResponseTypes = yield call(
      ...getSubjectObservationService(action.params),
    );
    yield put(getObservationsSuccess(response));
  } catch (e) {
    console.log('Can not get subject observations');
  }
}

function* watchGetItemObservation(action: { params: itemObservationParamTypes; type: string }) {
  try {
    const response: getObservationResponseTypes = yield call(
      ...getItemObservationService(action.params),
    );
    yield put(getObservationsSuccess(response));
  } catch (e) {
    console.log('Can not get item observations');
  }
}

function* watchGetSubItemObservation(action: {
  params: subItemObservationParamTypes;
  type: string;
}) {
  try {
    const response: getObservationResponseTypes = yield call(
      ...getSubItemObservationService(action.params),
    );
    yield put(getObservationsSuccess(response));
  } catch (e) {
    console.log('Can not get subItem observations');
  }
}

function* watchGetSubItemEvaluation(action: {
  params: subItemObservationParamTypes;
  type: string;
}) {
  try {
    const response: getEvaluationsResponseTypes = yield call(
      ...getSubItemEvaluationService(action.params),
    );
    yield put(getEvaluationsSuccess(response));
  } catch (e) {
    console.log('Can not get interim evaluations');
  }
}

function* watchGetPractice(action: { params: getPracticeParamTypes; type: string }) {
  try {
    const response: practiceResponseType = yield call(...getPracticeService(action.params));
    yield put(getPracticeActionSuccess(response));
  } catch (e) {
    console.log('Can not get practice');
  }
}

function* watchCreatePractice(action: { params: createPracticeParamTypes; type: string }) {
  try {
    yield call(...createPracticeService(action.params));
    yield put(
      getPracticeAction({
        id: action.params.studentId,
        izelId: action.params.izelId,
        page: action.params.page,
        size: 10,
      }),
    );
  } catch (e) {
    console.log('Can not create practice');
  }
}

function* watchUpdatePractice(action: { params: updatePracticeParamTypes; type: string }) {
  try {
    const response: practiceTypes = yield call(...updatePracticeService(action.params));
    yield put(updatePracticeActionSuccess(response));
  } catch (e) {
    console.log('Can not update practice');
  }
}

function* watchRemovePractice(action: { params: removePracticeParamTypes; type: string }) {
  try {
    yield call(...removePracticeService(action.params));
    yield put(
      getPracticeAction({
        id: action.params.studentId,
        izelId: action.params.izelId,
        page: 0,
        size: 10,
      }),
    );
  } catch (e) {
    console.log('Can not remove practice');
  }
}

function* watchGetPersonalLetter(action: { params: getPersonalLetterParamTypes; type: string }) {
  try {
    const response: getPersonalLetterResponseTypes = yield call(
      ...getPersonalLetterService(action.params),
    );
    yield put(getPersonalLetterActionSuccess(response));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not get personal letter');
  }
}

function* watchCreatePersonalLetter(action: {
  params: createPersonalLetterParamTypes;
  type: string;
}) {
  try {
    yield call(...createPersonalLetterService(action.params));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: 0,
        size: 10,
        izelId: action.params.izelId,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not create personal letter');
  }
}

function* watchGetAllObservations(action: { params: getAllObservationsParamTypes; type: string }) {
  try {
    const response: getAllObservationsResponseTypes = yield call(
      ...getAllObservationsService(action.params),
    );
    yield put(getAllObservationsActionSuccess(response));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log(e);
    console.log('Can not create personal letter');
  }
}

function* watchGetReportDocument(action: { params: getReportDocumentParamTypes; type: string }) {
  try {
    const response: { data: ArrayBufferLike } = yield call(
      ...getReportDocumentService(action.params),
    );
    const pdfFile = new Blob([response.data], { type: 'application/pdf' });
    const pdfData = URL.createObjectURL(pdfFile);
    const link = document.createElement('a');
    link.href = pdfData;
    link.download = action.params.reportName;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not download report', e);
  }
}

function* watchCreateStudentObservations(action: {
  params: createStudentObservationParamTypes;
  type: string;
}) {
  try {
    yield call(...createStudentObservationService(action.params));
  } catch (e) {
    console.log('Can not create subject observations');
  }
}

function* watchCreateSubjectObservations(action: {
  params: createSubjectObservationParamTypes;
  type: string;
}) {
  try {
    yield call(...createSubjectObservationService(action.params));
  } catch (e) {
    console.log('Can not create subject observations');
  }
}

function* watchCreateItemObservations(action: {
  params: createItemObservationParamTypes;
  type: string;
}) {
  try {
    yield call(...createItemObservationService(action.params));
  } catch (e) {
    console.log('Can not create subject observations');
  }
}

function* watchCreateSubItemObservations(action: {
  params: createSubItemObservationParamTypes;
  type: string;
}) {
  try {
    yield call(...createSubItemObservationService(action.params));
  } catch (e) {
    console.log('Can not create subject observations');
  }
}

function* watchSaveInterimEvaluations(action: {
  params: saveInterimEvaluationsParamTypes;
  type: string;
}) {
  try {
    yield call(...saveInterimEvaluationsService(action.params));
  } catch (e) {
    console.log('Can not create subject observations');
  }
}

function* watchGetObservationSubjects(action: {
  params: getObservationSubjectsParamTypes;
  type: string;
}) {
  try {
    const response: getObservationSubjectsResponseTypes = yield call(
      ...getObservationSubjectsService(action.params),
    );
    yield put(getObservationSubjectsActionSuccess(response));
  } catch (e) {
    console.log('Can not get interim evaluations');
  }
}

function* watchGetObservationItems(action: {
  params: getObservationItemsParamTypes;
  type: string;
}) {
  try {
    const response: getObservationSubjectsResponseTypes = yield call(
      ...getObservationItemsService(action.params),
    );
    yield put(getObservationItemsActionSuccess(response));
  } catch (e) {
    console.log('Can not get interim evaluations');
  }
}

function* watchGetObservationSubItems(action: {
  params: getObservationSubItemsParamTypes;
  type: string;
}) {
  try {
    const response: getObservationSubjectsResponseTypes = yield call(
      ...getObservationSubItemsService(action.params),
    );
    yield put(getObservationSubItemsActionSuccess(response));
  } catch (e) {
    console.log('Can not get interim evaluations');
  }
}

function* reportSaga() {
  yield all([takeLatest(ActionNames.GET_REPORT, watchGetReport)]);
  yield all([takeLatest(ActionNames.GET_SCHOOL_YEARS, watchGetSchoolYears)]);
  yield all([takeLatest(ActionNames.EVALUATE_SUB_ITEM, watchEvaluateSubItem)]);
  yield all([takeLatest(ActionNames.GET_STUDENT_OBSERVATION, watchGetStudentObservation)]);
  yield all([takeLatest(ActionNames.GET_SUBJECT_OBSERVATION, watchGetSubjectObservation)]);
  yield all([takeLatest(ActionNames.GET_ITEM_OBSERVATION, watchGetItemObservation)]);
  yield all([takeLatest(ActionNames.GET_SUB_ITEM_OBSERVATION, watchGetSubItemObservation)]);
  yield all([takeLatest(ActionNames.GET_SUB_ITEM_EVALUATION, watchGetSubItemEvaluation)]);
  yield all([takeLatest(ActionNames.GET_PRACTICE, watchGetPractice)]);
  yield all([takeLatest(ActionNames.CREATE_PRACTICE, watchCreatePractice)]);
  yield all([takeLatest(ActionNames.UPDATE_PRACTICE, watchUpdatePractice)]);
  yield all([takeLatest(ActionNames.REMOVE_PRACTICE, watchRemovePractice)]);
  yield all([takeLatest(ActionNames.GET_PERSONAL_LETTER, watchGetPersonalLetter)]);
  yield all([takeLatest(ActionNames.CREATE_PERSONAL_LETTER, watchCreatePersonalLetter)]);
  yield all([takeLatest(ActionNames.GET_ALL_OBSERVATIONS, watchGetAllObservations)]);
  yield all([takeLatest(ActionNames.GET_REPORT_DOCUMENT, watchGetReportDocument)]);
  yield all([takeLatest(ActionNames.CREATE_STUDENT_OBSERVATION, watchCreateStudentObservations)]);
  yield all([takeLatest(ActionNames.CREATE_SUBJECT_OBSERVATION, watchCreateSubjectObservations)]);
  yield all([takeLatest(ActionNames.CREATE_ITEM_OBSERVATION, watchCreateItemObservations)]);
  yield all([takeLatest(ActionNames.CREATE_SUB_ITEM_OBSERVATION, watchCreateSubItemObservations)]);
  yield all([takeLatest(ActionNames.SAVE_INTERIM_EVALUATION, watchSaveInterimEvaluations)]);
  yield all([takeLatest(ActionNames.GET_OBSERVATION_SUBJECTS, watchGetObservationSubjects)]);
  yield all([takeLatest(ActionNames.GET_OBSERVATION_ITEMS, watchGetObservationItems)]);
  yield all([takeLatest(ActionNames.GET_OBSERVATION_SUB_ITEMS, watchGetObservationSubItems)]);
}

export default reportSaga;
