import { createSubItemAction, editSubItemAction, subItemTypes } from 'store/actions/items.actions';
import { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getUserRole } from 'store/selectors/users.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { RadioChangeEvent, SelectProps } from 'antd';
import RadioGroup from 'UI/RadioGroup';
import { Text } from 'UI/Typography';
import Select from 'UI/Select';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import { gradesRangeSelector } from 'store/selectors/schools.selectors';

type AddSubItemModalType = {
  addSubItemModalVisible: boolean;
  toggleSubItemModal: (arg1: boolean) => void;
  parentId: number | null;
  handleSubItemParentId: (arg1: number | null) => void;
  editableSubItem: subItemTypes | null;
  handleChangeEditableSubItem: (arg1: subItemTypes | null) => void;
};

const ManageSubItemModal = ({
  addSubItemModalVisible,
  toggleSubItemModal,
  parentId,
  handleSubItemParentId,
  editableSubItem,
  handleChangeEditableSubItem,
}: AddSubItemModalType) => {
  const [subItemName, setSubItemName] = useState('');
  const [schoolYears, setSchoolYears] = useState<string[] | []>([]);
  const [subItemState, setSubItemState] = useState(2);
  const [subItemDesc, setSubItemDesc] = useState('');
  const [errorForUniqueName, setErrorForUniqueName] = useState(false);
  const userRole = useSelector(getUserRole);
  const options = useSelector(gradesRangeSelector) as SelectProps['options'];
  const dispatch = useDispatch();

  const editMode = useMemo(() => {
    return !!editableSubItem?.id;
  }, [editableSubItem?.id]);

  const okDisabled = useMemo(() => {
    return !subItemName || !schoolYears.length;
  }, [schoolYears.length, subItemName]);

  const handleSubItemName = useCallback((value: string) => {
    setSubItemName(value);
    setErrorForUniqueName(false);
  }, []);

  const handleChangeSchoolYears = useCallback((value: string[]) => {
    setSchoolYears(value);
  }, []);

  const handleSubItemOptional = useCallback((state: number) => {
    setSubItemState(state);
  }, []);

  const handleSubItemDesc = useCallback((value: string) => {
    setSubItemDesc(value);
  }, []);

  const handleResetSubItemModal = useCallback(() => {
    setSubItemName('');
    setSchoolYears([]);
    setSubItemState(1);
    setSubItemDesc('');
    handleSubItemParentId(null);
    handleChangeEditableSubItem(null);
    toggleSubItemModal(false);
  }, [handleChangeEditableSubItem, handleSubItemParentId, toggleSubItemModal]);

  const handleCreateSubItem = useCallback(() => {
    dispatch(
      createSubItemAction({
        name: subItemName,
        optional: subItemState === 2,
        schoolYears: schoolYears.map((value) => Number(value)),
        parentId: Number(parentId),
        description: subItemDesc,
        onSuccess: () => {
          handleResetSubItemModal();
        },
        onFailure: (message) => {
          if (message === 'not_unique') {
            setErrorForUniqueName(true);
          }
        },
      }),
    );
  }, [
    dispatch,
    handleResetSubItemModal,
    parentId,
    schoolYears,
    subItemDesc,
    subItemName,
    subItemState,
  ]);

  const handleEditSubItem = useCallback(() => {
    dispatch(
      editSubItemAction({
        id: Number(editableSubItem?.id),
        name: subItemName,
        optional: subItemState === 2,
        schoolYears: schoolYears,
        parentId: Number(parentId),
        description: subItemDesc,
        onSuccess: () => {
          handleResetSubItemModal();
        },
        onFailure: (message) => {
          if (message === 'not_unique') {
            setErrorForUniqueName(true);
          }
        },
      }),
    );
  }, [
    dispatch,
    editableSubItem?.id,
    handleResetSubItemModal,
    parentId,
    schoolYears,
    subItemDesc,
    subItemName,
    subItemState,
  ]);

  const handleManageSubItem = useCallback(() => {
    if (editMode) {
      handleEditSubItem();
    } else {
      handleCreateSubItem();
    }
  }, [editMode, handleCreateSubItem, handleEditSubItem]);

  useEffect(() => {
    if (editMode) {
      handleSubItemName(editableSubItem?.name || '');
      handleSubItemOptional(editableSubItem?.optional ? 2 : 1);
      handleChangeSchoolYears(editableSubItem?.schoolYears.map((value) => value) || []);
      handleSubItemDesc(editableSubItem?.description || '');
    }
  }, [
    editMode,
    editableSubItem?.description,
    editableSubItem?.name,
    editableSubItem?.optional,
    editableSubItem?.schoolYears,
    handleChangeSchoolYears,
    handleSubItemDesc,
    handleSubItemName,
    handleSubItemOptional,
  ]);

  return (
    <Modal
      okDisabled={okDisabled}
      centered
      title={!editMode ? 'Neues Subitem' : 'SubItem'}
      open={addSubItemModalVisible}
      onOk={() => handleManageSubItem()}
      onCancel={() => {
        handleResetSubItemModal();
      }}
      closable
      okText={!editMode ? 'Hinzufügen' : 'Speichern'}
      cancelText='Abbrechen'
    >
      <div className={styles.subItemLabel}>
        <Text level={1} bold>
          Subitem Name
        </Text>
      </div>
      <Input
        error={errorForUniqueName}
        value={subItemName}
        placeholder='bitte auswählen'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleSubItemName(e.target.value)}
        maxLength={150}
        showCount
      />
      {errorForUniqueName && <div className={styles.errorText}>Name ist bereits vorhanden</div>}
      <div className={styles.schoolYearsLabel}>
        <Text level={1} bold>
          Beschreibung (Optional)
        </Text>
      </div>
      <Input
        value={subItemDesc}
        placeholder='bitte auswählen'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleSubItemDesc(e.target.value)}
        maxLength={1000}
        showCount
      />
      <div className={styles.schoolYearsLabel}>
        <Text level={1} bold>
          Geeignet für Jahrgang
        </Text>
      </div>
      <Select
        showSearch={false}
        value={schoolYears}
        onChange={(years) => handleChangeSchoolYears(years)}
        showArrow
        options={options}
        placeholder='Jahrgang'
        mode='multiple'
        style={{ width: '100%' }}
      />
      {userRole === 'ASSOCIATION_ADMIN' && (
        <>
          <div className={styles.subItemTypeLabel}>
            <Text level={1} bold>
              Subitemtyp
            </Text>
          </div>
          <RadioGroup
            value={subItemState}
            onChange={(e: RadioChangeEvent) => handleSubItemOptional(e.target.value)}
            groupOptions={[
              { key: 1, label: 'Bindend' },
              { key: 2, label: 'Optional' },
            ]}
          />
        </>
      )}
    </Modal>
  );
};

export default ManageSubItemModal;
