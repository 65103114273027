import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const CheckOutlinedSVG = () => {
  return (
    <svg width='25' height='25' viewBox='0 0 25 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M10.7667 17.1L17.8167 10.05L16.4167 8.65L10.7667 14.3L7.91675 11.45L6.51675 12.85L10.7667 17.1ZM12.1667 22.5C10.7834 22.5 9.48341 22.2375 8.26675 21.7125C7.05008 21.1875 5.99175 20.475 5.09175 19.575C4.19175 18.675 3.47925 17.6167 2.95425 16.4C2.42925 15.1833 2.16675 13.8833 2.16675 12.5C2.16675 11.1167 2.42925 9.81667 2.95425 8.6C3.47925 7.38333 4.19175 6.325 5.09175 5.425C5.99175 4.525 7.05008 3.8125 8.26675 3.2875C9.48341 2.7625 10.7834 2.5 12.1667 2.5C13.5501 2.5 14.8501 2.7625 16.0667 3.2875C17.2834 3.8125 18.3417 4.525 19.2417 5.425C20.1417 6.325 20.8542 7.38333 21.3792 8.6C21.9042 9.81667 22.1667 11.1167 22.1667 12.5C22.1667 13.8833 21.9042 15.1833 21.3792 16.4C20.8542 17.6167 20.1417 18.675 19.2417 19.575C18.3417 20.475 17.2834 21.1875 16.0667 21.7125C14.8501 22.2375 13.5501 22.5 12.1667 22.5ZM12.1667 20.5C14.4001 20.5 16.2917 19.725 17.8417 18.175C19.3917 16.625 20.1667 14.7333 20.1667 12.5C20.1667 10.2667 19.3917 8.375 17.8417 6.825C16.2917 5.275 14.4001 4.5 12.1667 4.5C9.93341 4.5 8.04175 5.275 6.49175 6.825C4.94175 8.375 4.16675 10.2667 4.16675 12.5C4.16675 14.7333 4.94175 16.625 6.49175 18.175C8.04175 19.725 9.93341 20.5 12.1667 20.5Z'
        fill='currentColor'
      />
    </svg>
  );
};

const CheckOutlinedIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={CheckOutlinedSVG} {...props} />;
};

export default CheckOutlinedIcon;
