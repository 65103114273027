export const primary50 = '#46776F';
export const primary40 = '#4B8A80';
export const primary30 = '#89BCB7';
export const primary25 = '#B1CAC6';
export const primary20 = '#BEDAD7';
export const primary10 = '#DFEDEB';
export const primary15 = '#D1DEDC';
export const primary5 = '#EFF6F5';
export const secondary50 = '#1F1F1F';
export const secondary40 = '#545454';
export const secondary30 = '#8E8E8E';
export const secondary20 = '#E0E0E0';
export const secondary10 = '#F2F2F2';
export const secondary5 = '#3D3D3D';
export const secondary0 = '#FFFFFF';
