import { createStudentAction, updateStudentAction } from 'store/actions/students.actions';
import { DatePickerProps, SelectProps } from 'antd';
import DatePicker from 'UI/DatePicker';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Text } from 'UI/Typography';
import Select from 'UI/Select';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import dayjs from 'dayjs';
import { getStudentInfoSelector } from 'store/selectors/students.selectors';
import { useNavigate, useParams } from 'react-router-dom';
import cc from 'classcat';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Checkbox from '../../../../UI/Checkbox';
import { gradesRangeSelector } from 'store/selectors/schools.selectors';

const { Confirm } = Modal;

type addStudentModalTypes = {
  addStudentModalVisible: boolean;
  toggleAddStudentModal: (arg1: boolean) => void;
  mode?: 'edit';
  searchValue?: string;
  page?: number;
  active?: boolean;
  groupIds?: number[];
  schoolYears?: number[];
};

const genderOptions = [
  {
    value: 'MALE',
    label: 'männlich',
  },
  {
    value: 'FEMALE',
    label: 'weiblich',
  },
  {
    value: 'NOT_DEFINED',
    label: 'divers',
  },
];

const AddStudentModal = ({
  addStudentModalVisible,
  toggleAddStudentModal,
  mode,
  searchValue,
  page,
  active,
  groupIds,
  schoolYears,
}: addStudentModalTypes) => {
  const [updateLoading, setUpdateLoading] = useState(false);
  const [name, setName] = useState('');
  const [lastName, setLastName] = useState('');
  const [gender, setGender] = useState<string | null>(null);
  const [birthDate, setBirthDate] = useState('');
  const [schoolYear, setSchoolYear] = useState<number | null>(null);
  const [email, setEmail] = useState('');
  const [legalApproved, setLegalApproved] = useState(false);
  const [legalApprovedAdult, setLegalApprovedAdult] = useState(false);
  const [updateConfirmVisible, setUpdateConfirmVisible] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const navigate = useNavigate();
  const studentInfo = useSelector(getStudentInfoSelector);
  const options = useSelector(gradesRangeSelector) as SelectProps['options'];

  const gradeOptions = useMemo(() => {
    return [{ value: -1, label: 'Keine' }, ...(options || [])];
  }, [options]);

  const legalDisabled = useMemo(() => {
    return (
      !birthDate ||
      dayjs(birthDate, 'DD.MM.YYYY').unix() > dayjs(new Date()).subtract(16, 'year').unix()
    );
  }, [birthDate]);

  const okDisabled =
    !name ||
    !lastName ||
    !gender ||
    !birthDate ||
    !schoolYear ||
    (mode !== 'edit' && !legalApproved) ||
    (mode !== 'edit' && !legalDisabled && !legalApprovedAdult);

  const handleChangeName = useCallback((value: string) => {
    setErrorMessage('');
    setName(value);
  }, []);

  const handleChangeLastName = useCallback((value: string) => {
    setErrorMessage('');
    setLastName(value);
  }, []);

  const handleChangeGender = useCallback((value: string) => {
    setGender(value);
  }, []);

  const changeBirthDate = useCallback((value: DatePickerProps['value'], dateString: string) => {
    setErrorMessage('');
    setBirthDate(dateString);
  }, []);

  const handleChangeSchoolYears = useCallback((value: number) => {
    setSchoolYear(value);
  }, []);

  const handleChangeEmail = useCallback(
    (value: string) => {
      if (errorMessage === 'not_unique') {
        setErrorMessage('');
      }
      setEmail(value);
    },
    [errorMessage],
  );

  const handleResetModal = useCallback(() => {
    if (mode !== 'edit') {
      setGender(null);
      setName('');
      setLastName('');
      setSchoolYear(null);
      setBirthDate('');
      setEmail('');
      setLegalApproved(false);
      setLegalApprovedAdult(false);
      setErrorMessage('');
    }
    toggleAddStudentModal(false);
  }, [mode, toggleAddStudentModal]);

  const handleCreateStudent = useCallback(() => {
    setUpdateLoading(true);
    dispatch(
      createStudentAction({
        name,
        surname: lastName,
        birthDate,
        schoolYear: Number(schoolYear),
        gender: String(gender),
        onSuccess: () => {
          handleResetModal();
          setUpdateLoading(false);
        },
        onFailure: (message) => {
          setErrorMessage(message);
          setUpdateLoading(false);
        },
        email: email ? email : null,
        page: page ? page - 1 : 0,
        size: 10,
        groupIds: groupIds?.length ? groupIds : [],
        schoolYears: schoolYears?.length ? schoolYears : [],
        searchValue: String(searchValue),
        active: Boolean(active),
      }),
    );
  }, [
    active,
    birthDate,
    dispatch,
    email,
    gender,
    groupIds,
    handleResetModal,
    lastName,
    name,
    page,
    schoolYear,
    schoolYears,
    searchValue,
  ]);

  const handleUpdateStudent = useCallback(() => {
    setUpdateLoading(true);
    dispatch(
      updateStudentAction({
        id: Number(studentId),
        name,
        surname: lastName,
        birthDate,
        schoolYear: Number(schoolYear),
        gender: String(gender),
        onSuccess: (leftSchool) => {
          handleResetModal();
          setUpdateLoading(false);
          if (leftSchool) {
            navigate('/students');
          }
        },
        onFailure: (message) => {
          setErrorMessage(message);
          setUpdateLoading(false);
        },
        email: email ? email : null,
        parentConsent: legalApproved,
        studentConsent: legalApprovedAdult,
      }),
    );
  }, [
    birthDate,
    dispatch,
    email,
    gender,
    handleResetModal,
    lastName,
    legalApproved,
    legalApprovedAdult,
    name,
    navigate,
    schoolYear,
    studentId,
  ]);

  const handleEditModalConfirm = useCallback(() => {
    if (Number(schoolYear) !== studentInfo?.schoolYear) {
      setUpdateConfirmVisible(true);
    } else {
      handleUpdateStudent();
    }
  }, [handleUpdateStudent, schoolYear, studentInfo]);

  const handleManageStudent = useCallback(() => {
    if (mode === 'edit') {
      handleEditModalConfirm();
    } else {
      handleCreateStudent();
    }
  }, [handleCreateStudent, handleEditModalConfirm, mode]);

  useEffect(() => {
    if (mode === 'edit' && addStudentModalVisible) {
      setGender(studentInfo?.gender || '');
      setName(studentInfo?.name || '');
      setLastName(studentInfo?.surname || '');
      setSchoolYear(studentInfo?.schoolYear || null);
      setBirthDate(studentInfo?.birthDate || '');
      setEmail(studentInfo?.email || '');
      setLegalApproved(studentInfo?.parentConsent || false);
    }
  }, [addStudentModalVisible, mode, studentInfo]);

  useEffect(() => {
    if (!legalDisabled) {
      setLegalApprovedAdult(studentInfo?.studentConsent || false);
    } else {
      setLegalApprovedAdult(false);
    }
  }, [legalDisabled, studentInfo?.studentConsent]);

  return (
    <Modal
      className={cc([
        styles.addStudentWrapper,
        {
          [styles.rootModal]: updateConfirmVisible,
        },
      ])}
      open={addStudentModalVisible}
      onCancel={handleResetModal}
      title={mode === 'edit' ? 'Schüler/in' : 'Neue/r Schüler/in'}
      centered
      okText={mode === 'edit' ? 'Speichern' : 'Hinzufügen'}
      cancelText='Abbrechen'
      onOk={handleManageStudent}
      okDisabled={okDisabled}
      confirmLoading={updateLoading}
      destroyOnClose
    >
      <div className={styles.nameLabel}>
        <Text level={1} bold>
          Name
        </Text>
      </div>
      <Input
        status={errorMessage && errorMessage !== 'not_unique' ? 'error' : ''}
        value={lastName}
        placeholder='Schüler/in Name'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeLastName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Vorname
        </Text>
      </div>
      <Input
        status={errorMessage && errorMessage !== 'not_unique' ? 'error' : ''}
        value={name}
        placeholder='Schüler/in Vorname'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Geschlecht
        </Text>
      </div>
      <Select
        value={gender}
        onChange={(value) => handleChangeGender(value)}
        style={{ width: '100%' }}
        placeholder='männlich, weiblich, divers'
        options={genderOptions}
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Geburtsdatum
        </Text>
      </div>
      <DatePicker
        status={errorMessage && errorMessage !== 'not_unique' ? 'error' : ''}
        value={birthDate ? dayjs(birthDate, 'DD.MM.YYYY') : undefined}
        inputReadOnly
        placeholder='Geburtsdatum'
        onChange={changeBirthDate}
        style={{ width: '100%' }}
        format='DD.MM.YYYY'
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Jahrgang
        </Text>
      </div>
      <Select
        value={schoolYear}
        onChange={(years) => handleChangeSchoolYears(years)}
        showArrow
        options={mode !== 'edit' ? options : gradeOptions}
        placeholder='bitte auswählen'
        style={{ width: '100%' }}
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          E-Mail
        </Text>
      </div>
      <Input
        status={errorMessage === 'not_unique' ? 'error' : ''}
        value={email}
        placeholder='E-Mail'
        onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeEmail(e.target.value)}
      />
      <div className={styles.surNameLabel}>
        <Text level={1} bold>
          Zustimmung zur Datenverarbeitung
        </Text>
      </div>
      <Checkbox
        checked={legalApproved}
        onChange={(e: CheckboxChangeEvent) => {
          setLegalApproved(e.target.checked);
        }}
      >
        Zustimmung Erziehungsberechtigte
      </Checkbox>
      <div>
        <Checkbox
          disabled={legalDisabled}
          checked={legalApprovedAdult}
          onChange={(e: CheckboxChangeEvent) => {
            setLegalApprovedAdult(e.target.checked);
          }}
        >
          Zustimmung Schüler/in (ab 16 Jahre)
        </Checkbox>
      </div>
      {errorMessage ? (
        <Text level={1} className='errorMessage'>
          {errorMessage === 'not_unique'
            ? 'E-Mail ist bereits vorhanden'
            : 'Es existiert bereits eine Schülerin / ein Schüler mit dem gleichen Vor- und Nachnamen sowie dem gleichen Geburtsdatum. Es kann kein identischer Eintrag in die Schülerliste erstellt werden.'}
        </Text>
      ) : (
        ''
      )}
      <Confirm
        closable
        okText='Anwenden'
        cancelText='Abbrechen'
        centered
        message='Wenn sie den Jahrgang des Schülers / der Schülerin ändern, werden die fachlichen Inhalte entsprechend der neuen Jahrgangsstufe soweit möglich automatisch angepasst. Bitte prüfen Sie anschießend zunächst die Gruppenzugehörigkeiten und dann die Korrektheit und Vollständigkeit der Zuweisungen von fachlichen Inhalten.'
        open={updateConfirmVisible}
        onOk={() => {
          handleUpdateStudent();
          setUpdateConfirmVisible(false);
        }}
        onCancel={() => {
          setUpdateConfirmVisible(false);
        }}
      />
    </Modal>
  );
};

export default AddStudentModal;
