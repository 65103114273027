import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import UploadImageIcon from 'UI/Icons/UploadImageIcon';
import { Upload, UploadFile } from 'antd';
import { RcFile } from 'antd/es/upload';
import { Text } from 'UI/Typography';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { schoolInfoResponseTypes, updateSchoolInfoAction } from 'store/actions/school.actions';
import { schoolYearOptions } from '../../../../../../constants';
import Select from '../../../../../../UI/Select';

type UpdateSchoolIfoModalTypes = {
  schoolInfoVisible: boolean;
  toggleSchoolInfo: (arg1: boolean) => void;
  schoolData: schoolInfoResponseTypes | null;
};

const { Dragger } = Upload;

const getBase64 = (file: RcFile): Promise<string> =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result as string);
    reader.onerror = (error) => reject(error);
  });

const UpdateSchoolIfoModal = ({
  schoolInfoVisible,
  toggleSchoolInfo,
  schoolData,
}: UpdateSchoolIfoModalTypes) => {
  const [schoolName, setSchoolName] = useState('');
  const [officialName, setOfficialName] = useState('');
  const [city, setCity] = useState('');
  const [zip, setZip] = useState('');
  const [street, setStreet] = useState('');
  const [image, setImage] = useState<UploadFile | null>(null);
  const [imageUrl, setImageUrl] = useState('');
  const [wrongFile, setWrongFile] = useState(false);
  const [startYear, setStartYear] = useState<null | string>(null);
  const [endYear, setEndYear] = useState<null | string>(null);
  const dispatch = useDispatch();

  const validStartYear = useMemo(() => {
    if (endYear) {
      return schoolYearOptions?.filter((year) => Number(year.value) < Number(endYear));
    } else {
      return schoolYearOptions;
    }
  }, [endYear]);

  const validEndYear = useMemo(() => {
    if (startYear) {
      return schoolYearOptions?.filter((year) => Number(year.value) > Number(startYear));
    } else {
      return schoolYearOptions;
    }
  }, [startYear]);

  const handleLogoUpload = useCallback(({ fileList: newFileList }: { fileList: UploadFile[] }) => {
    setWrongFile(false);
    setImage(newFileList[1] ? newFileList[1] : newFileList[0]);
    getBase64((newFileList[1] ? newFileList[1] : newFileList[0]).originFileObj as RcFile).then(
      (res) => setImageUrl(res),
    );
  }, []);

  const handleUpdateSchoolInfo = useCallback(() => {
    dispatch(
      updateSchoolInfoAction({
        file: image ? image.originFileObj : null,
        request: new Blob(
          [
            JSON.stringify({
              name: schoolName,
              officialName: officialName,
              street: street,
              zip: zip,
              city: city,
              startGrade: startYear,
              endGrade: endYear,
            }),
          ],
          {
            type: 'application/json',
          },
        ),
        onSuccess: () => toggleSchoolInfo(false),
        onFailure: () => setWrongFile(true),
      }),
    );
  }, [
    city,
    dispatch,
    endYear,
    image,
    officialName,
    schoolName,
    startYear,
    street,
    toggleSchoolInfo,
    zip,
  ]);

  useEffect(() => {
    if (schoolInfoVisible) {
      setSchoolName(schoolData?.name || '');
      setOfficialName(schoolData?.officialName || '');
      setCity(schoolData?.city || '');
      setZip(schoolData?.zip || '');
      setStreet(schoolData?.street || '');
      setImageUrl('data:image/png;base64,' + schoolData?.logo || '');
      setStartYear(String(schoolData?.startGrade) || null);
      setEndYear(String(schoolData?.endGrade) || null);
    }
  }, [schoolData, schoolInfoVisible]);

  return (
    <Modal
      centered
      title='Schule'
      open={schoolInfoVisible}
      onOk={() => handleUpdateSchoolInfo()}
      onCancel={() => {
        toggleSchoolInfo(false);
        setWrongFile(false);
      }}
      closable
      okText='Speichern'
      cancelText='Abbrechen'
    >
      <div className={styles.firstInput}>
        <Text bold level={1}>
          Angebotene Jahrgangsstufen
        </Text>
      </div>
      <div style={{ display: 'flex', justifyContent: 'space-between' }}>
        <div>
          <Text level={1} bold>
            Niedrigste Jahrgangsstufe
          </Text>
          <Select
            value={startYear}
            showSearch={false}
            options={validStartYear}
            onChange={(years) => {
              setStartYear(years);
            }}
            showArrow={false}
            style={{ width: '220px' }}
          />
        </div>
        <div>
          <Text level={1} bold>
            Höchste Jahrgangsstufe
          </Text>
          <Select
            value={endYear}
            showSearch={false}
            options={validEndYear}
            onChange={(years) => {
              setEndYear(years);
            }}
            showArrow={false}
            style={{ width: '220px' }}
          />
        </div>
      </div>
      <div className={styles.schoolName}>
        <Text bold level={1}>
          Schulname
        </Text>
      </div>
      <Input
        placeholder='Schulname'
        value={schoolName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setSchoolName(e.target.value)}
      />
      <div className={styles.schoolName}>
        <Text bold level={1}>
          Offizieller Schulname
        </Text>
      </div>
      <Input
        placeholder='Offizieller Schulname'
        value={officialName}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setOfficialName(e.target.value)}
      />
      <div className={styles.schoolName}>
        <Text bold level={1}>
          Ort
        </Text>
      </div>
      <Input
        placeholder='z.b Munchen'
        value={city}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setCity(e.target.value)}
      />
      <div className={styles.schoolName}>
        <Text bold level={1}>
          PLZ
        </Text>
      </div>
      <Input
        placeholder='#####'
        value={zip}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setZip(e.target.value)}
      />
      <div className={styles.schoolName}>
        <Text bold level={1}>
          Straße und Hausnummer
        </Text>
      </div>
      <Input
        placeholder='bitte auswählen'
        value={street}
        onChange={(e: ChangeEvent<HTMLInputElement>) => setStreet(e.target.value)}
      />
      <div className={styles.uploadWrapper}>
        {imageUrl && imageUrl !== 'data:image/png;base64,undefined' && (
          <img src={imageUrl} alt='logo' width={94} height={94} />
        )}
        <div className={styles.upload}>
          <Dragger
            customRequest={() => null}
            name='image'
            onChange={handleLogoUpload}
            className={styles.logoUpload}
            fileList={image ? [image] : []}
          >
            <div>
              <UploadImageIcon />
            </div>
            <Text level={3}>
              Schul-Logo hochladen. Formate: png, jpeg; <br /> Bildgröße 600x600 oder 800x800 pixel;
              maximale Größe 3 BM
            </Text>
          </Dragger>
        </div>
      </div>
      <div>
        {wrongFile ? (
          <Text level={1} className='errorMessage'>
            Bild konnte nicht hochgeladen werden. Bitte überprüfen Sie die oben genannten
            Anforderungen und versuchen Sie, ein neues Bild hochzuladen
          </Text>
        ) : (
          ''
        )}
      </div>
    </Modal>
  );
};

export default UpdateSchoolIfoModal;
