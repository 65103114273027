import { ActionNames } from 'store/actions/actionNames';
import {
  groupsResponse,
  groupTypes,
  groupDetailsResponseType,
  groupStudentsResponseType,
  groupStudentType,
  groupTeachersResponseType,
  groupSubjectsResponseType,
  groupSubjectType,
  getSubjectsToAddResponseTypes,
  allGroupsResponse,
  groupAllSubjectsTypes,
  getGroupItemsResponseTypes,
  getGroupSubItemsResponseTypes,
} from 'store/actions/group.actions';
import produce from 'immer';

const initialState: {
  groups: Array<groupTypes> | [];
  allGroups: allGroupsResponse | [];
  groupCount: number;
  groupDetails: groupDetailsResponseType | null;
  groupStudents: Array<groupStudentType> | [];
  groupTeachers: Array<groupStudentType> | [];
  groupSubjects: Array<groupSubjectType> | [];
  subjectsToAdd: Array<{ id: number }> | [];
  teachersToAdd: Array<{ id: number }> | [];
  studentsToAdd: Array<{ id: number; name: string; surname: string }> | [];
  groupAllSubjects: groupAllSubjectsTypes | [];
  groupItems: getGroupItemsResponseTypes | [];
  groupSubItems: getGroupSubItemsResponseTypes | [];
  groupStudentsCount: number;
  groupTeachersCount: number;
  groupSubjectsCount: number;
} = {
  groups: [],
  allGroups: [],
  groupCount: 0,
  groupDetails: null,
  groupStudents: [],
  groupTeachers: [],
  groupSubjects: [],
  groupStudentsCount: 0,
  groupTeachersCount: 0,
  groupSubjectsCount: 0,
  subjectsToAdd: [],
  teachersToAdd: [],
  studentsToAdd: [],
  groupAllSubjects: [],
  groupItems: [],
  groupSubItems: [],
};

const groupsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: groupTypes &
        groupsResponse &
        groupDetailsResponseType &
        groupStudentsResponseType &
        groupTeachersResponseType &
        groupSubjectsResponseType &
        getSubjectsToAddResponseTypes &
        Array<groupSubjectType> &
        allGroupsResponse &
        Array<{ id: number; name: string; surname: string }> &
        groupAllSubjectsTypes &
        getGroupItemsResponseTypes &
        getGroupSubItemsResponseTypes;
    };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_GROUPS_SUCCESS:
        draft.groups = action.payload.data.groups;
        draft.groupCount = action.payload.data.count;
        break;
      case ActionNames.GET_ALL_GROUPS_SUCCESS:
        draft.allGroups = action.payload.data;
        break;
      case ActionNames.GET_GROUP_DETAILS_SUCCESS:
        draft.groupDetails = action.payload.data;
        break;
      case ActionNames.GET_GROUPS_STUDENTS_SUCCESS:
        draft.groupStudents = action.payload.data.students;
        draft.groupStudentsCount = action.payload.data.count;
        break;
      case ActionNames.GET_GROUPS_TEACHERS_SUCCESS:
        draft.groupTeachers = action.payload.data.teachers;
        draft.groupTeachersCount = action.payload.data.count;
        break;
      case ActionNames.GET_GROUPS_SUBJECTS_SUCCESS:
        draft.groupSubjects = action.payload.data.count
          ? action.payload.data.subjects
          : action.payload.data;
        draft.groupSubjectsCount = action.payload.data.count;
        break;
      case ActionNames.CREATE_GROUP_SUCCESS:
        const updated = [...state.groups];
        updated.push(action.payload.data);
        draft.groups = updated;
        break;
      case ActionNames.GET_SUBJECTS_TO_ADD_SUCCESS:
        draft.subjectsToAdd = action.payload?.data;
        break;
      case ActionNames.ADD_SUBJECTS_TO_GROUP_SUCCESS:
        const updatedGroupSubjects = [...state.groupSubjects];
        updatedGroupSubjects.push(...action.payload.data);
        draft.groupSubjects = updatedGroupSubjects;
        break;
      case ActionNames.GET_TEACHERS_TO_ADD_SUCCESS:
        draft.teachersToAdd = action.payload?.data;
        break;
      case ActionNames.GET_STUDENTS_TO_ADD_SUCCESS:
        draft.studentsToAdd = action.payload?.data;
        break;
      case ActionNames.GET_GROUP_ALL_SUBJECTS_SUCCESS:
        draft.groupAllSubjects = action.payload.data;
        break;
      case ActionNames.GET_GROUP_ITEMS_SUCCESS:
        draft.groupItems = action.payload.data;
        break;
      case ActionNames.GET_GROUP_SUB_ITEMS_SUCCESS:
        draft.groupSubItems = action.payload.data;
        break;
      default:
        break;
    }
  });

export default groupsReducer;
