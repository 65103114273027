import { getSchoolsCountSelector, getSchoolsSelector } from 'store/selectors/schools.selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
  activateSchoolAction,
  deactivateSchoolAction,
  getSchoolsAction,
} from 'store/actions/school.actions';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import Spinner from 'UI/Spinner';
import { Col, Dropdown, Row } from 'antd';
import Button from 'UI/Button';
import Table from 'UI/Table';
import ManageSchoolModal from './__partials/ManageSchoolModal';
import styles from './styles.module.css';
import ActionsIcon from '../../UI/Icons/ActionsIcon';
import AddAdminModal from 'pages/Schools/__partials/AddAdminModal';
import { Text, Title } from '../../UI/Typography';
import Pagination from '../../UI/Pagination';
import Modal from '../../UI/Modal';

const { Confirm } = Modal;

const columns = (
  handleSelectSchool: (arg1: number) => void,
  handleDeactivateSchool: (arg1: number) => void,
) => [
  {
    title: 'Schulname',
    dataIndex: 'name',
    key: 'name',
    render: (record: string) => (
      <Text level={1} bold>
        {record}
      </Text>
    ),
  },
  {
    title: 'Offizieller Schulname',
    dataIndex: 'officialName',
    key: 'officialName',
    render: (record: string) => <Text level={1}>{record}</Text>,
  },
  {
    title: 'Schulverwaltung',
    dataIndex: 'adminNames',
    key: 'adminNames',
    render: (record: string[]) => <Text level={1}>{record.join(', ')}</Text>,
  },
  {
    title: 'E-Mail',
    dataIndex: 'adminEmails',
    key: 'adminEmails',
    render: (record: string[]) => <Text level={1}>{record.join(', ')}</Text>,
  },
  {
    title: 'Status',
    dataIndex: 'active',
    key: 'active',
    render: (record: boolean) => (
      <span className={record ? styles.activeChips : styles.deActiveChips}>
        {record ? 'Aktiv' : 'Deaktiviert'}
      </span>
    ),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (record: number, school: { active: boolean }) => {
      return (
        <Dropdown
          overlayClassName={styles.dropdownContent}
          trigger={['click']}
          placement='bottomRight'
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handleDeactivateSchool(record);
                    }}
                  >
                    {school.active ? 'Deaktivieren' : 'Reaktivieren'}
                  </span>
                ),
              },
              {
                key: '2',
                label: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      if (school.active) {
                        handleSelectSchool(record);
                      }
                    }}
                  >
                    Nächste Schulverwaltung hinzufügen
                  </span>
                ),
              },
            ],
          }}
        >
          <ActionsIcon />
        </Dropdown>
      );
    },
    width: 50,
  },
];

const Schools = () => {
  const [schoolModalVisible, setSchoolModalVisible] = useState(false);
  const [selectedSchool, setSelectedSchool] = useState<number | null>(null);
  const [deactivatedSchoolId, setDeactivatedSchoolId] = useState<number | null>(null);
  const [deactivateSchoolLoading, setDeactivateSchoolLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const schools = useSelector(getSchoolsSelector);
  const schoolsCount = useSelector(getSchoolsCountSelector);

  const manageableSelectedSchool = useMemo(() => {
    return schools.find((school: { id: number }) => school.id === deactivatedSchoolId);
  }, [deactivatedSchoolId, schools]);

  const toggleManageSchool = useCallback((value: boolean) => {
    setSchoolModalVisible(value);
  }, []);

  const handleSelectSchool = useCallback((value: number | null) => {
    setSelectedSchool(value);
  }, []);

  const handleDeactivateSchool = useCallback((value: number) => {
    setDeactivatedSchoolId(value);
  }, []);

  const deactivateSchool = useCallback(() => {
    setDeactivateSchoolLoading(true);
    if (manageableSelectedSchool?.active) {
      dispatch(
        deactivateSchoolAction({
          id: Number(deactivatedSchoolId),
          page: currentPage - 1,
          onSuccess: () => {
            setDeactivateSchoolLoading(false);
            setDeactivatedSchoolId(null);
          },
        }),
      );
    } else {
      dispatch(
        activateSchoolAction({
          id: Number(deactivatedSchoolId),
          page: currentPage - 1,
          onSuccess: () => {
            setDeactivateSchoolLoading(false);
            setDeactivatedSchoolId(null);
          },
        }),
      );
    }
  }, [currentPage, deactivatedSchoolId, dispatch, manageableSelectedSchool?.active]);

  useEffect(() => {
    dispatch(getSchoolsAction({ page: currentPage - 1, size: 10 }));
  }, [currentPage, dispatch]);

  return (
    <div className={styles.schoolsWrapper}>
      <Row className={styles.schoolFiltersWrapper} justify='space-between'>
        <Col>
          <Title level={1}>Schulen</Title>
        </Col>
        <Col>
          <Button type='primary' icon={<PlusOutlined />} onClick={() => toggleManageSchool(true)}>
            Schule hinzufügen
          </Button>
        </Col>
      </Row>
      <div className={styles.schoolTableWrapper}>
        <Table
          rowClassName={(record: { active: boolean }) => (!record.active ? 'disabled-row' : '')}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: false }}
          pagination={false}
          dataSource={schools}
          columns={columns(handleSelectSchool, handleDeactivateSchool)}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/schools/${record.id}`);
              },
            };
          }}
        />
      </div>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => setCurrentPage(page)}
          total={schoolsCount}
        />
      </div>
      <ManageSchoolModal
        currentPage={currentPage}
        manageSchoolVisible={schoolModalVisible}
        toggleManageSchool={toggleManageSchool}
      />
      <AddAdminModal
        selectedSchool={selectedSchool}
        handleSelectSchool={handleSelectSchool}
        currentPage={currentPage}
      />
      <Confirm
        confirmLoading={deactivateSchoolLoading}
        closable
        okText={manageableSelectedSchool?.active ? 'Deaktivieren' : 'Reaktivieren'}
        cancelText='Abbrechen'
        centered
        message={
          manageableSelectedSchool?.active
            ? `Wenn Sie die Schule ${manageableSelectedSchool?.name} deaktivieren, kann kein Anwender dieser Schule auf die IzEL-Software zugreifen. Sind Sie sicher, dass Sie die Schule deaktivieren wollen?`
            : `Wenn Sie die Schule ${manageableSelectedSchool?.name} reaktivieren, können die Anwender dieser Schule wieder auf die IzEL-Software zugreifen. Sind Sie sicher, dass Sie die Schule reaktivieren wollen?`
        }
        open={!!deactivatedSchoolId}
        onOk={(e) => {
          e.stopPropagation();
          deactivateSchool();
        }}
        onCancel={() => {
          setDeactivatedSchoolId(null);
          setDeactivateSchoolLoading(false);
        }}
      />
    </div>
  );
};
export default Schools;
