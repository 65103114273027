import { loginAction, saveAuthAction } from 'store/actions/auth.actions';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { Text, Title } from 'UI/Typography';
import Button from 'UI/Button';
import Input from 'UI/Input';
import { Form } from 'antd';
import BrandIcon from 'UI/Icons/BrandIcon';
import { eventEmitter } from '../../utils';
import { getGradesRangeAction } from 'store/actions/students.actions';
import LinkIcon from '../../UI/Icons/LinkIcon';
import styles from './styles.module.css';

const Login = () => {
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = useCallback(
    (values: { username: string; password: string }) => {
      setLoginLoading(true);
      try {
        const authData = btoa(
          unescape(encodeURIComponent(`${values.username}:${values.password}`)),
        );
        dispatch(
          loginAction({
            email: values.username,
            password: values.password,
            onSuccess: (userData) => {
              dispatch(saveAuthAction({ authData }));
              dispatch(getGradesRangeAction);
              const userRole = userData.data.authorities.map((value: any) => value.authority);
              setLoginLoading(false);
              if (userRole.includes('TEACHER') || userRole.includes('SCHOOL_ADMIN')) {
                navigate('/students');
              } else if (userRole.includes('ASSOCIATION_ADMIN')) {
                navigate('/schools');
              } else if (userRole.includes('STUDENT')) {
                navigate('/student');
              }
            },
            onResolve: () => eventEmitter.emit(),
            onFailure: (message) => {
              setLoginLoading(false);
              setLoginError(message || 'true');
            },
          }),
        );
      } catch (e) {
        setLoginLoading(false);
        setLoginError('true');
      }
    },
    [dispatch, navigate],
  );

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <div style={{ width: '400px', marginTop: '150px' }}>
        <BrandIcon />

        <div style={{ margin: '40px 0 8px 0' }}>
          <Title level={1}>Anmeldung</Title>
        </div>
        <div style={{ marginBottom: '32px' }}>
          <Text level={2}>Geben Sie bitte Ihre E-Mail-Adresse und Ihr Passwort ein</Text>
        </div>
        <Form
          layout='vertical'
          labelCol={{ span: 6 }}
          name='basic'
          labelAlign='left'
          style={{ maxWidth: 400 }}
          onFinish={onFinish}
          requiredMark={false}
          onFieldsChange={() => {
            if (loginError) {
              setLoginError('');
            }
          }}
        >
          <Form.Item
            label={
              <Text bold level={2}>
                E-Mail
              </Text>
            }
            name='username'
            rules={[{ required: true, message: 'Geben Sie Ihre E-Mail-Adresse ein' }]}
            validateStatus={loginError ? 'error' : ''}
          >
            <Input placeholder='Geben Sie Ihre E-Mail-Adresse ein' />
          </Form.Item>
          <Form.Item
            style={{ marginBottom: 0 }}
            extra={
              loginError ? (
                <Text level={1} className='errorMessage'>
                  {loginError === 'school_not_active'
                    ? 'Deine Schule ist deaktiviert. Für weitere Fragen kannst du dich an deine Schulverwaltung wenden.'
                    : 'Angegebene E-Mail-Adresse oder Passwort sind unbekannt'}
                </Text>
              ) : (
                ''
              )
            }
            label={
              <Text bold level={2}>
                Passwort
              </Text>
            }
            name='password'
            rules={[{ required: true, message: 'Geben Sie Ihr Passwort ein' }]}
            validateStatus={loginError ? 'error' : ''}
          >
            <Input.Password autoComplete='off' placeholder='Geben Sie Ihr Passwort ein' />
          </Form.Item>
          <div
            style={{ textAlign: 'right', margin: '8px 0 24px', cursor: 'pointer' }}
            onClick={() => navigate('/reset-pass')}
          >
            <Text level={2} bold className='primary-40'>
              Passwort vergessen?
            </Text>
          </div>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loginLoading}
              style={{ width: '100%' }}
            >
              Anmelden
            </Button>
          </Form.Item>
        </Form>
        <div style={{ marginTop: '200px', marginLeft: '-50px' }} className={styles.linksWrapper}>
          <div>
            <a href='/impressum' target='_blank' rel='noreferrer nofollow noopener'>
              Impressum <LinkIcon />
            </a>
          </div>
          <div>
            <a href='/datenschutzhinweise' target='_blank' rel='noreferrer nofollow noopener'>
              Datenschutzhinweise <LinkIcon />
            </a>
          </div>
          <div>
            <a
              href='/endnutzerlizenzbedingungen'
              target='_blank'
              rel='noreferrer nofollow noopener'
            >
              Endnutzerlizenzbedingungen <LinkIcon />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
