import {
  getGroupsSubjectsAction,
  groupSubjectType,
  removeSubjectFromGroupAction,
} from 'store/actions/group.actions';
import {
  getGroupStudentsCountSelector,
  getGroupSubjectsCountSelector,
  getGroupSubjectsSelector,
} from 'store/selectors/groups.selectors';
import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useParams } from 'react-router-dom';
import Spinner from 'UI/Spinner';
import Table from 'UI/Table';
import Button from '../../../../UI/Button';
import { PlusOutlined } from '@ant-design/icons';
import GroupSubjectSummaryModal from 'pages/SingleGroup/__partials/GroupSubjects/__partials/GroupSubjectSummaryModal';
import { getUserRole } from 'store/selectors/users.selectors';
import EditIcon from '../../../../UI/Icons/EditIcon';
import { Dropdown } from 'antd';
import styles from 'pages/Students/styles.module.css';
import ActionsIcon from '../../../../UI/Icons/ActionsIcon';
import Modal from '../../../../UI/Modal';
import Pagination from '../../../../UI/Pagination';

const { Confirm } = Modal;

const GroupSubjects = ({ groupName, selectedTab }: { groupName: string; selectedTab: string }) => {
  const [summaryModalVisible, setSummaryModalVisible] = useState(false);
  const [selectedSubject, setSelectedSubject] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [groupText, setGroupText] = useState('');
  const [removeSubjectVisible, setRemoveSubjectVisible] = useState(false);
  const [removeSubjectLoading, setRemoveSubjectLoading] = useState(false);
  const [isAssigned, setIsAssigned] = useState(false);
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const groupSubjects = useSelector(getGroupSubjectsSelector);
  const studentsCount = useSelector(getGroupStudentsCountSelector);
  const userRole = useSelector(getUserRole);
  const subjectsCount = useSelector(getGroupSubjectsCountSelector);

  const itemsCount = useMemo(() => {
    if (groupSubjects?.length) {
      return groupSubjects
        ?.map((subject: groupSubjectType) => subject.items)
        .reduce((a, b) => a + b, 0);
    } else return 0;
  }, [groupSubjects]);

  const toggleSummaryModal = useCallback((value: boolean) => {
    setSummaryModalVisible(value);
  }, []);

  const handleGroupText = useCallback((value: string) => {
    setGroupText(value);
  }, []);

  const changeSelectedSubject = useCallback((value: number) => {
    setSelectedSubject(value);
  }, []);

  const toggleRemoveSubject = useCallback((value: boolean) => {
    setRemoveSubjectVisible(value);
  }, []);

  const changeWarning = useCallback((value: boolean) => {
    setIsAssigned(value);
  }, []);

  const handleRemoveSubject = useCallback(() => {
    setRemoveSubjectLoading(true);
    dispatch(
      removeSubjectFromGroupAction({
        groupId: Number(groupId),
        subjectId: Number(selectedSubject),
        onSuccess: () => {
          toggleRemoveSubject(false);
          setRemoveSubjectLoading(false);
        },
      }),
    );
    toggleRemoveSubject(false);
  }, [dispatch, groupId, selectedSubject, toggleRemoveSubject]);

  const columns = useMemo(() => {
    return [
      {
        title: 'Fächer',
        dataIndex: 'name',
        key: 'name',
      },
      {
        title: 'Typ',
        dataIndex: 'optional',
        key: 'optional',
        render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
      },
      {
        title: 'Autor',
        dataIndex: 'isAssociation',
        key: 'isAssociation',
        render: (record: boolean) => (record ? 'MLVB' : 'Schule'),
      },
      {
        title: itemsCount > 1 ? `${itemsCount} Items` : `${itemsCount} Item`,
        dataIndex: 'items',
        key: 'items',
      },
      {
        ...(userRole === 'TEACHER'
          ? {
              title: 'Aktion',
              dataIndex: 'groupText',
              key: 'groupText',
              width: 200,
              render: (record: string, i: { id: number; groupText: string }) => {
                return (
                  <Button
                    type='default'
                    icon={record && record !== '<p><br></p>' ? <EditIcon /> : <PlusOutlined />}
                    onClick={() => {
                      changeSelectedSubject(i.id);
                      handleGroupText(i.groupText);
                      toggleSummaryModal(true);
                    }}
                  >
                    {record && record !== '<p><br></p>'
                      ? 'Entwicklungsbericht bearbeiten'
                      : 'Entwicklungsbericht hinzufügen'}
                  </Button>
                );
              },
            }
          : {}),
      },
      {
        ...(userRole === 'SCHOOL_ADMIN'
          ? {
              title: '',
              dataIndex: 'action',
              key: 'action',
              width: 50,
              render: (record: number, i: { id: number; assignedToAnyStudent: boolean }) => {
                return (
                  <Dropdown
                    trigger={['click']}
                    overlayClassName={styles.dropdownContent}
                    placement='bottomRight'
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                toggleRemoveSubject(true);
                                changeSelectedSubject(i.id);
                                changeWarning(i.assignedToAnyStudent);
                              }}
                            >
                              Entfernen
                            </span>
                          ),
                        },
                      ],
                    }}
                  >
                    <ActionsIcon />
                  </Dropdown>
                );
              },
            }
          : {}),
      },
    ];
  }, [
    changeSelectedSubject,
    changeWarning,
    handleGroupText,
    itemsCount,
    toggleRemoveSubject,
    toggleSummaryModal,
    userRole,
  ]);

  const selectedSubjectName = useMemo(() => {
    if (groupSubjects?.length) {
      return (
        groupSubjects?.find((subject: { id: number }) => subject.id === selectedSubject)?.name || ''
      );
    } else return '';
  }, [groupSubjects, selectedSubject]);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(getGroupsSubjectsAction({ id: Number(groupId), page: page - 1 }));
    },
    [dispatch, groupId],
  );

  useEffect(() => {
    if (selectedTab) {
      setCurrentPage(1);
      dispatch(getGroupsSubjectsAction({ id: Number(groupId), page: 0 }));
    }
  }, [dispatch, groupId, selectedTab]);

  return (
    <div>
      <Table
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={groupSubjects?.length ? groupSubjects : []}
        columns={columns}
      />
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={subjectsCount}
        />
      </div>
      <GroupSubjectSummaryModal
        groupText={groupText}
        groupName={groupName}
        selectedSubjectName={selectedSubjectName}
        selectedSubject={Number(selectedSubject)}
        summaryModalVisible={summaryModalVisible}
        toggleSummaryModal={toggleSummaryModal}
      />
      <Confirm
        confirmLoading={removeSubjectLoading}
        closable
        okText='Entfernen'
        cancelText='Abbrechen'
        centered
        message={
          isAssigned
            ? `Wenn Sie dieses Fach entfernen, werden alle Zuweisungen aus diesem Fach für alle Schüler / innen in dieser Gruppe ebenfalls entfernt. Dies betrifft ${studentsCount} Schüler / innen`
            : 'Sind Sie sicher, dass sie das Fach aus der Gruppe entfernen wollen?'
        }
        open={removeSubjectVisible}
        onOk={handleRemoveSubject}
        onCancel={() => {
          toggleRemoveSubject(false);
        }}
      />
    </div>
  );
};

export default GroupSubjects;
