import { call, all, put, takeLatest } from 'redux-saga/effects';
import {
  getSubjectsActionSuccess,
  subjectResponse,
  createSubjectParamTypes,
  getSubjectsParamTypes,
  getReorderedSubjectsListSuccess,
  reorderSubjectsParamTypes,
  getSubjectsAction,
} from 'store/actions/subjects.actions';
import { ActionNames } from 'store/actions/actionNames';
import {
  getSubjectsService,
  createSubjectService,
  reorderSubjectsService,
  editSubjectService,
} from 'store/services/subjects.services';
import { notification } from 'antd';
import CloseOutlinedIcon from '../../UI/Icons/CloseOutlinedIcon';

function* watchGetSubjects(action: { params: getSubjectsParamTypes; type: string }) {
  try {
    const response: subjectResponse = yield call(...getSubjectsService(action.params));
    yield put(getSubjectsActionSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    console.log('Can not get subjects');
  }
}

function* watchGetSubjectsToReorder(action: { params: getSubjectsParamTypes; type: string }) {
  try {
    const response: subjectResponse = yield call(...getSubjectsService(action.params));
    yield put(getReorderedSubjectsListSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    console.log('Can not get subjects');
  }
}

function* watchCreateSubject(action: { params: createSubjectParamTypes; type: string }) {
  try {
    yield call(...createSubjectService(action.params));
    const response: subjectResponse = yield call(
      ...getSubjectsService({ page: action.params.page, size: 10 }),
    );
    yield put(getSubjectsActionSuccess(response));
    yield call(action.params.onSuccess);
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.name));
    console.log('Can not create subject');
  }
}

function* watchEditSubject(action: { params: createSubjectParamTypes; type: string }) {
  try {
    yield call(...editSubjectService(action.params));
    yield put(getSubjectsAction({ page: action.params.page, size: 10 }));
    yield call(action.params.onSuccess);
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.name));
    console.log('Can not create subject');
  }
}

function* watchReorderSubjects(action: { params: reorderSubjectsParamTypes; type: string }) {
  try {
    yield call(...reorderSubjectsService(action.params));
    yield put(getSubjectsAction({ page: 0, size: 10 }));
    yield call(action.params.onSuccess);
  } catch (e) {
    yield call(action.params.onFailure);
    yield notification.error({
      message: 'Leider ist etwas schiefgelaufen. Bitte versuchen Sie es später noch einmal.',
      duration: 3,
      className: 'notifyError',
      icon: <CloseOutlinedIcon />,
    });
    console.log('Can not reorder subjects');
  }
}

function* subjectsSaga() {
  yield all([
    takeLatest(ActionNames.GET_SUBJECTS, watchGetSubjects),
    takeLatest(ActionNames.GET_SUBJECTS_TO_REORDER, watchGetSubjectsToReorder),
    takeLatest(ActionNames.CREATE_SUBJECT, watchCreateSubject),
    takeLatest(ActionNames.EDIT_SUBJECT, watchEditSubject),
    takeLatest(ActionNames.REORDER_SUBJECTS, watchReorderSubjects),
  ]);
}

export default subjectsSaga;
