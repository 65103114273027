import React, { useCallback, useState } from 'react';
import { Text, Title } from 'UI/Typography';
import Button from 'UI/Button';
import Input from 'UI/Input';
import { Form } from 'antd';
import BrandIcon from 'UI/Icons/BrandIcon';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { firstLoginAction } from 'store/actions/auth.actions';

const FirstLogin = () => {
  const [loginLoading, setLoginLoading] = useState(false);
  const [loginError, setLoginError] = useState(false);
  const [expired, setExpired] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const token = searchParams.get('token');

  const onFinish = useCallback(
    (values: { password: string; confirm: string }) => {
      setLoginLoading(true);
      dispatch(
        firstLoginAction({
          token: String(token),
          password: values.password,
          onSuccess: () => {
            setLoginLoading(false);
            navigate('/login');
          },
          onFailure: () => {
            setExpired(true);
            setLoginLoading(false);
          },
        }),
      );
    },
    [dispatch, navigate, token],
  );

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <div style={{ width: '400px' }}>
        <BrandIcon />

        <div style={{ margin: '40px 0 8px 0' }}>
          <Title level={1}>Passwort erstellen</Title>
        </div>
        <Form
          layout='vertical'
          labelCol={{ span: 20 }}
          name='basic'
          labelAlign='left'
          style={{ maxWidth: 400 }}
          onFinish={onFinish}
          requiredMark={false}
          onFieldsChange={() => {
            if (loginError) {
              setLoginError(false);
            }
          }}
        >
          <Form.Item
            label={
              <Text bold level={2}>
                Passwort erstelllen
              </Text>
            }
            name='password'
            rules={[{ required: true, message: 'Geben Sie Ihr Passwort ein' }]}
          >
            <Input.Password placeholder='Geben Sie Ihr Passwort ein' />
          </Form.Item>
          <Form.Item
            extra={
              expired ? (
                <Text level={1} className='errorMessage'>
                  Einladungslink ist ungültig oder bereits abgelaufen
                </Text>
              ) : (
                ''
              )
            }
            label={
              <Text bold level={2}>
                Passwort wiederholen
              </Text>
            }
            name='confirm'
            dependencies={['password']}
            rules={[
              { required: true, message: 'Geben Sie Ihr Passwort ein' },
              ({ getFieldValue }) => ({
                validator(_, value) {
                  if (!value || getFieldValue('password') === value) {
                    return Promise.resolve();
                  }
                  return Promise.reject(
                    new Error('Die eingegebenen Passwörter stimmen nicht überein'),
                  );
                },
              }),
            ]}
            validateStatus={loginError ? 'error' : ''}
          >
            <Input.Password autoComplete='off' placeholder='Geben Sie Ihr Passwort ein' />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loginLoading}
              style={{ width: '100%' }}
            >
              Speichern
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default FirstLogin;
