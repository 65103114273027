import { createPersonalLetterAction, getPersonalLetterAction } from 'store/actions/report.actions';
import { getPersonalLetter } from 'store/selectors/report.selectors';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from 'UI/Icons/ArrowBackIcon';
import { useParams } from 'react-router-dom';
import styles from './styles.module.css';
import { Text } from 'UI/Typography';
import { useDebounce } from '../../../../hooks/useDebaunce';
import AllObservations from 'pages/Report/__partials/PersonalLetter/__partials/AllObservations';
import RichText from 'components/RichText';
import cc from 'classcat';
import SpinContainer from 'components/SpinContainer';

const PersonalLetter = ({
  handleChangeEvaluationLoading,
  reportState,
  activeReportId,
}: {
  handleChangeEvaluationLoading: (arg1: boolean) => void;
  reportState: 'active' | 'deactivated' | 'not_active';
  activeReportId: number;
}) => {
  const [allObservationsModalVisible, setAllObservationsModal] = useState(false);
  const [personalLetter, setPersonalLetter] = useState('');
  const [areaTouched, setAreaTouched] = useState(false);
  const [letterLength, setLetterLength] = useState(0);
  const [personalLetterLoading, setPersonalLetterLoading] = useState(false);
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const personalLetterData = useSelector(getPersonalLetter);

  const defaultValue = useMemo(() => {
    return personalLetterData?.text;
  }, [personalLetterData?.text]);

  const toggleAllObservations = useCallback((value: boolean) => {
    setAllObservationsModal(value);
  }, []);

  const handleSavePersonalLetter = useCallback(
    (value: string) => {
      handleChangeEvaluationLoading(true);
      dispatch(
        createPersonalLetterAction({
          text:
            value === '<p><br></p>'
              ? null
              : `<div style="font-size: 11pt; line-height: 1.5; font-family: 'Avenir Next', sans-serif" class="outer-div" >${value}</div>`,
          studentId: Number(studentId),
          onSuccess: () => handleChangeEvaluationLoading(false),
          izelId: activeReportId,
        }),
      );
    },
    [activeReportId, dispatch, handleChangeEvaluationLoading, studentId],
  );

  const debounceSave = useDebounce(handleSavePersonalLetter, 500);

  const handleChangePersonalLetter = useCallback(
    (value: string, length: number) => {
      if (!areaTouched) {
        setAreaTouched(true);
      }
      setLetterLength(length);
      setPersonalLetter(value);
      if (length <= 20000) {
        debounceSave(value);
      }
    },
    [areaTouched, debounceSave],
  );

  useEffect(() => {
    setPersonalLetterLoading(true);
    dispatch(
      getPersonalLetterAction({
        studentId: Number(studentId),
        izelId: activeReportId,
        onSuccess: () => setPersonalLetterLoading(false),
      }),
    );
  }, [activeReportId, dispatch, studentId]);

  useEffect(() => {
    setPersonalLetter(defaultValue);
  }, [defaultValue]);

  return (
    <div>
      <div className={styles.observationList} onClick={() => toggleAllObservations(true)}>
        <Text level={2} bold className='primary-40'>
          Alle Beobachtungen einblenden
        </Text>
        <span>
          <ArrowBackIcon className='primary-40' />
        </span>
      </div>
      <SpinContainer spinning={personalLetterLoading}>
        <div className={styles.editorWrapper}>
          <RichText
            readOnly={reportState !== 'active'}
            value={personalLetter}
            onChange={(value, length) => handleChangePersonalLetter(value, length)}
          />
        </div>
      </SpinContainer>

      <div
        className={cc([
          styles.editorCount,
          {
            [styles.error]: letterLength > 20000,
          },
        ])}
      >
        {letterLength}/20000
      </div>
      <AllObservations
        toggleAllObservations={toggleAllObservations}
        allObservationsModalVisible={allObservationsModalVisible}
        activeReportId={activeReportId}
      />
    </div>
  );
};

export default PersonalLetter;
