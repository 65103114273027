import ArrowBackIcon from 'UI/Icons/ArrowBackIcon';
import { Text } from 'UI/Typography';
import styles from './styles.module.css';
import React, { ReactNode } from 'react';
import { Tooltip } from 'antd';
import InfoIcon from '../../../../UI/Icons/InfoIcon';

const CollapseHeader = ({
  name,
  handleViewSubjectObservation,
  description,
}: {
  name: ReactNode | string;
  handleViewSubjectObservation: (arg1: { stopPropagation: () => void }) => void;
  description?: string;
}) => {
  return (
    <div>
      <div className={styles.titleWrapper}>
        <Text level={2} bold>
          {name}
        </Text>
        {description && (
          <Tooltip title={description}>
            <InfoIcon />
          </Tooltip>
        )}
      </div>
      <div
        className={styles.openReviewMargin}
        onClick={(e: { stopPropagation: () => void }) => handleViewSubjectObservation(e)}
      >
        <Text level={2} bold className='primary-40'>
          Beobachtungen einblenden
        </Text>
        <span>
          <ArrowBackIcon className='primary-40' />
        </span>
      </div>
    </div>
  );
};

export default CollapseHeader;
