import {
  createItemParaTypes,
  createSubItemParaTypes,
  deactivateItemParamTypes,
  editItemParamTypes,
  editSubItemParamTypes,
  getItemParamTypes,
  removeItemParamTypes,
  reorderSubItemActionParamTypes,
} from 'store/actions/items.actions';
import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import { client } from 'store/services/client';

export const getAssessmentScalesService = (): [ApiCall, string] => [
  client.get,
  '/assessment-scales',
];

export const getItemsService = (params: getItemParamTypes): [ApiCall, string] => [
  client.get,
  `/items?subjectId=${params.subjectId}&page=${params.page}&size=${params.size}&showDeactivatedItems=${params.showDeactivatedItems}`,
];

export const createItemService = (
  params: createItemParaTypes,
): [ApiCallWithParams, string, createItemParaTypes] => [
  client.post,
  '/items',
  { ...params, currentPage: undefined, showDeactivatedItems: undefined },
];

export const editItemService = (
  params: editItemParamTypes,
): [ApiCallWithParams, string, editItemParamTypes] => [client.put, `/items/${params.id}`, params];

export const createSubItemService = (
  params: createSubItemParaTypes,
): [ApiCallWithParams, string, createSubItemParaTypes] => [
  client.post,
  `/items/${params.parentId}/sub-items`,
  params,
];

export const editSubItemService = (
  params: editSubItemParamTypes,
): [ApiCallWithParams, string, editSubItemParamTypes] => [
  client.put,
  `/items/${params.parentId}/sub-items/${params.id}`,
  params,
];

export const removeItemService = (params: removeItemParamTypes): [ApiCall, string] => [
  client.delete,
  params.parentId ? `/items/${params.parentId}/sub-items/${params.id}` : `/items/${params.id}`,
];

export const deactivateItemService = (params: deactivateItemParamTypes): [ApiCall, string] => [
  client.delete,
  `/items/${params.id}/deactivate`,
];

export const reactivateItemService = (params: deactivateItemParamTypes): [ApiCall, string] => [
  client.delete,
  `/items/${params.id}/activate`,
];

export const reorderSubItemsService = (
  params: reorderSubItemActionParamTypes,
): [
  ApiCallWithParams,
  string,
  { previousSubItemId: number | null; nextSubItemId: number | null },
] => [
  client.post,
  `/items/${params.itemId}/sub-items/${params.subItemId}/order`,
  {
    previousSubItemId: params.previousSubItemId,
    nextSubItemId: params.nextSubItemId,
  },
];
