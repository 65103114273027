import { teachersResponseType, teacherTypes } from 'store/actions/teachers.actions';
import { ActionNames } from 'store/actions/actionNames';
import produce from 'immer';

const initialState: { teachers: teachersResponseType | null } = {
  teachers: null,
};

const teachersReducer = (
  state = initialState,
  action: {
    type: string;
    payload: { data: teachersResponseType & teacherTypes };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_TEACHERS_SUCCESS:
        draft.teachers = action.payload.data;
        break;
      case ActionNames.ADD_TEACHER_SUCCESS:
        const updated = [...(state.teachers?.teachers || [])];
        updated.push(action.payload.data);
        if (draft.teachers) {
          draft.teachers.teachers = updated;
        }
        break;
      default:
        break;
    }
  });

export default teachersReducer;
