import { getGroupCountSelector, getGroupsSelector } from 'store/selectors/groups.selectors';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getGroupsAction } from 'store/actions/group.actions';
import { getUserRole } from 'store/selectors/users.selectors';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import StarIcon from 'UI/Icons/StarIcon';
import Spinner from 'UI/Spinner';
import { Col, Dropdown, Row } from 'antd';
import Button from 'UI/Button';
import Input from 'UI/Input';
import Table from 'UI/Table';
import ManageGroupModal from './__partials/ManageGroupModal';
import { groupTypesMapping } from '../../constants';
import styles from './styles.module.css';
import Checkbox from '../../UI/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Pagination from '../../UI/Pagination';
import { Text, Title } from '../../UI/Typography';
import ActionsIcon from '../../UI/Icons/ActionsIcon';
import RemoveGroupModal from 'pages/Groups/__partials/RemoveGroupModal';

const { Search } = Input;

const columns = (
  userRole: 'SCHOOL_ADMIN' | 'TEACHER' | 'ASSOCIATION_ADMIN' | 'STUDENT',
  handleSelectGroup: (arg1: number) => void,
  toggleManageGroup: (arg1: boolean) => void,
  toggleRemoveGroup: (arg1: boolean) => void,
) => [
  {
    title: 'Gruppe',
    dataIndex: 'name',
    key: 'name',
    render: (record: string) => (
      <Text level={1} bold>
        {record}
      </Text>
    ),
  },
  {
    title: 'Art der Gruppe',
    dataIndex: 'type',
    key: 'type',
    render: (record: string) => groupTypesMapping[record],
  },
  {
    title: 'Schüleranzahl',
    dataIndex: 'studentsNumber',
    key: 'studentsNumber',
  },
  {
    title: 'Jahrgänge',
    dataIndex: 'schoolYears',
    key: 'schoolYears',
    render: (record: number[]) =>
      structuredClone(record)
        ?.sort((a: number, b: number) => Number(a) - Number(b))
        .join(', '),
  },
  {
    ...(userRole === 'TEACHER'
      ? {
          title: 'Zugewiesen',
          dataIndex: 'id',
          key: 'id',
          width: 100,
          render: (id: number, record: { teacherAssigned: boolean }) =>
            record.teacherAssigned ? <StarIcon /> : null,
        }
      : {}),
  },
  {
    ...(userRole === 'SCHOOL_ADMIN'
      ? {
          title: '',
          dataIndex: 'id',
          key: 'id',
          onCell: () => {
            return {
              onClick: (event: { stopPropagation: () => void }) => {
                event.stopPropagation();
              },
            };
          },
          render: (record: number, i: { active: boolean }) => {
            return (
              <Dropdown
                overlayClassName={styles.dropdownContent}
                trigger={['click']}
                placement='bottomRight'
                menu={{
                  items: [
                    {
                      key: '1',
                      label: (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectGroup(record);
                            toggleManageGroup(true);
                          }}
                        >
                          Ändern
                        </span>
                      ),
                    },
                    {
                      key: '2',
                      label: (
                        <span
                          onClick={(e) => {
                            e.stopPropagation();
                            handleSelectGroup(record);
                            toggleRemoveGroup(true);
                          }}
                        >
                          Löschen
                        </span>
                      ),
                    },
                  ],
                }}
              >
                <ActionsIcon />
              </Dropdown>
            );
          },
          width: 50,
        }
      : {}),
  },
];

const Groups = () => {
  const [groupModalVisible, setGroupModalVisible] = useState(false);
  const [showOnlyTeachers, setShowOnlyTeachers] = useState(false);
  const [removeGroupVisible, setRemoveGroupVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [getGroupsLoading, setGetGroupsLoading] = useState(false);
  const [selectedGroupId, setSelectedGroupId] = useState<number | null>(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const groups = useSelector(getGroupsSelector);
  const groupsCount = useSelector(getGroupCountSelector);
  const userRole = useSelector(getUserRole);
  const toggleManageGroup = useCallback((value: boolean) => {
    setGroupModalVisible(value);
  }, []);

  const selectedGroup = useMemo(() => {
    return groups?.find((group) => group.id === selectedGroupId);
  }, [groups, selectedGroupId]);

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(
        getGroupsAction({
          page: page - 1,
          size: 10,
          showOnlyTeacherGroups: showOnlyTeachers,
          ...(searchValue.length > 2 ? { name: `&name=${searchValue}` } : {}),
        }),
      );
    },
    [dispatch, searchValue, showOnlyTeachers],
  );

  const toggleRemoveGroup = useCallback((value: boolean) => setRemoveGroupVisible(value), []);

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      setSearchValue(value);
      setCurrentPage(1);
      if (value.length > 2) {
        dispatch(
          getGroupsAction({
            page: 0,
            size: 10,
            showOnlyTeacherGroups: showOnlyTeachers,
            name: `&name=${value}`,
          }),
        );
      } else {
        dispatch(
          getGroupsAction({
            page: 0,
            size: 10,
            showOnlyTeacherGroups: showOnlyTeachers,
          }),
        );
      }
    },
    [dispatch, showOnlyTeachers],
  );

  const handleSelectGroup = useCallback((value: number | null) => {
    setSelectedGroupId(value);
  }, []);

  useEffect(() => {
    setGetGroupsLoading(true);
    dispatch(
      getGroupsAction({
        page: 0,
        size: 10,
        showOnlyTeacherGroups: showOnlyTeachers,
        onSuccess: () => setGetGroupsLoading(false),
      }),
    );
  }, [dispatch, showOnlyTeachers]);

  return (
    <div className={styles.groupsWrapper}>
      <div className={styles.titleWrapper}>
        <Title level={1}>Gruppen</Title>
      </div>
      <Row className={styles.groupFiltersWrapper} justify='space-between'>
        <Col>
          <Search
            allowClear
            value={searchValue}
            onChange={(e: ChangeEvent<HTMLInputElement>) => handleChangeSearchValue(e.target.value)}
          />
        </Col>
        <Col>
          {userRole === 'SCHOOL_ADMIN' && (
            <Button type='primary' icon={<PlusOutlined />} onClick={() => toggleManageGroup(true)}>
              Gruppe hinzufügen
            </Button>
          )}
        </Col>
      </Row>
      {userRole === 'TEACHER' && (
        <div className={styles.checkboxWrapper}>
          <Checkbox
            checked={showOnlyTeachers}
            onChange={(e: CheckboxChangeEvent) => setShowOnlyTeachers(e.target.checked)}
          >
            Nur mir zugewiesene
          </Checkbox>
        </div>
      )}
      <div className={styles.groupsTableWrapper}>
        <Table
          className={styles.groupsTable}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: getGroupsLoading }}
          pagination={false}
          dataSource={groups}
          columns={columns(userRole, handleSelectGroup, toggleManageGroup, toggleRemoveGroup)}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/groups/${record.id}`);
              },
            };
          }}
        />
      </div>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={groupsCount}
        />
      </div>
      <ManageGroupModal
        manageGroupVisible={groupModalVisible}
        toggleManageGroup={toggleManageGroup}
        selectedGroupId={selectedGroupId}
        handleSelectGroup={handleSelectGroup}
        page={currentPage}
      />
      <RemoveGroupModal
        selectedGroup={selectedGroup}
        removeGroupVisible={removeGroupVisible}
        toggleRemoveGroup={toggleRemoveGroup}
        showOnlyTeachers={showOnlyTeachers}
        currentPage={currentPage}
        handleSelectGroup={handleSelectGroup}
      />
    </div>
  );
};
export default Groups;
