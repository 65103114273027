import { getUserRole } from 'store/selectors/users.selectors';
import LearningGroupsIcon from 'UI/Icons/LearningGroupIcon';
import { useLocation, useNavigate } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import SubjectsIcon from 'UI/Icons/SubjectsIcon';
import MainContent from 'components/MainContent';
import StudentIcon from 'UI/Icons/StudentIcon';
import SchoolIcon from 'UI/Icons/SchoolIcon';
import AvatarIcon from 'UI/Icons/AvatarIcon';
import { useSelector } from 'react-redux';
import { Layout, MenuProps, Popover } from 'antd';
import Header from 'components/Header';
import Menu from 'UI/Menu';
import styles from './styles.module.css';
import LinkIcon from '../../UI/Icons/LinkIcon';
import InfoFilledIcon from '../../UI/Icons/InfoFilledIcon';
import cc from 'classcat';

const { Sider, Content } = Layout;

const Home = () => {
  const [siderCollapsed, setSiderCollapsed] = useState(false);
  const userRole = useSelector(getUserRole);
  const navigate = useNavigate();
  const location = useLocation();

  const items: MenuProps['items'] =
    userRole === 'TEACHER'
      ? [
          {
            key: 'students',
            icon: <StudentIcon className={styles.sidebarIcons} />,
            label: 'Schüler/Schülerinnen',
          },
          {
            key: 'groups',
            icon: <LearningGroupsIcon className={styles.sidebarIcons} />,
            label: 'Lerngruppe',
          },
        ]
      : userRole === 'SCHOOL_ADMIN'
      ? [
          {
            key: 'students',
            icon: <StudentIcon className={styles.sidebarIcons} />,
            label: 'Schüler/Schülerinnen',
          },
          {
            key: 'teachers',
            icon: <AvatarIcon className={styles.sidebarIcons} />,
            label: 'Lehrkraft / Admins',
          },
          {
            key: 'groups',
            icon: <LearningGroupsIcon className={styles.sidebarIcons} />,
            label: 'Lerngruppe',
          },
          {
            key: 'subjects',
            icon: <SubjectsIcon className={styles.sidebarIcons} />,
            label: 'Fächer',
          },
          {
            key: 'schoolInfo',
            icon: <SchoolIcon className={styles.sidebarIcons} />,
            label: 'Meine Schule',
          },
        ]
      : [
          {
            key: 'schools',
            icon: <StudentIcon className={styles.sidebarIcons} />,
            label: 'Schulen',
          },
          {
            key: 'subjects',
            icon: <SubjectsIcon className={styles.sidebarIcons} />,
            label: 'Fächer',
          },
        ];

  const pageKey = location.pathname.split('/')[1] || 'dashboard';

  const handleSiderBarClick = useCallback(
    (key: string) => {
      navigate(`/${key}`);
    },
    [navigate],
  );

  return (
    <div>
      <Header avatar={''} name={''} />
      <Layout className={styles.layoutWrapper}>
        {userRole !== 'STUDENT' && (
          <Sider
            width='280px'
            className={styles.sidebar}
            theme='light'
            collapsed={siderCollapsed}
            collapsible
            breakpoint='lg'
            collapsedWidth='100'
            onCollapse={() => {
              setSiderCollapsed((prev) => !prev);
            }}
          >
            <div className={styles.menuWrapper}>
              <Menu
                className={styles.homeMenu}
                selectedKeys={[pageKey]}
                theme='light'
                defaultSelectedKeys={['dashboard']}
                items={items}
                onClick={(item: { key: string }) => handleSiderBarClick(item.key)}
              />
            </div>
            {siderCollapsed ? (
              <Popover
                content={
                  <div className={cc([styles.linksWrapper, styles.linksWrapperPopup])}>
                    <div>
                      <a href='/impressum' target='_blank' rel='noreferrer nofollow noopener'>
                        Impressum <LinkIcon />
                      </a>
                    </div>
                    <div>
                      <a
                        href='/datenschutzhinweise'
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                      >
                        Datenschutzhinweise <LinkIcon />
                      </a>
                    </div>
                    <div>
                      <a
                        href='/endnutzerlizenzbedingungen'
                        target='_blank'
                        rel='noreferrer nofollow noopener'
                      >
                        Endnutzerlizenzbedingungen <LinkIcon />
                      </a>
                    </div>
                    {userRole !== 'TEACHER' && (
                      <div>
                        <a
                          href='/nutzungsbedingungen'
                          target='_blank'
                          rel='noreferrer nofollow noopener'
                        >
                          Nutzungsbedingungen <LinkIcon />
                        </a>
                      </div>
                    )}
                  </div>
                }
                title=''
              >
                <div style={{ textAlign: 'center' }}>
                  <InfoFilledIcon />
                </div>
              </Popover>
            ) : (
              <div className={styles.linksWrapper}>
                <div>
                  <a href='/impressum' target='_blank' rel='noreferrer nofollow noopener'>
                    Impressum <LinkIcon />
                  </a>
                </div>
                <div>
                  <a href='/datenschutzhinweise' target='_blank' rel='noreferrer nofollow noopener'>
                    Datenschutzhinweise <LinkIcon />
                  </a>
                </div>
                <div>
                  <a
                    href='/endnutzerlizenzbedingungen'
                    target='_blank'
                    rel='noreferrer nofollow noopener'
                  >
                    Endnutzerlizenzbedingungen <LinkIcon />
                  </a>
                </div>
                {userRole !== 'TEACHER' && (
                  <div>
                    <a
                      href='/nutzungsbedingungen'
                      target='_blank'
                      rel='noreferrer nofollow noopener'
                    >
                      Nutzungsbedingungen <LinkIcon />
                    </a>
                  </div>
                )}
              </div>
            )}
          </Sider>
        )}
        <Layout className={styles.layout}>
          <Content>
            <MainContent pageKey={pageKey} />
          </Content>
        </Layout>
      </Layout>
    </div>
  );
};

export default Home;
