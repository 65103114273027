import type { Identifier, XYCoord } from 'dnd-core';
import React, { useRef } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import styles from 'pages/SingleSubject/styles.module.css';
import { Col, List, Row, Tooltip } from 'antd';
import { Title } from 'UI/Typography';
import { DeleteOutlined, EditOutlined } from '@ant-design/icons';
import { subItemTypes } from 'store/actions/items.actions';
import DragIcon from '../../../../UI/Icons/DragIcon';
import InfoIcon from '../../../../UI/Icons/InfoIcon';
import { useSelector } from 'react-redux';
import { getUserRole } from 'store/selectors/users.selectors';

export type CardProps = {
  id: number;
  item: subItemTypes;
  index: number;
  moveCard: (dragIndex: number, hoverIndex: number, itemId: number) => void;
  handleDragEnd: (dragIndex: number, hoverIndex: number, itemId: number) => void;
  handleEditSubItem: any;
  setDeletingSubItemId: any;
  setConfirmSubItem: any;
  itemId: number;
};

type DragItem = {
  index: number;
  id: string;
  type: string;
};

const SubItemList = ({
  id,
  item,
  index,
  moveCard,
  handleEditSubItem,
  setDeletingSubItemId,
  setConfirmSubItem,
  handleDragEnd,
  itemId,
}: CardProps) => {
  const userRole = useSelector(getUserRole);
  const ref = useRef<HTMLDivElement>(null);
  const [, drop] = useDrop<DragItem, void, { handlerId: Identifier | null }>({
    accept: 'list',
    collect(monitor) {
      return {
        handlerId: monitor.getHandlerId(),
      };
    },
    hover(item: DragItem, monitor) {
      if (!ref.current) {
        return;
      }
      const dragIndex = item.index;
      const hoverIndex = index;

      // Don't replace items with themselves
      if (dragIndex === hoverIndex) {
        return;
      }

      // Determine rectangle on screen
      const hoverBoundingRect = ref.current?.getBoundingClientRect();

      // Get vertical middle
      const hoverMiddleY = (hoverBoundingRect.bottom - hoverBoundingRect.top) / 2;

      // Determine mouse position
      const clientOffset = monitor.getClientOffset();

      // Get pixels to the top
      const hoverClientY = (clientOffset as XYCoord).y - hoverBoundingRect.top;

      // Only perform the move when the mouse has crossed half of the items height
      // When dragging downwards, only move when the cursor is below 50%
      // When dragging upwards, only move when the cursor is above 50%

      // Dragging downwards
      if (dragIndex < hoverIndex && hoverClientY < hoverMiddleY) {
        return;
      }

      // Dragging upwards
      if (dragIndex > hoverIndex && hoverClientY > hoverMiddleY) {
        return;
      }

      // Time to actually perform the action
      moveCard(dragIndex, hoverIndex, itemId);

      // Note: we're mutating the monitor item here!
      // Generally it's better to avoid mutations,
      // but it's good here for the sake of performance
      // to avoid expensive index searches.
      item.index = hoverIndex;
    },
  });

  const [, drag, preview] = useDrag({
    type: 'list',
    item: () => {
      return { id, index };
    },
    collect: (monitor: any) => ({
      isDragging: monitor.isDragging(),
    }),
    end: (item) => handleDragEnd(item.id, item.index, itemId),
  });

  drop(ref);
  return (
    <List.Item className={styles.subItemWrapper} ref={ref}>
      <div className={styles.subItemInner} ref={preview}>
        <span className={styles.dragHandler}>
          {(!item.isAssociation || userRole === 'ASSOCIATION_ADMIN') && (
            <span ref={drag}>
              <DragIcon />
            </span>
          )}
        </span>
        <Row justify='space-between' align='middle' className={styles.subItemRight}>
          <Col>
            <div className={styles.subItemTitle}>
              <Title level={5}>{item.name}</Title>
              {item.description && (
                <Tooltip title={item.description}>
                  <InfoIcon />
                </Tooltip>
              )}
            </div>
            <div className={styles.subInfoWrapper}>
              <div className={styles.subInfoItem}>
                Typ
                <Title level={5}>{item.optional ? 'Optional' : 'Bindend'}</Title>
              </div>
              <div className={styles.subInfoItem}>
                Jahrgänge{' '}
                <Title level={5}>
                  {structuredClone(item.schoolYears)
                    ?.sort((a: string, b: string) => Number(a) - Number(b))
                    .join(', ')}
                </Title>
              </div>
            </div>
          </Col>
          <Col>
            <div style={{ display: 'flex' }}>
              {(!item.isAssociation || userRole === 'ASSOCIATION_ADMIN') && (
                <span
                  className={styles.manageIcons}
                  onClick={(event) => handleEditSubItem(event, item.id, itemId)}
                >
                  <EditOutlined />
                </span>
              )}
              {!item.isAssociation && userRole === 'SCHOOL_ADMIN' && (
                <span
                  className={styles.manageIcons}
                  onClick={(event) => {
                    event.stopPropagation();
                    setDeletingSubItemId(item.id);
                    setConfirmSubItem(true);
                  }}
                >
                  <DeleteOutlined />
                </span>
              )}
            </div>
          </Col>
        </Row>
      </div>
    </List.Item>
  );
};

export default SubItemList;
