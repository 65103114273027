import { getUserRole, getUserSelector } from 'store/selectors/users.selectors';
import React, { useCallback, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import AvatarIcon from 'UI/Icons/AvatarIcon';
import LogoutIcon from 'UI/Icons/LogoutIcon';
import BrandIcon from 'UI/Icons/BrandIcon';
import { useDispatch, useSelector } from 'react-redux';
import { Title } from 'UI/Typography';
import { Row, Col, Dropdown } from 'antd';
import Modal from 'UI/Modal';
import { userRoles } from '../../constants';
import styles from './styles.module.css';
import { DownOutlined } from '@ant-design/icons';
import { changeUserRoleAction, saveAuthAction } from 'store/actions/auth.actions';
import { eventEmitter } from '../../utils';

const { Confirm } = Modal;

type HeaderTypes = {
  avatar: string;
  name: string;
};

const Header = ({ avatar = '', name = 'Name, Vorname' }: HeaderTypes) => {
  const [confirmLogout, setConfirmLogout] = useState(false);
  const [confirmChangeUser, setConfirmChangeUSer] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const userRole = useSelector(getUserRole);
  const user = useSelector(getUserSelector);
  const handleLogout = useCallback(() => {
    dispatch(saveAuthAction({ authData: '' }));
    eventEmitter.emit();
    window.location.href = '/login';
  }, [dispatch]);

  const hasTwoRoles =
    user?.authorities.map((auth) => auth.authority)?.includes('SCHOOL_ADMIN') &&
    user?.authorities.map((auth) => auth.authority)?.includes('TEACHER');

  const handleChangeUSerRole = useCallback(() => {
    dispatch(
      changeUserRoleAction({
        lastLoginRole: userRole === 'SCHOOL_ADMIN' ? 'TEACHER' : 'SCHOOL_ADMIN',
      }),
    );
    setConfirmChangeUSer(false);
    navigate('/students');
  }, [dispatch, navigate, userRole]);

  return (
    <div className={styles.headerWrapper}>
      <Row justify='space-between' align='middle'>
        <Col>
          <Row gutter={16}>
            <Col>
              <BrandIcon />
            </Col>
          </Row>
        </Col>
        <Col>
          <div className={styles.userInfo}>
            {hasTwoRoles ? (
              <div className={styles.selectWrapper}>
                {!avatar ? (
                  <span className={styles.avatarIcon}>
                    {<AvatarIcon style={{ fontSize: '28px' }} />}
                  </span>
                ) : (
                  <img src={avatar} width='40' height='40' alt='avatar' />
                )}
                <Title level={5}>{`${user?.surname}, ${user?.name}`}</Title>
                <Dropdown
                  trigger={['click']}
                  placement='bottomLeft'
                  overlayClassName={styles.dropdownContent}
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <span onClick={() => setConfirmChangeUSer(true)}>{`Zur ${
                            userRole === 'SCHOOL_ADMIN' ? 'Lehrkraft' : 'Administrator'
                          }-Ansicht wechseln`}</span>
                        ),
                      },
                    ],
                  }}
                >
                  <div className={styles.selectWrapper}>
                    <Title level={5} style={{ color: '#CF1322' }}>
                      {userRoles[userRole]}
                    </Title>
                    <DownOutlined />
                  </div>
                </Dropdown>
              </div>
            ) : (
              <div className={styles.selectWrapper}>
                {!avatar ? (
                  <span className={styles.avatarIcon}>
                    {<AvatarIcon style={{ fontSize: '28px' }} />}
                  </span>
                ) : (
                  <img src={avatar} width='40' height='40' alt='avatar' />
                )}
                <Title level={5}>{`${user?.surname}, ${user?.name}`}</Title>
                <Title level={5} style={{ color: '#CF1322' }}>
                  {userRoles[userRole]}
                </Title>
              </div>
            )}
            <span className={styles.logoutIcon} onClick={() => setConfirmLogout(true)}>
              <LogoutIcon />
            </span>
          </div>
        </Col>
      </Row>
      <Confirm
        width={400}
        className={styles.confirmModal}
        closable={true}
        okText='Abmelden'
        centered
        cancelText='Abbrechen'
        message='Möchten Sie sich abmelden?'
        open={confirmLogout}
        onOk={handleLogout}
        onCancel={() => setConfirmLogout(false)}
      />
      <Confirm
        width={400}
        className={styles.confirmModal}
        closable={true}
        okText='Wechseln'
        centered
        cancelText='Abbrechen'
        message='Sind Sie sicher, dass Sie die Ansicht ändern wollen?'
        open={confirmChangeUser}
        onOk={handleChangeUSerRole}
        onCancel={() => setConfirmChangeUSer(false)}
      />
    </div>
  );
};

export default Header;
