import Modal from '../../../../../../UI/Modal';
import { useCallback, useEffect, useState } from 'react';
import styles from 'pages/Report/__partials/Practice/PracticeModal/styles.module.css';
import { Text } from '../../../../../../UI/Typography';
import DatePicker from '../../../../../../UI/DatePicker';
import dayjs from 'dayjs';
import { DatePickerProps } from 'antd';
import { useDispatch } from 'react-redux';
import { editIssueDateAction } from 'store/actions/school.actions';

type manageIssueDateProps = {
  toggleManageIssueDate: (visible: boolean) => void;
  manageIssueDateVisible: boolean;
  halfYear: boolean;
  toggleIssueHalfYear: (value: boolean) => void;
  currentIssueDate: string;
  handelChangeCurrentIssueDate: (value: string) => void;
};

const ManageIssueDate = ({
  toggleManageIssueDate,
  manageIssueDateVisible,
  halfYear,
  toggleIssueHalfYear,
  currentIssueDate,
  handelChangeCurrentIssueDate,
}: manageIssueDateProps) => {
  const [issueDate, setIssueDate] = useState('');
  const dispatch = useDispatch();

  const handleChangeIssueDate = useCallback(
    (value: DatePickerProps['value'], dateString: string) => {
      setIssueDate(dateString);
    },
    [],
  );

  const handleResetModal = useCallback(() => {
    toggleIssueHalfYear(false);
    toggleManageIssueDate(false);
    handelChangeCurrentIssueDate('');
    setIssueDate('');
  }, [toggleIssueHalfYear, toggleManageIssueDate, handelChangeCurrentIssueDate]);

  const handleEditIssueDate = useCallback(() => {
    dispatch(
      editIssueDateAction({ izelHalfYear: halfYear, issueDate, onSuccess: handleResetModal }),
    );
  }, [dispatch, halfYear, handleResetModal, issueDate]);

  useEffect(() => {
    if (currentIssueDate) {
      setIssueDate(currentIssueDate);
    }
  }, [currentIssueDate]);

  return (
    <Modal
      destroyOnClose
      cancelText='Abbrechen'
      okText='Speichern'
      centered
      open={manageIssueDateVisible}
      onCancel={handleResetModal}
      title={halfYear ? 'Datum Halbjahres IzEL' : 'Datum Schuljahres IzEL'}
      onOk={handleEditIssueDate}
    >
      <div className={styles.endDateLabel}>
        <Text level={1} bold>
          IzEL Daten festlegen
        </Text>
      </div>
      <DatePicker
        value={issueDate ? dayjs(issueDate, 'DD.MM.YYYY') : undefined}
        placeholder='tt.mm.jjjj'
        onChange={handleChangeIssueDate}
        style={{ width: '100%' }}
        format='DD.MM.YYYY'
      />
    </Modal>
  );
};

export default ManageIssueDate;
