import { getSubjectsToAddSelector } from 'store/selectors/groups.selectors';
import { AddSubjectToGroupAction, getSubjectsToAddAction } from 'store/actions/group.actions';
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from 'UI/Spinner';
import Modal from 'UI/Modal';
import Table from 'UI/Table';
import Input from 'UI/Input';

type groupSubjectModal = {
  groupSubjectVisible: boolean;
  toggleGroupSubject: (arg1: boolean) => void;
};

const { Search } = Input;

const columns = [
  {
    title: 'Fächer',
    dataIndex: 'name',
    key: 'name',
  },
  {
    title: 'Typ',
    dataIndex: 'optional',
    key: 'optional',
    render: (type: boolean) => (type ? 'Optional' : 'Bindend'),
  },
  {
    title: 'Autor',
    dataIndex: 'isAssociation',
    key: 'isAssociation',
    render: (record: boolean) => (record ? 'MLVB' : 'Schule'),
  },
];

const AddGroupSubjectModal = ({ groupSubjectVisible, toggleGroupSubject }: groupSubjectModal) => {
  const [selectedSubjects, setSelectedSubjects] = useState<Array<number>>([]);
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const subjectsToAdd = useSelector(getSubjectsToAddSelector);

  const handleResetModal = useCallback(() => {
    setSelectedSubjects([]);
    toggleGroupSubject(false);
  }, [toggleGroupSubject]);

  const handleAddSubjectToGroup = useCallback(() => {
    dispatch(AddSubjectToGroupAction({ id: Number(groupId), subjects: selectedSubjects }));
    handleResetModal();
  }, [dispatch, groupId, handleResetModal, selectedSubjects]);

  useEffect(() => {
    dispatch(getSubjectsToAddAction({ id: Number(groupId) }));
  }, [dispatch, groupId]);

  return (
    <Modal
      width={800}
      centered
      open={groupSubjectVisible}
      onCancel={handleResetModal}
      onOk={handleAddSubjectToGroup}
      title='Fächer'
      okText='Hinzufügen'
      cancelText='Abbrechen'
      okDisabled={!selectedSubjects.length}
      destroyOnClose
      fixScroll
    >
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: Array<number>) => setSelectedSubjects(selectedRowKeys),
        }}
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={subjectsToAdd}
        columns={columns}
      />
    </Modal>
  );
};

export default AddGroupSubjectModal;
