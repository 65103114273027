import { useCallback, useState } from 'react';
import { Text, Title } from 'UI/Typography';
import Button from 'UI/Button';
import Input from 'UI/Input';
import { Form } from 'antd';
import BrandIcon from 'UI/Icons/BrandIcon';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { resetPasswordAction } from 'store/actions/auth.actions';

const ResetPassword = () => {
  const [loginLoading, setLoginLoading] = useState(false);
  const [incorrectEmail, setIncorrectEmail] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const onFinish = useCallback(
    (values: { username: string }) => {
      setLoginLoading(true);
      dispatch(
        resetPasswordAction({
          email: values.username,
          onSuccess: () => {
            setLoginLoading(false);
            navigate('/login');
          },
          onFailure: () => {
            setIncorrectEmail(true);
            setLoginLoading(false);
          },
        }),
      );
    },
    [dispatch, navigate],
  );

  return (
    <div
      style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100vh' }}
    >
      <div style={{ width: '400px' }}>
        <BrandIcon />

        <div style={{ margin: '40px 0 8px 0' }}>
          <Title level={1}>Passwort Reset durchführen</Title>
        </div>
        <div style={{ marginBottom: '32px' }}>
          <Text level={2}>
            Geben Sie die E-Mail-Adresse ein, mit der Sie im System angelegt wurden. Prüfen Sie das
            damit verbundene Postfach, sie erhalten dort eine Mail mit einem Link zu einer Seite,
            auf der Sie Ihr neues Passwort festlegen können.
          </Text>
        </div>
        <Form
          layout='vertical'
          labelCol={{ span: 6 }}
          name='basic'
          labelAlign='left'
          style={{ maxWidth: 400 }}
          onFinish={onFinish}
          requiredMark={false}
          onFieldsChange={() => {
            if (incorrectEmail) {
              setIncorrectEmail(false);
            }
          }}
        >
          <Form.Item
            label={
              <Text bold level={2}>
                E-Mail
              </Text>
            }
            extra={
              incorrectEmail ? (
                <Text level={1} className='errorMessage'>
                  Die angegebenen E-Mail-Adresse ist im System nicht bekannt. Bitte prüfen Sie Ihre
                  Eingabe
                </Text>
              ) : (
                ''
              )
            }
            name='username'
            rules={[{ required: true, message: 'Geben Sie Ihre E-Mail-Adresse ein' }]}
            validateStatus={incorrectEmail ? 'error' : ''}
          >
            <Input placeholder='Geben Sie Ihre E-Mail-Adresse ein' />
          </Form.Item>
          <Form.Item>
            <Button
              type='primary'
              htmlType='submit'
              loading={loginLoading}
              style={{ width: '100%' }}
            >
              Absenden
            </Button>
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ResetPassword;
