import { Select as AntdSelect, SelectProps, ConfigProvider } from 'antd';
import { primary40 } from '../../colors';
import deDe from 'antd/locale/de_DE';

const Select = ({ ...props }: SelectProps) => {
  return (
    <ConfigProvider
      locale={deDe}
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <AntdSelect {...props} size='large' />
    </ConfigProvider>
  );
};

export default Select;
