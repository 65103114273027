import { Text } from '../../../../../../UI/Typography';
import Modal from '../../../../../../UI/Modal';
import React, { useCallback, useState } from 'react';
import Checkbox from '../../../../../../UI/Checkbox';
import AttentionIcon from '../../../../../../UI/Icons/AttentionIcon';
import styles from './styles.module.css';
import { useDispatch } from 'react-redux';
import { startSchoolYearAction } from 'store/actions/school.actions';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import { Row } from 'antd';

type editYearsModalTypes = {
  startSchoolYearVisible: boolean;
  toggleStartSchoolYearModal: (arg1: boolean) => void;
  hasPreviousSchoolYear: boolean;
  currentYear: string;
  nextYear: string;
};

const EditYearsModal = ({
  startSchoolYearVisible,
  toggleStartSchoolYearModal,
  hasPreviousSchoolYear,
  currentYear,
  nextYear,
}: editYearsModalTypes) => {
  const [startSchoolYearLoading, setStartSchoolYearLoading] = useState(false);
  const [consentGiven, setConsentGiven] = useState(false);
  const dispatch = useDispatch();

  const handleChangeConsent = useCallback((value: boolean) => {
    setConsentGiven(value);
  }, []);

  const handleResetModal = useCallback(() => {
    toggleStartSchoolYearModal(false);
    setStartSchoolYearLoading(false);
    setConsentGiven(false);
  }, [toggleStartSchoolYearModal]);

  const handleStartSchoolYear = useCallback(() => {
    setStartSchoolYearLoading(true);
    dispatch(startSchoolYearAction({ onSuccess: () => handleResetModal() }));
  }, [dispatch, handleResetModal]);

  return (
    <Modal
      title=''
      confirmLoading={startSchoolYearLoading}
      closable
      okText='Schuljahr starten'
      cancelText='Abbrechen'
      centered
      open={startSchoolYearVisible}
      onOk={() => {
        handleStartSchoolYear();
      }}
      onCancel={() => {
        handleResetModal();
      }}
      okDisabled={!consentGiven}
    >
      <div className={styles.confirmIconWrapper}>
        <div>
          <AttentionIcon />
        </div>
        {hasPreviousSchoolYear ? (
          <div>
            <div style={{ marginBottom: '24px' }}>
              <Text level={1}>
                {` Wenn sie das neue Schuljahr ${nextYear} starten, wird das aktuelle Schuljahr ${currentYear} beendet. Im alten Schuljahr ${currentYear} können dann keine weiteren Beobachtungen oder
                Zwischenbewertungen dokumentiert werden und es können keine Änderungen an den
                Zuweisungen fachlicher Inhalte zu den Schülern mehr vorgenommen werden. Diese
                Tätigkeiten können dann nur noch für das neue Schuljahr ${nextYear} ausgeführt werden.`}
              </Text>
            </div>
            <div style={{ marginBottom: '24px' }}>
              <Text level={1} bold>
                {`Sind Sie sicher, dass Sie ${currentYear} beenden und ${nextYear} starten wollen?`}
              </Text>
            </div>
            <Row align='middle'>
              <Checkbox
                onChange={(e: CheckboxChangeEvent) => handleChangeConsent(e.target.checked)}
                checked={consentGiven}
              >
                {`Ich habe die Auswirkungen des Schuljahreswechsels verstanden und bin mir sicher,
                  dass ich ${nextYear} starten möchte.`}
              </Checkbox>
            </Row>
          </div>
        ) : (
          <div>
            <div style={{ marginBottom: '24px' }}>
              <Text level={1}>{`Sie sind im Begriff das Schuljahr ${nextYear} zu starten.`}</Text>
            </div>
            <div style={{ marginBottom: '24px' }}>
              <Text level={1} bold>
                {`Sind Sie sicher dass Sie ${nextYear} starten wollen?`}
              </Text>
            </div>
            <Row align='middle'>
              <Checkbox
                onChange={(e: CheckboxChangeEvent) => handleChangeConsent(e.target.checked)}
                checked={consentGiven}
              >
                {`Ich habe die Auswirkungen verstanden und bin mir sicher, dass ich ${nextYear} starten
                möchte.`}
              </Checkbox>
            </Row>
          </div>
        )}
      </div>
    </Modal>
  );
};

export default EditYearsModal;
