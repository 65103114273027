import ObservationsModal from 'pages/Report/__partials/ReportForm/ObservationsModal';
import { evaluateSubItemAction } from 'store/actions/report.actions';
import { getReportSelector } from 'store/selectors/report.selectors';
import { Col, Collapse, RadioChangeEvent, Row, Tooltip } from 'antd';
import React, { ChangeEvent, useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import ArrowBackIcon from 'UI/Icons/ArrowBackIcon';
import CollapseHeader from './CollapseHeader';
import { useParams } from 'react-router-dom';
import RadioGroup from 'UI/RadioGroup';
import { Text } from 'UI/Typography';
import cc from 'classcat';
import { primary5 } from '../../../../colors';
import styles from './styles.module.css';
import PlusIcon from '../../../../UI/Icons/PlusIcon';
import SubjectSummaryModal from 'pages/Report/__partials/ReportForm/SubjectSummaryModal';
import EditIcon from '../../../../UI/Icons/EditIcon';
import PurpleCheckIcon from '../../../../UI/Icons/PurpleCheck.Icon';
import Pagination from '../../../../UI/Pagination';
import InfoIcon from '../../../../UI/Icons/InfoIcon';
import RemoveCircleIcon from '../../../../UI/Icons/RemoveCircle';
import Spinner from '../../../../UI/Spinner';
import SpinContainer from 'components/SpinContainer';

const { Panel } = Collapse;

const ReportForm = ({
  handleChangeEvaluationLoading,
  compareView,
  currentPage,
  setCurrentPage,
  reportState,
  activeReportId,
  evaluationLoading,
}: {
  handleChangeEvaluationLoading: (arg1: boolean) => void;
  compareView: boolean;
  currentPage: number;
  setCurrentPage: (arg1: number) => void;
  reportState: 'active' | 'deactivated' | 'not_active';
  activeReportId: number;
  evaluationLoading: boolean;
}) => {
  const [observationModalVisible, setObservationModalVisible] = useState(false);
  const [subjectId, setSubjectId] = useState<number | null>(null);
  const [itemId, setItemId] = useState<number | null>(null);
  const [subItemId, setSubItemId] = useState<number | null>(null);
  const [subjectName, setSubjectName] = useState('');
  const [observationType, setObservationType] = useState<
    'student' | 'subject' | 'item' | 'subItem' | null
  >(null);
  const [breadCrumbValue, setBreadCrumbValue] = useState('');
  const [subjectSummaryVisible, setSubjectSummaryVisible] = useState(false);
  const [groupText, setGroupText] = useState<string[]>([]);
  const [personalText, setSummaryText] = useState('');
  const dispatch = useDispatch();
  const { studentId } = useParams();
  const report = useSelector(getReportSelector);

  const toggleObservationModal = useCallback((value: boolean) => {
    if (!value) {
      setSubjectId(null);
      setSubItemId(null);
      setItemId(null);
      setSubjectId(null);
      setBreadCrumbValue('');
    }
    setObservationModalVisible(value);
  }, []);

  const handleViewStudentObservation = useCallback(
    (breadCrumb: string) => {
      toggleObservationModal(true);
      setBreadCrumbValue(breadCrumb);
      setObservationType('student');
    },
    [toggleObservationModal],
  );

  const handleViewSubjectObservation = useCallback(
    (
      e: ChangeEvent<HTMLDivElement>,
      subjectId: number | null,
      breadCrumb: string,
      name: string,
    ) => {
      e.stopPropagation();
      setBreadCrumbValue(breadCrumb);
      toggleObservationModal(true);
      setObservationType('subject');
      setSubjectId(subjectId);
      setSubjectName(name);
    },
    [toggleObservationModal],
  );

  const handleViewItemObservation = useCallback(
    (
      e: ChangeEvent<HTMLDivElement>,
      subjectId: number | null,
      itemId: number | null,
      breadCrumb: string,
      itemName: string,
    ) => {
      e.stopPropagation();
      setBreadCrumbValue(breadCrumb);
      toggleObservationModal(true);
      setObservationType('item');
      setSubjectId(subjectId);
      setItemId(itemId);
      setSubjectName(itemName);
    },
    [toggleObservationModal],
  );

  const handleViewSubItemObservation = useCallback(
    (
      subjectId: number | null,
      itemId: number | null,
      subItemId: number | null,
      breadCrumb: string,
      subItemName: string,
    ) => {
      setBreadCrumbValue(breadCrumb);
      toggleObservationModal(true);
      setObservationType('subItem');
      setSubjectId(subjectId);
      setItemId(itemId);
      setSubItemId(subItemId);
      setSubjectName(subItemName);
    },
    [toggleObservationModal],
  );

  const handleEvaluateSubItem = useCallback(
    (value: number, subItemId: number) => {
      handleChangeEvaluationLoading(true);
      dispatch(
        evaluateSubItemAction({
          subItemId,
          assessmentScaleValueId: Number(value),
          studentId: Number(studentId),
          onSuccess: () => handleChangeEvaluationLoading(false),
          page: currentPage - 1,
          izelId: activeReportId,
        }),
      );
    },
    [activeReportId, currentPage, dispatch, handleChangeEvaluationLoading, studentId],
  );

  const toggleSubjectSummary = useCallback((value: boolean) => {
    setSubjectSummaryVisible(value);
  }, []);

  return (
    <div>
      <div
        className={styles.personalReview}
        onClick={() => handleViewStudentObservation(`${report.surname}, ${report.name}`)}
      >
        <Text level={2} bold className='primary-40'>
          Schüler/Schülerinnen persönliche Beobachtungen einblenden
        </Text>
        <span>
          <ArrowBackIcon className='primary-40' />
        </span>
      </div>
      <div>
        <Collapse className={styles.collapseWrapper}>
          {report.subjects?.map((subject) => {
            return (
              <Panel
                className={cc([
                  {
                    [compareView ? styles.outlineCompare : styles.collapseOutline]: compareView
                      ? !subject.selfEvaluationMatches
                      : !subject.filled,
                  },
                ])}
                key={subject.id}
                header={
                  <CollapseHeader
                    description={subject.description}
                    name={subject.name}
                    handleViewSubjectObservation={(e: ChangeEvent<HTMLDivElement>) =>
                      handleViewSubjectObservation(
                        e,
                        subject.id,
                        `${report.surname}, ${report.name} / ${subject.name}`,
                        subject.name,
                      )
                    }
                  />
                }
              >
                <div
                  className={cc([
                    styles.subjectSummary,
                    {
                      [styles.disabledSummary]: true,
                    },
                  ])}
                  onClick={() => {
                    if (reportState === 'active') {
                      setGroupText(subject.groupTexts);
                      setSummaryText(subject.personalText);
                      setSubjectId(subject.id);
                      toggleSubjectSummary(true);
                    }
                  }}
                >
                  <span className={styles.plusWrapper}>
                    {subject.personalText ? (
                      <EditIcon
                        className={`${reportState === 'deactivated' ? '' : 'primary-40'} ${
                          styles.iconFontSize
                        }`}
                      />
                    ) : (
                      <PlusIcon
                        className={`${reportState === 'deactivated' ? '' : 'primary-40'} ${
                          styles.iconFontSize
                        }`}
                      />
                    )}
                  </span>
                  <Text
                    level={2}
                    bold
                    className={reportState === 'deactivated' ? 'secondary-30' : 'primary-40'}
                  >
                    Entwicklungsbericht
                  </Text>
                </div>
                <Collapse
                  bordered={false}
                  ghost
                  className={styles.innerCollapse}
                  destroyInactivePanel
                >
                  {subject.items?.map((item) => {
                    return (
                      <Panel
                        className={cc([
                          {
                            [compareView ? styles.compareNotFilled : styles.notFilledCollapse]: item
                              ?.subItems?.length
                              ? compareView
                                ? !item.selfEvaluationMatches
                                : !item.filled
                              : false,
                          },
                        ])}
                        key={item.id}
                        showArrow={!!item?.subItems?.length}
                        header={
                          <CollapseHeader
                            description={item.alias}
                            handleViewSubjectObservation={(e: ChangeEvent<HTMLDivElement>) =>
                              handleViewItemObservation(
                                e,
                                subject.id,
                                item.id,
                                `${report.surname}, ${report.name} / ${subject.name} / ${item.name}`,
                                item.name,
                              )
                            }
                            name={item.name}
                          />
                        }
                      >
                        {item?.subItems?.length ? (
                          <Row style={{ backgroundColor: primary5 }}>
                            <Col span={12} offset={12}>
                              <div
                                style={{
                                  display: 'flex',
                                  textAlign: 'right',
                                  justifyContent: 'space-between',
                                  padding: '17px 0',
                                }}
                              >
                                {item.assessmentScaleValues?.map((value) => {
                                  return (
                                    <div
                                      style={{ width: '25%', textAlign: 'center' }}
                                      key={value.id}
                                    >
                                      {value.scaleValue}
                                    </div>
                                  );
                                })}
                              </div>
                            </Col>
                          </Row>
                        ) : null}
                        {item.subItems?.map((subItem) => {
                          const selfEvaluationMatches =
                            subItem?.selfEvaluatedScaleValueId === subItem.evaluatedScaleValueId &&
                            subItem.evaluatedScaleValueId &&
                            subItem?.selfEvaluatedScaleValueId;
                          const evalScaleValuesInitial = item.assessmentScaleValues.map((scale) => {
                            return {
                              key: scale.id,
                              label:
                                scale.id === subItem?.selfEvaluatedScaleValueId && compareView ? (
                                  <PurpleCheckIcon />
                                ) : (
                                  ''
                                ),
                            };
                          });
                          const evalScaleValues = evalScaleValuesInitial
                            .filter((val: { key: number }) => val.key !== 12)
                            .filter((v: { key: number }) => v.key !== 11);
                          return (
                            <Row
                              key={subItem.id}
                              className={cc([
                                styles.openReviewBottomBorder,
                                {
                                  [compareView
                                    ? styles.compareNotFilledSubItem
                                    : styles.notFilledSubItem]: compareView
                                    ? !selfEvaluationMatches
                                    : !subItem.evaluatedScaleValueId,
                                },
                              ])}
                              align='middle'
                            >
                              <Col span={12} style={{ paddingLeft: '34px' }}>
                                <div className={styles.titleWrapper}>
                                  <Text level={2} bold>
                                    {subItem.name}
                                  </Text>
                                  {subItem.description && (
                                    <Tooltip title={subItem.description}>
                                      <InfoIcon />
                                    </Tooltip>
                                  )}
                                </div>
                                <div
                                  className={styles.openReviewMargin}
                                  onClick={() =>
                                    handleViewSubItemObservation(
                                      subject.id,
                                      item.id,
                                      subItem.id,
                                      `${report.surname}, ${report.name} / ${subject.name} / ${item.name} / ${subItem.name}`,
                                      subItem.name,
                                    )
                                  }
                                >
                                  <Text level={2} bold className='primary-40'>
                                    Beobachtungen einblenden
                                  </Text>
                                  <span>
                                    <ArrowBackIcon className='primary-40' />
                                  </span>
                                </div>
                              </Col>
                              <Col span={12}>
                                <div style={{ display: 'flex' }}>
                                  <RadioGroup
                                    disabled={reportState === 'deactivated'}
                                    value={subItem.evaluatedScaleValueId}
                                    onChange={(e: RadioChangeEvent) =>
                                      handleEvaluateSubItem(e.target.value, subItem.id)
                                    }
                                    className={styles.radioGroup}
                                    style={{
                                      width: '100%',
                                      display: 'flex',
                                      justifyContent: 'space-between',
                                    }}
                                    groupOptions={evalScaleValues}
                                  />
                                  {evalScaleValuesInitial.length !== 2 &&
                                    (reportState !== 'deactivated' ? (
                                      <Tooltip title='Bewertung entfernen'>
                                        <span
                                          className={styles.removeEval}
                                          onClick={() => {
                                            handleEvaluateSubItem(
                                              item.assessmentScale.id === 1 ? 11 : 12,
                                              subItem.id,
                                            );
                                          }}
                                        >
                                          <RemoveCircleIcon
                                            style={
                                              subItem?.evaluatedScaleValueId === 12 ||
                                              subItem?.evaluatedScaleValueId === 11
                                                ? { strokeWidth: '1px', stroke: 'black' }
                                                : {}
                                            }
                                          />
                                        </span>
                                      </Tooltip>
                                    ) : (
                                      <span className={styles.notActive}>
                                        <RemoveCircleIcon
                                          style={
                                            subItem?.evaluatedScaleValueId === 12 ||
                                            subItem?.evaluatedScaleValueId === 11
                                              ? { strokeWidth: '1px', stroke: 'black' }
                                              : {}
                                          }
                                        />
                                      </span>
                                    ))}
                                </div>
                              </Col>
                            </Row>
                          );
                        })}
                      </Panel>
                    );
                  })}
                </Collapse>
              </Panel>
            );
          })}
        </Collapse>
        <div className={styles.paginationWrapper}>
          <Pagination
            pageSize={25}
            current={currentPage}
            onChange={(page: number) => setCurrentPage(page)}
            total={report?.count}
          />
        </div>
      </div>
      <ObservationsModal
        subjectName={subjectName}
        activeReportId={activeReportId}
        breadCrumbValue={breadCrumbValue}
        itemId={Number(itemId)}
        observationModalVisible={observationModalVisible}
        observationType={observationType}
        subjectId={Number(subjectId)}
        subItemId={Number(subItemId)}
        toggleObservationModal={toggleObservationModal}
      />
      <SubjectSummaryModal
        activeReportId={activeReportId}
        page={currentPage}
        subjectId={subjectId}
        personalText={personalText}
        groupText={groupText}
        subjectSummaryVisible={subjectSummaryVisible}
        toggleSubjectSummary={toggleSubjectSummary}
      />
    </div>
  );
};
export default ReportForm;
