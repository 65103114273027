import { ConfigProvider, Input as AntdInput, InputProps } from 'antd';
import cc from 'classcat';
import styles from './styles.module.css';
import AntdSearch, { SearchProps } from 'antd/es/input/Search';
import AntdTextArea, { TextAreaProps } from 'antd/es/input/TextArea';
import AntdPassword, { PasswordProps } from 'antd/es/input/Password';
import { primary40 } from '../../colors';

type errorTypes = {
  error?: boolean;
};

const Search = ({ className, ...props }: SearchProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
          borderRadius: 8,
        },
      }}
    >
      <AntdSearch size='large' className={cc([className, styles.search])} {...props} />
    </ConfigProvider>
  );
};

const TextArea = ({ className, ...props }: TextAreaProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
          borderRadius: 8,
        },
      }}
    >
      <AntdTextArea className={cc([className])} {...props} />
    </ConfigProvider>
  );
};

const Password = ({ className, ...props }: PasswordProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
          borderRadius: 8,
        },
      }}
    >
      <AntdPassword size='large' className={cc([className])} {...props} />
    </ConfigProvider>
  );
};

const Input = ({ className, error, ...props }: InputProps & errorTypes) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
          borderRadius: 8,
        },
      }}
    >
      <AntdInput
        size='large'
        className={cc([className, styles.input])}
        {...(error ? { status: 'error' } : {})}
        {...props}
      />
    </ConfigProvider>
  );
};

Input.Search = Search;
Input.TextArea = TextArea;
Input.Password = Password;

export default Input;
