import { getTeachersSelector } from 'store/selectors/teachers.selectors';
import React, { useState, useCallback, useEffect, useMemo } from 'react';
import {
  deactivateTeacherAction,
  getTeacherActions,
  reactivateTeacherAction,
} from 'store/actions/teachers.actions';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom';
import { Text, Title } from 'UI/Typography';
import Spinner from 'UI/Spinner';
import { Col, Dropdown, Row } from 'antd';
import Button from 'UI/Button';
import Table from 'UI/Table';
import AddTeacherModal from './__partials/AddTeacherModal';
import styles from './styles.module.css';
import ActionsIcon from '../../UI/Icons/ActionsIcon';
import Modal from '../../UI/Modal';
import Checkbox from '../../UI/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Pagination from '../../UI/Pagination';
import { getUserRole } from 'store/selectors/users.selectors';
import CheckOutlinedIcon from '../../UI/Icons/CheckOutLinedIcon';

const { Confirm } = Modal;

const labelMap = {
  SCHOOL_ADMIN: 'Admin',
  TEACHER: 'Lehrkraft',
};

const columns = (
  handleSelectTeacher: (arg1: number) => void,
  handleSelectToReactivate: (arg1: number) => void,
  handleEditVisible: (arg1: number | null) => void,
  toggleAddTeacherModal: (arg1: boolean) => void,
) => [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName',
    render: (record: string) => (
      <Text level={1} bold>
        {record}
      </Text>
    ),
  },
  {
    title: 'E-Mail',
    dataIndex: 'email',
    key: 'email',
  },
  {
    title: 'Rolle',
    dataIndex: 'roles',
    key: 'roles',
    render: (record: string[]) =>
      record.map((value: 'SCHOOL_ADMIN' | 'TEACHER') => (
        <span key={value} className={value === 'TEACHER' ? styles.teacherChips : styles.roleChips}>
          {labelMap[value]}
        </span>
      )),
  },
  {
    title: (
      <Text level={1}>
        Zustimmung <br /> zur Datenverarbeitung
      </Text>
    ),
    dataIndex: 'consent',
    key: 'consent',
    render: (record: boolean) => (record ? <CheckOutlinedIcon /> : ''),
  },
  {
    title: '',
    dataIndex: 'id',
    key: 'id',
    render: (record: number, i: { active: boolean }) => {
      return (
        <Dropdown
          overlayClassName={styles.dropdownContent}
          trigger={['click']}
          placement='bottomRight'
          menu={{
            items: [
              {
                key: '1',
                label: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      handleEditVisible(record);
                      toggleAddTeacherModal(true);
                    }}
                  >
                    Ändern
                  </span>
                ),
              },
              {
                key: '2',
                label: (
                  <span
                    onClick={(e) => {
                      e.stopPropagation();
                      if (i.active) {
                        handleSelectTeacher(record);
                      } else handleSelectToReactivate(record);
                    }}
                  >
                    {i.active ? ' Deaktivieren' : 'Reaktivieren'}
                  </span>
                ),
              },
            ],
          }}
        >
          <ActionsIcon />
        </Dropdown>
      );
    },
    width: 50,
  },
];

const Teachers = () => {
  const [teacherModalVisible, setTeacherModalVisible] = useState(false);
  const [selectedTeacher, setSelectedTeacher] = useState<number | null>(null);
  const [deactivateTeacherLoading, setDeactivateTeacherLoading] = useState(false);
  const [showDeactivatedTeachers, setShowDeactivatedTeachers] = useState(false);
  const [editableTeacherId, setEditVisible] = useState<number | null>(null);
  const [getTeacherLoading, setGetTeacherLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const teachers = useSelector(getTeachersSelector) as any;
  const userRole = useSelector(getUserRole);
  const teachersTableData = useMemo(() => {
    return teachers?.teachers?.map((teacher: { name: string; surname: string }) => {
      return { ...teacher, fullName: teacher.surname + ', ' + teacher.name };
    });
  }, [teachers]);

  const teacherName = useMemo(
    () =>
      teachersTableData?.find((teacher: { id: number }) => teacher.id === selectedTeacher)
        ?.fullName,
    [selectedTeacher, teachersTableData],
  );

  const editableTeacher = useMemo(() => {
    return teachers?.teachers?.find((teacher: any) => teacher.id === editableTeacherId);
  }, [editableTeacherId, teachers]);

  const toggleAddTeacherModal = useCallback((value: boolean) => {
    setTeacherModalVisible(value);
  }, []);

  const handleSelectedTeacher = useCallback((value: number) => {
    setSelectedTeacher(value);
  }, []);

  const handleDeactivateTeacher = useCallback(() => {
    setDeactivateTeacherLoading(true);
    dispatch(
      deactivateTeacherAction({
        id: Number(selectedTeacher),
        active: showDeactivatedTeachers,
        page: currentPage - 1,
        size: 10,
        onSuccess: () => {
          setSelectedTeacher(null);
          setDeactivateTeacherLoading(false);
        },
      }),
    );
  }, [currentPage, dispatch, selectedTeacher, showDeactivatedTeachers]);

  const handleSelectToReactivate = useCallback(
    (value: number | null) => {
      dispatch(
        reactivateTeacherAction({
          id: Number(value),
          active: showDeactivatedTeachers,
          page: currentPage - 1,
          size: 10,
          onSuccess: () => null,
        }),
      );
    },
    [currentPage, dispatch, showDeactivatedTeachers],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      setCurrentPage(page);
      dispatch(
        getTeacherActions({
          active: showDeactivatedTeachers,
          page: page - 1,
          size: 10,
        }),
      );
    },
    [dispatch, showDeactivatedTeachers],
  );

  const handleEditVisible = useCallback((value: number | null) => {
    setEditVisible(value);
  }, []);

  useEffect(() => {
    setGetTeacherLoading(true);
    dispatch(
      getTeacherActions({
        active: showDeactivatedTeachers,
        page: 0,
        size: 10,
        onSuccess: () => setGetTeacherLoading(false),
      }),
    );
  }, [dispatch, showDeactivatedTeachers]);

  return (
    <div className={styles.teachersWrapper}>
      <div className={styles.titleWrapper}>
        <Title level={1}>Lehrkraft / Admins</Title>
      </div>
      <Row className={styles.dashboardWrapper} justify='space-between'>
        <Col>
          <Row gutter={16}>
            <Col>
              {userRole === 'SCHOOL_ADMIN' && (
                <div className={styles.checkboxWrapper}>
                  <Checkbox
                    checked={showDeactivatedTeachers}
                    onChange={(e: CheckboxChangeEvent) => {
                      setCurrentPage(1);
                      setShowDeactivatedTeachers(e.target.checked);
                    }}
                  >
                    Deaktivierte Lehrkraft / Admins anzeigen
                  </Checkbox>
                </div>
              )}
            </Col>
          </Row>
        </Col>
        <Col>
          <Button
            type='primary'
            icon={<PlusOutlined />}
            onClick={() => toggleAddTeacherModal(true)}
          >
            Person hinzufügen
          </Button>
        </Col>
      </Row>

      <div className={styles.tableWrapper}>
        <Table
          rowClassName={(record: { active: boolean }) => (!record.active ? 'disabled-row' : '')}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: getTeacherLoading }}
          pagination={false}
          dataSource={teachersTableData}
          className={styles.teachersTable}
          columns={columns(
            handleSelectedTeacher,
            handleSelectToReactivate,
            handleEditVisible,
            toggleAddTeacherModal,
          )}
          onRow={(record) => {
            return {
              onClick: () => {
                navigate(`/teachers/${record.id}`);
              },
            };
          }}
        />
      </div>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={teachers?.count}
        />
      </div>
      <AddTeacherModal
        showDeactivatedTeachers={showDeactivatedTeachers}
        currentPage={currentPage}
        editableTeacher={editableTeacher}
        handleEditVisible={handleEditVisible}
        teacherModalVisible={teacherModalVisible}
        toggleAddTeacherModal={toggleAddTeacherModal}
      />
      <Confirm
        confirmLoading={deactivateTeacherLoading}
        closable
        okText='Deaktivieren'
        cancelText='Abbrechen'
        centered
        message={`Wenn sie die Lehrkraft ${teacherName} deaktivieren, hat sie keinen Zugriff mehr auf das System und kann im System keinen Gruppen mehr als Lehrkraft hinzugefügt werden und wird aus den Gruppen entfernt, denen sie derzeit zugewiesen ist. Sind Sie sicher, dass Sie die Lehrkraft deaktivieren wollen?`}
        open={!!selectedTeacher}
        onOk={(e) => {
          e.stopPropagation();
          handleDeactivateTeacher();
        }}
        onCancel={() => {
          setSelectedTeacher(null);
          setDeactivateTeacherLoading(false);
        }}
      />
    </div>
  );
};

export default Teachers;
