import {
  deactivateStudentActions,
  getStudentsAction,
  reactivateStudentAction,
} from 'store/actions/students.actions';
import { getStudentsCountSelector, getStudentsSelector } from 'store/selectors/students.selectors';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { getAllGroupsSelector } from 'store/selectors/groups.selectors';
import AddStudentModal from 'pages/Students/__partials/AddStudentModal';
import { getUserRole } from 'store/selectors/users.selectors';
import { getAllGroupsAction } from 'store/actions/group.actions';
import { useDispatch, useSelector } from 'react-redux';
import { Col, Dropdown, Row, SelectProps } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import ActionsIcon from 'UI/Icons/ActionsIcon';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import FilterIcon from 'UI/Icons/FilterIcon';
import { Text, Title } from 'UI/Typography';
import Spinner from 'UI/Spinner';
import Select from 'UI/Select';
import Button from 'UI/Button';
import Input from 'UI/Input';
import Table from 'UI/Table';
import styles from './styles.module.css';
import Pagination from '../../UI/Pagination';
import Modal from '../../UI/Modal';
import { CheckboxChangeEvent } from 'antd/es/checkbox';
import Checkbox from '../../UI/Checkbox';
import PlusCircle from '../../UI/Icons/PlusCircle';
import EditIcon from '../../UI/Icons/EditIcon';
import { gradesRangeSelector } from 'store/selectors/schools.selectors';

const { Search } = Input;

const columns = (
  handleNavigate: (arg1: number, arg2: string) => void,
  userRole: string | undefined,
  handleSelectStudent: (arg1: number) => void,
  handleReactivateStudent: (arg1: number) => void,
) => {
  return [
    {
      title: 'Name, Vorname',
      dataIndex: 'fullName',
      key: 'fullName',
      render: (record: string) => (
        <Text level={1} bold>
          {record}
        </Text>
      ),
      sorter: true,
    },
    {
      title: 'Geboren',
      dataIndex: 'birthDate',
      key: 'birthDate',
      onCell: () => {
        return {
          onClick: (event: { stopPropagation: () => void }) => {
            event.stopPropagation();
          },
        };
      },
    },
    {
      title: 'Zugeordnete Gruppen',
      dataIndex: 'coreGroup',
      key: 'coreGroup',
      render: (record: { name: string }) => record?.name,
      onCell: () => {
        return {
          onClick: (event: { stopPropagation: () => void }) => {
            event.stopPropagation();
          },
        };
      },
    },
    {
      title: 'Jahrgang',
      dataIndex: 'schoolYear',
      key: 'schoolYear',
      render: (record: string, i: { leftSchool: boolean }) =>
        i.leftSchool ? `Keine (${record})` : record,
      onCell: () => {
        return {
          onClick: (event: { stopPropagation: () => void }) => {
            event.stopPropagation();
          },
        };
      },
    },
    {
      ...(userRole === 'TEACHER'
        ? {
            title: (
              <div style={{ textAlign: 'center' }}>
                <Text level={1}>
                  Schüler/innen <br /> Dokumentation
                </Text>
              </div>
            ),
            dataIndex: 'id',
            key: 'id',
            render: (record: number) => (
              <div
                style={{ textAlign: 'center' }}
                className={styles.navIcon}
                onClick={() => handleNavigate(record, 'interim-report')}
              >
                <PlusCircle />
              </div>
            ),
            onCell: () => {
              return {
                onClick: (event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                },
              };
            },
          }
        : {}),
    },
    {
      title: (
        <div style={{ textAlign: 'center' }}>
          <Text level={1}>IzEL</Text>
        </div>
      ),
      dataIndex: 'id',
      key: 'id',
      render: (record: number, i: { active: boolean }) => (
        <div
          style={{ textAlign: 'center', fontSize: '24px' }}
          className={styles.navIcon}
          onClick={() => {
            if (i.active) {
              handleNavigate(record, 'report');
            }
          }}
        >
          <EditIcon />
        </div>
      ),
      onCell: () => {
        return {
          onClick: (event: { stopPropagation: () => void }) => {
            event.stopPropagation();
          },
        };
      },
    },
    {
      ...(userRole === 'SCHOOL_ADMIN'
        ? {
            title: '',
            dataIndex: 'id',
            key: 'id',
            onCell: () => {
              return {
                onClick: (event: { stopPropagation: () => void }) => {
                  event.stopPropagation();
                },
              };
            },
            render: (record: number, i: { active: boolean }) => {
              return (
                <Dropdown
                  trigger={['click']}
                  overlayClassName={styles.dropdownContent}
                  placement='bottomRight'
                  menu={{
                    items: [
                      {
                        key: '1',
                        label: (
                          <span
                            onClick={(e) => {
                              e.stopPropagation();
                              if (i.active) {
                                handleSelectStudent(record);
                              } else {
                                handleReactivateStudent(record);
                              }
                            }}
                          >
                            {i.active ? ' Deaktivieren' : 'Reaktivieren'}
                          </span>
                        ),
                      },
                    ],
                  }}
                >
                  <ActionsIcon />
                </Dropdown>
              );
            },
            width: 50,
          }
        : {}),
    },
  ];
};

const Students = () => {
  const [filtersEnabled, setFiltersEnabled] = useState(false);
  const [selectedSchoolYears, setSelectedSchoolYears] = useState<Array<number>>([]);
  const [selectedGroups, setSelectedGroups] = useState<Array<number>>([]);
  const [addStudentModalVisible, setAddStudentModalVisible] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [searchValue, setSearchValue] = useState('');
  const [selectedStudent, setSelectedStudent] = useState<number | null>(null);
  const [deactivateStudentLoading, setDeactivateStudentLoading] = useState(false);
  const [showDeactivatedStudentsBox, setShowDeactivatedStudents] = useState(false);
  const [sort, setSort] = useState<string | undefined>(undefined);
  const [getStudentsLoading, setGetStudentLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const students = useSelector(getStudentsSelector);
  const studentsCount = useSelector(getStudentsCountSelector);
  const allGroups = useSelector(getAllGroupsSelector);
  const userRole = useSelector(getUserRole);
  const schoolYears = useSelector(gradesRangeSelector) as SelectProps['options'];
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();
  const urlPage = searchParams.get('page');
  const urlSearch = searchParams.get('search');
  const urlShowDeactivated = searchParams.get('showDeactivated');
  const urlShowFilters = searchParams.get('showFilters');
  const urlGroupIds = searchParams.get('groupIds');
  const urlSchoolYears = searchParams.get('schoolYears');

  const showDeactivatedStudents = useMemo(
    () => (userRole === 'SCHOOL_ADMIN' ? showDeactivatedStudentsBox : false),
    [showDeactivatedStudentsBox, userRole],
  );

  const studentsTable = useMemo(() => {
    return students?.map((student) => {
      return { ...student, fullName: student.surname + ', ' + student.name };
    });
  }, [students]);

  const handleEnableFilters = useCallback(() => {
    searchParams.set('showFilters', String(!filtersEnabled));
    setSearchParams(searchParams);
  }, [filtersEnabled, searchParams, setSearchParams]);

  const handleChangeSchoolYears = useCallback(
    (years: number[]) => {
      searchParams.set('schoolYears', years.join(','));
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleChangeGroups = useCallback(
    (groups: number[]) => {
      searchParams.set('groupIds', groups.join(','));
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const toggleAddStudentModal = useCallback((value: boolean) => {
    setAddStudentModalVisible(value);
  }, []);

  const handleSelectStudent = useCallback((value: number) => {
    setSelectedStudent(value);
  }, []);

  const handleChangeSearchValue = useCallback(
    (value: string) => {
      searchParams.set('search', value);
      searchParams.set('page', '1');
      setSearchParams(searchParams);
    },
    [searchParams, setSearchParams],
  );

  const handleDeactivateStudent = useCallback(() => {
    dispatch(
      deactivateStudentActions({
        id: Number(selectedStudent),
        groupIds: selectedGroups,
        schoolYears: selectedSchoolYears,
        active: !showDeactivatedStudents,
        page: currentPage - 1,
        size: 10,
        name: searchValue,
        onSuccess: () => {
          setSelectedStudent(null);
          setDeactivateStudentLoading(false);
        },
      }),
    );
  }, [
    currentPage,
    dispatch,
    searchValue,
    selectedGroups,
    selectedSchoolYears,
    selectedStudent,
    showDeactivatedStudents,
  ]);

  const handleReactivateStudent = useCallback(
    (value: number) => {
      dispatch(
        reactivateStudentAction({
          id: Number(value),
          groupIds: selectedGroups,
          schoolYears: selectedSchoolYears,
          active: !showDeactivatedStudents,
          page: currentPage - 1,
          size: 10,
          name: searchValue,
          onSuccess: () => null,
        }),
      );
    },
    [
      currentPage,
      dispatch,
      searchValue,
      selectedGroups,
      selectedSchoolYears,
      showDeactivatedStudents,
    ],
  );

  const handleNavigate = useCallback(
    (value: number, path: string) => {
      navigate({
        pathname: `/students/${value}/${path}`,
        search: location.search,
      });
    },
    [location.search, navigate],
  );

  const handlePageChange = useCallback(
    (page: number) => {
      searchParams.set('page', String(page));
      setSearchParams(searchParams);
      dispatch(
        getStudentsAction({
          groupIds: selectedGroups,
          schoolYears: selectedSchoolYears,
          active: !showDeactivatedStudents,
          page: page - 1,
          size: 10,
          sort,
          name: searchValue,
        }),
      );
    },
    [
      dispatch,
      searchParams,
      searchValue,
      selectedGroups,
      selectedSchoolYears,
      setSearchParams,
      showDeactivatedStudents,
      sort,
    ],
  );

  const handleSort = useCallback(
    (value: { order: string }) => {
      setSort(`sort=surname,${value.order === 'ascend' ? 'asc' : 'desc'}`);
      dispatch(
        getStudentsAction({
          groupIds: selectedGroups,
          schoolYears: selectedSchoolYears,
          active: !showDeactivatedStudents,
          page: 0,
          size: 10,
          sort: `sort=surname,${value.order === 'ascend' ? 'asc' : 'desc'}`,
          name: searchValue,
        }),
      );
    },
    [dispatch, searchValue, selectedGroups, selectedSchoolYears, showDeactivatedStudents],
  );

  useEffect(() => {
    setGetStudentLoading(true);
    dispatch(
      getStudentsAction({
        groupIds: selectedGroups,
        schoolYears: selectedSchoolYears,
        active: !showDeactivatedStudents,
        page: currentPage - 1,
        size: 10,
        name: searchValue,
        onSuccess: () => setGetStudentLoading(false),
      }),
    );
    dispatch(getAllGroupsAction);
  }, [
    currentPage,
    dispatch,
    searchValue,
    selectedGroups,
    selectedSchoolYears,
    showDeactivatedStudents,
  ]);

  useEffect(() => {
    setCurrentPage(Number(urlPage));
    setSearchValue(urlSearch || '');
    setShowDeactivatedStudents(JSON.parse(String(urlShowDeactivated)));
    setFiltersEnabled(JSON.parse(String(urlShowFilters)));
    setSelectedGroups(urlGroupIds ? urlGroupIds?.split(',').map((value) => Number(value)) : []);
    setSelectedSchoolYears(
      urlSchoolYears ? urlSchoolYears?.split(',').map((value) => Number(value)) : [],
    );
  }, [urlGroupIds, urlPage, urlSchoolYears, urlSearch, urlShowDeactivated, urlShowFilters]);

  return (
    <div className={styles.studentsWrapper}>
      <div className={styles.titleWrapper}>
        <Title level={1}>Schüler/Schülerinnen</Title>
      </div>
      <Row className={styles.dashboardWrapper} justify='space-between'>
        <Col>
          <Row gutter={8}>
            <Col>
              <Search
                allowClear
                value={searchValue}
                onChange={(e: ChangeEvent<HTMLInputElement>) =>
                  handleChangeSearchValue(e.target.value)
                }
              />
            </Col>
            <Col>
              <Button icon={<FilterIcon />} type='default' onClick={handleEnableFilters}>
                Filter
              </Button>
            </Col>
          </Row>
        </Col>
        <Col>
          {userRole === 'SCHOOL_ADMIN' && (
            <Button
              icon={<PlusOutlined />}
              onClick={() => toggleAddStudentModal(true)}
              type='primary'
            >
              Schüler/Schülerinnen hinzufügen
            </Button>
          )}
        </Col>
      </Row>
      {filtersEnabled && (
        <Row className={styles.filtersWrapper}>
          <Col>
            <Select
              value={selectedGroups}
              fieldNames={{ label: 'name', value: 'id' }}
              showSearch
              mode='multiple'
              options={allGroups}
              style={{ width: '200px' }}
              maxTagCount='responsive'
              optionFilterProp='name'
              onChange={(groups) => handleChangeGroups(groups)}
              allowClear
            />
          </Col>
          <Col style={{ marginLeft: '8px' }}>
            <Select
              value={selectedSchoolYears}
              style={{ width: '200px' }}
              showSearch={false}
              onChange={(years) => handleChangeSchoolYears(years)}
              showArrow
              options={schoolYears}
              mode='multiple'
              maxTagCount='responsive'
              allowClear
            />
          </Col>
        </Row>
      )}
      {userRole === 'SCHOOL_ADMIN' && (
        <div className={styles.checkboxWrapper}>
          <Checkbox
            checked={showDeactivatedStudentsBox}
            onChange={(e: CheckboxChangeEvent) => {
              searchParams.set('showDeactivated', String(e.target.checked));
              searchParams.set('page', '1');
              setSearchParams(searchParams);
            }}
          >
            Deaktivierte Schüler anzeigen
          </Checkbox>
        </div>
      )}
      <div className={styles.studentsTableWrapper}>
        <Table
          onChange={(page, filter, sorter) => {
            handleSort(sorter as { order: string });
          }}
          className={styles.studentsTable}
          rowClassName={(record: { active: boolean }) => (!record.active ? 'disabled-row' : '')}
          rowKey={(record) => record.id}
          loading={{ indicator: <Spinner />, spinning: getStudentsLoading }}
          pagination={false}
          dataSource={studentsTable}
          columns={columns(handleNavigate, userRole, handleSelectStudent, handleReactivateStudent)}
          onRow={(record) => {
            return {
              onClick: () => {
                if (record.active) {
                  navigate({
                    pathname: `/students/${record.id}`,
                    search: location.search,
                  });
                }
              },
            };
          }}
        />
      </div>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={10}
          current={currentPage}
          onChange={(page: number) => handlePageChange(page)}
          total={studentsCount}
        />
      </div>
      <AddStudentModal
        groupIds={selectedGroups}
        schoolYears={selectedSchoolYears}
        page={currentPage}
        active={!showDeactivatedStudents}
        searchValue={searchValue}
        addStudentModalVisible={addStudentModalVisible}
        toggleAddStudentModal={toggleAddStudentModal}
      />
      <Modal
        title=''
        confirmLoading={deactivateStudentLoading}
        closable
        okText='Deaktivieren'
        cancelText='Abbrechen'
        centered
        open={!!selectedStudent}
        onOk={() => {
          handleDeactivateStudent();
        }}
        onCancel={() => {
          setSelectedStudent(null);
          setDeactivateStudentLoading(false);
        }}
      >
        <div>
          <Text level={1} bold>
            Wenn sie diesen Schüler / diese Schülerin deaktivieren:
          </Text>
          <ul>
            <li>
              <Text level={1} bold>
                Kann sich der Schüler / die Schülerin nicht am System anmelden
              </Text>
            </li>
            <li>
              <Text level={1} bold>
                Können Lehrkraft nicht mehr auf den Schüler / die Schülerin zugreifeen
              </Text>
            </li>
          </ul>
          <Text level={1} bold>
            Sind Sie sicher, dass die den Schüler / die Schülerin deaktivieren wollen?
          </Text>
        </div>
      </Modal>
    </div>
  );
};

export default Students;
