import {
  addStudentToGroupParamTypes,
  addSubjectToGroupParamTypes,
  addTeacherToGroupParamTypes,
  createGroupObservationParamTypes,
  createGroupParamTypes,
  createGroupSubjectSummaryParamTypes,
  editGroupParamTypes,
  getGroupAllSubjectsParamTypes,
  getGroupItemsParamTypes,
  getGroupsParamTypes,
  getGroupStudentsParamTypes,
  getGroupStudentsToAddParamTypes,
  getGroupSubItemsParamTypes,
  groupDetailsParamTypes,
  removeGroupParamTypes,
  removeStudentFromGroupParamTypes,
  removeSubjectFromGroupParamTypes,
  removeTeacherFromGroupParamTypes,
} from 'store/actions/group.actions';
import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import { client } from 'store/services/client';

export const getGroupsService = (params: getGroupsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups?showOnlyTeacherGroups=${params.showOnlyTeacherGroups}&page=${params.page}&size=10${
    params.name || ''
  }`,
];
export const getAllGroupsService = (): [ApiCall, string] => [client.get, '/groups?all=true'];

export const getGroupDetailsService = (params: groupDetailsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}`,
];

export const createGroupService = (
  params: createGroupParamTypes,
): [
  ApiCallWithParams,
  string,
  { name: string; schoolYears: number[]; description: string; type: string },
] => [
  client.post,
  '/groups',
  {
    name: params.name,
    description: params.description,
    schoolYears: params.schoolYears,
    type: params.type,
  },
];

export const editGroupService = (
  params: editGroupParamTypes,
): [ApiCallWithParams, string, { name: string; schoolYears: number[]; description: string }] => [
  client.put,
  `/groups/${params.id}`,
  { name: params.name, description: params.description, schoolYears: params.schoolYears },
];

export const removeGroupService = (params: removeGroupParamTypes): [ApiCall, string] => [
  client.delete,
  `/groups/${params.id}?keepAssignments=${params.keepAssignments}`,
];

export const getGroupStudentsService = (params: getGroupStudentsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/students?page=${params.page}&size=10`,
];

export const getGroupTeachersService = (params: getGroupStudentsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/teachers?page=${params.page}&size=10`,
];

export const getGroupSubjectsService = (params: getGroupStudentsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/subjects?${params.all ? 'all=true' : `page=${params.page}&size=10`}`,
];

export const getSubjectsToAddService = (params: getGroupStudentsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/subjects-to-add`,
];

export const addSubjectToGroupService = (
  params: addSubjectToGroupParamTypes,
): [ApiCallWithParams, string, number[]] => [
  client.post,
  `/groups/${params.id}/subjects`,
  params.subjects,
];

export const removeSubjectFromGroupService = (
  params: removeSubjectFromGroupParamTypes,
): [ApiCall, string] => [client.delete, `/groups/${params.groupId}/subjects/${params.subjectId}`];

export const getTeachersToAddService = (params: getGroupStudentsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/teachers-to-add`,
];

export const addTeacherToGroupService = (
  params: addTeacherToGroupParamTypes,
): [ApiCallWithParams, string, addTeacherToGroupParamTypes] => [
  client.post,
  `/groups/${params.groupId}/teachers`,
  params,
];

export const getStudentsToAddService = (
  params: getGroupStudentsToAddParamTypes,
): [ApiCall, string] => {
  const groupQuery = params.groups?.map((groupId) => `groupIds=${groupId}&`).join('');
  const schoolYears = params.schoolYears
    ?.map((schoolYear) => `schoolYears=${schoolYear}&`)
    .join('');
  return [
    client.get,
    `/groups/${params.id}/students-to-add?${
      params.name && params.name.length > 2 ? `name=${params.name}` : ''
    }&${schoolYears}${groupQuery}`,
  ];
};

export const addStudentToGroupService = (
  params: addStudentToGroupParamTypes,
): [ApiCallWithParams, string, number[]] => [
  client.post,
  `/groups/${params.id}/students`,
  params.students,
];

export const removeStudentFromGroupService = (
  params: removeStudentFromGroupParamTypes,
): [ApiCall, string] => [client.delete, `/groups/${params.groupId}/students/${params.studentId}`];

export const removeTeacherFromGroupService = (
  params: removeTeacherFromGroupParamTypes,
): [ApiCall, string] => [client.delete, `/groups/${params.groupId}/teachers/${params.teacherId}`];

export const createGroupSubjectSummaryService = (
  params: createGroupSubjectSummaryParamTypes,
): [ApiCallWithParams, string, any] => [
  client.post,
  `/subjects/${params.subjectId}/group-text`,
  { groupId: params.groupId, text: params.text },
];

export const getGroupAllSubjectsService = (
  params: getGroupAllSubjectsParamTypes,
): [ApiCall, string] => [client.get, `/groups/${params.id}/subjects?all=true`];

export const getGroupItemsService = (params: getGroupItemsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/subjects/${params.subjectId}/items`,
];

export const getGroupSubItemsService = (params: getGroupSubItemsParamTypes): [ApiCall, string] => [
  client.get,
  `/groups/${params.id}/subjects/${params.subjectId}/items/${params.itemId}/sub-items`,
];

export const createGroupObservationService = (
  params: createGroupObservationParamTypes,
): [
  ApiCallWithParams,
  string,
  { studentIds: number[]; subjectId: number; itemId?: number; subItemId?: number; text: string },
] => [
  client.post,
  `/groups/${params.id}/observations`,
  {
    studentIds: params.studentIds,
    subjectId: params.subjectId,
    itemId: params.itemId ? params.itemId : undefined,
    subItemId: params.subItemId ? params.subItemId : undefined,
    text: params.text,
  },
];
