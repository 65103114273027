import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const FilterSVG = () => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M10 18V16H14V18H10ZM6 13V11H18V13H6ZM3 8V6H21V8H3Z' fill='currentColor' />
    </svg>
  );
};

const FilterIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={FilterSVG} {...props} />;
};

export default FilterIcon;
