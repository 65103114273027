import AddGroupSubjectModal from 'pages/SingleGroup/__partials/AddGroupSubjectModal';
import AddGroupTeacherModal from 'pages/SingleGroup/__partials/AddGroupTeacherModal';
import AddGroupStudentModal from 'pages/SingleGroup/__partials/AddGroupStudentModal';
import { getGroupDetailsSelector } from 'store/selectors/groups.selectors';
import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import GroupSubjects from 'pages/SingleGroup/__partials/GroupSubjects';
import GroupTeachers from 'pages/SingleGroup/__partials/GroupTeachers';
import { getGroupDetailsAction } from 'store/actions/group.actions';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import Breadcrumb from 'components/Breadcrumb';
import { useParams } from 'react-router-dom';
import { Text, Title } from 'UI/Typography';
import { Collapse } from 'antd';
import Button from 'UI/Button';
import Tabs from 'UI/Tabs';
import cc from 'classcat';
import GroupStudents from './__partials/GroupStudents';
import { groupTypesMapping } from '../../constants';
import styles from './styles.module.css';
import { getUserRole } from 'store/selectors/users.selectors';
import GroupObservationModal from 'pages/SingleGroup/__partials/GroupObservationModal';

const { Panel } = Collapse;

const items = (
  handleChangeSelectedStudents: (arg1: { id: number }[]) => void,
  groupName: string,
  groupType: string,
  selectedStudents: { id: number }[],
  selectedTab: string,
) => [
  {
    label: <span style={{ padding: '0 40px' }}>Schüler/Schülerinnen</span>,
    key: 'student',
    children: (
      <GroupStudents
        selectedStudents={selectedStudents}
        handleChangeSelectedStudents={handleChangeSelectedStudents}
        groupType={groupType}
      />
    ),
  },
  {
    label: <span style={{ padding: '0 40px' }}>Fächer</span>,
    key: 'subject',
    children: <GroupSubjects groupName={groupName} selectedTab={selectedTab} />,
  },
  {
    label: <span style={{ padding: '0 40px' }}>Lehrkraft</span>,
    key: 'teacher',
    children: <GroupTeachers />,
  },
];

const SingleGroup = () => {
  const [groupSubjectVisible, setGroupSubjectVisible] = useState(false);
  const [groupTeacherVisible, setGroupTeacherVisible] = useState(false);
  const [groupStudentVisible, setGroupStudentVisible] = useState(false);
  const [groupObservationVisible, setGroupObservationVisible] = useState(false);
  const [selectedTab, setSelectedTab] = useState<'teacher' | 'subject' | 'student'>('student');
  const [selectedStudents, setSelectedStudents] = useState<Array<{ id: number }>>([]);
  const [scrollPosition, setScrollPosition] = useState(0);
  const myElementRef = useRef<null>(null);
  const groupDetails = useSelector(getGroupDetailsSelector);
  const userRole = useSelector(getUserRole);
  const { groupId } = useParams();
  const dispatch = useDispatch();

  const toggleGroupSubject = useCallback((value: boolean) => {
    setGroupSubjectVisible(value);
  }, []);

  const toggleGroupTeacher = useCallback((value: boolean) => {
    setGroupTeacherVisible(value);
  }, []);

  const toggleGroupStudent = useCallback((value: boolean) => {
    setGroupStudentVisible(value);
  }, []);

  const handleChangeSelectedStudents = useCallback((value: { id: number }[]) => {
    setSelectedStudents(value);
  }, []);

  const selectedTabValues = useMemo(() => {
    return {
      teacher: {
        name: 'Lehrkraft hinzufügen',
        action: () => toggleGroupTeacher(true),
      },
      subject: { name: 'Fächer hinzufügen', action: () => toggleGroupSubject(true) },
      student: { name: 'Schüler/Schülerinnen hinzufügen', action: () => toggleGroupStudent(true) },
    };
  }, [toggleGroupStudent, toggleGroupSubject, toggleGroupTeacher]);

  const handleGroupAction = useCallback(() => {
    selectedTabValues[selectedTab]?.action();
  }, [selectedTab, selectedTabValues]);

  const toggleGroupObservation = useCallback((value: boolean) => {
    setGroupObservationVisible(value);
  }, []);

  const handleScrollPosition = useCallback(() => {
    if (myElementRef.current) {
      const elTop: { scrollTop: number } = myElementRef.current;
      if (
        (elTop.scrollTop === 0 && scrollPosition !== 0) ||
        (elTop.scrollTop !== 0 && scrollPosition === 0)
      ) {
        setScrollPosition(elTop.scrollTop);
      }
    }
  }, [scrollPosition]);

  useEffect(() => {
    dispatch(getGroupDetailsAction({ id: Number(groupId) }));
  }, [dispatch, groupId]);

  return (
    <div className={styles.singleGroupContainer}>
      <div className={cc([{ [styles.scrollView]: scrollPosition > 0 }])}>
        <div className={styles.breadcrumbWrapper}>
          <Breadcrumb
            items={[
              { title: 'Gruppen', url: '/groups' },
              { title: groupDetails?.name, url: '' },
            ]}
          />
        </div>
        <div className={styles.titleWrapper}>
          <Title level={1}>{groupDetails?.name}</Title>
        </div>
      </div>
      <div className={styles.scrollWrapper} onScroll={handleScrollPosition} ref={myElementRef}>
        <div className={styles.descWrapper}>
          <Collapse
            className={cc([
              styles.collapseWrapper,
              {
                [styles.descCollapse]: true,
              },
            ])}
          >
            <Panel
              key=''
              header={
                <div>
                  <div className={styles.typWrapper}>
                    Art der Gruppe <Title level={5}>{groupTypesMapping[groupDetails?.type]}</Title>
                  </div>
                  <div className={styles.typWrapper}>
                    Jahrgänge{' '}
                    <Title level={5}>
                      {structuredClone(groupDetails?.schoolYears)
                        ?.sort((a: number, b: number) => Number(a) - Number(b))
                        .join(', ')}
                    </Title>
                  </div>
                </div>
              }
            >
              <div className={styles.descInner}>
                Beschreibung
                <Text level={2}>{groupDetails?.description || ''}</Text>
              </div>
            </Panel>
          </Collapse>
        </div>
        <div className={styles.descWrapper}>
          <Tabs
            defaultActiveKey='1'
            items={items(
              handleChangeSelectedStudents,
              groupDetails?.name,
              groupDetails?.type,
              selectedStudents,
              selectedTab,
            )}
            onChange={(key: 'teacher' | 'subject' | 'student') => setSelectedTab(key)}
            tabBarExtraContent={
              <div className={styles.createButtons}>
                {userRole === 'SCHOOL_ADMIN' ||
                (userRole === 'TEACHER' &&
                  groupDetails?.type !== 'CORE_GROUP' &&
                  selectedTab === 'student') ? (
                  <Button
                    type={userRole === 'SCHOOL_ADMIN' ? 'primary' : 'default'}
                    icon={<PlusOutlined />}
                    onClick={handleGroupAction}
                  >
                    {selectedTabValues[selectedTab].name}
                  </Button>
                ) : null}
                {userRole === 'TEACHER' && selectedTab === 'student' ? (
                  <Button
                    type='primary'
                    icon={<PlusOutlined />}
                    onClick={() => toggleGroupObservation(true)}
                    disabled={!selectedStudents.length}
                  >
                    Beobachtung hinzufügen
                  </Button>
                ) : null}
              </div>
            }
          />
        </div>
      </div>
      <AddGroupSubjectModal
        groupSubjectVisible={groupSubjectVisible}
        toggleGroupSubject={toggleGroupSubject}
      />
      <AddGroupTeacherModal
        selectedTab={selectedTab}
        groupType={groupDetails?.type}
        groupTeacherVisible={groupTeacherVisible}
        toggleGroupTeacher={toggleGroupTeacher}
      />
      <AddGroupStudentModal
        groupType={groupDetails?.type}
        groupStudentVisible={groupStudentVisible}
        toggleGroupStudent={toggleGroupStudent}
      />
      <GroupObservationModal
        handleChangeSelectedStudents={handleChangeSelectedStudents}
        selectedStudents={selectedStudents}
        groupObservationVisible={groupObservationVisible}
        toggleGroupObservation={toggleGroupObservation}
      />
    </div>
  );
};

export default SingleGroup;
