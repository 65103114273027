import styles from './styles.module.css';
import CollapseHeader from './CollapseHeader';
import React, { useCallback, useEffect, useState } from 'react';
import { Collapse, Dropdown, List } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import { getReportSelector } from 'store/selectors/report.selectors';
import { getReportAction } from 'store/actions/report.actions';
import { useParams } from 'react-router-dom';
import Button from 'UI/Button';
import { PlusOutlined } from '@ant-design/icons';
import { Text } from 'UI/Typography';
import AssignSubjectModal from 'pages/SingleStudent/__partials/ManualAssign/__partials/AssignSubjectModal';
import AssignItemModal from 'pages/SingleStudent/__partials/ManualAssign/__partials/AssignItemModal';
import AssignSubItemModal from 'pages/SingleStudent/__partials/ManualAssign/__partials/AssignSubItemModal';
import Pagination from '../../../../UI/Pagination';
import RemoveOutlinedIcon from '../../../../UI/Icons/RemoveOutlinedIcon';
import Modal from '../../../../UI/Modal';
import { getUserRole } from 'store/selectors/users.selectors';
import {
  grantAccessAction,
  reassignItemAction,
  reassignSubItemAction,
  reassignSubjectAction,
  removeAccessAction,
  resendLinkAction,
} from 'store/actions/students.actions';
import ActionsIcon from '../../../../UI/Icons/ActionsIcon';
import { getStudentInfoSelector } from 'store/selectors/students.selectors';

const { Panel } = Collapse;
const { Confirm } = Modal;

type manualAssignTypes = {
  assignSubjectVisible: boolean;
  toggleAssignSubject: (arg1: boolean) => void;
  selectedTab: 'personalInfo' | 'manualAssign' | 'assistedEval' | 'observationList';
};

const ManualAssign = ({
  assignSubjectVisible,
  toggleAssignSubject,
  selectedTab,
}: manualAssignTypes) => {
  const [assignItemVisible, setAssignItemVisible] = useState(false);
  const [assignSubItemVisible, setAssignSubItemVisible] = useState(false);
  const [selectedSubjectId, setSelectedSubjectId] = useState<number | null>(null);
  const [selectedItemId, setSelectedItemId] = useState<number | null>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [selectedSubjectToRemove, setSelectedSubjectToRemove] = useState<number | null>(null);
  const [selectedItemToRemove, setSelectedItemToRemove] = useState<number | null>(null);
  const [selectedSubItemToRemove, setSelectedSubItemToRemove] = useState<number | null>(null);
  const [isBinded, setIsBinded] = useState(false);
  const dispatch = useDispatch();
  const report = useSelector(getReportSelector);
  const studentInfo = useSelector(getStudentInfoSelector);
  const { studentId } = useParams();
  const userRole = useSelector(getUserRole);

  const handleSelectRemovableSubject = useCallback((value: number) => {
    setSelectedSubjectToRemove(value);
  }, []);

  const handleSelectRemovableItem = useCallback((value: number) => {
    setSelectedItemToRemove(value);
  }, []);

  const handleSelectSubRemovableItem = useCallback((value: number) => {
    setSelectedSubItemToRemove(value);
  }, []);

  const toggleAssignItem = useCallback((value: boolean) => {
    setAssignItemVisible(value);
  }, []);

  const toggleAssignSubItem = useCallback((value: boolean) => {
    setAssignSubItemVisible(value);
  }, []);

  const handleReassignSubject = useCallback(() => {
    dispatch(
      reassignSubjectAction({
        studentId: Number(studentId),
        subjectId: Number(selectedSubjectToRemove),
        onSuccess: () => setSelectedSubjectToRemove(null),
      }),
    );
  }, [dispatch, selectedSubjectToRemove, studentId]);

  const handleReassignItem = useCallback(() => {
    dispatch(
      reassignItemAction({
        studentId: Number(studentId),
        subjectId: Number(selectedSubjectToRemove),
        itemId: Number(selectedItemToRemove),
        page: currentPage - 1,
        onSuccess: () => {
          setSelectedSubjectToRemove(null);
          setSelectedItemToRemove(null);
        },
      }),
    );
  }, [currentPage, dispatch, selectedItemToRemove, selectedSubjectToRemove, studentId]);

  const handleReassignSubItem = useCallback(() => {
    dispatch(
      reassignSubItemAction({
        studentId: Number(studentId),
        subjectId: Number(selectedSubjectToRemove),
        itemId: Number(selectedItemToRemove),
        subItemId: Number(selectedSubItemToRemove),
        page: currentPage - 1,
        onSuccess: () => {
          setSelectedSubjectToRemove(null);
          setSelectedItemToRemove(null);
          setSelectedSubItemToRemove(null);
        },
      }),
    );
  }, [
    currentPage,
    dispatch,
    selectedItemToRemove,
    selectedSubItemToRemove,
    selectedSubjectToRemove,
    studentId,
  ]);

  const handleGrantAccess = useCallback(() => {
    dispatch(grantAccessAction({ studentId: Number(studentId) }));
  }, [dispatch, studentId]);

  const handleRemoveAccess = useCallback(() => {
    dispatch(removeAccessAction({ studentId: Number(studentId) }));
  }, [dispatch, studentId]);

  const handleResendLink = useCallback(() => {
    dispatch(resendLinkAction({ studentId: Number(studentId) }));
  }, [dispatch, studentId]);

  useEffect(() => {
    if (selectedTab === 'manualAssign') {
      dispatch(
        getReportAction({
          id: Number(studentId),
          page: currentPage - 1,
          size: 10,
          showActualData: true,
        }),
      );
    }
  }, [currentPage, dispatch, selectedTab, studentId]);

  return (
    <div>
      {userRole === 'SCHOOL_ADMIN' && (
        <div className={styles.grantAccessWrapper}>
          <div>
            <div>
              <Text level={2} bold>
                Systemzugang
              </Text>
            </div>
            {!report.nonLocked && !studentInfo?.email && (
              <div className={styles.accessTitleWrapper}>
                <Text level={1}>
                  Wenn der Schüler / die Schülerin die Selbstbewertung mit eigenem Login durchführen
                  können soll, können Sie hier <br /> die Zugangsdaten erzeugen. Sie werden dann
                  automatisch an die E-Mail-Adresse des Schülers / der Schülerin <br /> gesendet.
                  Dies ist nur möglich, wenn Sie im Profil des Schülers / der Schülerin die
                  E-Mail-Adresse hinterlegt haben.
                </Text>
              </div>
            )}
          </div>
          <div>
            {!report.nonLocked ? (
              <Button type='default' disabled={!studentInfo?.email} onClick={handleGrantAccess}>
                <Text level={1} bold>
                  Systemzugang gewähren
                </Text>
              </Button>
            ) : (
              <>
                <div className={styles.deactivateAccess}>
                  <Text level={1}>Zugang aktiv</Text>
                  <Dropdown
                    trigger={['click']}
                    placement='topLeft'
                    menu={{
                      items: [
                        {
                          key: '1',
                          label: (
                            <span
                              onClick={(e) => {
                                e.stopPropagation();
                                handleRemoveAccess();
                              }}
                            >
                              Zugang entfernen
                            </span>
                          ),
                        },
                      ],
                    }}
                  >
                    <ActionsIcon />
                  </Dropdown>
                </div>
                <div className={styles.resendLink} onClick={handleResendLink}>
                  <Text level={2} bold className='primary-40'>
                    Link zur Selbstbewertungsseite erneut senden
                  </Text>
                </div>
              </>
            )}
          </div>
        </div>
      )}
      <Collapse className={styles.collapseWrapper}>
        {report.subjects?.map((subject) => {
          return (
            <Panel
              key={subject.id}
              header={
                <CollapseHeader
                  name={subject.name}
                  removable={userRole === 'SCHOOL_ADMIN'}
                  handleSelectRemovableSubject={() => {
                    handleSelectRemovableSubject(subject.id);
                  }}
                />
              }
            >
              <Collapse
                bordered={false}
                ghost
                className={styles.innerCollapse}
                destroyInactivePanel
              >
                <div style={{ padding: '12px 16px' }}>
                  <Button
                    type='text'
                    icon={<PlusOutlined />}
                    onClick={() => {
                      toggleAssignItem(true);
                      setSelectedSubjectId(subject.id);
                    }}
                  >
                    Item hinzufügen
                  </Button>
                </div>
                {subject.items?.map((item) => {
                  return (
                    <Panel
                      key={item.id}
                      header={
                        <CollapseHeader
                          name={item.name}
                          description={item.alias}
                          item
                          itemProps={item}
                          removable
                          handleSelectRemovableItem={() => {
                            handleSelectRemovableSubject(subject.id);
                            handleSelectRemovableItem(item.id);
                            setIsBinded(item.optional);
                          }}
                        />
                      }
                    >
                      <List
                        locale={{
                          emptyText: (
                            <List.Item>
                              <Button
                                type='text'
                                icon={<PlusOutlined />}
                                onClick={() => {
                                  toggleAssignSubItem(true);
                                  setSelectedItemId(item.id);
                                  setSelectedSubjectId(subject.id);
                                }}
                              >
                                Subitem hinzufügen
                              </Button>
                            </List.Item>
                          ),
                        }}
                        size='small'
                        dataSource={item.subItems}
                        renderItem={(itemToRender, index) => {
                          return (
                            <div key={index} className={styles.subItemWrapper}>
                              <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                <Text level={1} bold>
                                  {itemToRender.name}
                                </Text>
                                <span
                                  style={{ cursor: 'pointer' }}
                                  onClick={() => {
                                    handleSelectSubRemovableItem(itemToRender.id);
                                    handleSelectRemovableSubject(subject.id);
                                    handleSelectRemovableItem(item.id);
                                  }}
                                >
                                  <RemoveOutlinedIcon />
                                </span>
                              </div>
                              {index === item.subItems?.length - 1 && (
                                <List.Item>
                                  <Button
                                    type='text'
                                    icon={<PlusOutlined />}
                                    onClick={() => {
                                      toggleAssignSubItem(true);
                                      setSelectedItemId(item.id);
                                      setSelectedSubjectId(subject.id);
                                    }}
                                  >
                                    Subitem hinzufügen
                                  </Button>
                                </List.Item>
                              )}
                            </div>
                          );
                        }}
                      />
                    </Panel>
                  );
                })}
              </Collapse>
            </Panel>
          );
        })}
      </Collapse>
      <div className={styles.paginationWrapper}>
        <Pagination
          pageSize={25}
          current={currentPage}
          onChange={(page: number) => setCurrentPage(page)}
          total={report?.count}
        />
      </div>
      <AssignSubjectModal
        page={currentPage}
        assignSubjectVisible={assignSubjectVisible}
        toggleAssignSubject={toggleAssignSubject}
      />
      <AssignItemModal
        page={currentPage}
        assignItemVisible={assignItemVisible}
        toggleAssignItem={toggleAssignItem}
        subjectId={selectedSubjectId}
      />
      <AssignSubItemModal
        page={currentPage}
        subjectId={selectedSubjectId}
        itemId={selectedItemId}
        assignSubItemVisible={assignSubItemVisible}
        toggleAssignSubItem={toggleAssignSubItem}
      />
      <Confirm
        open={!!selectedSubjectToRemove && !selectedItemToRemove && !selectedSubItemToRemove}
        message={
          !isBinded
            ? 'Sie sind im Begriff einen fachlichen Inhalt zu entfernen, der vom MLVB als ‚bindend‘ für den Jahrgang der Schülerin / des Schülers definiert wurde. Dies soll nur in begründeten Ausnahmen erfolgen. Sind Sie sich sicher, diese Aktion durchzuführen?'
            : 'Sind Sie sicher, dass Sie diesen zugewiesenen fachlichen Inhalt entfernen wollen?'
        }
        width={400}
        closable={true}
        okText='Entfernen'
        centered
        cancelText='Abbrechen'
        onOk={() => handleReassignSubject()}
        onCancel={() => setSelectedSubjectToRemove(null)}
      />
      <Confirm
        open={!!selectedItemToRemove && !selectedSubItemToRemove}
        message={
          !isBinded
            ? 'Sie sind im Begriff einen fachlichen Inhalt zu entfernen, der vom MLVB als ‚bindend‘ für den Jahrgang der Schülerin / des Schülers definiert wurde. Dies soll nur in begründeten Ausnahmen erfolgen. Sind Sie sich sicher, diese Aktion durchzuführen?'
            : 'Sind Sie sicher, dass Sie diesen zugewiesenen fachlichen Inhalt entfernen wollen?'
        }
        width={400}
        closable={true}
        okText='Entfernen'
        centered
        cancelText='Abbrechen'
        onOk={() => handleReassignItem()}
        onCancel={() => {
          setSelectedSubjectToRemove(null);
          setSelectedItemToRemove(null);
        }}
      />
      <Confirm
        open={!!selectedSubItemToRemove}
        message='Sind Sie sicher, dass Sie diesen zugewiesenen fachlichen Inhalt entfernen wollen?'
        width={400}
        closable={true}
        okText='Entfernen'
        centered
        cancelText='Abbrechen'
        onOk={() => handleReassignSubItem()}
        onCancel={() => {
          setSelectedSubjectToRemove(null);
          setSelectedItemToRemove(null);
          setSelectedSubItemToRemove(null);
        }}
      />
    </div>
  );
};

export default ManualAssign;
