import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const StarSVG = () => {
  return (
    <svg width='24' height='25' viewBox='0 0 24 25' fill='none' xmlns='http://www.w3.org/2000/svg'>
      <path
        d='M5.825 22.5L7.45 15.475L2 10.75L9.2 10.125L12 3.5L14.8 10.125L22 10.75L16.55 15.475L18.175 22.5L12 18.775L5.825 22.5Z'
        fill='#89BCB7'
      />
    </svg>
  );
};

const StarIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={StarSVG} {...props} />;
};

export default StarIcon;
