import { all, call, put, takeLatest } from 'redux-saga/effects';
import { ActionNames } from 'store/actions/actionNames';
import {
  activateSchoolYearsParamTypes,
  createSchoolAdminParamTypes,
  createSchoolParamTypes,
  deactivateIzelParamTypes,
  deactivateSchoolParamTypes,
  editIssueDateParamTypes,
  editSchoolYearSettingParamTypes,
  getSchoolInfoActionSuccess,
  getSchoolsAction,
  getSchoolsActionSuccess,
  getSchoolsParams,
  getSchoolYearDataAction,
  getSchoolYearDataActionSuccess,
  getSchoolYearDataResponseTypes,
  schoolInfoResponseTypes,
  schoolsResponseType,
  startSchoolYearParams,
  updateSchoolInfoParamTypes,
} from 'store/actions/school.actions';
import {
  activateSchoolService,
  activateSchoolYearService,
  createSchoolAdminService,
  createSchoolService,
  deactivateHalfIzelService,
  deactivateIZelService,
  deactivateSchoolService,
  editIssueDatesService,
  editSchoolYearSettingsService,
  getGradesRangeService,
  getSchoolService,
  getSchoolsService,
  getSchoolYearDataService,
  startSchoolYearService,
  updateSchoolInfoService,
} from 'store/services/school.services';
import {
  getGradesActionSuccess,
  getGradesRangeAction,
  getGradesRangeResponseTypes,
} from 'store/actions/students.actions';
import { notification } from 'antd';
import AlertCheckIcon from '../../UI/Icons/AlertCheck';

function* watchGetSchools(action: { params: getSchoolsParams; type: string }) {
  try {
    const response: schoolsResponseType = yield call(...getSchoolsService(action.params));
    yield put(getSchoolsActionSuccess(response));
  } catch (e) {
    console.log('Can not get schools');
  }
}

function* watchCreateSchool(action: { params: createSchoolParamTypes; type: string }) {
  try {
    yield call(...createSchoolService(action.params));
    yield put(getSchoolsAction({ page: action.params.page, size: 10 }));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    yield call(() => action.params.onFailure('we will send error message from backend'));
    console.log('Can not create school');
  }
}

function* watchGetSchoolInfo() {
  try {
    const response: schoolInfoResponseTypes = yield call(...getSchoolService());
    yield put(getSchoolInfoActionSuccess(response));
  } catch (e) {
    console.log('Can not create school');
  }
}

function* watchUpdateSchoolInfo(action: { params: updateSchoolInfoParamTypes; type: string }) {
  try {
    yield call(...updateSchoolInfoService(action.params));
    const response: schoolInfoResponseTypes = yield call(...getSchoolService());
    yield put(getSchoolInfoActionSuccess(response));
    yield put(getGradesRangeAction);
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
    yield call(action.params.onFailure);
  }
}

function* watchCreateSchoolAdmin(action: { params: createSchoolAdminParamTypes; type: string }) {
  try {
    yield call(...createSchoolAdminService(action.params));
    yield put(getSchoolsAction({ page: action.params.page, size: 10 }));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchDeactivateSchool(action: { params: deactivateSchoolParamTypes; type: string }) {
  try {
    yield call(...deactivateSchoolService(action.params));
    yield put(getSchoolsAction({ page: action.params.page, size: 10 }));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchActivateSchool(action: { params: deactivateSchoolParamTypes; type: string }) {
  try {
    yield call(...activateSchoolService(action.params));
    yield put(getSchoolsAction({ page: action.params.page, size: 10 }));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchGetGrades() {
  try {
    const response: getGradesRangeResponseTypes = yield call(...getGradesRangeService());
    yield put(getGradesActionSuccess(response));
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchGetSchoolYearData() {
  try {
    const response: getSchoolYearDataResponseTypes = yield call(...getSchoolYearDataService());
    yield put(getSchoolYearDataActionSuccess(response));
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchStartSchoolYear(action: { params: startSchoolYearParams; type: string }) {
  try {
    yield call(...startSchoolYearService());
    yield put(getSchoolYearDataAction);
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchEditSchoolYearSettings(action: {
  params: editSchoolYearSettingParamTypes;
  type: string;
}) {
  try {
    yield call(...editSchoolYearSettingsService(action.params));
    yield put(getSchoolYearDataAction);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchActivateSchoolYear(action: { params: activateSchoolYearsParamTypes; type: string }) {
  try {
    yield call(...activateSchoolYearService());
    yield put(getSchoolYearDataAction);
    yield notification.success({
      message: 'IzEL für dieses Schuljahr wurde erfolgreich aktiviert',
      duration: 3,
      icon: <AlertCheckIcon />,
    });
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchDeActivateFullIzel(action: { params: deactivateIzelParamTypes; type: string }) {
  try {
    yield call(...deactivateIZelService(action.params));
    yield put(getSchoolYearDataAction);
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* watchDeActivateHalfIzel(action: { params: deactivateIzelParamTypes; type: string }) {
  try {
    yield call(...deactivateHalfIzelService(action.params));
    yield put(getSchoolYearDataAction);
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}
function* watchEditIssueDates(action: { params: editIssueDateParamTypes; type: string }) {
  try {
    yield call(...editIssueDatesService(action.params));
    yield put(getSchoolYearDataAction);
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not update school');
  }
}

function* schoolSaga() {
  yield all([takeLatest(ActionNames.GET_SCHOOLS, watchGetSchools)]);
  yield all([takeLatest(ActionNames.CREATE_SCHOOL, watchCreateSchool)]);
  yield all([takeLatest(ActionNames.GET_SCHOOL_INFO, watchGetSchoolInfo)]);
  yield all([takeLatest(ActionNames.UPDATE_SCHOOL_INFO, watchUpdateSchoolInfo)]);
  yield all([takeLatest(ActionNames.CREATE_SCHOOL_ADMIN, watchCreateSchoolAdmin)]);
  yield all([takeLatest(ActionNames.DEACTIVATE_SCHOOL, watchDeactivateSchool)]);
  yield all([takeLatest(ActionNames.ACTIVATE_SCHOOL, watchActivateSchool)]);
  yield all([takeLatest(ActionNames.GET_GRADES_ACTION, watchGetGrades)]);
  yield all([takeLatest(ActionNames.GET_SCHOOL_YEAR_DATA, watchGetSchoolYearData)]);
  yield all([takeLatest(ActionNames.START_SCHOOL_YEAR, watchStartSchoolYear)]);
  yield all([takeLatest(ActionNames.EDIT_SCHOOL_YEAR_SETTINGS, watchEditSchoolYearSettings)]);
  yield all([takeLatest(ActionNames.ACTIVATE_SCHOOL_YEAR, watchActivateSchoolYear)]);
  yield all([takeLatest(ActionNames.DE_ACTIVATE_FULL_IZEL, watchDeActivateFullIzel)]);
  yield all([takeLatest(ActionNames.DE_ACTIVATE_HALF_IZEL, watchDeActivateHalfIzel)]);
  yield all([takeLatest(ActionNames.EDIT_ISSUE_DATES, watchEditIssueDates)]);
}

export default schoolSaga;
