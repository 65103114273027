import Modal from 'UI/Modal';
import { useDispatch, useSelector } from 'react-redux';
import { getSubjectsToReorderSelector } from 'store/selectors/subjects.selectors';
import styles from './styles.module.css';
import { Col, Row } from 'antd';
import React, { useCallback, useEffect, useState } from 'react';
import update from 'immutability-helper';
import SubjectList from 'pages/Subjects/__partials/ReorderSubjectsModal/SubjectList';
import {
  clearSubjectListAction,
  getReorderedSubjectsList,
  ReorderSubjectsAction,
  subjectTypes,
} from 'store/actions/subjects.actions';
import Button from '../../../../UI/Button';
import Spinner from '../../../../UI/Spinner';
import { Text } from '../../../../UI/Typography';

type reorderModalTypes = {
  reorderVisible: boolean;
  toggleReorderModal: (arg1: boolean) => void;
  handlePageChange: (arg1: number) => void;
};

const ReorderSubjectsModal = ({
  reorderVisible,
  toggleReorderModal,
  handlePageChange,
}: reorderModalTypes) => {
  const [subjectsLoading, setSubjectsLoading] = useState(false);
  const [subjectsList, setSubjectsList] = useState<Array<subjectTypes>>([]);
  const [currentPage, setCurrentPage] = useState(0);
  const [saveLoading, setSaveLoading] = useState(false);
  const [scrollHidden, setScrollHidden] = useState(false);
  const subjects = useSelector(getSubjectsToReorderSelector);
  const dispatch = useDispatch();

  console.log('subjects', subjects);

  const handleChangeScrollHidden = useCallback(
    (value: boolean) => {
      if (scrollHidden !== value) {
        setScrollHidden(value);
      }
    },
    [scrollHidden],
  );

  const moveCard = useCallback((dragIndex: number, hoverIndex: number) => {
    setSubjectsList((prevCards) =>
      update(prevCards, {
        $splice: [
          [dragIndex, 1],
          [hoverIndex, 0, prevCards[dragIndex]],
        ],
      }),
    );
  }, []);

  const handleChangePage = useCallback(() => {
    setCurrentPage((prev) => prev + 1);
  }, []);

  const handleReorderSubjects = useCallback(() => {
    const subjectIds = subjectsList.map((subject) => subject.id);
    setSaveLoading(true);
    dispatch(
      ReorderSubjectsAction({
        subjectIds,
        onSuccess: () => {
          toggleReorderModal(false);
          setCurrentPage(0);
          dispatch(clearSubjectListAction);
          setSubjectsList([]);
          setSaveLoading(false);
          handlePageChange(1);
        },
        onFailure: () => {
          toggleReorderModal(false);
          setCurrentPage(0);
          dispatch(clearSubjectListAction);
          setSubjectsList([]);
          setSaveLoading(false);
          handlePageChange(1);
        },
      }),
    );
  }, [dispatch, handlePageChange, subjectsList, toggleReorderModal]);

  useEffect(() => {
    if (!reorderVisible) {
      setCurrentPage(0);
      dispatch(clearSubjectListAction);
      setSubjectsList([]);
    }
  }, [dispatch, reorderVisible]);

  useEffect(() => {
    const fullSubjectList = [...subjectsList, ...(subjects?.subjects || [])];
    setSubjectsList(fullSubjectList);
  }, [subjects?.subjects]);

  useEffect(() => {
    if (reorderVisible) {
      setSubjectsLoading(true);
      dispatch(
        getReorderedSubjectsList({
          page: currentPage,
          size: 10,
          onSuccess: () => setSubjectsLoading(false),
        }),
      );
    }
  }, [currentPage, dispatch, reorderVisible]);

  return (
    <Modal
      width={800}
      centered
      title='Umordnen'
      open={reorderVisible}
      onOk={() => handleReorderSubjects()}
      onCancel={() => {
        toggleReorderModal(false);
        setCurrentPage(0);
        dispatch(clearSubjectListAction);
        setSubjectsList([]);
      }}
      closable
      okText='Speichern'
      cancelText='Abbrechen'
      confirmLoading={saveLoading}
      fixScroll={false}
    >
      {subjectsLoading && !subjectsList.length ? (
        <div className={styles.spinWrapper}>
          <Spinner />
        </div>
      ) : (
        <>
          <div className={styles.tableWrapper}>
            <Row className={styles.tableHeader}>
              <Col span={4} />
              <Col span={12}>
                <Text level={1} bold>
                  Fächer
                </Text>
              </Col>
              <Col span={8}>
                <Text level={1} bold>
                  Typ
                </Text>
              </Col>
            </Row>
            <div className={scrollHidden ? styles.tableInnerNoScroll : styles.tableInner}>
              {subjectsList?.map((subject: subjectTypes, index) => {
                return (
                  <SubjectList
                    handleChangeScrollHidden={handleChangeScrollHidden}
                    subject={subject}
                    index={index}
                    key={subject.id}
                    moveCard={moveCard}
                  />
                );
              })}
            </div>
          </div>
          <div className={styles.showMore}>
            {(Number(subjects?.count) !== subjectsList?.length ||
              Number(subjects?.count) < subjectsList?.length) && (
              <Button type='primary' onClick={handleChangePage}>
                Mehr anzeigen
              </Button>
            )}
          </div>
        </>
      )}
    </Modal>
  );
};

export default ReorderSubjectsModal;
