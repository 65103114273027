import { ActionNames } from 'store/actions/actionNames';

export type userTypes = {
  name: string;
  surname: string;
  authorities: Array<{
    [key: string]: string;
  }>;
  accountNonExpired: boolean;
  accountNonLocked: boolean;
  credentialsNonExpired: boolean;
  enabled: boolean;
  lastLoginRole: string;
};

export type loginParaTypes = {
  email: string;
  password: string;
  onSuccess: (arg1: any) => void;
  onFailure: (arg1: string) => void;
  onResolve: () => void;
};

export type saveAuthParamTypes = {
  authData: string;
};

export type firstLoginParamTypes = {
  token: string;
  password: string;
  onSuccess: () => void;
  onFailure: () => void;
};

export type resetPasswordParamTypes = {
  email: string;
  onSuccess: () => void;
  onFailure: () => void;
};

export type changeUserRoleParamTypes = {
  lastLoginRole: 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN';
};

export const loginAction = (params: loginParaTypes) => ({
  type: ActionNames.LOGIN,
  params,
});

export const saveAuthAction = (params: saveAuthParamTypes) => ({
  type: ActionNames.SAVE_AUTH,
  payload: { data: params },
});

export const loginActionSuccess = (user: userTypes) => ({
  type: ActionNames.LOGIN_SUCCESS,
  payload: user,
});

export const firstLoginAction = (params: firstLoginParamTypes) => ({
  type: ActionNames.FIRST_LOGIN,
  params,
});

export const resetPasswordAction = (params: resetPasswordParamTypes) => ({
  type: ActionNames.RESET_PASSWORD,
  params,
});

export const changeUserRoleAction = (params: changeUserRoleParamTypes) => ({
  type: ActionNames.CHANGE_USER_ROLE,
  payload: { data: params },
});
