import { Button as AntdButton, ButtonProps, ConfigProvider } from 'antd';
import styles from './styles.module.css';
import { primary40 } from '../../colors';
import cc from 'classcat';

const Button = ({ ...props }: ButtonProps) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
          borderRadius: 8,
        },
      }}
    >
      <AntdButton
        {...props}
        size='large'
        className={cc([
          {
            [styles.button]: props.type === 'default',
            [styles.text]: props.type === 'text',
            [styles.primary]: props.type === 'primary',
          },
        ])}
      />
    </ConfigProvider>
  );
};

export default Button;
