import { createSchoolAction } from 'store/actions/school.actions';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Text } from 'UI/Typography';
import Modal from 'UI/Modal';
import Input from 'UI/Input';
import styles from './styles.module.css';
import Checkbox from '../../../../UI/Checkbox';
import { CheckboxChangeEvent } from 'antd/es/checkbox';

type manageSchoolModalTypes = {
  manageSchoolVisible: boolean;
  toggleManageSchool: (arg1: boolean) => void;
  currentPage: number;
};

const ManageSchoolModal = ({
  manageSchoolVisible,
  toggleManageSchool,
  currentPage,
}: manageSchoolModalTypes) => {
  const [schoolName, setSchoolName] = useState('');
  const [schoolDisplayName, setSchoolDisplayName] = useState('');
  const [schoolAdmin, setSchoolAdmin] = useState('');
  const [adminLastName, setAdminLastName] = useState('');
  const [adminEmail, setAdminEmail] = useState('');
  const [legalApproved, setLegalApproved] = useState(false);
  const [schoolLoading, setSchoolLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);
  const dispatch = useDispatch();
  const editMode = false;
  const okButtonDisabled = useMemo(() => {
    return !schoolName || !schoolAdmin || !adminLastName || !adminEmail || !legalApproved;
  }, [adminEmail, adminLastName, legalApproved, schoolAdmin, schoolName]);

  const handleSchoolName = useCallback((value: string) => {
    setSchoolName(value);
  }, []);

  const handleSchoolDisplayName = useCallback((value: string) => {
    setSchoolDisplayName(value);
  }, []);

  const handleSchoolAdmin = useCallback((value: string) => {
    setSchoolAdmin(value);
  }, []);

  const handleAdminLastName = useCallback((value: string) => {
    setAdminLastName(value);
  }, []);

  const handleAdminEmail = useCallback((value: string) => {
    setAdminEmail(value);
  }, []);

  const handleResetSchoolModal = useCallback(() => {
    setSchoolName('');
    setSchoolDisplayName('');
    setSchoolAdmin('');
    setAdminEmail('');
    setAdminLastName('');
    setErrorMessage(false);
    setLegalApproved(false);
    toggleManageSchool(false);
  }, [toggleManageSchool]);

  const handleCreateSchool = useCallback(() => {
    setSchoolLoading(true);
    dispatch(
      createSchoolAction({
        page: currentPage - 1,
        name: schoolName,
        officialName: schoolDisplayName,
        adminName: schoolAdmin,
        adminSurname: adminLastName,
        email: adminEmail,
        onSuccess: () => {
          setSchoolLoading(false);
          handleResetSchoolModal();
        },
        onFailure: () => {
          setSchoolLoading(false);
          setErrorMessage(true);
        },
      }),
    );
  }, [
    adminEmail,
    adminLastName,
    currentPage,
    dispatch,
    handleResetSchoolModal,
    schoolAdmin,
    schoolDisplayName,
    schoolName,
  ]);

  const handleManageSchool = useCallback(() => {
    if (!editMode) {
      handleCreateSchool();
    }
  }, [editMode, handleCreateSchool]);

  return (
    <Modal
      centered
      title='Neue Schule'
      open={manageSchoolVisible}
      onOk={() => handleManageSchool()}
      onCancel={() => handleResetSchoolModal()}
      closable
      okText='Hinzufügen'
      cancelText='Abbrechen'
      okDisabled={okButtonDisabled}
      confirmLoading={schoolLoading}
    >
      <div className={styles.schoolLabel}>
        <Text level={1} bold>
          Schulname
        </Text>
      </div>
      <Input
        placeholder='Schulname'
        value={schoolName}
        onChange={(e) => handleSchoolName(e.target.value)}
        maxLength={150}
        showCount
      />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Offizieller Schulname (Optional)
        </Text>
      </div>
      <Input
        placeholder='Offizieller Schulname (Optional)'
        value={schoolDisplayName}
        onChange={(e) => handleSchoolDisplayName(e.target.value)}
        maxLength={150}
        showCount
      />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Schulverwaltung Name
        </Text>
      </div>
      <Input
        placeholder='Schulverwaltung Name'
        value={adminLastName}
        onChange={(e) => handleAdminLastName(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Schulverwaltung Vorname
        </Text>
      </div>
      <Input
        placeholder='Schulverwaltung Vorname'
        value={schoolAdmin}
        onChange={(e) => handleSchoolAdmin(e.target.value)}
        maxLength={100}
        showCount
      />
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Schulverwaltung E-Mail
        </Text>
      </div>
      <Input
        type='email'
        placeholder='E-Mail'
        value={adminEmail}
        onChange={(e) => handleAdminEmail(e.target.value)}
      />
      {errorMessage ? (
        <Text level={1} className='errorMessage'>
          E-Mail ist bereits vorhanden
        </Text>
      ) : (
        ''
      )}
      <div className={styles.schoolDisplayLabel}>
        <Text level={1} bold>
          Zustimmung zur Datenverarbeitung
        </Text>
      </div>
      <Checkbox
        checked={legalApproved}
        onChange={(e: CheckboxChangeEvent) => {
          setLegalApproved(e.target.checked);
        }}
      >
        Liegt vor
      </Checkbox>
    </Modal>
  );
};

export default ManageSchoolModal;
