import Icon from '@ant-design/icons';
import { CustomIconComponentProps } from '@ant-design/icons/lib/components/Icon';

const EditSVG = () => {
  return (
    <svg
      width='1em'
      height='1em'
      viewBox='0 0 24 24'
      fill='none'
      xmlns='http://www.w3.org/2000/svg'
    >
      <path d='M15 16L11 20H21V16H15Z' fill='currentColor' />
      <path
        d='M12.06 7.19043L3 16.2504V20.0004H6.75L15.81 10.9404L12.06 7.19043ZM5.92 18.0004H5V17.0804L12.06 10.0204L12.98 10.9404L5.92 18.0004Z'
        fill='currentColor'
      />
      <path
        d='M18.7109 8.04C19.1009 7.65 19.1009 7.02 18.7109 6.63L16.3709 4.29C16.1709 4.09 15.9209 4 15.6609 4C15.4109 4 15.1509 4.1 14.9609 4.29L13.1309 6.12L16.8809 9.87L18.7109 8.04Z'
        fill='currentColor'
      />
    </svg>
  );
};

const EditIcon = (props: Partial<CustomIconComponentProps>) => {
  return <Icon component={EditSVG} {...props} />;
};

export default EditIcon;
