import styles from 'pages/Legal/styles.module.css';
import { Col, Row } from 'antd';

const Daten = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mb12}>
        <strong>Datenschutzerklärung</strong>
      </div>
      <Row className={styles.mb12}>
        <Col span={1}></Col>
        <Col span={23}>
          Der Schutz Ihrer Privatsphäre ist uns ein wichtiges Anliegen. Wir verarbeiten Ihre
          personenbezogenen Daten nur entsprechend den Bestimmungen der
          EU-Datenschutzgrundverordnung (DSGVO) sowie den sonstigen gesetzlichen
          Datenschutzbestimmungen, insbesondere dem Bundesdatenschutzgesetz (BDSG).
          Selbstverständlich werden sämtliche Daten vertraulich behandelt. Mit den nachfolgenden
          Datenschutzinformationen möchten wir Ihnen im Einzelnen näher erläutern, wie mit Ihren
          Daten bei Nutzung unserer Websites umgegangen wird.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>1.</strong>
        </Col>
        <Col span={23}>
          <strong>Allgemeine Informationen über die Erhebung personenbezogener Daten</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}></Col>
        <Col span={23}>
          Grundsätzlich ist die Erhebung, Verarbeitung und Nutzung von personenbezogenen Daten zur
          Nutzung unseres Internetauftritts auf das erforderliche Maß und die erforderlichen Daten
          beschränkt. Personenbezogene Daten sind alle Daten, die auf Sie persönlich beziehbar sind,
          z. B. Name, Adresse, E-Mail-Adressen, Nutzerverhalten.Zudem verwenden wir innerhalb
          unserer Website das verbreitete SSL-Verfahren (Secure-Socket Layer) in Verbindung mit der
          jeweils höchsten Verschlüsselungsstufe, die von Ihrem Web-Browser unterstützt wird. In der
          Regel handelt es sich hierbei um eine 256-Bit-Verschlüsselung. Falls Ihr Browser keine
          256-Bit-Verschlüsselung unterstützt, greifen wir stattdessen auf eine 128-Bit
          v3-Technologie zurück. Ob eine einzelne Seite unseres Internetauftritts verschlüsselt
          übertragen wird, erkennen Sie an der geschlossenen Darstellung des Schlüssel- bzw.
          Schloss-Symbols in der unteren Statusleiste Ihres Browsers.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>1.1</strong>
        </Col>
        <Col span={23}>Verantwortlicher</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Verantwortlicher für die Erhebung, Verarbeitung und Nutzung Ihrer personenbezogenen Daten
          gem. Art. 4 Abs. 7 DSGVO ist:
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>Montessori Landesverband Bayern e.V</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>vertreten durch den Vorstand Monika Ullmann und Manfred Burghardt</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>Hirtenstraße 26</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>D-80335 München</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>Tel:</strong> +49(0)89-5480173-0
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>Fax:</strong> +49(0)89-5480173-33
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>E-Mail:</strong> bildungsakademie@montessoribayern.de
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>1.2</strong>
        </Col>
        <Col span={23}>
          <strong>Name und Anschrift des Datenschutzbeauftragten</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Den Datenschutzbeauftragten des Verantwortlichenerreichen Sie unter den nachfolgenden
          Kontaktdaten:
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>E-Mail:</strong> datenschutz@montessoribayern.de
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.</strong>
        </Col>
        <Col span={23}>
          <strong>
            Zwecke und Rechtsgrundlagen der Verarbeitung Ihrer personenbezogenen Daten sowie weitere
            Information zur konkreten Datenverarbeitung
          </strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.1</strong>
        </Col>
        <Col span={23}>
          <strong>Besuch unserer Websites</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.1.1</strong>
        </Col>
        <Col span={23}>Beschreibung und Umfang der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Bei jedem Aufruf unserer Websites erfassen unsere Systeme automatisiert Daten und
          Informationen des Computersystems des aufrufenden Rechners (personenbezogenen Daten, die
          Ihr Browser an unseren Server übermittelt). Dies erfolgt auch, sofern Sie sich nicht
          registrieren oder uns anderweitig etwa durch aktive Eingaben Informationen übermitteln.
          Folgende Daten werden in jedem Fall bei Besuch unserer Websites erhoben:
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={2}></Col>
        <Col span={22}>
          <ul>
            <li>IP-Adresse des Nutzers</li>
            <li>Datum und Uhrzeit der Anfrage bzw. des Zugriffs</li>
            <li>Zeitzonendifferenz zur Greenwich Mean Time (GMT)</li>
            <li>Inhalt der Anforderung (konkrete Seite)</li>
            <li>Zugriffsstatus/HTTP-Statuscode</li>
            <li>jeweils übertragene Datenmenge</li>
            <li>
              Website, von der die Anforderung kommt (von denen das System des Nutzers auf unsere
              Internetseite gelangt)
            </li>
            <li>Website, die vom System des Nutzers über unsere Website aufgerufen wird</li>
            <li>Informationen über den Browsertyp und die verwendete Version</li>
            <li>Betriebssystem und dessen Oberfläche</li>
            <li>Sprache und Version der Browsersoftware</li>
          </ul>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Diese Daten werden in den Logfiles unseres Systems gespeichert. Eine Speicherung zusammen
          mit anderen Ihrer personenbezogenen Daten findet regelmäßig nicht statt.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.1.2</strong>
        </Col>
        <Col span={23}>Zwecke der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Speicherung der genannten Daten, insbesondere der IP-Adresse durch unsere Systeme,
          erfolgt grundsätzlich nur vorübergehend für die Dauer der Sitzung und ist erforderlich, um
          den ordnungsgemäßen Betrieb und eine ordnungsgemäße Darstellung der Website zu
          ermöglichen. Diese Verarbeitung Ihrer Daten dient ferner den Zwecken, die Systemsicherheit
          und Systemstabilität auszuwerten und weiterhin zu gewährleisten sowie weiteren
          administrativen Zwecken. Soweit eine Speicherung Ihrer Daten in unseren Logfiles erfolgt,
          geschieht dies ebenfalls nur aus den Gründen, die Funktionsfähigkeit unserer Websites
          sicherzustellen. Zudem dienen uns die Daten zur Optimierung und zur Sicherstellung der
          Sicherheit unserer informationstechnischen Systeme. Eine Auswertung der Daten zu
          Marketingzwecken findet in diesem Zusammenhang nicht statt.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.1.3</strong>
        </Col>
        <Col span={23}>Rechtsgrundlagen der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Rechtsgrundlage für die Verarbeitung und vorübergehende Speicherung Ihrer
          personenbezogenen Daten ist Art. 6 Abs. 1 S. 1 lit. f DSGVO. Unsere berechtigten
          Interessen folgen aus den dargestellten Zwecken zur Datenerhebung.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.1.4</strong>
        </Col>
        <Col span={23}>Dauer der Speicherung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Ihre Daten werden gelöscht, sobald sie für die Erreichung des Zweckes nicht mehr
          erforderlich sind. Im Falle der Erfassung der Daten zur Bereitstellung der Website ist
          dies der Fall, wenn die jeweilige Sitzung beendet ist.Im Falle der Speicherung Ihrer Daten
          in Logfiles ist dies nach spätestens sieben Tagen der Fall. Eine darüberhinausgehende
          Speicherung ist nur in Ausnahmefällen vorgesehen, etwa wenn dies aus technischen Gründen
          oder zur Verbesserung unserer Systeme erforderlich ist. In diesem Fall werden die
          IP-Adressen der Nutzer gelöscht oder verfremdet, sodass eine Zuordnung nicht mehr möglich
          ist.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.1.5</strong>
        </Col>
        <Col span={23}>Widerspruchs- und Beseitigungsmöglichkeit</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Erfassung Ihrer Daten zur Bereitstellung der Website und die gegebenenfalls erfolgende
          Speicherung in Logfiles ist für den Betrieb der Internetseite zwingend erforderlich. Es
          besteht daher keine Widerspruchsmöglichkeit.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2</strong>
        </Col>
        <Col span={23}>E-Mail-Kontakt</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2</strong>
        </Col>
        <Col span={23}>E-Mail-Kontakt</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2.1</strong>
        </Col>
        <Col span={23}>Beschreibung und Umfang der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Es ist eine Kontaktaufnahme über von uns bereitgestellte E-Mail-Adressen möglich. In
          diesem Fall werden Ihre mit der E-Mail übermittelten personenbezogenen Daten von uns
          gespeichert und weiterverarbeitet, insbesondere um Ihre Anfrage oder den Grund Ihrer
          Kontaktaufnahme zu uns zu bearbeiten. Es erfolgt keine Weitergabe der Daten an Dritte. Die
          Daten werden ausschließlich für die Verarbeitung der Konversation verwendet.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2.2</strong>
        </Col>
        <Col span={23}>Rechtsgrundlage der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Rechtsgrundlage für die Verarbeitung Ihrer Daten bei Übersendung einer E-Mail an uns
          ist Art. 6 Abs. 1 S. 1 lit. a sowie f DSGVO. Die Verarbeitung erfolgt auf Grundlage einer
          konkludent abgegebenen Einwilligung sowie auf Grundlage unserer berechtigten Commented
          [S&P1]: Nach unserer Durchsicht ist bislang kein Kontaktformular auf der Webseite
          vorgesehen. Sollte dies doch der Fall sein, bitten wir um einen kurzen Hinweis. Wir würden
          ansonsten an dieser Stelle marginale Anpassungen vornehmen. Interessen. Zielt der
          E-Mail-Kontakt auf den Abschluss eines Vertrages ab, so ist zusätzliche Rechtsgrundlage
          für die Verarbeitung Art. 6 Abs. 1 S. 1 lit. b DSGVO.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2.3</strong>
        </Col>
        <Col span={23}>Zwecke der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Verarbeitung der personenbezogenen Daten aus der an uns übersandten E-Mail dient nur
          zur Bearbeitung Ihrer Kontaktaufnahme mit uns. Im Falle einer Kontaktaufnahme per E-Mail
          ist hierin das erforderliche berechtigte Interesse an der Verarbeitung der Daten durch uns
          zu sehen. Die sonstigen während des Absendevorgangs verarbeiteten personenbezogenen Daten
          dienen dazu, einen Missbrauch des Kontaktformulars zu verhindern und die Sicherheit
          unserer informationstechnischen Systeme sicherzustellen.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2.4</strong>
        </Col>
        <Col span={23}>Dauer der Speicherung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Wir werden Ihre Daten löschen, sobald wird diese für die Erreichung der dargestellten
          Zwecke nicht mehr benötigen. Für die personenbezogenen, die per E-Mail übersandt wurden,
          ist dies dann der Fall, wenn die jeweilige Konversation mit Ihnen beendet ist. Beendet ist
          die Konversation in der Regel dann, wenn sich aus den Umständen entnehmen lässt, dass
          Grund Ihrer Kontaktaufnahme zu uns abschließend geklärt ist. Die während des
          Absendevorgangs zusätzlich erhobenen personenbezogenen Daten werden spätestens nach einer
          Frist von 7 Tagen gelöscht
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.2.5</strong>
        </Col>
        <Col span={23}>Widerspruchs- und Beseitigungsmöglichkeit</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Nehmen Sie per E-Mail Kontakt mit uns auf, so können Sie der Speicherung Ihrer
          personenbezogenen Daten jederzeit widersprechen. In einem solchen Fall kann die
          Konversation zu Ihnen jedoch unter Umständen nicht fortgeführt werden. Alle
          personenbezogenen Daten, die im Zuge der Kontaktaufnahme gespeichert wurden, werden in
          diesem Fall gelöscht.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.3</strong>
        </Col>
        <Col span={23}>
          <strong>Nutzung Ihres persönlichen Accounts</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.3.1</strong>
        </Col>
        <Col span={23}>Beschreibung und Umfang der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Als registrierter Nutzer haben Sie (mittels Ihrer E-Mail-Adresse und des von Ihnen selbst
          gewählten Passworts) Zugang zu Ihrem persönlichen Account. Folgende Daten werden im Rahmen
          des Login-Prozesses erhoben: <br />
          <ul>
            <li>E-Mail-Adresse</li>
            <li>Passwort</li>
          </ul>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Im Zeitpunkt des Logins werden zudem folgende Daten gespeichert: <br />
          <ul>
            <li>Die IP-Adresse des Nutzers</li>
            <li>Datum und Uhrzeit des Logins</li>
          </ul>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.3.2</strong>
        </Col>
        <Col span={23}>Rechtsgrundlage der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Rechtsgrundlage für die Verarbeitung Ihrer Daten bei ist Art. 6 Abs. 1 S. 1 lit. b
          DS-GVO. Die Verarbeitung der Daten ist zur Durchführung des mit Ihnen geschlossenen
          Nutzungsvertrags erforderlich.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.3.3</strong>
        </Col>
        <Col span={23}>Zwecke der Datenverarbeitung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Verarbeitung der personenbezogenen Daten aus dem Registrierungsvorgangs dient nur zur
          Einrichtung Ihres Accounts sowie zur Verhinderung eines Missbrauchs des Accounts.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.3.4</strong>
        </Col>
        <Col span={23}>Dauer der Speicherung</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Wir werden Ihre Daten löschen, sobald wird diese für die Erreichung der dargestellten
          Zwecke nicht mehr benötigen. Für die personenbezogenen Daten bei der Nutzung des Accounts
          ist dies dann der Fall, wenn der Vertrag vollständig abgewickelt ist. Ihre Daten werden
          für die weitere Verarbeitung eingeschränkt und nach Ablauf der steuer- und
          handelsrechtlichen Aufbewahrungsfristen gelöscht, sofern Sie nicht ausdrücklich in eine
          weitere Nutzung Ihrer Daten eingewilligt haben oder wir uns eine darüber hinausgehende
          Datenverwendung vorbehalten, die gesetzlich erlaubt ist und über die wir Sie in dieser
          Erklärung informieren. Die während des Absendevorgangs zusätzlich erhobenen
          personenbezogenen Daten werden spätestens nach einer Frist von 7 Tagen gelöscht.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>2.4</strong>
        </Col>
        <Col span={23}>
          <strong>Externe Verlinkungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Sofern von unseren Webseiten auf die Seiten anderer Anbieter oder Partner verlinkt wird,
          gilt unsere Datenschutzerklärung nicht für deren Inhalte. Wir haben keinen Einfluss auf
          die Einhaltung der gesetzlichen Datenschutzbestimmungen durch diese Drittanbieter.
          Informationen über den Datenschutz der Betreiber dieser Seiten erhalten Sie auf den
          jeweiligen Webseiten.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>3</strong>
        </Col>
        <Col span={23}>
          <strong>Weitergabe Ihrer Daten an Dritte</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Wir geben keine personenbezogenen Daten an Unternehmen, Organisationen oder Personen
          außerhalb unseres Unternehmens weiter, außer in einem der folgenden Umstände:
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>3.1</strong>
        </Col>
        <Col span={23}>
          <strong>Mit Ihrer Einwilligung</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Wir geben personenbezogene Daten an dritte Unternehmen, Organisationen oder Personen
          außerhalb unseres Unternehmens weiter, wenn Sie uns hierzu Ihre explizite Einwilligung
          gegeben haben.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>3.2</strong>
        </Col>
        <Col span={23}>
          <strong>Verarbeitungen durch andere Stellen</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Wir stellen Ihre personenbezogenen Daten gegebenenfalls unseren dritten Geschäftspartnern,
          anderen vertrauenswürdigen Unternehmen oder Personen zur Verfügung, die diese in unserem
          Auftrag verarbeiten. Dies geschieht stets auf der Grundlage unserer Weisungen und im
          Einklang mit unserer Datenschutzerklärung sowie anderen geeigneten Vertraulichkeits- und
          Sicherheitsmaßnahmen.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>3.3</strong>
        </Col>
        <Col span={23}>
          <strong>Aus rechtlichen Gründen</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Wir werden Ihre personenbezogene Daten an Unternehmen, Organisationen oder Personen
          außerhalb unseres Unternehmens weitergeben, wenn wir nach Treu und Glauben davon ausgehen
          dürfen, dass der Zugriff auf diese Daten oder ihre Nutzung, Aufbewahrung oder Weitergabe
          vernünftigerweise notwendig ist, um insbesondere geltende Gesetze, Vorschriften oder
          Rechtsverfahren einzuhalten oder einer vollstreckbaren behördlichen Anordnung
          nachzukommen.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>4.</strong>
        </Col>
        <Col span={23}>
          <strong>
            Weitergabe Ihrer Daten an ein Drittland oder eine internationale Organisation
          </strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Soweit im Rahmen dieser Datenschutzerklärung nicht ausdrücklich dargestellt, findet eine
          Übermittlung Ihrer personenbezogenen Daten an Drittländer oder internationale
          Organisationen nicht statt.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>5.</strong>
        </Col>
        <Col span={23}>
          <strong>Automatisierte Entscheidungsfindungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>Eine automatisierte Entscheidungsfindung findet nicht statt.</Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>6.</strong>
        </Col>
        <Col span={23}>
          <strong>Hosting und Einsatz der Software IzEL-Software</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>6.1</strong>
        </Col>
        <Col span={23}>
          <strong>Beschreibung und Umfang der Datenverarbeitung</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Nach den Grundsätzen der Montessori Pädagogik erfahren Schüler wie Eltern eine
          ausführliche Rückmeldung zum individuellen Entwicklungs- und Lernprozess(nachfolgend
          „IzEL“). In dieser Dokumentation sind Beobachtungen zur Persönlichkeitsentwicklung, zum
          Sozial- und Arbeitsverhalten und zum Lernfortschritt festgehalten. Sie sind sowohl in Form
          von pädagogischen Wortgutachten als auch in einer kategorisierten Dokumentationsform
          abgefasst.Bei der Beobachtungssoftware IzEL-Software handelt sich um eine webbasierte
          Softwarelösung. Der IzEL wird hierbei in eine digitale Infrastruktur ausgelagert.Die
          webbasierte Software wird von uns an die jeweilige Montessori Schule lizenziert. Wir sind
          für die jeweilige Montessori Schule Auftragsverarbeiter im Sinne des Art. 28 DSGVO. Die
          jeweilige Montessori Schule hat mit uns einen Auftragsverarbeitungsvertrag im Sinne des
          Art. 28 Abs. 3 DSGVO geschlossen. Hiermit werden die Rechte und Pflichten im Hinblick auf
          die Verarbeitung der gegenständlichen personenbezogenen Daten umfassend geregelt.Beim
          Einsatz der Software werden folgende Stammdaten der Schüler verarbeitet:
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          <strong>Stammdaten:</strong>
          <ul>
            <li>Name, Vorname</li>
            <li>Geschlecht</li>
            <li>Geburtsdatum</li>
            <li>Jahrgangsstufe</li>
            <li>E-Mail-Adresse</li>
            <li>Anschrift (Ort, PLZ, Straße, Hausnummer)</li>
          </ul>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Ferner wird der Entwicklungs- und Lernfortschritt der Schüler laufend dokumentiert und
          bewertet. Diese Dokumentationen enthalten personenbezogene Daten der Schüler, insbesondere
          Eigeneinschätzungen der Schüler sowie Bewertungen der Lehrer über die oben genannten
          Entwicklungen und Fortschritte sowie der jeweiligen Lehrinhalte.Sämtliche Daten werden
          verschlüsselt abgelegt und können nur von der IzEL App entschlüsselt werden. Ferner werden
          die Daten von anderen Montessori Schulen logisch getrennt. Diesen ist es entsprechend
          nicht möglich, auf die gegenständlichen Daten zuzugreifen. Es ist durch technische und
          organisatorische Maßnahmen sichergestellt, dass nur diejenigen Personen auf die abgelegten
          Daten Zugriff erhalten, die diese zur Durchführung des Schulvertrages benötigen („need to
          know“).Wir haben entsprechend des Auftragsverarbeitungsvertrags zu gewährleisten, dass die
          Erbringung der vereinbarten Datenverarbeitung ausschließlich in einem Mitgliedsstaat der
          Europäischen Union (EU) oder in einem anderen Vertragsstaat des Abkommens über den
          Europäischen Wirtschaftsraum (EWR) stattfindet. Jede Verlagerung in ein Drittland bedarf
          der vorherigen Zustimmung der jeweiligen Montessori Schule und darf nur erfolgen, wenn die
          besonderen Voraussetzungen der Art. 44 ff. DSGVOerfüllt sind. Das Hosting der Software
          erfolgt über die Google Cloud Platform („Google Cloud“). Anbieter der Google Cloud ist die
          Google Ireland Limited, Gordon House, 4 Barrow St, Grand Canal Dock, Dublin 4, D04 V4X7,
          Irland (nachfolgend „Google“), ein Tochterunternehmen der Google LLC, 1600 Amphitheatre
          Parkway Mountain View, CA 94043, USA. Wir sind unmittelbarer Vertragspartner von Google.
          Insoweit agiert Google als unser Unterauftragsnehmer. Zwischen uns und Google wurde
          ebenfalls ein Auftragsverarbeitungsvertrag nach Art. 28 DSGVO geschlossen. Google hat sich
          ferner den Standardvertragsklauseln der EU unterworfen.Beim Hosting werden die oben
          genannten Anwendungsdaten auf Servern von Google und in der Regel innerhalb Europas
          gespeichert. Google hat zugesichert, dass für den Fall, dass personenbezogene Daten
          außerhalb der EU an Drittländer übertragen werden, die nicht durch
          Angemessenheitsbeschlüsse erfasst sind, ein Mechanismus verwendet wird, der diese
          Übertragungen gemäß der DSGVO unterstützt (siehe hierzu{' '}
          <a
            href='https://cloud.google.com/privacy/gdpr?tab=tab7&hl=de'
            target='_blank'
            rel='noreferrer'
          >
            https://cloud.google.com/privacy/gdpr?tab=tab7&hl=de
          </a>
          ).
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>6.2</strong>
        </Col>
        <Col span={23}>
          <strong>Zweck der Datenverarbeitung</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die beschriebene Datenverarbeitung erfolgt allein deshalb, um eine Durchführung des
          Schulvertrags zwischen Montessori Schule und dem Schüler zu ermöglichen. Die Angabe der
          Stammdaten ist erforderlich, um eine Identifikation des Schülers zu ermöglichen. Die
          Verarbeitung personenbezogener Daten der Lern- und Entwicklungsfortschritte dienen dem
          Zweck, die Fortschritte des Schülers dokumentiert zu haben, um diesen die bestmögliche
          Förderung zu ermöglichen.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>6.3</strong>
        </Col>
        <Col span={23}>
          <strong>Rechtsgrundlage</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Verarbeitung der personenbezogenen Daten ist zur Abwicklung des Schulvertrags
          erforderlich. Rechtsgrundlage für die Verarbeitung ist Art. 6 Abs. 1 S. 1 lit. b) DSGVO.
          Dies gilt ebenso für die Verarbeitung personenbezogener Daten durch Google.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>6.4</strong>
        </Col>
        <Col span={23}>
          <strong>Dauer der Speicherung</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Die Daten werden nur so lang gespeichert, wie dies zur Durchführung des
          Vertragsverhältnisses erforderlich ist. Endet das Vertragsverhältnis werden die Daten
          zunächst gesperrt und im Rahmen der gesetzlichen Aufbewahrungsfristen gespeichert.
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>6.5</strong>
        </Col>
        <Col span={23}>
          <strong>Weitere Informationen</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Weitere Informationen dahingehend, wie Google personenbezogene Daten verarbeitet, finden
          Sie unter{' '}
          <a
            href='https://cloud.google.com/terms/cloud-privacy-notice?hl=de'
            target='_blank'
            rel='noreferrer'
          >
            https://cloud.google.com/terms/cloud-privacy-notice?hl=de
          </a>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>7.</strong>
        </Col>
        <Col span={23}>
          <strong>Ihre Rechte</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}> </Col>
        <Col span={23}>
          Sie haben das Recht: <br />
          <ul>
            <li>
              gemäß Art. 15 DSGVO Auskunft über Ihre von uns verarbeiteten personenbezogenen Daten
              zu verlangen. Insbesondere können Sie Auskunft über die Verarbeitungszwecke, die
              Kategorie der personenbezogenen Daten, die Kategorien von Empfängern, gegenüber denen
              Ihre Daten offengelegt wurden oder werden, die geplante Speicherdauer, das Bestehen
              eines Rechts auf Berichtigung, Löschung, Einschränkung der Verarbeitung oder
              Widerspruch, das Bestehen eines Beschwerderechts, die Herkunft Ihrer Daten, sofern
              diese nicht bei uns erhoben wurden, sowie über das Bestehen einer automatisierten
              Entscheidungsfindung einschließlich Profiling und ggf. aussagekräftigen Informationen
              zu deren Einzelheiten verlangen;
            </li>
            <li>
              gemäß Art. 16 DSGVO unverzüglich die Berichtigung unrichtiger oder Vervollständigung
              Ihrer bei uns gespeicherten personenbezogenen Daten zu verlangen;
            </li>
            <li>
              gemäß Art. 17 DSGVO die Löschung Ihrer bei uns gespeicherten personenbezogenen Daten
              zu verlangen, soweit nicht die Verarbeitung zur Ausübung des Rechts auf freie
              Meinungsäußerung und Information, zur Erfüllung einer rechtlichen Verpflichtung, aus
              Gründen des öffentlichen Interesses oder zur Geltendmachung, Ausübung oder
              Verteidigung von Rechtsansprüchen erforderlich ist;
            </li>
            <li>
              gemäß Art. 18 DSGVO die Einschränkung der Verarbeitung Ihrer personenbezogenen Daten
              zu verlangen, soweit die Richtigkeit der Daten von Ihnen bestritten wird, die
              Verarbeitung unrechtmäßig ist, Sie aber deren Löschung ablehnen und wir die Daten
              nicht mehr benötigen, Sie jedoch diese zur Geltendmachung, Ausübung oder Verteidigung
              von Rechtsansprüchen benötigen oder Sie gemäß Art. 21 DSGVO Widerspruch gegen die
              Verarbeitung eingelegt haben;
            </li>
            <li>
              gemäß Art. 20 DSGVO Ihre personenbezogenen Daten, die Sie uns bereitgestellt haben, in
              einem strukturierten, gängigen und maschinenlesbaren Format zu erhalten oder die
              Übermittlung an einen anderen Verantwortlichen zu verlangen;
            </li>
            <li>
              gemäß Art. 7 Abs. 3 DSGVO Ihre einmal erteilte Einwilligung jederzeit gegenüber uns zu
              widerrufen. Dies hat zur Folge, dass wir die Datenverarbeitung, die auf dieser
              Einwilligung beruhte, für die Zukunft nicht mehr fortführen dürfen und
            </li>
            <li>
              gemäß Art. 77 DSGVO sich bei einer Aufsichtsbehörde zu beschweren. In der Regel können
              Sie sich hierfür an die Aufsichtsbehörde Ihres üblichen Aufenthaltsortes oder
              Arbeitsplatzes oder unseres Unternehmenssitzes wenden.
            </li>
          </ul>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}>
          <strong>8.</strong>
        </Col>
        <Col span={23}>
          <strong>Widerspruch oder Widerruf gegen die Verarbeitung Ihrer Daten</strong>
        </Col>
      </Row>
      <Row className={styles.mb8}>
        <Col span={1}></Col>
        <Col span={23}>
          Falls Sie eine Einwilligung zur Verarbeitung Ihrer Daten erteilt haben, können Sie diese
          jederzeit gemäß Art. 7 Abs. 3 DSGVO widerrufen. Ein solcher Widerruf beeinflusst die
          Zulässigkeit der Verarbeitung Ihrer personenbezogenen Daten, nachdem Sie ihn gegenüber uns
          ausgesprochen haben. Soweit die Verarbeitung Ihrer personenbezogenen Daten aufgrund
          unserer berechtigten Interessen gemäß Art. 6 Abs. 1 S. 1 lit. f DSGVO erfolgt, haben Sie
          das Recht, gemäß Art. 21 DSGVO Widerspruch gegen die Verarbeitung einzulegen. Dies ist der
          Fall, wenn die Verarbeitung insbesondere nicht zur Erfüllung eines Vertrags mit Ihnen
          erforderlich ist, was von uns jeweils bei der Beschreibung der Funktionen dargestellt
          wird. Bei Ausübung eines solchen Widerspruchs bitten wir um Darlegung der Gründe, weshalb
          wir Ihre personenbezogenen Daten nicht wie von uns durchgeführt verarbeiten sollten. Im
          Falle Ihres begründeten Widerspruchs prüfen wir die Sachlage und werden entweder die
          Datenverarbeitung einstellen bzw. anpassen oder Ihnen unsere zwingenden schutzwürdigen
          Gründe aufzeigen, aufgrund derer wir die Verarbeitung fortführen. Selbstverständlich
          können Sie der Verarbeitung Ihrer personenbezogenen Daten für Zwecke der Werbung und
          Datenanalyse jederzeit widersprechen. Über Ihren Werbewiderspruch können Sie uns unter den
          vorstehend genannten Kontaktdaten informieren.
        </Col>
      </Row>
    </div>
  );
};

export default Daten;
