import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import { client, pdfClient } from 'store/services/client';
import {
  createItemObservationParamTypes,
  createPersonalLetterParamTypes,
  createPracticeParamTypes,
  createStudentObservationParamTypes,
  createSubItemObservationParamTypes,
  createSubjectObservationParamTypes,
  evaluateSubItemTypes,
  getAllObservationsParamTypes,
  getObservationItemsParamTypes,
  getObservationSubItemsParamTypes,
  getObservationSubjectsParamTypes,
  getPersonalLetterParamTypes,
  getPracticeParamTypes,
  getReportDocumentParamTypes,
  getReportParamTypes,
  getSchoolYearsParamTypes,
  itemObservationParamTypes,
  removePracticeParamTypes,
  saveInterimEvaluationsParamTypes,
  studentObservationParamTypes,
  subItemObservationParamTypes,
  subjectObservationParamTypes,
  updatePracticeParamTypes,
} from 'store/actions/report.actions';

export const getReportService = (params: getReportParamTypes): [ApiCall, string] => [
  client.get,
  `/students/${params.id}/izel?page=${params.page}&size=25${
    params.izelId ? '&izelId=' + params.izelId : ''
  }${params.showActualData ? '&showActualData=true' : ''}`,
];

export const getSchoolYearsService = (params: getSchoolYearsParamTypes): [ApiCall, string] => [
  client.get,
  `/students/${params.id}/school-years`,
];

export const evaluateSubItemService = (
  params: evaluateSubItemTypes,
): [
  ApiCallWithParams,
  string,
  { subItemId: number; assessmentScaleValueId: number; izelId: number },
] => [
  client.post,
  `/students/${params.studentId}/evaluations`,
  {
    subItemId: params.subItemId,
    assessmentScaleValueId: params.assessmentScaleValueId,
    izelId: params.izelId,
  },
];

export const getStudentObservationService = (
  params: studentObservationParamTypes,
): [ApiCall, string] => [client.get, `/students/${params.id}/observations?izelId=${params.izelId}`];

export const getSubjectObservationService = (
  params: subjectObservationParamTypes,
): [ApiCall, string] => [
  client.get,
  `/subjects/${params.id}/observations?studentId=${params.studentId}&izelId=${params.izelId}`,
];

export const getItemObservationService = (params: itemObservationParamTypes): [ApiCall, string] => [
  client.get,
  `/items/${params.itemId}/observations?studentId=${params.id}&izelId=${params.izelId}`,
];

export const getSubItemObservationService = (
  params: subItemObservationParamTypes,
): [ApiCall, string] => [
  client.get,
  `/items/${params.itemId}/sub-items/${params.subItemId}/observations?studentId=${params.id}&izelId=${params.izelId}`,
];

export const getSubItemEvaluationService = (
  params: subItemObservationParamTypes,
): [ApiCall, string] => [
  client.get,
  `/items/${params.itemId}/sub-items/${params.subItemId}/evaluations?studentId=${params.id}&izelId=${params.izelId}`,
];

export const getPracticeService = (params: getPracticeParamTypes): [ApiCall, string] => [
  client.get,
  `/students/${params.id}/internships?izelId=${params.izelId}&page=${params.page}&size=${params.size}`,
];

export const createPracticeService = (
  params: createPracticeParamTypes,
): [ApiCallWithParams, string, any] => [
  client.post,
  `/students/${params.studentId}/internships`,
  {
    startDate: params.startDate,
    endDate: params.endDate,
    city: params.city,
    description: params.description,
    organization: params.organization,
    izelId: params.izelId,
  },
];

export const updatePracticeService = (
  params: updatePracticeParamTypes,
): [ApiCallWithParams, string, any] => [
  client.put,
  `/students/${params.studentId}/internships/${params.id}`,
  {
    startDate: params.startDate,
    endDate: params.endDate,
    city: params.city,
    description: params.description,
    organization: params.organization,
    izelId: params.izelId,
  },
];

export const removePracticeService = (params: removePracticeParamTypes): [ApiCall, string] => [
  client.delete,
  `/students/${params.studentId}/internships/${params.id}?izelId=${params.izelId}`,
];

export const getPersonalLetterService = (
  params: getPersonalLetterParamTypes,
): [ApiCall, string] => [
  client.get,
  `/students/${params.studentId}/personal-letter?izelId=${params.izelId}`,
];

export const createPersonalLetterService = (
  params: createPersonalLetterParamTypes,
): [ApiCallWithParams, string, { text: string | null; izelId: number }] => [
  client.post,
  `/students/${params.studentId}/personal-letter`,
  { text: params.text, izelId: params.izelId },
];

export const getAllObservationsService = (
  params: getAllObservationsParamTypes,
): [ApiCall, string] => {
  const subjectIdsQuery = params.subjectIds?.map((id) => `subjectIds=${id}&`).join('');
  const itemIdsQuery = params.itemIds?.map((id) => `itemIds=${id}&`).join('');
  const subItemIdsQuery = params.subItemIds?.map((id) => `subItemIds=${id}&`).join('');
  return [
    client.get,
    `/students/${params.studentId}/observations?page=${params.page}&size=${params.size}${
      params.profile ? `&profile=${params.profile}` : '&all=true'
    }${params.izelId ? `&izelId=${params.izelId}` : ''}${
      params.onlyMyDocumentation ? `&onlyMyDocumentation=${params.onlyMyDocumentation}` : ''
    }${params.personalObservations ? `&personalObservations=${params.personalObservations}` : ''}${
      params.startDate ? `&startDate=${params.startDate}` : ''
    }${params.endDate ? `&endDate=${params.endDate}` : ''}&${subjectIdsQuery || ''}${
      itemIdsQuery || ''
    }${subItemIdsQuery || ''}`,
  ];
};

export const getReportDocumentService = (
  params: getReportDocumentParamTypes,
): [ApiCall, string] => [
  pdfClient.get,
  params.printLetter && !params.printReport
    ? `/reports/personal-letter?studentId=${params.studentId}&izelId=${params.izelId}`
    : `/reports/izel?studentId=${params.studentId}&printPersonalLetter=${params.printLetter}&izelId=${params.izelId}`,
];

export const createStudentObservationService = (
  params: createStudentObservationParamTypes,
): [ApiCallWithParams, string, createStudentObservationParamTypes] => [
  client.post,
  `/students/${params.studentId}/observations`,
  params,
];

export const createSubjectObservationService = (
  params: createSubjectObservationParamTypes,
): [ApiCallWithParams, string, { text: string; studentId: number }] => [
  client.post,
  `/subjects/${params.subjectId}/observations`,
  { text: params.text, studentId: params.studentId },
];

export const createItemObservationService = (
  params: createItemObservationParamTypes,
): [ApiCallWithParams, string, { text: string; studentId: number }] => [
  client.post,
  `/items/${params.itemId}/observations`,
  { text: params.text, studentId: params.studentId },
];

export const createSubItemObservationService = (
  params: createSubItemObservationParamTypes,
): [ApiCallWithParams, string, { text: string; studentId: number }] => [
  client.post,
  `/items/${params.itemId}/sub-items/${params.subItemId}/observations`,
  { text: params.text, studentId: params.studentId },
];

export const saveInterimEvaluationsService = (
  params: saveInterimEvaluationsParamTypes,
): [ApiCallWithParams, string, Array<{ subItemId: number; assessmentScaleValueId: number }>] => [
  client.post,
  `/students/${params.id}/evaluations?interim=true`,
  params.selectedScales,
];

export const getObservationSubjectsService = (
  params: getObservationSubjectsParamTypes,
): [ApiCall, string] => [
  client.get,
  `/students/${params.studentId}/observations/subjects?${
    params.izelId ? `&izelId=${params.izelId}` : ''
  }`,
];

export const getObservationItemsService = (
  params: getObservationItemsParamTypes,
): [ApiCall, string] => {
  const subjectIdsQuery = params.subjectIds?.map((id) => `subjectIds=${id}&`).join('');
  return [client.get, `/students/${params.id}/observations/items?${subjectIdsQuery}`];
};

export const getObservationSubItemsService = (
  params: getObservationSubItemsParamTypes,
): [ApiCall, string] => {
  const itemIdsQuery = params.itemIds?.map((id) => `itemIds=${id}&`).join('');
  return [client.get, `/students/${params.id}/observations/sub-items?${itemIdsQuery}`];
};
