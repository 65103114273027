import styles from 'pages/Legal/styles.module.css';
import { Col, Row } from 'antd';

const Nutzun = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mb20}>
        <strong>
          LIZENZBEDINGUNGEN FÜR DIE NUTZUNG VON SOFTWARE DES MONTESSORI LANDESVERBAND BAYERN E.V,
          HIRTENSTRAßE 26, 80335 MÜNCHEN DEUTSCHLAND
        </strong>
      </div>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 1</strong>
        </Col>
        <Col>
          <strong>Geltungsbereich dieser Lizenzbedingungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.1</Col>
        <Col span={23}>
          Nach den Grundsätzen der Montessori Pädagogik erfahren Schüler wie Eltern eine
          ausführliche Rückmeldung zum individuellen Entwicklungs- und Lernprozess (nachfolgend „
          <strong>IzEL</strong>“). In dieser Dokumentation sind Beobachtungen zur
          Persönlichkeitsentwicklung, zum Sozial- und Arbeitsverhalten und zum Lernfortschritt
          festgehalten. Sie sind sowohl in Form von pädagogischen Wortgutachten als auch in einer
          kategorisierten Dokumentationsform abgefasst. Der Montessori Landesverband Bayern e.V.
          (nachfolgend „<strong>Lizenzgeber</strong>
          “) ist Inhaber ausschließlicher Nutzungsrechte an der Software [IzEL-Software link*]
          (nachfolgend „Vertragssoftware“). Es handelt es sich um eine browserbasierte
          Softwarelösung. Die Vertragssoftware ermöglicht es den
          <strong>IzEL</strong> in eine digitale Infrastruktur auszulagern. Der Schulträger betreibt
          auf der Grundlage der Pädagogik Maria Montessoris eine staatlich genehmigte Ersatzschule
          in freier Trägerschaft gemäß Artikel 7 Absatz 4 des Grundgesetzes (GG) sowie Artikel 134
          der Verfassung des Freistaates Bayern (BV) (im Folgenden „<strong>Lizenznehmer</strong>“
          genannt). Der
          <strong>Lizenznehmer</strong>
          plant, die von dem
          <strong>Lizenzgeber</strong>
          angebotene Vertragssoftware an seiner Schule einzusetzen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.2</Col>
        <Col span={23}>
          Diese Lizenzbedingungen gelten zwischen dem <strong>Lizenzgeber</strong> und dem
          <strong>Lizenznehmer</strong>, soweit dem <strong>Lizenznehmer</strong> zeitlich auf die
          Vertragslaufzeit befristet die Vertragssoftware entgeltlich zur Nutzung überlassen wird.
          Für sonstige Lieferungen und Leistungen anderer Art des
          <strong>Lizenzgebers</strong>, insbesondere hinsichtlich der notwendigen Hardware, ferner
          für sonstige Drittsoftware oder -hardware können darüber hinaus weitergehende oder
          ergänzende Vertragsbedingungen gelten. Ferner gelten regelmäßig für den Zugang zum
          Internet oder Mobilfunknetz sowie deren Nutzung ggfs. gesonderte Vertragsbedingungen der
          entsprechenden Telekommunikationsdienste.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.3</Col>
        <Col span={23}>
          Individuelle Vertragsabreden haben darüber hinaus Vorrang vor diesen Lizenzbedingen.
          Abweichende, entgegenstehende oder ergänzende Allgemeine LIZENZBEDINGUNGEN FÜR DIE NUTZUNG
          VON SOFTWARE DES MONTESSORI LANDESVERBAND BAYERN E.V, HIRTENSTRAßE 26, 80335 MÜNCHEN
          DEUTSCHLAND Seite 2 | 14.07.2023Geschäftsbedingungen des <strong>Lizenznehmers</strong>
          werden nicht Vertragsbestandteil, es sei denn, ihrer Geltung wird ausdrücklich zugestimmt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.4</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> hat sein Einverständnis mit der Geltung dieses
          Lizenzbedingungen bereits vor dem Vertragsschluss erklärt. Spätestens durch eine Nutzung
          der Vertragssoftware werden diese Lizenzbedingungen als verbindlich anerkannt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 2</strong>
        </Col>
        <Col span={23}>
          <strong>Lizenzgegenstand</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>2.1</Col>
        <Col span={23}>
          Gegenstand der Lizenz ist die Nutzung der Vertragssoftware, die dem
          <strong>Lizenznehmer</strong>entgeltlich vom <strong>Lizenzgeber</strong> überlassen wird.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>2.2</Col>
        <Col span={23}>
          Es besteht seitens des <strong>Lizenznehmers</strong> kein Anspruch auf Überlassung des
          Quellcodes.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>2.3</Col>
        <Col span={23}>
          Für Software-Produkte Dritter gelten die Lizenzbedingungen des jeweiligen Herstellers. Das
          gilt auch für Open Source Software.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 3</strong>
        </Col>
        <Col span={23}>
          <strong>Selbstbelieferungsvorbehalt</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}></Col>
        <Col span={23}>
          Der Vertragsschluss erfolgt unter dem Vorbehalt, im Falle nicht richtiger oder nicht
          ordnungsgemäßer Selbstbelieferung, nicht oder nur teilweise zu leisten. Dies gilt nur für
          den Fall, dass die Nichtlieferung nicht vom <strong>Lizenzgeber</strong> zu vertreten ist
          und mit der gebotenen Sorgfalt ein konkretes Deckungsgeschäft abgeschlossen wurde. Der{' '}
          <strong>Lizenzgeber</strong>
          wird alle zumutbaren Anstrengungen unternehmen, um die vertragsgemäß geschuldeten
          Leistungen bereitstellen zu können. Andernfalls wird die Gegenleistung unverzüglich
          zurückerstattet. Im Falle der Nichtverfügbarkeit oder der nur teilweisen Verfügbarkeit
          wird der <strong>Lizenznehmer</strong> unverzüglich informiert.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 4</strong>
        </Col>
        <Col span={23}>
          <strong>Nutzungsrechte</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.1</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> erhält ein zeitlich und räumlich unbeschränktes, nicht
          jedoch ausschließliches, einfaches und unterlizenzierbares Recht, den Lizenzgegenstand
          nach Maßgabe dieser Lizenzbedingungen zu nutzen und zu vervielfältigen. Eine
          Unterlizenzierung ist nur im Rahmen des § 4 Abs. 3 möglich.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.2</Col>
        <Col span={23}>
          Das Recht zur Vervielfältigung beschränkt auf die Vervielfältigung, die für das Laden,
          Anzeigen, Ablaufen, Übertragen und Speichern der Vertragssoftware erforderlich ist, sowie
          weiter auf das Recht, Sicherheitskopien im für seinen Betrieb erforderlichen Umfang durch
          eine dafür berechtigte Person gemäß § 69 d Abs. 2 UrhG anzufertigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.3</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> ist berechtigt Dritten Nutzungsrechte an der
          Vertragssoftware gemäß diesen Lizenzbedingungen unentgeltlich einzuräumen soweit wie es
          für die ordnungsgemäße Nutzung der Vertragssoftware erforderlich ist. Insbesondere ist der{' '}
          <strong>Lizenznehmer</strong>
          berechtigt seinen bei ihm tätigen Lehrern sowie Schülern und deren Eltern entsprechende
          Nutzungsrechte unentgeltlich einzuräumen. Eine darüberhinausgehende Übertragung von
          Nutzungsrechten bedarf der ausdrücklichen Zustimmung des <strong>Lizenzgebers</strong>.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.4</Col>
        <Col span={23}>
          Das Recht zur Bearbeitung der Vertragssoftware ist beschränkt auf den Erhalt oder die
          Wiederherstellung der vereinbarten Funktionalität der Vertragssoftware. Eine Bearbeitung
          ist nur zulässig, wenn sie für die Beseitigung eines Mangels notwendig ist und der
          <strong>Lizenzgeber</strong> sich mit der Berichtigung des Mangels in Verzug befindet, die
          Mängelbeseitigung unberechtigt ablehnt oder aus sonstigen, dem{' '}
          <strong>Lizenzgeber</strong>zuzurechnenden Gründen zur unverzüglichen Mängelbeseitigung
          außerstande ist. Eine Bearbeitung ist auch zulässig, wenn sie zur Behebung von
          Kompatibilitätsproblemen beim bestimmungsgemäßen Zusammenwirken der Software mit anderen
          vom <strong>Lizenznehmer</strong> benötigten Programmen erforderlich ist, und der{' '}
          <strong>Lizenzgeber</strong> nicht bereit oder in der Lage ist, diese gegen eine
          angemessene marktübliche Vergütung zu beseitigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.5</Col>
        <Col span={23}>
          Es wird nochmals ausdrücklich klargestellt, dass die Vertragssoftware auch für andere
          <strong>Lizenznehmer</strong> zur Nutzung überlassen wird. Die Bearbeitung der
          Vertragssoftware ist dem
          <strong>Lizenznehmer</strong> ausschließlich im Rahmen des vorgenannten § 4 Abs. 4
          gestattet. Es ist dem
          <strong>Lizenznehmer</strong> ausdrücklich untersagt Bearbeitungen an der Vertragssoftware
          vorzunehmen, wenn hiermit eine Änderung des Funktionsumfangs der Vertragssoftware erreicht
          werden soll. Der <strong>Lizenznehmer</strong> kann jedoch mit Änderungswünschen an der
          Vertragssoftware auf den
          <strong>Lizenzgeber</strong> zukommen. In diesem Fall wird der{' '}
          <strong>Lizenzgeber</strong> mit seinen weiteren <strong>Lizenznehmer</strong>n den
          Änderungswunsch diskutieren und im Falle eines mehrheitlichen Änderungswunsches diesen
          versuchen umzusetzen. Es besteht jedoch kein Anspruch des <strong>Lizenznehmer</strong>
          sauf Änderungen an der Vertragssoftware. Hierbei handelt es sich ausschließlich um eine
          Ermessensentscheidung des <strong>Lizenzgebers</strong>.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.6</Col>
        <Col span={23}>
          Das Recht zur Dekompilierung von Software wird nur unter der Bedingung des § 69 e Abs. 1
          Nr. 1 bis 3 UrhG und im Rahmen des § 69 e Abs. 2 Nr. 1 bis 3 UrhG gewährt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.7</Col>
        <Col span={23}>
          Diese Lizenz berechtigt nicht dazu, zukünftige Upgrades, Updates (mit Ausnahme von
          Sicherheitsupdates) oder Erweiterungen der Software zu erhalten. Falls solche Upgrades,
          Updates oder Erweiterungen bezogen werden, unterliegt die Nutzung dieser Upgrades oder
          Updates sowie die Änderungen, denen sie unterliegen können, gleichwohl diesen
          Lizenzbedingungen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.8</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> ist nicht berechtigt, technische Schutzmaßnahmen von
          Software zu entfernen oder zu umgehen, es sei denn, dies ist erforderlich, um die
          störungsfreie Programmnutzung zu erreichen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.9</Col>
        <Col span={23}>
          Verstößt der <strong>Lizenznehmer</strong> gegen eine der vorstehenden Bestimmungen,
          werden sämtliche im Rahmen des Vertragsverhältnisses erteilten Nutzungsrechte sofort
          unwirksam und fallen automatisch an den <strong>Lizenzgeber</strong> zurück. In diesem
          Fall hat der
          <strong>Lizenznehmer</strong> die Nutzung der Vertragssoftware unverzüglich und
          vollständig einzustellen sowie ggf. erstellte Sicherungskopien zu löschen und dem{' '}
          <strong>Lizenzgeber</strong>
          auszuhändigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.10</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> kann die Rechte nach § 4 aus wichtigem Grund beenden. Ein
          wichtiger Grund liegt insbesondere vor, wenn der <strong>Lizenzgeber</strong> das weitere
          Festhalten am Softwarevertrag nicht zuzumuten ist, insbesondere wenn der{' '}
          <strong>Lizenznehmer</strong> die Vergütung nicht zahlt oder in erheblicher Weise gegen §
          4 verstößt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.11</Col>
        <Col span={23}>
          Weitergehende Nutzungsrechte an Software werden dem <strong>Lizenznehmer</strong> nicht
          eingeräumt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.12</Col>
        <Col span={23}>Vorgenannte Regelungen binden die Parteien auch schuldrechtlich.</Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 5</strong>
        </Col>
        <Col span={23}>
          <strong>
            Rechte des <strong>Lizenznehmers</strong> an etwa entstehenden
            Datenbanken/Datenbankwerken
          </strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>5.1</Col>
        <Col span={23}>
          Sofern und soweit während der Laufzeit des Vertrages, insb. durch Zusammenstellung von
          Anwendungsdaten, durch nach diesem Vertrag erlaubte Tätigkeiten des{' '}
          <strong>Lizenznehmers</strong> auf dem Server von des <strong>Lizenzgebers</strong> eine
          Datenbank, Datenbanken, ein Datenbankwerk oder Datenbankwerke entstehen, stehen alle
          Rechte hieran dem <strong>Lizenznehmer</strong>
          zu. Der <strong>Lizenznehmer</strong> bleibt auch nach Vertragsende Eigentümer der
          Datenbanken bzw. Datenbankwerke.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>5.2</Col>
        <Col span={23}>
          Dem <strong>Lizenzgeber</strong> verbleibt an anonymisierten Anwendungsdaten ein
          einfaches, räumlich unbeschränktes, zeitlich unbefristetes übertragbares Recht, diese
          Daten zu internen Zwecken zu verarbeiten, zu statistischen Auswertungen zu übertragen, zu
          Zwecken der Marktforschung, zur Gewinnung von Erkenntnissen zur Verbesserung eigener
          Leistungen sowie zur technischen Administration zu verwenden. Die anonymisierten Daten
          werden keinesfalls mit den personenbezogenen Daten in Verbindung gebracht. Damit ist
          ausgeschlossen, dass hiermit Rückschlüsse auf eine bestimmte Person möglich sind.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 6</strong>
        </Col>
        <Col span={23}>
          <strong>Open Source Software</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.1</Col>
        <Col span={23}>
          Die Software kann Open Source Software enthalten, die der <strong>Lizenzgeber</strong> von
          einem Dritten erhalten hat. Für die Einbeziehung Open Source Software hat der{' '}
          <strong>Lizenzgeber</strong> keine Lizenzgebühren gezahlt; für die Nutzung der Open Source
          Software werden dem <strong>Lizenznehmer</strong> entsprechend auch keine Lizenzgebühren
          berechnet.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.2</Col>
        <Col span={23}>
          Für Open Source Software gelten ausschließlich die dieser Software zugrundliegenden
          Lizenzbedingungen des Rechteinhabers. Der <strong>Lizenzgeber</strong> liefert die
          entsprechenden Softwarelizenzbedingungen für die Open Source Software entweder in der
          Dokumentation zur Software mit oder stellt in der Dokumentation den Link zur Verfügung,
          unter welchem die geltenden Lizenzbedingungen herunterladen werden können. Der
          <strong>Lizenznehmer</strong> wird die Nutzungsbedingungen einhalten.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.3</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> steht nicht für Fehler an Open Source Software ein.
          Gewährleistungsansprüche bezüglich der genutzten Open Source Software sind gegenüber dem
          <strong>Lizenzgeber</strong> ausgeschlossen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.4</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> haftet nicht für etwaige Schäden, gleich welcher Art, die
          dem <strong>Lizenznehmer</strong> durch die Nutzung von Open Source Software entstehen,
          auch dann nicht, wenn der
          <strong>Lizenzgeber</strong> die Open Source Software dem <strong>Lizenznehmer</strong>{' '}
          geliefert hat oder wenn der
          <strong>Lizenzgeber</strong> in der Dokumentation darauf hingewiesen hat, dass für den
          Betrieb der Software von der <strong>Lizenzgeber</strong> auch Open Source Software
          erforderlich ist. Die Haftung für Open Source Software ist dann nicht ausgeschlossen, wenn
          sie gesetzlich zwingend vorgeschrieben ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 7</strong>
        </Col>
        <Col span={23}>
          <strong>Bereitstellung der Software /Softwaredokumentation</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.1</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> stellt dem <strong>Lizenznehmer</strong> die die
          Vertragssoftware über einen Internetzugang in Verbindung mit einem marktüblichen Browser
          zur Verfügung.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.2</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> stellt dem <strong>Lizenznehmer</strong> nebst der
          Vertragssoftware in erforderlichem Umfang jeweils ein Benutzerhandbuch und eine
          Produktdokumentation zur Verfügung. Die Dokumentation erhält der{' '}
          <strong>Lizenznehmer</strong> in elektronischer Form.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.3</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> ist berechtigt, die zur Verfügung gestellte
          Dokumentation nebst Handbuch unter Aufrechterhaltung vorhandener Schutzrechtsvermerke zu
          speichern, auszudrucken und für Zwecke dieses Vertrags in angemessener Anzahl zu
          vervielfältigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 8</strong>
        </Col>
        <Col span={23}>
          <strong>
            Pflichten des <strong>Lizenznehmers</strong>
          </strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.1</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> hat die ordnungsgemäße Nutzung der Anwendungen jeweils
          durch aktive und angemessene Mitwirkungshandlungen zu fördern. Er hat dem{' '}
          <strong>Lizenzgeber</strong>die zur ordnungsgemäßen Leistungserbringung notwendigen
          Informationen und Daten zur Verfügung zustellen. Der <strong>Lizenznehmer</strong> ist
          dafür verantwortlich, dass bei ihm die technischen Voraussetzungen für die Nutzung der
          Anwendungen geschaffen werden, insbesondere im Hinblick auf die eingesetzte Hard- und
          Software, die Verbindung mit dem Internet und aktuelle Browsersoftware.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.2</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> hat grundsätzlich das geltende Recht der Bundesrepublik
          Deutschland, insbesondere Daten- und Jugendschutzvorschriften, strafrechtliche
          Bestimmungen sowie die vorliegenden Lizenzbedingungen zu beachten. Insbesondere ist der{' '}
          <strong>Lizenznehmer</strong>
          verpflichtet:
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.2.1</Col>
        <Col span={23}>
          bereit gestellte Zugangsdaten sowie entsprechende Identifikations- und
          Authentifikationsmechanismen vor dem Zugriff unbefugter Dritter zu schützen und an solche
          Dritte nicht weiterzugeben;
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.2.2</Col>
        <Col span={23}>
          auf dem zu Verfügung gestellten Speicherplatz keine rechtswidrigen, die Gesetze,
          behördlichen Auflagen verletzenden Inhalte abzulegen;
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.2.3</Col>
        <Col span={23}>
          Rechte Dritter, insbesondere Urheber- und Leistungsschutzrechte, Marken-, Patent- und
          sonstige Eigentums- sowie Persönlichkeitsrechte, nicht zu verletzen;
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.2.4</Col>
        <Col span={23}>
          keine Anwendungen auszuführen, die zu einer Veränderung der physikalischen oder logischen
          Struktur der Netzwerke führen können, wie etwa Viren.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.3</Col>
        <Col span={23}>
          Grundsätzlich hat der <strong>Lizenznehmer</strong> sich selbst um angemessene
          Vorkehrungen zur Datensicherung zu kümmern, damit die Daten aus den Datenbeständen, die in
          maschinenlesbarer Form bereitgehalten werden, mit vertretbarem Aufwand reproduziert werden
          können.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.4</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> darf den nach § 4 Abs. 3 betroffenem Personenkreis keine
          weitergehenden Nutzungsrechte einräumen, als ihm selbst nach diesen Lizenzvereinbarung
          zusteht.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>8.5</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> ist verpflichtet, die mit dem{' '}
          <strong>Lizenzgeber</strong> abgestimmte Endnutzerlizenzvereinbarung in das
          Vertragsverhältnis mit dem nach § 4 Abs. 3 betroffenem Personenkreis mit einzubeziehen.
          Die mit dem <strong>Lizenzgeber</strong> abgestimmte Endnutzerlizenzvereinbarung ist
          einsehbar unter{' '}
          <a
            href={`${window.location.origin}/endnutzerlizenzbedingungen`}
            target='_blank'
            rel='noreferrer'
          >{`${window.location.origin}/endnutzerlizenzbedingungen`}</a>
          .
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 9</strong>
        </Col>
        <Col span={23}>
          <strong>Freistellung</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.1</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> ist für eigene Inhalte des <strong>Lizenznehmers</strong>
          grundsätzlich nicht verantwortlich. Insbesondere ist der <strong>Lizenzgeber</strong>{' '}
          nicht verpflichtet, die Inhalte auf mögliche Rechtsverstöße zu überprüfen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.2</Col>
        <Col span={23}>
          Der <strong>Lizenznehmer</strong> stellt den <strong>Lizenzgeber</strong> von sämtlichen
          berechtigten Ansprüchen frei, die Dritte gegen diese wegen der Verletzung ihrer Rechte
          geltend machen und die der <strong>Lizenznehmer</strong> zu vertreten hat. Der{' '}
          <strong>Lizenznehmer</strong> übernimmt diesbezüglich auch die Kosten der
          Rechtsverteidigung des <strong>Lizenzgebers</strong> einschließlich sämtlicher Gerichts-
          und Anwaltskosten in Höhe der gesetzlichen Gebühren. Hierfür hat der
          <strong>Lizenznehmer</strong> dem
          <strong>Lizenzgeber</strong> einen angemessenen Vorschuss zu gewähren.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 10</strong>
        </Col>
        <Col span={23}>
          <strong>Gewährleistung</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.1</Col>
        <Col span={23}>
          Es wird ausdrücklich darauf hingewiesen, dass die störungsfreie und uneingeschränkte
          Beschaffenheit und Funktionalität der Software regelmäßig auch von Softwarekomponenten
          dritter Anbieter abhängig ist. Jegliche Veränderungen in solchen Softwarekomponenten bzw.
          in den Hardware- und Softwareumgebungen des <strong>Lizenznehmers</strong> können zu
          Einschränkungen der Funktionalität, der von dem <strong>Lizenzgeber</strong> zu
          überlassenden Software führen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.2</Col>
        <Col span={23}>
          Beschaffenheit und Funktionalität der Software ergeben sich aus der entsprechenden
          Leistungsbeschreibung, welche nicht als Garantie zu verstehen sind. Eine Garantie wird nur
          gewährt, wenn sie als solche ausdrücklich bezeichnet worden ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.3</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> erbringt alle Lieferungen und Leistungen nach dem
          aktuellen Stand der Technik.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.4</Col>
        <Col span={23}>
          Es gilt allgemein für bereit gestellte Softwareanwendungen die ausdrückliche
          Einschränkung, dass keine auf dem Markt befindliche Software bzw. IT-Infrastruktur zu 100
          % sicher und zu 100 % frei von Mängeln ist. Dies ist u. a. auf die Vielzahl der im Umlauf
          befindlichen Viren und auf den Umstand zurückzuführen, dass grundsätzlich
          Sicherheitsrisiken bestehen, denen nach dem jeweils herrschenden Stand der Technik ggfs.
          noch gar nicht entgegengewirkt werden kann. Der <strong>Lizenzgeber</strong> kann per se
          keinen Schutz vor unsachgemäßen Bedienungen oder Veränderungen von Softwareanwendungen,
          vor einer etwaigen Verseuchung von Softwarekomponenten mit Computerviren oder sonstiger
          Schadsoftware sowie vor sonstige Sicherheitslücken liefern, die nicht im Einflussbereich
          des <strong>Lizenzgebers</strong> liegen oder sonst auch nicht von dem{' '}
          <strong>Lizenzgeber</strong> zu vertreten sind. Die von dem
          <strong>Lizenzgeber</strong> gelieferten Leistungen schützen nicht vor möglichen
          Verletzungen des geistigen Eigentums oder anderen Seite 8 | 14.07.2023rechtswidrigen
          Tätigkeiten Dritter – etwa durch Cyber-Angriffe/Hacker-Attacken, Ausspähen und Abfangen
          von Daten oder sonstigen rechtswidrigen Datenveränderungen und Computersabotagen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.5</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> gewährleistet im Allgemeinen, dass die geschuldeten
          Leistungen frei von wesentlichen, die gewöhnliche Verwendung der Leistungen
          einschränkenden Mängeln und Rechten Dritter sind. Der <strong>Lizenzgeber</strong> steht
          dafür ein, dass die von dem <strong>Lizenzgeber</strong>
          geschuldeten Leistungen die Beschaffenheit haben, die bei Leistungen der gleichen Art
          üblich ist und die der <strong>Lizenzgeber</strong> nach der Art der konkreten Leistung
          erwarten kann. Nach Maßgabe der vorstehenden Regelungen aus § 6 Abs. 4 wird ausdrücklich
          darauf hingewiesen, dass die störungsfreie und uneingeschränkte Beschaffenheit und
          Funktionalität der von dem
          <strong>Lizenzgeber</strong> geschuldeten Leistungen regelmäßig auch von Soft- und
          Hardwarekomponenten dritter Anbieter abhängig ist, die von dem{' '}
          <strong>Lizenzgeber</strong> nicht beeinflussbar sind. Insbesondere können jegliche
          Veränderungen in solchen Softwarekomponenten bzw. in den Hardware- und Softwareumgebungen
          des <strong>Lizenznehmers</strong> zu Einschränkungen der Funktionalität der von dem{' '}
          <strong>Lizenzgeber</strong> geschuldeten Leistungen führen. Einschränkungen aufgrund von
          technischen oder sonstigen Problemen, die nicht im Einflussbereich von des{' '}
          <strong>Lizenzgebers</strong> liegen (höhere Gewalt, Verschulden Dritter etc.) sind dem{' '}
          <strong>Lizenzgeber</strong>nicht zuzurechnen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.6</Col>
        <Col span={23}>
          Die in die ggfs. bereit gestellte Infrastruktur des <strong>Lizenzgebers</strong>
          eingestellten Inhalte sind für den <strong>Lizenzgeber</strong> fremde Inhalte. Die
          rechtliche Verantwortung liegt diesbezüglich bei dem <strong>Lizenznehmer</strong>.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.7</Col>
        <Col span={23}>
          Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht
          fehlerfrei und/oder jederzeit verfügbar gewährleistet werden. Daher übernimmt der
          <strong>Lizenzgeber</strong> keine Gewähr für technische Mängel, die nicht vom{' '}
          <strong>Lizenzgeber</strong> zu vertreten sind, insbesondere für die ständige und
          ununterbrochene Verfügbarkeit der Datenbanken und ihrer Inhalte oder für die vollständige
          und fehlerfreie Wiedergabe
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 11</strong>
        </Col>
        <Col span={23}>
          <strong>Haftung</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>11.1</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> übernimmt keine Haftung für die unterbrechungsfreie
          Verfügbarkeit von Systemen sowie für systembedingte Ausfälle, Unterbrechungen und
          Störungen der technischen Anlagen und der Dienste, die nicht von dem{' '}
          <strong>Lizenzgeber</strong> zu vertreten sind. Der <strong>Lizenzgeber</strong>
          haftet insbesondere nicht für Störungen der Qualität des Zugangs zu Leistungen aufgrund
          höherer Gewalt oder aufgrund von Ereignissen, die der <strong>Lizenzgeber</strong> nicht
          zu vertreten hat. Darunter fallen insbesondere Streiks, Aussperrungen, rechtmäßige
          unternehmensinterne Arbeitskampfmaßnahmen und Seite 9 | 14.07.2023behördliche Anordnungen.
          Weiter zählen hierzu auch der vollständige oder teilweise Ausfall der zur eigenen
          Leistungserbringung erforderlichen Kommunikations- und Netzwerkstrukturen und Gateways
          anderer Anbieter und Betreiber. Der <strong>Lizenzgeber</strong> ist berechtigt, die der{' '}
          <strong>Lizenzgeber</strong> obliegenden Leistungen für die Dauer des hindernden
          Ereignisses zuzüglich einer angemessenen Anlauffrist aufzuschieben. Für unwesentliche
          Unterbrechungen übernimmt der <strong>Lizenzgeber</strong> keine Haftung. Der{' '}
          <strong>Lizenzgeber</strong> haftet ferner nicht bei Fehlern aus dem Risikobereich des
          <strong>Lizenznehmers</strong>oder sonstiger Dritter, insbesondere nicht bei Fehlern, die
          verursacht wurden durch unsachgemäße Bedienung oder Veränderung der Anwendungen oder
          sonstiger Drittsoftware, durch Verseuchung entsprechender Softwarekomponenten mit
          Computerviren, Verwendung ungeeigneter Datenträger, fehlerhafte Hardware, Ausfall der
          Stromversorgung oder datenführender Leitungen, vor Fehlern aufgrund mangelnder
          Informationssicherheit oder ungeeigneter Umweltbedingungen am Ort des Betriebs von
          Anwendungen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>11.2</Col>
        <Col span={23}>
          Bei leicht fahrlässigen Pflichtverletzungen beschränkt sich die Haftung auf den nach Art
          der Ware vorhersehbaren, vertragstypischen, unmittelbaren Durchschnittsschaden. Dies gilt
          auch bei leicht fahrlässigen Pflichtverletzungen der gesetzlichen Vertreter oder
          Verrichtungs- bzw. Erfüllungsgehilfen des <strong>Lizenzgebers</strong>. Der{' '}
          <strong>Lizenzgeber</strong> haftet nicht bei leicht fahrlässiger Verletzung
          unwesentlicher Vertragspflichten. Der <strong>Lizenzgeber</strong>haftet hingegen für die
          Verletzung vertragswesentlicher Rechtspositionen des <strong>Lizenznehmers</strong>.
          Vertragswesentliche Rechtspositionen sind solche, die der Vertrag dem{' '}
          <strong>Lizenznehmer</strong> nach dem Vertragsinhalt und -zweck zu gewähren hat. Der{' '}
          <strong>Lizenzgeber</strong> haftet ferner für die Verletzung von Verpflichtungen, deren
          Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf
          deren Einhaltung der Der <strong>Lizenznehmer</strong> vertrauen darf.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>11.3</Col>
        <Col span={23}>
          Die vorstehenden Haftungsbeschränkungen betreffen nicht Ansprüche des{' '}
          <strong>Lizenznehmers</strong> aus Garantien und/oder Produkthaftung. Weiter gelten die
          Haftungsbeschränkungen nicht bei Arglist, bei Verletzung vertragswesentlicher Pflichten
          sowie dem <strong>Lizenzgeber</strong> zurechenbaren Körper- und Gesundheitsschäden bzw.
          bei Verlust des Lebens des <strong>Lizenznehmers</strong>. Die vorstehenden
          Haftungsbeschränkungen betreffen ferner keine Ansprüche bei denen die Haftung des{' '}
          <strong>Lizenzgebers</strong> gesetzlich zwingend vorgeschrieben ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>11.4</Col>
        <Col span={23}>
          Für den Verlust von Daten und/oder Programmen haftet der <strong>Lizenzgeber</strong>{' '}
          insoweit nicht, als der Schaden darauf beruht, dass der <strong>Lizenznehmer</strong> es
          zu unterlassen hat, eine Datensicherung durchzuführen und dadurch sicherzustellen, dass
          verloren gegangene Daten mit vertretbarem Aufwand wiederhergestellt werden können.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 12</strong>
        </Col>
        <Col span={23}>
          <strong>Vertraulichkeit und Datenschutz</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.1</Col>
        <Col span={23}>
          „Vertrauliche Informationen“ sind alle Informationen und Unterlagen der jeweils anderen
          Partei, die als vertraulich gekennzeichnet oder aus den Umständen heraus als vertraulich
          anzusehen sind, insbesondere Informationen über betriebliche Abläufe, Geschäftsbeziehungen
          und Know-how, sowie – für den <strong>Lizenzgeber</strong>– sämtliche Arbeitsergebnisse.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.2</Col>
        <Col span={23}>
          Die Parteien vereinbaren, über solche vertrauliche Informationen Stillschweigen zu wahren.
          Diese Verpflichtung besteht auch nach Beendigung des Vertrags fort. Von dieser
          Verpflichtung ausgenommen sind solche vertraulichen Informationen,
          <br />- die dem Empfänger bei Abschluss des Vertrags nachweislich bereits bekannt waren
          oder danach von dritter Seite bekannt werden, ohne dass dadurch eine
          Vertraulichkeitsvereinbarung, gesetzliche Vorschriften oder behördliche Anordnungen
          verletzt werden; <br />- die bei Abschluss des Vertrags öffentlich bekannt sind oder
          danach öffentlich bekannt gemacht werden, soweit dies nicht auf einer Verletzung dieses
          Vertrags beruht;
          <br />- die aufgrund gesetzlicher Verpflichtungen oder auf Anordnung eines Gerichtes oder
          einer Behörde offengelegt werden müssen. Soweit zulässig und möglich wird der zur
          Offenlegung verpflichtete Empfänger die andere Partei vorab unterrichten und ihr
          Gelegenheit geben, gegen die Offenlegung vorzugehen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.3</Col>
        <Col span={23}>
          Die Parteien werden nur solchen Dritten Zugang zu vertraulichen Informationen gewähren,
          die dem Berufsgeheimnis unterliegen oder denen zuvor die Geheimhaltungsverpflichtungen
          dieser Lizenzbedingungen entsprechende Verpflichtungen auferlegt worden sind. Des Weiteren
          werden die Parteien nur denjenigen Mitarbeitern die vertraulichen Informationen
          offenlegen, die diese für die Durchführung des Vertrags kennen müssen, und diese
          Mitarbeiter auch für die Zeit nach ihrem Ausscheiden in arbeitsrechtlich zulässigem Umfang
          zur Geheimhaltung verpflichten.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.4</Col>
        <Col span={23}>
          Jede Verarbeitung personenbezogener Daten erfolgt wechselseitig entsprechend den
          Bestimmungen der EU-Datenschutzgrundverordnung (DSGVO) sowie insbesondere den gesetzlichen
          Datenschutzbestimmungen des Bundesdatenschutzgesetzes (BDSG). Die ausführlichen
          Datenschutzbestimmungen des <strong>Lizenzgebers</strong> können im Übrigen unter{' '}
          <a
            href={`${window.location.origin}/datenschutzhinweise`}
            target='_blank'
            rel='noreferrer'
          >{`${window.location.origin}/datenschutzhinweise`}</a>{' '}
          eingesehen werden. Diese enthalten detaillierte Angaben, wie mit persönlichen Daten
          umgegangen wird, wie diese geschützt werden und welche Rechte die betroffene Person
          diesbezüglich hat.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.5</Col>
        <Col span={23}>
          Erhebt, verarbeitet oder nutzt der <strong>Lizenznehmer</strong> personenbezogene Daten,
          insbesondere seiner eigenen Nutzer, so steht er dafür ein, dass er dazu nach den
          anwendbaren, insb. datenschutzrechtlichen, Bestimmungen berechtigt ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 13</strong>
        </Col>
        <Col span={23}>
          <strong>Sperrung des Zugangs</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>13.1</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> behält sich das Recht vor, Informationen von Servern zu
          löschen und Benutzerkonten zu sperren, wenn gegen die vorliegenden Lizenzbedingungen
          verstoßen wird. Bei Verstoß gegen geltende Gesetze ist der <strong>Lizenzgeber</strong>{' '}
          berechtigt, die entsprechenden Informationen an die zuständigen staatlichen Stellen
          weiterzuleiten.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>13.2</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> behält sich das Recht vor, den Zugang zu den vereinbarten
          Leistungen vorläufig ganz oder teilweise zu sperren, wenn und soweit der{' '}
          <strong>Lizenznehmer</strong>
          diese Leistungen rechtswidrig nutzt bzw. gegen die in diesen Lizenzbedingungen verankerten
          wesentlichen Pflichten verstößt. Hiervon wird dieser umgehend per E-Mail informiert.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 14</strong>
        </Col>
        <Col span={23}>
          <strong>Änderung Lizenzbedingungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>14.1</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> behält sich das Recht vor, diese Lizenzbedingungen
          jederzeit unter Wahrung einer angemessenen Ankündigungsfrist von mindestens sechs Wochen
          zu ändern. Der
          <strong>Lizenzgeber</strong> teilt dem <strong>Lizenznehmer</strong> eine entsprechende
          Änderung in Textform mit.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>14.2</Col>
        <Col span={23}>
          Widerspricht der <strong>Lizenznehmer</strong> nicht, so gelten die geänderten
          Lizenzbedingungen als angenommen. Im Falle des Widerspruchs besteht der Vertrag
          unverändert mit dem bisherigen Lizenzbedingungen fort, der <strong>Lizenzgeber</strong>{' '}
          ist jedoch berechtigt, den Vertrag ordentlich zu kündigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 15</strong>
        </Col>
        <Col span={23}>
          <strong>Sonstiges</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>15.1</Col>
        <Col span={23}>
          Auf das Vertragsverhältnis findet deutsches materielles Recht Anwendung. Der{' '}
          <strong>Lizenznehmer</strong>
          wird darauf hingewiesen, dass IT-Leistungen Export- und Importbeschränkungen unterliegen
          können. Insbesondere können Genehmigungspflichten bestehen bzw. kann die Nutzung der
          Software oder damit verbundener Technologien im Ausland Beschränkungen unterliegen. Die
          Vertragserfüllung steht ggf. unter dem Vorbehalt, dass der Erfüllung keine Hindernisse
          aufgrund von nationalen und internationalen Vorschriften des Export- und Importrechts
          sowie keine sonstigen gesetzlichen Vorschriften entgegenstehen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>15.2</Col>
        <Col span={23}>
          Die etwaige Unwirksamkeit einzelner Bestimmungen beeinträchtigt nicht die Gültigkeit des
          übrigen Vertragsinhalts.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>15.3</Col>
        <Col span={23}>
          Ausschließlicher Gerichtsstand für alle Streitigkeiten aus dem Vertragsverhältnis ist der
          Sitz des <strong>Lizenzgebers</strong>, sofern nicht eine Norm zwingend einen anderen
          Gerichtsstand anordnet.
        </Col>
      </Row>
    </div>
  );
};

export default Nutzun;
