import styles from 'pages/Legal/styles.module.css';
import { Col, Row } from 'antd';

const Endnut = () => {
  return (
    <div className={styles.container}>
      <div className={styles.mb20}>
        <strong>END Nutzer LIZENZVEREINBARUNG FÜR DIE NUTZUNG DER SOFTWARE ‚IzEL-SOFTWARE‘</strong>
      </div>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 1</strong>
        </Col>
        <Col>
          <strong>Geltungsbereich dieser Lizenzbedingungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.1</Col>
        <Col span={23}>
          Der Schulträger betreibt auf der Grundlage der Pädagogik Maria Montessoris eine staatlich
          genehmigte Ersatzschule in freier Trägerschaft gemäß Artikel 7 Absatz 4 des Grundgesetzes
          (GG) sowie Artikel 134 der Verfassung des Freistaates Bayern (BV) (im Folgenden „
          <strong>Lizenzgeber</strong>“ genannt). Nach den Grundsätzen der Montessori Pädagogik
          erfahren Schüler wie Eltern eine ausführliche Rückmeldung zum individuellen Entwicklungs-
          und Lernprozess (nachfolgend „<strong>IzEL</strong>“). In dieser Dokumentation sind
          Beobachtungen zur Persönlichkeitsentwicklung, zum Sozial- und Arbeitsverhalten und zum
          Lernfortschritt festgehalten. Sie sind sowohl in Form von pädagogischen Wortgutachten als
          auch in einer kategorisierten Dokumentationsform abgefasst. Der
          <strong>Lizenzgeber</strong>
          ist Anbieter der Software [IzEL-Software-link*] (nachfolgend „Vertragssoftware“). Es
          handelt es sich um eine browserbasierte Softwarelösung. Die Vertragssoftware ermöglicht es
          den <strong>IzEL</strong> in eine digitale Infrastruktur auszulagern.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.2</Col>
        <Col span={23}>
          Dieses EULA gelten zwischen dem <strong>Lizenzgeber</strong> und dem{' '}
          <strong>Nutzer</strong>, soweit dem <strong>Nutzer</strong>zeitlich auf die
          Vertragslaufzeit befristet die Vertragssoftware unentgeltlich zur Nutzung überlassen wird.
          Für sonstige Lieferungen und Leistungen anderer Art des <strong>Lizenzgeber</strong>
          skönnen darüber hinaus weitergehende oder ergänzende Vertragsbedingungen gelten. Ferner
          gelten regelmäßig für den Zugang zum Internet oder Mobilfunknetz sowie deren Nutzung ggfs.
          gesonderte Vertragsbedingungen der entsprechenden Telekommunikationsdienste.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.3</Col>
        <Col span={23}>
          Individuelle Vertragsabreden haben darüber hinaus Vorrang vor diesem EULA. Abweichende,
          entgegenstehende oder ergänzende Allgemeine Geschäftsbedingungen des{' '}
          <strong>Nutzers</strong> werden nicht Vertragsbestandteil, es sei denn, ihrer Geltung wird
          ausdrücklich zugestimmt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>1.4</Col>
        <Col span={23}>
          Der <strong>Nutzer</strong> hat sein Einverständnis mit der Geltung dieses EULA bereits
          vor dem Vertragsschluss erklärt. Spätestens durch eine Nutzung der Vertragssoftware wird
          dieser EULA als verbindlich anerkannt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 2</strong>
        </Col>
        <Col>
          <strong>Lizenzgegenstand</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>2.1</Col>
        <Col span={23}>
          Gegenstand der Lizenz ist die Nutzung der Vertragssoftware, die dem{' '}
          <strong>Nutzer</strong> unentgeltlich vom <strong>Lizenzgeber</strong> überlassen wird.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>2.2</Col>
        <Col span={23}>
          Es besteht seitens des <strong>Nutzers</strong> kein Anspruch auf Überlassung des
          Quellcodes.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>2.3</Col>
        <Col span={23}>
          Für Software-Produkte Dritter gelten die Lizenzbedingungen des jeweiligen Herstellers. Das
          gilt auch für Open Source Software.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 3</strong>
        </Col>
        <Col>
          <strong>Selbstbelieferungsvorbehalt</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}></Col>
        <Col span={23}>
          Der Vertragsschluss erfolgt unter dem Vorbehalt, im Falle nicht richtiger oder nicht
          ordnungsgemäßer Selbstbelieferung, nicht oder nur teilweise zu leisten. Dies gilt nur für
          den Fall, dass die Nichtlieferung nicht vom <strong>Lizenzgeber</strong> zu vertreten ist
          und mit der gebotenen Sorgfalt ein konkretes Deckungsgeschäft abgeschlossen wurde. Der{' '}
          <strong>Lizenzgeber</strong>
          wird alle zumutbaren Anstrengungen unternehmen, um die vertragsgemäß geschuldeten
          Leistungen bereitstellen zu können. Andernfalls wird die Gegenleistung unverzüglich
          zurückerstattet. Im Falle der Nichtverfügbarkeit oder der nur teilweisen Verfügbarkeit
          wird der <strong>Nutzer</strong> unverzüglich informiert.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 4</strong>
        </Col>
        <Col>
          <strong>Nutzungsrechte</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.1</Col>
        <Col span={23}>
          Der <strong>Nutzer</strong> erhält ein zeitlich und räumlich unbeschränktes, nicht jedoch
          ausschließliches, einfaches und nicht unterlizenzierbares Recht, den Lizenzgegenstand nach
          Maßgabe dieser Lizenzbedingungen zu nutzen und zu vervielfältigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.2</Col>
        <Col span={23}>
          Das Recht zur Vervielfältigung beschränkt auf die Vervielfältigung, die für das Laden,
          Anzeigen, Ablaufen, Übertragen und Speichern der Vertragssoftware erforderlich ist, sowie
          weiter auf das Recht, Sicherheitskopien im für seinen Betrieb erforderlichen Umfang durch
          eine dafür berechtigte Person gemäß § 69 d Abs. 2 UrhG anzufertigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.3</Col>
        <Col span={23}>
          Das Recht zur Dekompilierung von Software wird nur unter der Bedingung des § 69 e Abs. 1
          Nr. 1 bis 3 UrhG und im Rahmen des § 69 e Abs. 2 Nr. 1 bis 3 UrhG gewährt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.4</Col>
        <Col span={23}>
          Diese Lizenz berechtigt nicht dazu, zukünftige Upgrades, Updates (mit Ausnahme von
          Sicherheitsupdates) oder Erweiterungen der Software zu erhalten. Falls solche Upgrades,
          Updates oder Erweiterungen bezogen werden, unterliegt die Nutzung dieser Upgrades oder
          Updates sowie die Änderungen, denen sie unterliegen können, gleichwohl diesem EULA.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.5</Col>
        <Col span={23}>
          Der <strong>Nutzer</strong> ist nicht berechtigt, technische Schutzmaßnahmen von Software
          zu entfernen oder zu umgehen, es sei denn, dies ist erforderlich, um die störungsfreie
          Programmnutzung zu erreichen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.6</Col>
        <Col span={23}>
          Verstößt der <strong>Nutzer</strong> gegen eine der vorstehenden Bestimmungen, werden
          sämtliche im Rahmen des Vertragsverhältnisses erteilten Nutzungsrechte sofort unwirksam
          und Seite 3 | 14.07.2023fallen automatisch an den <strong>Lizenzgeber</strong> zurück. In
          diesem Fall hat der <strong>Nutzer</strong> die Nutzung der Vertragssoftware unverzüglich
          und vollständig einzustellen sowie ggf. erstellte Sicherungskopien zu löschen und dem{' '}
          <strong>Lizenzgeber</strong>
          auszuhändigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.7</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> kann die Rechte nach § 4 aus wichtigem Grund beenden. Ein
          wichtiger Grund liegt insbesondere vor, wenn der <strong>Lizenzgeber</strong> das weitere
          Festhalten am Vertrag nicht zuzumuten ist, insbesondere wenn der <strong>Nutzer</strong>{' '}
          in erheblicher Weise gegen § 4 verstößt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.8</Col>
        <Col span={23}>
          Weitergehende Nutzungsrechte an Software werden dem <strong>Nutzer</strong> nicht
          eingeräumt.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>4.8</Col>
        <Col span={23}>Vorgenannte Regelungen binden die Parteien auch schuldrechtlich.</Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 5</strong>
        </Col>
        <Col>
          <strong>Open Source Software</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>5.1</Col>
        <Col span={23}>
          Die Software kann Open Source Software enthalten, die der <strong>Lizenzgeber</strong> von
          einem Dritten erhalten hat. Für die Einbeziehung Open Source Software hat der{' '}
          <strong>Lizenzgeber</strong> keine Lizenzgebühren gezahlt; für die Nutzung der Open Source
          Software werden dem <strong>Nutzer</strong>
          entsprechend auch keine Lizenzgebühren berechnet.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>5.2</Col>
        <Col span={23}>
          Für Open Source Software gelten ausschließlich die dieser Software zugrundliegenden
          Lizenzbedingungen des Rechteinhabers. Der <strong>Lizenzgeber</strong> liefert die
          entsprechenden Softwarelizenzbedingungen für die Open Source Software entweder in der
          Dokumentation zur Software mit oder stellt in der Dokumentation den Link zur Verfügung,
          unter welchem die geltenden Lizenzbedingungen herunterladen werden können. Der{' '}
          <strong>Nutzer</strong> wird die Nutzungsbedingungen einhalten.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>5.3</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> steht nicht für Fehler an Open Source Software ein.
          Gewährleistungsansprüche bezüglich der genutzten Open Source Software sind gegenüber dem
          <strong>Lizenzgeber</strong> ausgeschlossen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>5.4</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> haftet nicht für etwaige Schäden, gleich welcher Art, die
          dem <strong>Nutzer</strong>durch die Nutzung von Open Source Software entstehen, auch dann
          nicht, wenn der <strong>Lizenzgeber</strong> die Open Source Software dem{' '}
          <strong>Nutzer</strong> geliefert hat oder wenn der <strong>Lizenzgeber</strong> in der
          Dokumentation darauf hingewiesen hat, dass für den Betrieb der Software von der{' '}
          <strong>Lizenzgeber</strong> auch Open Source Software erforderlich ist. Die Haftung für
          Open Source Software ist dann nicht ausgeschlossen, wenn sie gesetzlich zwingend
          vorgeschrieben ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 6</strong>
        </Col>
        <Col>
          <strong>Bereitstellung der Software /Softwaredokumentation</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.1</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> stellt dem <strong>Nutzer</strong> die die
          Vertragssoftware über einen Internetzugang in Verbindung mit einem marktüblichen Browser
          zur Verfügung.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.2</Col>
        <Col span={23}>
          Der <strong>Lizenzgeber</strong> stellt dem <strong>Nutzer</strong> nebst der
          Vertragssoftware in erforderlichem Umfang jeweils ein Be<strong>Nutzer</strong>handbuch
          und eine Produktdokumentation zur Verfügung. Die Dokumentation erhält der{' '}
          <strong>Nutzer</strong> in elektronischer Form.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>6.3</Col>
        <Col span={23}>
          Der <strong>Nutzer</strong> ist berechtigt, die zur Verfügung gestellte Dokumentation
          nebst Handbuch unter Aufrechterhaltung vorhandener Schutzrechtsvermerke zu speichern,
          auszudrucken und für Zwecke dieses Vertrags in angemessener Anzahl zu vervielfältigen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 7</strong>
        </Col>
        <Col>
          <strong>
            Pflichten des <strong>Nutzers</strong>
          </strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.1</Col>
        <Col span={23}>
          Der <strong>Nutzer</strong> hat die ordnungsgemäße Nutzung der Anwendungen jeweils durch
          aktive und angemessene Mitwirkungshandlungen zu fördern. Er hat dem{' '}
          <strong>Lizenzgeber</strong> die zur ordnungsgemäßen Leistungserbringung notwendigen
          Informationen und Daten zur Verfügung zustellen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.2</Col>
        <Col span={23}>
          Der <strong>Nutzer</strong> hat grundsätzlich das geltende Recht der Bundesrepublik
          Deutschland, insbesondere Daten- und Jugendschutzvorschriften, strafrechtliche
          Bestimmungen sowie die vorliegenden Lizenzbedingungen zu beachten. Insbesondere ist der{' '}
          <strong>Nutzer</strong>verpflichtet:
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.2.1</Col>
        <Col span={23}>
          bereit gestellte Zugangsdaten sowie entsprechende Identifikations- und
          Authentifikationsmechanismen vor dem Zugriff unbefugter Dritter zu schützen und an solche
          Dritte nicht weiterzugeben;
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.2.2</Col>
        <Col span={23}>
          auf dem zu Verfügung gestellten Speicherplatz keine rechtswidrigen, die Gesetze,
          behördlichen Auflagen verletzenden Inhalte abzulegen;
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.2.3</Col>
        <Col span={23}>
          Rechte Dritter, insbesondere Urheber- und Leistungsschutzrechte, Marken-, Patent- und
          sonstige Eigentums- sowie Persönlichkeitsrechte, nicht zu verletzen;
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>7.2.4</Col>
        <Col span={23}>
          keine Anwendungen auszuführen, die zu einer Veränderung der physikalischen oder logischen
          Struktur der Netzwerke führen können, wie etwa Viren.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 8</strong>
        </Col>
        <Col>
          <strong>Abgabe von Bewertungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}></Col>
        <Col>
          Der <strong>Nutzer</strong> wird auf Rechte und Pflichten im Rahmen der Abgabe einer
          Bewertung gesondert hingewiesen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 9</strong>
        </Col>
        <Col>
          <strong>Gewährleistung</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.1</Col>
        <Col>
          Es wird ausdrücklich darauf hingewiesen, dass die störungsfreie und uneingeschränkte
          Beschaffenheit und Funktionalität der Software regelmäßig auch von Softwarekomponenten
          dritter Anbieter abhängig ist. Jegliche Veränderungen in solchen Softwarekomponenten bzw.
          in den Hardware- und Softwareumgebungen des <strong>Nutzers</strong>können zu
          Einschränkungen der Funktionalität, der von dem <strong>Lizenzgeber</strong> zu
          überlassenden Software führen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.2</Col>
        <Col>
          Beschaffenheit und Funktionalität der Software ergeben sich aus der entsprechenden
          Leistungsbeschreibung, welche nicht als Garantie zu verstehen sind. Eine Garantie wird nur
          gewährt, wenn sie als solche ausdrücklich bezeichnet worden ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.3</Col>
        <Col>
          Der <strong>Lizenzgeber</strong> erbringt alle Lieferungen und Leistungen nach dem
          aktuellen Stand der Technik.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.4</Col>
        <Col>
          Es gilt allgemein für bereit gestellte Softwareanwendungen die ausdrückliche
          Einschränkung, dass keine auf dem Markt befindliche Software bzw. IT-Infrastruktur zu 100
          % sicher und zu 100 % frei von Mängeln ist. Dies ist u. a. auf die Vielzahl der im Umlauf
          befindlichen Viren und auf den Umstand zurückzuführen, dass grundsätzlich
          Sicherheitsrisiken bestehen, denen nach dem jeweils herrschenden Stand der Technik ggfs.
          noch gar nicht entgegengewirkt werden kann. Der <strong>Lizenzgeber</strong> kann per se
          keinen Schutz vor unsachgemäßen Bedienungen oder Veränderungen von Softwareanwendungen,
          vor einer etwaigen Verseuchung von Softwarekomponenten mit Computerviren oder sonstiger
          Schadsoftware sowie vor sonstige Sicherheitslücken liefern, die nicht im Einflussbereich
          des <strong>Lizenzgebers</strong> liegen oder sonst auch nicht von dem{' '}
          <strong>Lizenzgeber</strong> zu vertreten sind. Die von dem
          <strong>Lizenzgeber</strong> gelieferten Leistungen schützen nicht vor möglichen
          Verletzungen des geistigen Eigentums oder anderen rechtswidrigen Tätigkeiten Dritter –
          etwa durch Cyber-Angriffe/Hacker-Attacken, Ausspähen und Abfangen von Daten oder sonstigen
          rechtswidrigen Datenveränderungen und Computersabotagen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.5</Col>
        <Col>
          Der <strong>Lizenzgeber</strong> gewährleistet im Allgemeinen, dass die geschuldeten
          Leistungen frei von wesentlichen, die gewöhnliche Verwendung der Leistungen
          einschränkenden Mängeln und Rechten Dritter sind. Der <strong>Lizenzgeber</strong> steht
          dafür ein, dass die von dem <strong>Lizenzgeber</strong>
          geschuldeten Leistungen die Beschaffenheit haben, die bei Leistungen Seite 6 |
          14.07.2023der gleichen Art üblich ist und die der <strong>Lizenzgeber</strong> nach der
          Art der konkreten Leistung erwarten kann. Nach Maßgabe der vorstehenden Regelungen aus § 6
          Abs. 4 wird ausdrücklich darauf hingewiesen, dass die störungsfreie und uneingeschränkte
          Beschaffenheit und Funktionalität der von dem <strong>Lizenzgeber</strong> geschuldeten
          Leistungen regelmäßig auch von Soft- und Hardwarekomponenten dritter Anbieter abhängig
          ist, die von dem <strong>Lizenzgeber</strong> nicht beeinflussbar sind. Insbesondere
          können jegliche Veränderungen in solchen Softwarekomponenten bzw. in den Hardware- und
          Softwareumgebungen des <strong>Nutzers</strong> zu Einschränkungen der Funktionalität der
          von dem <strong>Lizenzgeber</strong>
          geschuldeten Leistungen führen. Einschränkungen aufgrund von technischen oder sonstigen
          Problemen, die nicht im Einflussbereich des <strong>Lizenzgebers</strong>liegen (höhere
          Gewalt, Verschulden Dritter etc.) sind dem <strong>Lizenzgeber</strong> nicht zuzurechnen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.6</Col>
        <Col>
          Die in die ggfs. bereit gestellte Infrastruktur des <strong>Lizenzgebers</strong>
          eingestellten Inhalte sind für den <strong>Lizenzgeber</strong> fremde Inhalte. Die
          rechtliche Verantwortung liegt diesbezüglich bei dem <strong>Nutzer</strong>.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>9.7</Col>
        <Col>
          Die Datenkommunikation über das Internet kann nach dem derzeitigen Stand der Technik nicht
          fehlerfrei und/oder jederzeit verfügbar gewährleistet werden. Daher übernimmt der
          <strong>Lizenzgeber</strong> keine Gewähr für technische Mängel, die nicht vom{' '}
          <strong>Lizenzgeber</strong> zu vertreten sind, insbesondere für die ständige und
          ununterbrochene Verfügbarkeit der Datenbanken und ihrer Inhalte oder für die vollständige
          und fehlerfreie Wiedergabe
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 10</strong>
        </Col>
        <Col>
          <strong>Haftung</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.1</Col>
        <Col>
          Der <strong>Lizenzgeber</strong> übernimmt keine Haftung für die unterbrechungsfreie
          Verfügbarkeit von Systemen sowie für systembedingte Ausfälle, Unterbrechungen und
          Störungen der technischen Anlagen und der Dienste, die nicht von dem{' '}
          <strong>Lizenzgeber</strong> zu vertreten sind. Der <strong>Lizenzgeber</strong>
          haftet insbesondere nicht für Störungen der Qualität des Zugangs zu Leistungen aufgrund
          höherer Gewalt oder aufgrund von Ereignissen, die der <strong>Lizenzgeber</strong> nicht
          zu vertreten hat. Darunter fallen insbesondere Streiks, Aussperrungen, rechtmäßige
          unternehmensinterne Arbeitskampfmaßnahmen und behördliche Anordnungen. Weiter zählen
          hierzu auch der vollständige oder teilweise Ausfall der zur eigenen Leistungserbringung
          erforderlichen Kommunikations- und Netzwerkstrukturen und Gateways anderer Anbieter und
          Betreiber. Der
          <strong>Lizenzgeber</strong> ist berechtigt, die der <strong>Lizenzgeber</strong>{' '}
          obliegenden Leistungen für die Dauer des hindernden Ereignisses zuzüglich einer
          angemessenen Anlauffrist aufzuschieben. Für unwesentliche Unterbrechungen übernimmt der{' '}
          <strong>Lizenzgeber</strong> keine Haftung. Der <strong>Lizenzgeber</strong>
          haftet ferner nicht bei Fehlern aus dem Risikobereich des <strong>Nutzers</strong> oder
          sonstiger Dritter, insbesondere nicht bei Fehlern, die verursacht wurden durch Seite 7 |
          14.07.2023unsachgemäße Bedienung oder Veränderung der Anwendungen oder sonstiger
          Drittsoftware, durch Verseuchung entsprechender Softwarekomponenten mit Computerviren,
          Verwendung ungeeigneter Datenträger, fehlerhafte Hardware, Ausfall der Stromversorgung
          oder datenführender Leitungen, vor Fehlern aufgrund mangelnder Informationssicherheit oder
          ungeeigneter Umweltbedingungen am Ort des Betriebs von Anwendungen.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.2</Col>
        <Col>
          Bei leicht fahrlässigen Pflichtverletzungen beschränkt sich die Haftung auf den nach Art
          der Ware vorhersehbaren, vertragstypischen, unmittelbaren Durchschnittsschaden. Dies gilt
          auch bei leicht fahrlässigen Pflichtverletzungen der gesetzlichen Vertreter oder
          Verrichtungs- bzw. Erfüllungsgehilfen des <strong>Lizenzgebers</strong>. Der{' '}
          <strong>Lizenzgeber</strong> haftet nicht bei leicht fahrlässiger Verletzung
          unwesentlicher Vertragspflichten. Der <strong>Lizenzgeber</strong>haftet hingegen für die
          Verletzung vertragswesentlicher Rechtspositionen des <strong>Nutzers</strong>.
          Vertragswesentliche Rechtspositionen sind solche, die der Vertrag dem{' '}
          <strong>Nutzer</strong> nach dem Vertragsinhalt und -zweck zu gewähren hat. Der{' '}
          <strong>Lizenzgeber</strong> haftet ferner für die Verletzung von Verpflichtungen, deren
          Erfüllung die ordnungsgemäße Durchführung des Vertrags überhaupt erst ermöglicht und auf
          deren Einhaltung der Der <strong>Nutzer</strong>vertrauen darf.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>10.3</Col>
        <Col>
          Die vorstehenden Haftungsbeschränkungen betreffen nicht Ansprüche des{' '}
          <strong>Nutzers</strong> aus Garantien und/oder Produkthaftung. Weiter gelten die
          Haftungsbeschränkungen nicht bei Arglist, bei Verletzung vertragswesentlicher Pflichten
          sowie dem <strong>Lizenzgeber</strong>zurechenbaren Körper- und Gesundheitsschäden bzw.
          bei Verlust des Lebens des <strong>Nutzers</strong>. Die vorstehenden
          Haftungsbeschränkungen betreffen ferner keine Ansprüche bei denen die Haftung des{' '}
          <strong>Lizenzgebers</strong> gesetzlich zwingend vorgeschrieben ist.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 11</strong>
        </Col>
        <Col>
          <strong>Datenschutz</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>11.1</Col>
        <Col>
          Jede Verarbeitung personenbezogener Daten erfolgt entsprechend den Bestimmungen der
          EU-Datenschutzgrundverordnung (DSGVO) sowie insbesondere den gesetzlichen
          Datenschutzbestimmungen des Bundesdatenschutzgesetzes (BDSG). Die ausführlichen
          Datenschutzbestimmungen des <strong>Lizenzgebers</strong> können im Übrigen unter{' '}
          <a
            href={`${window.location.origin}/datenschutzhinweise`}
            target='_blank'
            rel='noreferrer'
          >{`${window.location.origin}/datenschutzhinweise`}</a>{' '}
          eingesehen werden. Diese enthalten detaillierte Angaben, wie mit persönlichen Daten
          umgegangen wird, wie diese geschützt werden und welche Rechte die betroffene Person
          diesbezüglich hat.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 12</strong>
        </Col>
        <Col>
          <strong>Sperrung des Zugangs</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.1</Col>
        <Col>
          Der <strong>Lizenzgeber</strong> behält sich das Recht vor, Informationen von Servern zu
          löschen und Be
          <strong>Nutzer</strong>konten zu sperren, wenn gegen die vorliegenden Lizenzbedingungen
          Seite 8 | 14.07.2023verstoßen wird. Bei Verstoß gegen geltende Gesetze ist der{' '}
          <strong>Lizenzgeber</strong>
          berechtigt, die entsprechenden Informationen an die zuständigen staatlichen Stellen
          weiterzuleiten.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>12.1</Col>
        <Col>
          Der
          <strong>Lizenzgeber</strong>
          behält sich das Recht vor, den Zugang zu den vereinbarten Leistungen vorläufig ganz oder
          teilweise zu sperren, wenn und soweit der <strong>Nutzer</strong> diese Leistungen
          rechtswidrig nutzt bzw. gegen die in diesen Lizenzbedingungen verankerten wesentlichen
          Pflichten verstößt. Hiervon wird dieser umgehend informiert.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>
          <strong>§ 13</strong>
        </Col>
        <Col>
          <strong>Änderung Lizenzbedingungen</strong>
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>13.1</Col>
        <Col>
          Der <strong>Lizenzgeber</strong> behält sich das Recht vor, diese Lizenzbedingungen
          jederzeit unter Wahrung einer angemessenen Ankündigungsfrist von mindestens sechs Wochen
          zu ändern. Der
          <strong>Lizenzgeber</strong> teilt dem <strong>Nutzer</strong> eine entsprechende Änderung
          in Textform mit.
        </Col>
      </Row>
      <Row className={styles.mb12}>
        <Col span={1}>13.2</Col>
        <Col>
          Widerspricht der <strong>Nutzer</strong> nicht, so gelten die geänderten Lizenzbedingungen
          als angenommen. Im Falle des Widerspruchs besteht der Vertrag unverändert mit dem
          bisherigen Lizenzbedingungen fort, der <strong>Lizenzgeber</strong> ist jedoch berechtigt,
          den Vertrag ordentlich zu kündigen.
        </Col>
      </Row>
    </div>
  );
};

export default Endnut;
