import { createSelector } from 'reselect';
import {
  allGroupsResponse,
  getGroupItemsResponseTypes,
  getGroupSubItemsResponseTypes,
  groupAllSubjectsTypes,
  groupDetailsResponseType,
  groupStudentType,
  groupSubjectType,
  groupTypes,
} from 'store/actions/group.actions';
import { subjectTypes } from 'actions/subjects.actions';

const appState = (state: {
  groupsReducer: {
    groups: Array<groupTypes>;
    groupCount: number;
    allGroups: allGroupsResponse & Array<{ label: string; value: string }>;
    groupDetails: groupDetailsResponseType;
    groupStudents: Array<groupStudentType> | [];
    groupTeachers: Array<groupStudentType> | [];
    groupSubjects: Array<groupSubjectType> | [];
    subjectsToAdd: Array<{ id: number }> | [];
    teachersToAdd: Array<{ id: number }> | [];
    studentsToAdd: Array<{ id: number; name: string; surname: string }> | [];
    groupAllSubjects: groupAllSubjectsTypes | [];
    groupItems: getGroupItemsResponseTypes | [];
    groupSubItems: getGroupSubItemsResponseTypes | [];
    groupStudentsCount: number;
    groupTeachersCount: number;
    groupSubjectsCount: number;
  };
}): {
  groups: Array<groupTypes>;
  groupCount: number;
  allGroups: allGroupsResponse & Array<{ label: string; value: string }>;
  groupDetails: groupDetailsResponseType;
  groupStudents: Array<groupStudentType> | [];
  groupTeachers: Array<groupStudentType> | [];
  groupSubjects: Array<groupSubjectType> | [];
  subjectsToAdd: Array<{ id: number }> | [];
  teachersToAdd: Array<{ id: number }> | [];
  studentsToAdd: Array<{ id: number; name: string; surname: string }> | [];
  groupAllSubjects: groupAllSubjectsTypes | [];
  groupItems: getGroupItemsResponseTypes | [];
  groupSubItems: getGroupSubItemsResponseTypes | [];
  groupStudentsCount: number;
  groupTeachersCount: number;
  groupSubjectsCount: number;
} => state.groupsReducer;

export const getGroupsSelector = createSelector(appState, (state) => state.groups);
export const getGroupCountSelector = createSelector(appState, (state) => state.groupCount);
export const getAllGroupsSelector = createSelector(appState, (state) => state.allGroups);
export const getGroupDetailsSelector = createSelector(appState, (state) => state.groupDetails);
export const getGroupStudentsSelector = createSelector(appState, (state) => state.groupStudents);
export const getGroupTeachersSelector = createSelector(appState, (state) => state.groupTeachers);
export const getGroupSubjectsSelector = createSelector(appState, (state) => state.groupSubjects);

export const getGroupStudentsCountSelector = createSelector(
  appState,
  (state) => state.groupStudentsCount,
);
export const getGroupTeachersCountSelector = createSelector(
  appState,
  (state) => state.groupTeachersCount,
);
export const getGroupSubjectsCountSelector = createSelector(
  appState,
  (state) => state.groupSubjectsCount,
);
export const getSubjectsToAddSelector = createSelector(appState, (state) => state.subjectsToAdd);
export const getTeachersToAddSelector = createSelector(appState, (state) => state.teachersToAdd);
export const getStudentsToAddSelector = createSelector(appState, (state) => state.studentsToAdd);
export const grtGroupAllSubjectsSelector = createSelector(
  appState,
  (state) => state.groupAllSubjects,
);
export const grtGroupItemsSelector = createSelector(appState, (state) => state.groupItems);
export const grtGroupSubItemsSelector = createSelector(appState, (state) => state.groupSubItems);
