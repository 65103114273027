import { ActionNames } from 'store/actions/actionNames';
import { userTypes } from 'store/actions/auth.actions';
import produce from 'immer';

const initialState: {
  user: userTypes | null;
  userRole: 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN';
  authData: string;
} = {
  user: null,
  userRole: 'SCHOOL_ADMIN',
  authData: '',
};

const userReducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: userTypes & { authData: string } & {
        lastLoginRole: 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN';
      };
    };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.LOGIN_SUCCESS:
        draft.user = action.payload.data;
        const role =
          action.payload.data?.authorities
            .map((auth) => auth.authority)
            ?.includes('SCHOOL_ADMIN') &&
          action.payload.data?.authorities.map((auth) => auth.authority)?.includes('TEACHER')
            ? action.payload.data.lastLoginRole
              ? action.payload.data.lastLoginRole
              : 'SCHOOL_ADMIN'
            : action.payload.data?.authorities
                .map((auth) => auth.authority)
                ?.includes('SCHOOL_ADMIN')
            ? 'SCHOOL_ADMIN'
            : action.payload.data?.authorities.map((auth) => auth.authority)?.includes('TEACHER')
            ? 'TEACHER'
            : action.payload.data?.authorities.map((auth) => auth.authority)?.includes('STUDENT')
            ? 'STUDENT'
            : 'ASSOCIATION_ADMIN';

        draft.userRole = role as 'ASSOCIATION_ADMIN' | 'STUDENT' | 'TEACHER' | 'SCHOOL_ADMIN';
        break;
      case ActionNames.CHANGE_USER_ROLE:
        draft.userRole = action.payload.data.lastLoginRole;
        break;
      case ActionNames.SAVE_AUTH:
        draft.authData = action.payload.data.authData;
        break;
      default:
        break;
    }
  });

export default userReducer;
