import { ConfigProvider, Table as AntdTable, TableProps, Empty } from 'antd';
import cc from 'classcat';
import styles from './styles.module.css';
import { primary30 } from '../../colors';

const Table = ({ className, ...props }: TableProps<any>) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary30,
        },
      }}
    >
      <AntdTable
        showSorterTooltip={false}
        className={cc([className, styles.table])}
        {...props}
        locale={{
          emptyText: (
            <div>
              <div>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={'Keine Daten'} />
              </div>
            </div>
          ),
        }}
      />
    </ConfigProvider>
  );
};

export default Table;
