import { ActionNames } from 'store/actions/actionNames';

export type getReportParamTypes = {
  id: number;
  page: number;
  size: number;
  izelId?: number;
  showActualData?: boolean;
};

export type getSchoolYearsParamTypes = {
  id: number;
};

export type getSchoolYearsResponseTypes = Array<{
  schoolYearId: number;
  izelId: number;
  schoolYearName: string;
  schoolYearActive: boolean;
  izelActive: boolean;
  izelHalfYear: boolean;
  grade: number;
  izelNotActivated: boolean;
}>;

export type getReportResponseTypes = {
  id: number;
  izelId?: number;
  count: number;
  name: string;
  schoolYear: string;
  surname: string;
  hasAnyEvaluations: boolean;
  hasPersonalLetter: boolean;
  hasAnyTexts: boolean;
  nonLocked: boolean;
  subjects: Array<{
    id: number;
    name: string;
    filled: boolean;
    groupTexts: string[];
    personalText: string;
    selfEvaluationMatches: boolean;
    description: string;
    items: Array<{
      id: number;
      name: string;
      filled: boolean;
      selfEvaluationMatches: boolean;
      optional: boolean;
      alias: string;
      assessmentScale: { id: number };
      subItems: Array<{
        id: number;
        name: string;
        evaluatedScaleValueId: number;
        selfEvaluationMatches: boolean;
        selfEvaluatedScaleValueId: number;
        description: string;
        optional: boolean;
      }>;
      assessmentScaleValues: Array<{ assessmentScaleId: number; id: number; scaleValue: string }>;
    }>;
  }>;
};

export type evaluateSubItemTypes = {
  subItemId: number;
  assessmentScaleValueId: number;
  studentId: number;
  onSuccess: () => void;
  page: number;
  izelId: number;
};

export type evalDataTypes = {
  self?: boolean;
  itemId: number;
  subjectId: number;
  subItemId: number;
  scaleId: number;
  subItemEvaluationData: {
    filled: boolean;
    selfEvaluationFilled: boolean;
    selfEvaluationMatches: boolean;
  };
  itemEvaluationData: {
    filled: boolean;
    selfEvaluationFilled: boolean;
    selfEvaluationMatches: boolean;
  };
  subjectEvaluationData: {
    filled: boolean;
    selfEvaluationFilled: boolean;
    selfEvaluationMatches: boolean;
  };
};

export type studentObservationParamTypes = {
  id: number;
  izelId: number;
};

export type subjectObservationParamTypes = {
  id: number;
  studentId: number;
  izelId: number;
};

export type itemObservationParamTypes = {
  id: number;
  itemId: number;
  izelId: number;
};

export type subItemObservationParamTypes = {
  id: number;
  itemId: number;
  subItemId: number;
  izelId: number;
};

export type getObservationResponseTypes = Array<{
  id: number;
  itemId?: number;
  observationDateTime: string;
  teacherName: string;
  teacherSurname: string;
  text: string;
}>;

export type getEvaluationsResponseTypes = {
  assessmentScaleValues: Array<{ id: number; assessmentScaleId: number; scaleValue: string }>;
  interimEvaluations: Array<{
    assessmentScaleValueId: number;
    evaluationDateTime: string;
    id: number;
    teacherName: string;
    teacherSurname: string;
  }>;
};

export type getPracticeParamTypes = {
  id: number;
  izelId: number;
  page: number;
  size: number;
};

export type practiceTypes = {
  id: number;
  startDate: string;
  endDate: string;
  city: string;
  description: string;
  name: string;
  organization: string;
};

export type practiceResponseType = { internships: Array<practiceTypes>; count: number };

export type createPracticeParamTypes = {
  studentId: number;
  startDate: string;
  endDate: string;
  city: string;
  description: string;
  organization: string;
  izelId: number;
  page: number;
};

export type updatePracticeParamTypes = createPracticeParamTypes & { id: number };

export type removePracticeParamTypes = {
  id: number;
  studentId: number;
  izelId: number;
};

export type getPersonalLetterParamTypes = {
  studentId: number;
  izelId: number;
  onSuccess: () => void;
};

export type getPersonalLetterResponseTypes = {
  text: string;
};

export type createPersonalLetterParamTypes = {
  studentId: number;
  text: string | null;
  onSuccess: () => void;
  izelId: number;
};

export type getAllObservationsParamTypes = {
  studentId: number;
  page?: number;
  size?: number;
  onSuccess: () => void;
  izelId?: number;
  subjectIds?: number[];
  profile?: boolean;
  onlyMyDocumentation?: boolean;
  personalObservations?: boolean;
  startDate?: string;
  endDate?: string;
  itemIds?: number[];
  subItemIds?: number[];
};

export type getAllObservationsResponseTypes = {
  observations: Array<{
    id: number;
    subjectName?: string;
    itemName?: string;
    subItemName?: string;
    observationDateTime: string;
    teacherName: string;
    teacherSurname: string;
    text: string;
  }>;
  count: number;
} & {
  data: {
    id: number;
    subjectName?: string;
    itemName?: string;
    subItemName?: string;
    observationDateTime: string;
    teacherName: string;
    teacherSurname: string;
    text: string;
    evaluationDateTime: string;
    assessmentScaleValues: any;
    assessmentScaleValueId: number;
  }[];
  count: number;
};

export type getReportDocumentParamTypes = {
  studentId: number;
  printLetter: boolean;
  printReport: boolean;
  reportName: string;
  izelId: number;
  onSuccess: () => void;
};

export type createStudentObservationParamTypes = {
  studentId: number;
  text: string;
};

export type createSubjectObservationParamTypes = {
  studentId: number;
  subjectId: number;
  text: string;
};

export type createItemObservationParamTypes = {
  studentId: number;
  itemId: number;
  text: string;
};

export type createSubItemObservationParamTypes = {
  studentId: number;
  subItemId: number;
  itemId: number;
  text: string;
};

export type saveInterimEvaluationsParamTypes = {
  id: number;
  selectedScales: Array<{ subItemId: number; assessmentScaleValueId: number }>;
};

export type getObservationSubjectsParamTypes = {
  studentId: number;
  izelId?: number;
};

export type getObservationItemsParamTypes = {
  id: number;
  subjectIds: number[];
};

export type getObservationSubItemsParamTypes = {
  id: number;
  itemIds: number[];
};

export type getObservationSubjectsResponseTypes = Array<{
  id: number;
  name: string;
}>;

export const getReportAction = (params: getReportParamTypes) => ({
  type: ActionNames.GET_REPORT,
  params,
});

export const getReportActionSuccess = (report: getReportResponseTypes) => ({
  type: ActionNames.GET_REPORT_SUCCESS,
  payload: report,
});

export const getSchoolYearsAction = (params: getSchoolYearsParamTypes) => ({
  type: ActionNames.GET_SCHOOL_YEARS,
  params,
});

export const getSchoolYearsActionSuccess = (schoolYears: getSchoolYearsResponseTypes) => ({
  type: ActionNames.GET_SCHOOL_YEARS_SUCCESS,
  payload: schoolYears,
});

export const evaluateSubItemAction = (params: evaluateSubItemTypes) => ({
  type: ActionNames.EVALUATE_SUB_ITEM,
  params,
});

export const evaluateSubItemActionSuccess = (evalData: evalDataTypes) => ({
  type: ActionNames.EVALUATE_SUB_ITEM_SUCCESS,
  payload: evalData,
});

export const getStudentObservationAction = (params: studentObservationParamTypes) => ({
  type: ActionNames.GET_STUDENT_OBSERVATION,
  params,
});

export const getSubjectObservationAction = (params: subjectObservationParamTypes) => ({
  type: ActionNames.GET_SUBJECT_OBSERVATION,
  params,
});

export const getItemObservationAction = (params: itemObservationParamTypes) => ({
  type: ActionNames.GET_ITEM_OBSERVATION,
  params,
});

export const getSubItemObservationAction = (params: subItemObservationParamTypes) => ({
  type: ActionNames.GET_SUB_ITEM_OBSERVATION,
  params,
});

export const getObservationsSuccess = (observations: getObservationResponseTypes) => ({
  type: ActionNames.GET_OBSERVATIONS_SUCCESS,
  payload: observations,
});

export const getSubItemEvaluationAction = (params: subItemObservationParamTypes) => ({
  type: ActionNames.GET_SUB_ITEM_EVALUATION,
  params,
});

export const getEvaluationsSuccess = (evaluations: getEvaluationsResponseTypes) => ({
  type: ActionNames.GET_SUB_ITEM_EVALUATION_SUCCESS,
  payload: evaluations,
});

export const getPracticeAction = (params: getPracticeParamTypes) => ({
  type: ActionNames.GET_PRACTICE,
  params,
});

export const getPracticeActionSuccess = (practice: practiceResponseType) => ({
  type: ActionNames.GET_PRACTICE_SUCCESS,
  payload: practice,
});

export const createPracticeAction = (params: createPracticeParamTypes) => ({
  type: ActionNames.CREATE_PRACTICE,
  params,
});

export const createPracticeActionSuccess = (practice: practiceTypes) => ({
  type: ActionNames.CREATE_PRACTICE_SUCCESS,
  payload: practice,
});

export const updatePracticeAction = (params: updatePracticeParamTypes) => ({
  type: ActionNames.UPDATE_PRACTICE,
  params,
});

export const updatePracticeActionSuccess = (practice: practiceTypes) => ({
  type: ActionNames.UPDATE_PRACTICE_SUCCESS,
  payload: practice,
});

export const removePracticeAction = (params: removePracticeParamTypes) => ({
  type: ActionNames.REMOVE_PRACTICE,
  params,
});

export const removePracticeActionSuccess = (practice: { data: { id: number } }) => ({
  type: ActionNames.REMOVE_PRACTICE_SUCCESS,
  payload: practice,
});

export const getPersonalLetterAction = (params: getPersonalLetterParamTypes) => ({
  type: ActionNames.GET_PERSONAL_LETTER,
  params,
});

export const getPersonalLetterActionSuccess = (letter: getPersonalLetterResponseTypes) => ({
  type: ActionNames.GET_PERSONAL_LETTER_SUCCESS,
  payload: letter,
});

export const createPersonalLetterAction = (params: createPersonalLetterParamTypes) => ({
  type: ActionNames.CREATE_PERSONAL_LETTER,
  params,
});

export const getAllObservationsAction = (params: getAllObservationsParamTypes) => ({
  type: ActionNames.GET_ALL_OBSERVATIONS,
  params,
});

export const removeAllObservations = { type: ActionNames.REMOVE_ALL_OBSERVATIONS };

export const getAllObservationsActionSuccess = (observations: getAllObservationsResponseTypes) => ({
  type: ActionNames.GET_ALL_OBSERVATIONS_SUCCESS,
  payload: observations,
});

export const getReportDocumentAction = (params: getReportDocumentParamTypes) => ({
  type: ActionNames.GET_REPORT_DOCUMENT,
  params,
});

export const createStudentObservationAction = (params: createStudentObservationParamTypes) => ({
  type: ActionNames.CREATE_STUDENT_OBSERVATION,
  params,
});

export const createSubjectObservationAction = (params: createSubjectObservationParamTypes) => ({
  type: ActionNames.CREATE_SUBJECT_OBSERVATION,
  params,
});

export const createItemObservationAction = (params: createItemObservationParamTypes) => ({
  type: ActionNames.CREATE_ITEM_OBSERVATION,
  params,
});

export const createSubItemObservationAction = (params: createSubItemObservationParamTypes) => ({
  type: ActionNames.CREATE_SUB_ITEM_OBSERVATION,
  params,
});

export const saveInterimEvaluationsAction = (params: saveInterimEvaluationsParamTypes) => ({
  type: ActionNames.SAVE_INTERIM_EVALUATION,
  params,
});

export const getObservationSubjectsAction = (params: getObservationSubjectsParamTypes) => ({
  type: ActionNames.GET_OBSERVATION_SUBJECTS,
  params,
});

export const getObservationSubjectsActionSuccess = (
  response: getObservationSubjectsResponseTypes,
) => ({
  type: ActionNames.GET_OBSERVATION_SUBJECTS_SUCCESS,
  payload: response,
});

export const getObservationItemsAction = (params: getObservationItemsParamTypes) => ({
  type: ActionNames.GET_OBSERVATION_ITEMS,
  params,
});

export const getObservationItemsActionSuccess = (
  response: getObservationSubjectsResponseTypes,
) => ({
  type: ActionNames.GET_OBSERVATION_ITEMS_SUCCESS,
  payload: response,
});

export const getObservationSubItemsAction = (params: getObservationSubItemsParamTypes) => ({
  type: ActionNames.GET_OBSERVATION_SUB_ITEMS,
  params,
});

export const getObservationSubItemsActionSuccess = (
  response: getObservationSubjectsResponseTypes,
) => ({
  type: ActionNames.GET_OBSERVATION_SUB_ITEMS_SUCCESS,
  payload: response,
});
