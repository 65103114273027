import { ActionNames } from 'store/actions/actionNames';
import {
  assessmentScalesTypes,
  itemsResponseType,
  itemTypes,
  removeItemParamTypes,
  removeSubItemParamTypes,
  subItemTypes,
} from 'store/actions/items.actions';
import { subjectTypes } from 'store/actions/subjects.actions';
import produce from 'immer';

const initialState: {
  items: Array<itemTypes> | [];
  singleSubject: subjectTypes | null;
  itemsCount: number;
  assessmentScales: assessmentScalesTypes | [];
} = {
  items: [],
  itemsCount: 0,
  assessmentScales: [],
  singleSubject: null,
};

const itemsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: itemsResponseType &
        itemTypes &
        subItemTypes &
        assessmentScalesTypes &
        removeItemParamTypes &
        removeSubItemParamTypes;
    };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_ASSESSMENT_SCALES_SUCCESS:
        draft.assessmentScales = action.payload.data;
        break;
      case ActionNames.GET_ITEMS_SUCCESS:
        draft.items = action.payload.data.items;
        draft.singleSubject = action.payload.data.subject;
        draft.itemsCount = action.payload.data.count;
        break;
      case ActionNames.CREATE_ITEM_SUCCESS:
        const updated = [...state.items];
        updated.push(action.payload.data);
        draft.itemsCount = state.itemsCount + 1;
        draft.items = updated;
        break;
      case ActionNames.EDIT_ITEM_SUCCESS:
        const editedItem = action.payload.data;
        const updatedItems = state.items.map((item) =>
          item.id === editedItem.id
            ? {
                ...item,
                name: editedItem.name,
                optional: editedItem.optional,
                assessmentScaleId: editedItem.assessmentScaleId,
                alias: editedItem.alias,
              }
            : item,
        );
        draft.items = updatedItems;
        break;
      case ActionNames.CREATE_SUB_ITEM_SUCCESS:
        const parentItemToAdd = state.items?.find(
          (item: itemTypes) => item.id === action.payload.data.parentId,
        );
        const updatedList = parentItemToAdd ? [...(parentItemToAdd.subItems || [])] : [];
        updatedList.push(action.payload.data);
        draft.items = state.items.map((item) =>
          item.id === action.payload.data.parentId ? { ...item, subItems: updatedList } : item,
        );
        break;
      case ActionNames.EDIT_SUB_ITEM_SUCCESS:
        const editedSubItem = action.payload.data;
        const subItemsToUpdate = state.items
          .find((item: itemTypes) => item.id === editedSubItem.parentId)
          ?.subItems.map((subItem: subItemTypes) =>
            subItem.id === editedSubItem.id
              ? {
                  ...subItem,
                  description: editedSubItem.description,
                  name: editedSubItem.name,
                  optional: editedSubItem.optional,
                  schoolYears: editedSubItem.schoolYears,
                }
              : subItem,
          );
        const updatedItemsLIst: Array<itemTypes> = state.items.map((item: itemTypes) =>
          item.id === editedSubItem.parentId
            ? {
                ...item,
                subItems: subItemsToUpdate as Array<subItemTypes>,
              }
            : item,
        );
        draft.items = updatedItemsLIst;
        break;
      case ActionNames.REMOVE_ITEM_SUCCESS:
        const filtered = state.items.filter(
          (item: itemTypes) => item.id !== action.payload.data.id,
        );
        draft.items = filtered;
        draft.itemsCount = state.itemsCount - 1;
        break;
      case ActionNames.REMOVE_SUB_ITEM_SUCCESS:
        const parentItem = state.items.find(
          (item: itemTypes) => item.id === action.payload.data.parentId,
        );
        const filteredSubItems: subItemTypes[] =
          parentItem?.subItems.filter(
            (subItem: subItemTypes) => subItem.id !== action.payload.data.id,
          ) || [];
        const newItems: Array<itemTypes> = state.items?.map((item) =>
          item.id === action.payload.data.parentId
            ? ({ ...item, subItems: filteredSubItems } as itemTypes)
            : { ...item },
        );
        draft.items = newItems;
        break;
      default:
        break;
    }
  });

export default itemsReducer;
