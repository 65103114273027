import {
  schoolInfoResponseTypes,
  schoolsResponseType,
  schoolType,
  getSchoolYearDataResponseTypes,
} from 'store/actions/school.actions';
import { ActionNames } from 'store/actions/actionNames';
import produce from 'immer';
import { getGradesRangeResponseTypes } from 'actions/students.actions';

const initialState: {
  schools: Array<schoolType> | [];
  schoolInfo: schoolInfoResponseTypes | null;
  schoolsCount: number;
  gradesRange: getGradesRangeResponseTypes | [];
  schoolYearData: getSchoolYearDataResponseTypes | null;
} = {
  schools: [],
  schoolInfo: null,
  schoolsCount: 0,
  gradesRange: [],
  schoolYearData: null,
};

const schoolsReducer = (
  state = initialState,
  action: {
    type: string;
    payload: {
      data: schoolsResponseType &
        schoolType &
        schoolInfoResponseTypes &
        getGradesRangeResponseTypes &
        getSchoolYearDataResponseTypes;
    };
  },
) =>
  produce(state, (draft) => {
    switch (action.type) {
      case ActionNames.GET_SCHOOLS_SUCCESS:
        draft.schools = action.payload.data.schools;
        draft.schoolsCount = action.payload.data.count;
        break;
      case ActionNames.CREATE_SCHOOL_SUCCESS:
        const updated = [...state.schools];
        updated.push(action.payload.data);
        draft.schools = updated;
        break;
      case ActionNames.GET_SCHOOL_INFO_SUCCESS:
        draft.schoolInfo = action.payload.data;
        break;
      case ActionNames.GET_GRADES_ACTION_SUCCESS:
        draft.gradesRange = action.payload.data;
        break;
      case ActionNames.GET_SCHOOL_YEAR_DATA_SUCCESS:
        draft.schoolYearData = action.payload.data;
        break;
      default:
        break;
    }
  });

export default schoolsReducer;
