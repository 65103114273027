import { AddStudentToGroupAction, getStudentsToAddAction } from 'store/actions/group.actions';
import { getStudentsToAddSelector } from 'store/selectors/groups.selectors';
import React, { ChangeEvent, useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import Spinner from 'UI/Spinner';
import { Col, Row } from 'antd';
import Modal from 'UI/Modal';
import Table from 'UI/Table';
import Input from 'UI/Input';
import styles from './styles.module.css';
import GroupStudentFilters from 'pages/SingleGroup/__partials/AddGroupStudentModal/__partials/GroupStudentFilters';

const { Search } = Input;

const columns = [
  {
    title: 'Name',
    dataIndex: 'fullName',
    key: 'fullName',
  },
  {
    title: 'Jahrgang',
    dataIndex: 'schoolYear',
    key: 'schoolYear',
  },
  {
    title: 'Stammgruppe',
    dataIndex: 'coreGroup',
    key: 'coreGroup',
    render: (record: { name: string }) => record?.name || '',
  },
];

type groupStudentModal = {
  groupStudentVisible: boolean;
  toggleGroupStudent: (arg1: boolean) => void;
  groupType: string;
};

const AddGroupStudentModal = ({
  groupStudentVisible,
  toggleGroupStudent,
  groupType,
}: groupStudentModal) => {
  const [selectedStudent, setSelectedStudents] = useState<Array<number>>([]);
  const [searchValue, setSearchValue] = useState('');
  const [schoolYears, setSchoolYears] = useState<Array<string>>([]);
  const [groups, setGroups] = useState<Array<string>>([]);
  const dispatch = useDispatch();
  const { groupId } = useParams();
  const studentsToAdd = useSelector(getStudentsToAddSelector);
  const studentsToAddData = useMemo(() => {
    return studentsToAdd.map((student: { name: string; surname: string }) => {
      return { ...student, fullName: student.surname + ', ' + student.name };
    });
  }, [studentsToAdd]);

  const handleResetModal = useCallback(() => {
    setSelectedStudents([]);
    setSearchValue('');
    setSchoolYears([]);
    setGroups([]);
    toggleGroupStudent(false);
  }, [toggleGroupStudent]);

  const handleAddGroupStudent = useCallback(() => {
    dispatch(AddStudentToGroupAction({ id: Number(groupId), students: selectedStudent }));
    handleResetModal();
  }, [dispatch, groupId, handleResetModal, selectedStudent]);

  const handleChangeSearchValue = useCallback((value: string) => {
    setSearchValue(value);
  }, []);

  const handleChangeSchoolYears = useCallback((value: string[]) => {
    setSchoolYears(value);
  }, []);

  const handleChangeGroups = useCallback((value: string[]) => {
    setGroups(value);
  }, []);

  useEffect(() => {
    dispatch(
      getStudentsToAddAction({ id: Number(groupId), name: searchValue, groups, schoolYears }),
    );
  }, [dispatch, groupId, groups, schoolYears, searchValue]);

  useEffect(() => {
    if (!groupStudentVisible) {
      handleResetModal();
    }
  }, [groupStudentVisible, handleResetModal]);

  return (
    <Modal
      width={800}
      centered
      okText='Hinzufügen'
      cancelText='Abbrechen'
      open={groupStudentVisible}
      title='Schüler/Schülerinnen'
      onOk={handleAddGroupStudent}
      onCancel={handleResetModal}
      destroyOnClose
      okDisabled={!selectedStudent.length}
    >
      <Row className={styles.searchWrapper}>
        <GroupStudentFilters
          schoolYears={schoolYears}
          groups={groups}
          handleChangeSchoolYears={handleChangeSchoolYears}
          handleChangeGroup={handleChangeGroups}
          handleChangeSearchValue={handleChangeSearchValue}
          searchValue={searchValue}
        />
      </Row>
      <Table
        rowSelection={{
          type: 'checkbox',
          onChange: (selectedRowKeys: Array<number>) => setSelectedStudents(selectedRowKeys),
          getCheckboxProps: (record) => ({
            disabled: !!record?.coreGroup?.id && groupType === 'CORE_GROUP',
          }),
          preserveSelectedRowKeys: true,
        }}
        scroll={{ y: 400 }}
        rowKey={(record) => record.id}
        loading={{ indicator: <Spinner />, spinning: false }}
        pagination={false}
        dataSource={studentsToAddData}
        columns={columns}
        rowClassName={(record) =>
          record?.coreGroup?.id && groupType === 'CORE_GROUP' ? 'disabled-row' : ''
        }
      />
    </Modal>
  );
};

export default AddGroupStudentModal;
