import { all, call, put, takeLatest } from 'redux-saga/effects';
import {
  createGroupParamTypes,
  groupsResponse,
  getGroupsActionSuccess,
  groupDetailsParamTypes,
  getGroupDetailsActionSuccess,
  groupDetailsResponseType,
  getGroupsStudentsActionSuccess,
  groupStudentsResponseType,
  getGroupStudentsParamTypes,
  groupSubjectsResponseType,
  groupTeachersResponseType,
  getGroupsTeachersActionSuccess,
  getGroupsSubjectsActionSuccess,
  getSubjectsToAddActionSuccess,
  getSubjectsToAddResponseTypes,
  addSubjectToGroupParamTypes,
  getTeachersToAddActionSuccess,
  getTeachersToAddResponseTypes,
  addTeacherToGroupParamTypes,
  getGroupsTeachersAction,
  getStudentsToAddActionSuccess,
  addStudentToGroupParamTypes,
  getGroupsStudentsAction,
  getAllGroupsActionSuccess,
  allGroupsResponse,
  getStudentsToAddAction,
  getSubjectsToAddAction,
  removeStudentFromGroupParamTypes,
  createGroupSubjectSummaryParamTypes,
  getGroupsParamTypes,
  getGroupAllSubjectsParamTypes,
  getGroupsAllSubjectsActionSuccess,
  groupAllSubjectsTypes,
  getGroupItemsParamTypes,
  getGroupItemsResponseTypes,
  getGroupItemsActionSuccess,
  getGroupSubItemsParamTypes,
  getGroupSubItemsResponseTypes,
  getGroupSubItemsActionSuccess,
  createGroupObservationParamTypes,
  removeSubjectFromGroupParamTypes,
  getGroupsSubjectsAction,
  getGroupStudentsToAddParamTypes,
  removeTeacherFromGroupParamTypes,
  editGroupParamTypes,
  getGroupsAction,
  removeGroupParamTypes,
} from 'store/actions/group.actions';
import {
  addStudentToGroupService,
  addSubjectToGroupService,
  addTeacherToGroupService,
  createGroupObservationService,
  createGroupService,
  createGroupSubjectSummaryService,
  editGroupService,
  getAllGroupsService,
  getGroupAllSubjectsService,
  getGroupDetailsService,
  getGroupItemsService,
  getGroupsService,
  getGroupStudentsService,
  getGroupSubItemsService,
  getGroupSubjectsService,
  getGroupTeachersService,
  getStudentsToAddService,
  getSubjectsToAddService,
  getTeachersToAddService,
  removeGroupService,
  removeStudentFromGroupService,
  removeSubjectFromGroupService,
  removeTeacherFromGroupService,
} from 'store/services/group.services';
import { ActionNames } from 'store/actions/actionNames';

function* watchGetGroups(action: { params: getGroupsParamTypes; type: string }) {
  try {
    const response: groupsResponse = yield call(...getGroupsService(action.params));
    yield put(getGroupsActionSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    console.log('Can not get groups');
  }
}

function* watchGetAllGroups() {
  try {
    const response: allGroupsResponse = yield call(...getAllGroupsService());
    yield put(getAllGroupsActionSuccess(response));
  } catch (e) {
    console.log('Can not get groups');
  }
}

function* watchGetGroupDetails(action: { params: groupDetailsParamTypes; type: string }) {
  try {
    const response: groupDetailsResponseType = yield call(...getGroupDetailsService(action.params));
    yield put(getGroupDetailsActionSuccess(response));
  } catch (e) {
    console.log('Can not get group details');
  }
}

function* watchCreateGroup(action: { params: createGroupParamTypes; type: string }) {
  try {
    yield call(...createGroupService(action.params));
    yield put(
      getGroupsAction({ page: action.params.page, size: 10, showOnlyTeacherGroups: false }),
    );
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    if (action.params.onFailure) {
      yield call(() => action.params.onFailure(e.response.data.groupCreateRequest));
    }
    console.log('Can not create group');
  }
}

function* watchEditGroup(action: { params: editGroupParamTypes; type: string }) {
  try {
    yield call(...editGroupService(action.params));
    yield put(
      getGroupsAction({ page: action.params.page, size: 10, showOnlyTeacherGroups: false }),
    );
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    if (action.params.onFailure) {
      yield call(() => action.params.onFailure(e.response.data.groupUpdateRequest));
    }
    console.log('Can not update group');
  }
}

function* watchRemoveGroup(action: { params: removeGroupParamTypes; type: string }) {
  try {
    yield call(...removeGroupService(action.params));
    yield put(
      getGroupsAction({
        page: action.params.page,
        size: 10,
        showOnlyTeacherGroups: action.params.showTeachers,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not remove group');
  }
}

function* watchGetGroupStudents(action: { params: getGroupStudentsParamTypes; type: string }) {
  try {
    const response: groupStudentsResponseType = yield call(
      ...getGroupStudentsService(action.params),
    );
    yield put(getGroupsStudentsActionSuccess(response));
  } catch (e) {
    console.log('Can not get group students');
  }
}

function* watchGetGroupTeachers(action: { params: getGroupStudentsParamTypes; type: string }) {
  try {
    const response: groupTeachersResponseType = yield call(
      ...getGroupTeachersService(action.params),
    );
    yield put(getGroupsTeachersActionSuccess(response));
  } catch (e) {
    console.log('Can not get group teachers');
  }
}

function* watchGetGroupSubjects(action: { params: getGroupStudentsParamTypes; type: string }) {
  try {
    const response: groupSubjectsResponseType = yield call(
      ...getGroupSubjectsService(action.params),
    );
    yield put(getGroupsSubjectsActionSuccess(response));
  } catch (e) {
    console.log('Can not get group subjects');
  }
}

function* watchGetSubjectsToAdd(action: { params: getGroupStudentsParamTypes; type: string }) {
  try {
    const response: getSubjectsToAddResponseTypes = yield call(
      ...getSubjectsToAddService(action.params),
    );
    yield put(getSubjectsToAddActionSuccess(response));
  } catch (e) {
    console.log('Can not get subjects to add');
  }
}

function* watchAddSubjectToGroup(action: { params: addSubjectToGroupParamTypes; type: string }) {
  try {
    yield call(...addSubjectToGroupService(action.params));
    yield put(getGroupsSubjectsAction({ id: action.params.id, page: 0 }));
    yield put(getSubjectsToAddAction({ id: action.params.id }));
  } catch (e) {
    console.log('Can not add subject to group');
  }
}

function* watchRemoveSubjectFromGroup(action: {
  params: removeSubjectFromGroupParamTypes;
  type: string;
}) {
  try {
    yield call(...removeSubjectFromGroupService(action.params));
    yield put(getGroupsSubjectsAction({ id: action.params.groupId, page: 0 }));
    yield put(getSubjectsToAddAction({ id: action.params.groupId }));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not add student to group');
  }
}

function* watchGetTeachersToAdd(action: { params: getGroupStudentsParamTypes; type: string }) {
  try {
    const response: getTeachersToAddResponseTypes = yield call(
      ...getTeachersToAddService(action.params),
    );
    yield put(getTeachersToAddActionSuccess(response));
  } catch (e) {
    console.log('Can not get teachers to add');
  }
}

function* watchAddTeacherToGroup(action: { params: addTeacherToGroupParamTypes; type: string }) {
  try {
    yield call(...addTeacherToGroupService(action.params));
    yield put(getGroupsTeachersAction({ id: action.params.groupId, page: 0 }));
    yield put(getGroupsSubjectsAction({ id: action.params.groupId, page: 0 }));
    const response: getTeachersToAddResponseTypes = yield call(
      ...getTeachersToAddService({ id: action.params.groupId, page: 0 }),
    );
    yield put(getTeachersToAddActionSuccess(response));
  } catch (e) {
    console.log('Can not add teacher to group');
  }
}

function* watchGetStudentsToAdd(action: { params: getGroupStudentsToAddParamTypes; type: string }) {
  try {
    const response: getTeachersToAddResponseTypes = yield call(
      ...getStudentsToAddService(action.params),
    );
    yield put(getStudentsToAddActionSuccess(response));
  } catch (e) {
    console.log('Can not get students to add');
  }
}

function* watchAddStudentToGroup(action: { params: addStudentToGroupParamTypes; type: string }) {
  try {
    yield call(...addStudentToGroupService(action.params));
    yield put(getGroupsStudentsAction({ id: action.params.id, page: 0 }));
    yield put(getStudentsToAddAction({ id: action.params.id, schoolYears: [], groups: [] }));
  } catch (e) {
    console.log('Can not add student to group');
  }
}

function* watchRemoveStudentFromGroup(action: {
  params: removeStudentFromGroupParamTypes;
  type: string;
}) {
  try {
    yield call(...removeStudentFromGroupService(action.params));
    yield put(getGroupsStudentsAction({ id: action.params.groupId, page: 0 }));
    const response: getTeachersToAddResponseTypes = yield call(
      ...getStudentsToAddService({ id: action.params.groupId, groups: [], schoolYears: [] }),
    );
    yield put(getStudentsToAddActionSuccess(response));
  } catch (e) {
    console.log('Can not add student to group');
  }
}

function* watchRemoveTeacherFromGroup(action: {
  params: removeTeacherFromGroupParamTypes;
  type: string;
}) {
  try {
    yield call(...removeTeacherFromGroupService(action.params));
    yield put(getGroupsTeachersAction({ id: action.params.groupId, page: 0 }));
    const response: getTeachersToAddResponseTypes = yield call(
      ...getTeachersToAddService({ id: action.params.groupId, page: 0 }),
    );
    yield put(getTeachersToAddActionSuccess(response));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not add student to group');
  }
}

function* watchAddGroupSubjectSummary(action: {
  params: createGroupSubjectSummaryParamTypes;
  type: string;
}) {
  try {
    yield call(...createGroupSubjectSummaryService(action.params));
    const response: groupSubjectsResponseType = yield call(
      ...getGroupSubjectsService({ id: action.params.groupId, page: 0 }),
    );
    yield put(getGroupsSubjectsActionSuccess(response));
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not add student to group');
  }
}

function* watchGroupAllSubjects(action: { params: getGroupAllSubjectsParamTypes; type: string }) {
  try {
    const response: groupAllSubjectsTypes = yield call(
      ...getGroupAllSubjectsService(action.params),
    );
    yield put(getGroupsAllSubjectsActionSuccess(response));
  } catch (e) {
    console.log('Can not get all subjects');
  }
}

function* watchGroupItems(action: { params: getGroupItemsParamTypes; type: string }) {
  try {
    const response: getGroupItemsResponseTypes = yield call(...getGroupItemsService(action.params));
    yield put(getGroupItemsActionSuccess(response));
  } catch (e) {
    console.log('Can not get all subjects');
  }
}

function* watchGroupSubItems(action: { params: getGroupSubItemsParamTypes; type: string }) {
  try {
    const response: getGroupSubItemsResponseTypes = yield call(
      ...getGroupSubItemsService(action.params),
    );
    yield put(getGroupSubItemsActionSuccess(response));
  } catch (e) {
    console.log('Can not get all subjects');
  }
}

function* watchCreateGroupObservation(action: {
  params: createGroupObservationParamTypes;
  type: string;
}) {
  try {
    yield call(...createGroupObservationService(action.params));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    console.log('Can not get all subjects');
  }
}

function* groupSaga() {
  yield all([
    takeLatest(ActionNames.GET_GROUPS, watchGetGroups),
    takeLatest(ActionNames.GET_ALL_GROUPS, watchGetAllGroups),
    takeLatest(ActionNames.CREATE_GROUP, watchCreateGroup),
    takeLatest(ActionNames.EDIT_GROUP, watchEditGroup),
    takeLatest(ActionNames.REMOVE_GROUP, watchRemoveGroup),
    takeLatest(ActionNames.GET_GROUP_DETAILS, watchGetGroupDetails),
    takeLatest(ActionNames.GET_GROUPS_STUDENTS, watchGetGroupStudents),
    takeLatest(ActionNames.GET_GROUPS_SUBJECTS, watchGetGroupSubjects),
    takeLatest(ActionNames.GET_GROUPS_TEACHERS, watchGetGroupTeachers),
    takeLatest(ActionNames.GET_SUBJECTS_TO_ADD, watchGetSubjectsToAdd),
    takeLatest(ActionNames.ADD_SUBJECTS_TO_GROUP, watchAddSubjectToGroup),
    takeLatest(ActionNames.REMOVE_SUBJECT_FROM_GROUP, watchRemoveSubjectFromGroup),
    takeLatest(ActionNames.GET_TEACHERS_TO_ADD, watchGetTeachersToAdd),
    takeLatest(ActionNames.ADD_TEACHER_TO_GROUP, watchAddTeacherToGroup),
    takeLatest(ActionNames.GET_STUDENTS_TO_ADD, watchGetStudentsToAdd),
    takeLatest(ActionNames.ADD_STUDENT_TO_GROUP, watchAddStudentToGroup),
    takeLatest(ActionNames.REMOVE_STUDENT_FROM_GROUP, watchRemoveStudentFromGroup),
    takeLatest(ActionNames.CREATE_GROUP_SUBJECT_SUMMARY, watchAddGroupSubjectSummary),
    takeLatest(ActionNames.GET_GROUP_ALL_SUBJECTS, watchGroupAllSubjects),
    takeLatest(ActionNames.GET_GROUP_ITEMS, watchGroupItems),
    takeLatest(ActionNames.GET_GROUP_SUB_ITEMS, watchGroupSubItems),
    takeLatest(ActionNames.CREATE_GROUP_OBSERVATION, watchCreateGroupObservation),
    takeLatest(ActionNames.REMOVE_TEACHER_FROM_GROUP, watchRemoveTeacherFromGroup),
  ]);
}

export default groupSaga;
