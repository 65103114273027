import { Checkbox as AntdCheckbox, ConfigProvider } from 'antd';
import { primary40 } from '../../colors';

const Checkbox = ({ ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <AntdCheckbox {...props} />
    </ConfigProvider>
  );
};

const Group = ({ ...props }) => {
  return (
    <ConfigProvider
      theme={{
        token: {
          colorPrimary: primary40,
        },
      }}
    >
      <AntdCheckbox.Group {...props} />
    </ConfigProvider>
  );
};

Checkbox.Group = Group;

export default Checkbox;
