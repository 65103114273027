import { client } from 'store/services/client';
import { ApiCall, ApiCallWithParams } from 'store/services/services.types';
import {
  createSubjectParamTypes,
  getSubjectsParamTypes,
  reorderSubjectsParamTypes,
} from 'store/actions/subjects.actions';

export const getSubjectsService = (params: getSubjectsParamTypes): [ApiCall, string] => [
  client.get,
  `/subjects?page=${params.page}&size=10${params.name || ''}`,
];

export const createSubjectService = (
  params: createSubjectParamTypes,
): [ApiCallWithParams, string, any] => [
  client.post,
  '/subjects',
  {
    name: params.name,
    optional: params.optional,
    description: params.description,
    displayTextAfterContent: params.displayTextBeforeContent,
  },
];

export const editSubjectService = (
  params: createSubjectParamTypes,
): [ApiCallWithParams, string, any] => [
  client.put,
  `/subjects/${params.id}`,
  {
    name: params.name,
    optional: params.optional,
    description: params.description,
    displayTextAfterContent: params.displayTextBeforeContent,
    id: params.id,
  },
];

export const reorderSubjectsService = (
  params: reorderSubjectsParamTypes,
): [ApiCallWithParams, string, number[]] => [client.post, '/subjects/order', params.subjectIds];
