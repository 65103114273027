import { notification } from 'antd';
import { call, all, put, takeLatest } from 'redux-saga/effects';
import { ActionNames } from 'store/actions/actionNames';
import {
  activateStudentIzelService,
  assignItemsService,
  assignSubItemsService,
  assignSubjectService,
  createStudentService,
  createSubjectSummaryService,
  deActivateStudentIzelService,
  deactivateStudentService,
  getItemsToAssignService,
  getReportListService,
  getStudentInfoService,
  getStudentsReportService,
  getStudentsService,
  getSubItemsToAssignService,
  getSubjectsToAssignService,
  grantAccessService,
  reactivateStudentService,
  reassignItemService,
  reassignSubItemService,
  reassignSubjectService,
  removeAccessService,
  resendLinkService,
  selfEvaluateService,
  updateStudentService,
} from 'store/services/students.services';
import {
  activateIzelActionParamTtypes,
  assignItemsParamTypes,
  assignSubItemsParamTypes,
  assignSubjectParamTypes,
  createStudentParamTypes,
  createSubjectSummaryParamTypes,
  deactivateStudentParamTypes,
  getItemsToAssignActionSuccess,
  getItemsToAssignParamTypes,
  getItemsToAssignResponseTypes,
  getReportListAction,
  getReportListActionSuccess,
  getReportListParamTypes,
  getReportListResponseTypes,
  getStudentEvaluationSuccess,
  getStudentIfoResponseTypes,
  getStudentInfoAction,
  getStudentInfoActionSuccess,
  getStudentInfoParamTypes,
  getStudentReportActionSuccess,
  getStudentReportParamTypes,
  getStudentReportResponseTypes,
  getStudentsAction,
  getStudentsActionSuccess,
  getStudentsParams,
  getSubItemsToAssignActionSuccess,
  getSubItemsToAssignParamTypes,
  getSubItemsToAssignResponseTypes,
  getSubjectsToAssignActionSuccess,
  getSubjectsToAssignParamTypes,
  getSubjectsToAssignResponseTypes,
  grantAccessActionParamTypes,
  reassignItemParamTypes,
  reassignSubItemParamTypes,
  reassignSubjectParamTypes,
  selfEvaluateParamTypes,
  studentsResponse,
  updateStudentParamTypes,
} from 'store/actions/students.actions';
import { evaluateSubItemActionSuccess, getReportAction } from 'store/actions/report.actions';
import AlertCheckIcon from '../../UI/Icons/AlertCheck';

function* watchGetStudents(action: { params: getStudentsParams; type: string }) {
  try {
    const response: studentsResponse = yield call(...getStudentsService(action.params));
    yield put(getStudentsActionSuccess(response));
    if (action.params.onSuccess) {
      yield call(action.params.onSuccess);
    }
  } catch (e) {
    console.log('Can not get students');
  }
}

function* watchCreateStudent(action: { params: createStudentParamTypes; type: string }) {
  try {
    yield call(
      ...createStudentService({
        name: action.params.name,
        surname: action.params.surname,
        gender: action.params.gender,
        schoolYear: action.params.schoolYear,
        birthDate: action.params.birthDate,
        email: action.params.email,
      }),
    );
    const response: studentsResponse = yield call(
      ...getStudentsService({
        ...action.params,
        name: action.params.searchValue,
      }),
    );
    yield put(getStudentsActionSuccess(response));
    yield call(action.params.onSuccess);
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.createStudentRequest));
    console.log('cannot create student');
  }
}

function* watchUpdateStudent(action: { params: updateStudentParamTypes; type: string }) {
  try {
    const response: { data: { leftSchool: boolean } } = yield call(
      ...updateStudentService(action.params),
    );
    yield put(getStudentInfoAction({ studentId: action.params.id }));
    yield call(() => action.params.onSuccess(response?.data?.leftSchool));
  } catch (e) {
    yield call(() => action.params.onFailure(e.response.data.studentSaveRequest));
    console.log('Can not get students');
  }
}

function* watchGetSubjectsToAssign(action: {
  params: getSubjectsToAssignParamTypes;
  type: string;
}) {
  try {
    const response: getSubjectsToAssignResponseTypes = yield call(
      ...getSubjectsToAssignService(action.params),
    );
    yield put(getSubjectsToAssignActionSuccess(response));
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchGetItemsToAssign(action: { params: getItemsToAssignParamTypes; type: string }) {
  try {
    const response: getItemsToAssignResponseTypes = yield call(
      ...getItemsToAssignService(action.params),
    );
    yield put(getItemsToAssignActionSuccess(response));
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchGetSubItemsToAssign(action: {
  params: getSubItemsToAssignParamTypes;
  type: string;
}) {
  try {
    const response: getSubItemsToAssignResponseTypes = yield call(
      ...getSubItemsToAssignService(action.params),
    );
    yield put(getSubItemsToAssignActionSuccess(response));
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchAssignSubject(action: { params: assignSubjectParamTypes; type: string }) {
  try {
    yield call(...assignSubjectService(action.params));
    const response: getSubjectsToAssignResponseTypes = yield call(
      ...getSubjectsToAssignService(action.params),
    );
    yield put(getSubjectsToAssignActionSuccess(response));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: action.params.page,
        size: 10,
        showActualData: true,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchAssignItems(action: { params: assignItemsParamTypes; type: string }) {
  try {
    yield call(...assignItemsService(action.params));
    const response: getItemsToAssignResponseTypes = yield call(
      ...getItemsToAssignService(action.params),
    );
    yield put(getItemsToAssignActionSuccess(response));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: action.params.page,
        size: 10,
        showActualData: true,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchAssignSubItems(action: { params: assignSubItemsParamTypes; type: string }) {
  try {
    yield call(...assignSubItemsService(action.params));
    const response: getSubItemsToAssignResponseTypes = yield call(
      ...getSubItemsToAssignService(action.params),
    );
    yield put(getSubItemsToAssignActionSuccess(response));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: action.params.page,
        size: 10,
        showActualData: true,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchReAssignSubject(action: { params: reassignSubjectParamTypes; type: string }) {
  try {
    yield call(...reassignSubjectService(action.params));
    const response: getSubjectsToAssignResponseTypes = yield call(
      ...getSubjectsToAssignService(action.params),
    );
    yield put(getSubjectsToAssignActionSuccess(response));
    yield put(
      getReportAction({ id: action.params.studentId, page: 0, size: 10, showActualData: true }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchReAssignItem(action: { params: reassignItemParamTypes; type: string }) {
  try {
    yield call(...reassignItemService(action.params));
    const response: getItemsToAssignResponseTypes = yield call(
      ...getItemsToAssignService(action.params),
    );
    yield put(getItemsToAssignActionSuccess(response));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: action.params.page,
        size: 10,
        showActualData: true,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchReAssignSubItem(action: { params: reassignSubItemParamTypes; type: string }) {
  try {
    yield call(...reassignSubItemService(action.params));
    const response: getSubItemsToAssignResponseTypes = yield call(
      ...getSubItemsToAssignService(action.params),
    );
    yield put(getSubItemsToAssignActionSuccess(response));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: action.params.page,
        size: 10,
        showActualData: true,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchGetStudentInfo(action: { params: getStudentInfoParamTypes; type: string }) {
  try {
    const response: getStudentIfoResponseTypes = yield call(
      ...getStudentInfoService(action.params),
    );
    yield put(getStudentInfoActionSuccess(response));
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchGetStudentReport(action: { params: getStudentReportParamTypes; type: string }) {
  try {
    const response: getStudentReportResponseTypes = yield call(
      ...getStudentsReportService(action.params),
    );
    yield put(getStudentReportActionSuccess(response));
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchSelfEvaluate(action: { params: selfEvaluateParamTypes; type: string }) {
  try {
    const response: getStudentReportResponseTypes = yield call(
      ...selfEvaluateService(action.params),
    );
    if (action.params.studentId) {
      yield put(
        evaluateSubItemActionSuccess({
          ...response,
          self: true,
          subItemId: action.params.subItemId,
          scaleId: action.params.assessmentScaleValueId,
        }),
      );
    } else {
      yield put(
        getStudentEvaluationSuccess({
          ...response,
          subItemId: action.params.subItemId,
          scaleId: action.params.assessmentScaleValueId,
        }),
      );
    }
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot evaluate student');
  }
}

function* watchCreateSubjectSummary(action: {
  params: createSubjectSummaryParamTypes;
  type: string;
}) {
  try {
    yield call(...createSubjectSummaryService(action.params));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: action.params.page,
        size: 10,
        izelId: action.params.izelId,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('cannot create student');
  }
}

function* watchDeactivateStudent(action: { params: deactivateStudentParamTypes; type: string }) {
  try {
    yield call(...deactivateStudentService(action.params));
    yield put(
      getStudentsAction({
        groupIds: action.params.groupIds,
        schoolYears: action.params.schoolYears,
        active: action.params.active,
        page: action.params.page,
        size: 10,
        name: action.params.name,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchReactivateStudent(action: { params: deactivateStudentParamTypes; type: string }) {
  try {
    yield call(...reactivateStudentService(action.params));
    yield put(
      getStudentsAction({
        groupIds: action.params.groupIds,
        schoolYears: action.params.schoolYears,
        active: action.params.active,
        page: action.params.page,
        size: 10,
        name: action.params.name,
      }),
    );
    yield call(action.params.onSuccess);
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchGrantAccess(action: { params: grantAccessActionParamTypes; type: string }) {
  try {
    yield call(...grantAccessService(action.params));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: 0,
        size: 10,
      }),
    );
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchRemoveAccess(action: { params: grantAccessActionParamTypes; type: string }) {
  try {
    yield call(...removeAccessService(action.params));
    yield put(
      getReportAction({
        id: action.params.studentId,
        page: 0,
        size: 10,
      }),
    );
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchResendLink(action: { params: grantAccessActionParamTypes; type: string }) {
  try {
    yield call(...resendLinkService(action.params));
    yield notification.success({
      message: 'Link ist erfolgreich an die Schülerin/den Schüler gesendet',
      duration: 3,
      icon: <AlertCheckIcon />,
    });
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchGetReportList(action: { params: getReportListParamTypes; type: string }) {
  try {
    const response: getReportListResponseTypes = yield call(...getReportListService(action.params));
    yield put(getReportListActionSuccess(response));
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchActivateStudentIZel(action: {
  params: activateIzelActionParamTtypes;
  type: string;
}) {
  try {
    yield call(...activateStudentIzelService(action.params));
    yield put(getReportListAction(action.params));
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* watchDeActivateStudentIZel(action: {
  params: activateIzelActionParamTtypes;
  type: string;
}) {
  try {
    yield call(...deActivateStudentIzelService(action.params));
    yield put(getReportListAction(action.params));
  } catch (e) {
    console.log('Can not create teacher');
  }
}

function* studentsSaga() {
  yield all([takeLatest(ActionNames.GET_STUDENTS, watchGetStudents)]);
  yield all([takeLatest(ActionNames.CREATE_STUDENT, watchCreateStudent)]);
  yield all([takeLatest(ActionNames.UPDATE_STUDENT, watchUpdateStudent)]);
  yield all([takeLatest(ActionNames.GET_SUBJECTS_TO_ASSIGN, watchGetSubjectsToAssign)]);
  yield all([takeLatest(ActionNames.GET_ITEMS_TO_ASSIGN, watchGetItemsToAssign)]);
  yield all([takeLatest(ActionNames.GET_SUB_ITEMS_TO_ASSIGN, watchGetSubItemsToAssign)]);
  yield all([takeLatest(ActionNames.ASSIGN_SUBJECTS, watchAssignSubject)]);
  yield all([takeLatest(ActionNames.ASSIGN_ITEMS, watchAssignItems)]);
  yield all([takeLatest(ActionNames.ASSIGN_SUB_ITEMS, watchAssignSubItems)]);
  yield all([takeLatest(ActionNames.RE_ASSIGN_SUBJECTS, watchReAssignSubject)]);
  yield all([takeLatest(ActionNames.RE_ASSIGN_ITEMS, watchReAssignItem)]);
  yield all([takeLatest(ActionNames.RE_ASSIGN_SUB_ITEMS, watchReAssignSubItem)]);
  yield all([takeLatest(ActionNames.GET_STUDENT_INFO, watchGetStudentInfo)]);
  yield all([takeLatest(ActionNames.GET_STUDENT_REPORT, watchGetStudentReport)]);
  yield all([takeLatest(ActionNames.SELF_EVALUATE, watchSelfEvaluate)]);
  yield all([takeLatest(ActionNames.CREATE_SUBJECT_SUMMARY, watchCreateSubjectSummary)]);
  yield all([takeLatest(ActionNames.DEACTIVATE_STUDENT, watchDeactivateStudent)]);
  yield all([takeLatest(ActionNames.REACTIVATE_STUDENT, watchReactivateStudent)]);
  yield all([takeLatest(ActionNames.GRANT_ACCESS, watchGrantAccess)]);
  yield all([takeLatest(ActionNames.REMOVE_ACCESS, watchRemoveAccess)]);
  yield all([takeLatest(ActionNames.RESEND_LINK, watchResendLink)]);
  yield all([takeLatest(ActionNames.GET_REPORT_LIST, watchGetReportList)]);
  yield all([takeLatest(ActionNames.ACTIVATE_STUDENT_IZEL, watchActivateStudentIZel)]);
  yield all([takeLatest(ActionNames.DE_ACTIVATE_STUDENT_IZEL, watchDeActivateStudentIZel)]);
}

export default studentsSaga;
